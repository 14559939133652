import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {
        orderProductItems: [],
        paginateLinks: {},
        orderProductItem: {},
    },

    getters: {
        orderProductItems: (state) => {
            return state.orderProductItems;
        },

        paginateLinks: (state) => {
            return state.paginateLinks;
        },

        orderProductItem: (state) => {
            return state.orderProductItem;
        },
    },
    mutations: {
        SET_ORDER_PRODUCT_ITEMS(state, orderProductItems) {
            state.orderProductItems = orderProductItems;
        },

        SET_PAGINATE_LINKS(state, paginateLinks) {
            state.paginateLinks = paginateLinks;
        },

        SET_ORDER_PRODUCT_ITEM(state, orderProductItem) {
            state.orderProductItem = orderProductItem;
        },

        CREATE_ORDER_PRODUCT_ITEM_ON_LIST(state, orderProductItem) {
            state.orderProductItems.unshift(orderProductItem);
        },

        CREATE_ORDER_PRODUCT_ITEM(state, orderProductItem) {
            state.orderProductItem = orderProductItem;
        },

        UPDATE_ORDER_PRODUCT_ITEM_ON_LIST(state, orderProductItem) {
            let index = state.orderProductItems.findIndex(item => item.id === orderProductItem.id);
            state.orderProductItems.splice(index, 1, orderProductItem);
        },

        UPDATE_ORDER_PRODUCT_ITEM(state, orderProductItem) {
            state.orderProductItem = orderProductItem;
        },

        DELETE_ORDER_PRODUCT_ITEM_ON_LIST(state, orderProductItemId) {
            let index = state.orderProductItems.findIndex(item => item.id === orderProductItemId);
            state.orderProductItems.splice(index, 1);
        },

        DELETE_ORDER_PRODUCT_ITEM(state) {
            state.orderProductItem = {};
        },

        RESET_ORDER_PRODUCT_ITEMS(state) {
            state.orderProductItems = [];
        },

        RESET_ORDER_PRODUCT_ITEM(state) {
            state.orderProductItem = {};
        },
    },
    actions: {
        commitSetOrderProductItems: async ({commit}, responseData) => {
            await commit('SET_ORDER_PRODUCT_ITEMS', responseData.data);
        },

        commitSetPaginateLinks: async ({commit}, responseData) => {
            await commit('SET_PAGINATE_LINKS', responseData.meta);
        },

        commitSetOrderProductItem: async ({commit}, responseData) => {
            await commit('SET_ORDER_PRODUCT_ITEM', responseData.data)
        },

        commitCreateOrderProductItemOnList: async ({commit}, responseData) => {
            await commit('CREATE_ORDER_PRODUCT_ITEM_ON_LIST', responseData.data);
        },

        commitCreateOrderProductItem: async ({commit}, responseData) => {
            await commit('CREATE_ORDER_PRODUCT_ITEM', responseData.data);
        },

        commitUpdateOrderProductItemOnList: async ({commit}, responseData) => {
            await commit('UPDATE_ORDER_PRODUCT_ITEM_ON_LIST', responseData.data);
        },

        commitUpdateOrderProductItem: async ({commit}, responseData) => {
            await commit('UPDATE_ORDER_PRODUCT_ITEM', responseData.data);
        },

        commitDeleteOrderProductItemOnList: async ({commit}, orderProductItemId) => {
            await commit('DELETE_ORDER_PRODUCT_ITEM_ON_LIST', orderProductItemId);
        },

        commitDeleteOrderProductItem: async ({commit}) => {
            await commit('DELETE_ORDER_PRODUCT_ITEM');
        },

        commitResetOrderProductItems: async ({commit}) => {
            await commit('RESET_ORDER_PRODUCT_ITEMS');
        },

        commitResetOrderProductItem: async ({commit}) => {
            await commit('RESET_ORDER_PRODUCT_ITEM');
        },

        // get list of orderProductItem by api call.
        getOrderProductItems: async ({dispatch}, params = {}) => {
            const path = `v1/order-product-items`;

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetOrderProductItems', response.data);
                dispatch('commitSetPaginateLinks', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // get single orderProductItem by api call.
        getOrderProductItem: async ({dispatch}, paramObj) => {
            const path = `v1/order-product-items/${paramObj.id}`;
            const params = paramObj.params ?? {};

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetOrderProductItem', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // create new orderProductItem on list by api call.
        postOrderProductItemOnList: async ({dispatch}, data) => {
            const path = `v1/order-product-items`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateOrderProductItemOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // create new orderProductItem by api call.
        postOrderProductItem: async ({dispatch}, data) => {
            const path = `v1/order-product-items`;

            return axios.post(path, data).then((response) => {

                dispatch('commitCreateOrderProductItem', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing orderProductItem on list by api call.
        putOrderProductItemOnList: async ({dispatch}, dataObj) => {
            const path = `v1/order-product-items/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateOrderProductItemOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing orderProductItem by api call.
        putOrderProductItem: async ({dispatch}, dataObj) => {
            const path = `v1/order-product-items/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateOrderProductItem', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular orderProductItem on list by api call.
        deleteOrderProductItemOnList: async ({dispatch}, id) => {
            const path = `v1/order-product-items/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteOrderProductItemOnList', id);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular orderProductItem by api call.
        deleteOrderProductItem: async ({dispatch}, id) => {
            const path = `v1/order-product-items/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteOrderProductItem', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },
        postOrderProductItemStoreList: async ({dispatch}, data) => {
            const path = `v1/order-product-items/store-list`;

            return axios.post(path, data).then((response) => {

                dispatch('commitCreateOrderProductItem', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },


        // reset orderProductItems state.
        resetOrderProductItems: async ({dispatch}) => {
            dispatch('commitResetOrderProductItems');
        },

        // reset orderProductItem state.
        resetOrderProductItem: async ({dispatch}) => {
            dispatch('commitResetOrderProductItem')
        },

    },
};
