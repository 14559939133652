import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {
        couponUsers: [],
        paginateLinks: {},
        couponUser: {},


    },

    getters: {
        couponUsers: (state) => {
            return state.couponUsers;
        },

        paginateLinks: (state) => {
            return state.paginateLinks;
        },

        couponUser: (state) => {
            return state.couponUser;
        },

    },
    mutations: {
        SET_COUPON_USERS(state, couponUsers) {
            state.couponUsers = couponUsers;
        },


        SET_PAGINATE_LINKS(state, paginateLinks) {
            state.paginateLinks = paginateLinks;
        },

        SET_COUPON_USER(state, couponUser) {
            state.couponUser = couponUser;
        },

        CREATE_COUPON_USER_ON_LIST(state, couponUser) {
            state.couponUsers.unshift(couponUser);
        },

        CREATE_COUPON_USER(state, couponUser) {
            state.couponUser = couponUser;
        },

        UPDATE_COUPON_USER_ON_LIST(state, couponUser) {
            let index = state.couponUsers.findIndex(item => item.id === couponUser.id);
            state.couponUsers.splice(index, 1, couponUser);
        },

        UPDATE_COUPON_USER(state, couponUser) {
            state.couponUser = couponUser;
        },

        DELETE_COUPON_USER_ON_LIST(state, couponUserId) {
            let index = state.couponUsers.findIndex(item => item.id === couponUserId);
            state.couponUsers.splice(index, 1);
        },

        DELETE_COUPON_USER(state) {
            state.couponUser = {};
        },

        RESET_COUPON_USERS(state) {
            state.couponUsers = [];
        },

        RESET_COUPON_USER(state) {
            state.couponUser = {};
        },
    },
    actions: {
        commitSetCouponUsers: async ({commit}, responseData) => {
            await commit('SET_COUPON_USERS', responseData.data);
        },

        commitSetPaginateLinks: async ({commit}, responseData) => {
            await commit('SET_PAGINATE_LINKS', responseData.meta);
        },

        commitSetCouponUser: async ({commit}, responseData) => {
            await commit('SET_COUPON_USER', responseData.data)
        },

        commitCreateCouponUserOnList: async ({commit}, responseData) => {
            await commit('CREATE_COUPON_USER_ON_LIST', responseData.data);
        },

        commitCreateCouponUser: async ({commit}, responseData) => {
            await commit('CREATE_COUPON_USER', responseData.data);
        },

        commitUpdateCouponUserOnList: async ({commit}, responseData) => {
            await commit('UPDATE_COUPON_USER_ON_LIST', responseData.data);
        },

        commitUpdateCouponUser: async ({commit}, responseData) => {
            await commit('UPDATE_COUPON_USER', responseData.data);
        },

        commitDeleteCouponUserOnList: async ({commit}, couponUserId) => {
            await commit('DELETE_COUPON_USER_ON_LIST', couponUserId);
        },

        commitDeleteCouponUser: async ({commit}) => {
            await commit('DELETE_COUPON_USER');
        },

        commitResetCouponUsers: async ({commit}) => {
            await commit('RESET_COUPON_USERS');
        },

        commitResetCouponUser: async ({commit}) => {
            await commit('RESET_COUPON_USER');
        },

        // get list of couponUser by api call.
        getCouponUsers: async ({dispatch}, params = {}) => {
            const path = `v1/coupon-users`;

            return axios.get(path, {params: params}).then(response => {

                dispatch('commitSetCouponUsers', response.data);
                dispatch('commitSetPaginateLinks', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },


        // get single couponUser by api call.
        getCouponUser: async ({dispatch}, paramObj) => {
            const path = `v1/coupon-users/${paramObj.id}`;
            const params = paramObj.params ?? {};

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetCouponUser', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // create new couponUser on list by api call.
        postCouponUserOnList: async ({dispatch}, data) => {
            const path = `v1/coupon-users`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateCouponUserOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // create new couponUser by api call.
        postCouponUser: async ({dispatch}, data) => {
            const path = `v1/coupon-users`;

            return axios.post(path, data).then((response) => {

                dispatch('commitCreateCouponUser', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing couponUser on list by api call.
        putCouponUserOnList: async ({dispatch}, dataObj) => {
            const path = `v1/coupon-users/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateCouponUserOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing couponUser by api call.
        putCouponUser: async ({dispatch}, dataObj) => {
            const path = `v1/coupon-users/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateCouponUser', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular couponUser on list by api call.
        deleteCouponUserOnList: async ({dispatch}, id) => {
            const path = `v1/coupon-users/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteCouponUserOnList', id);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular couponUser by api call.
        deleteCouponUser: async ({dispatch}, id) => {
            const path = `v1/coupon-users/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteCouponUser', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // reset couponUsers state.
        resetCouponUsers: async ({dispatch}) => {
            dispatch('commitResetCouponUsers');
        },

        // reset couponUser state.
        resetCouponUser: async ({dispatch}) => {
            dispatch('commitResetCouponUser')
        },

    },
};
