import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {
        productItems: [],
        paginateLinks: {},
        productItem: {},
    },

    getters: {
        productItems: (state) => {
            return state.productItems;
        },

        paginateLinks: (state) => {
            return state.paginateLinks;
        },

        productItem: (state) => {
            return state.productItem;
        },
    },
    mutations: {
        SET_PRODUCT_ITEMS(state, productItems) {
            state.productItems = productItems;
        },

        SET_PAGINATE_LINKS(state, paginateLinks) {
            state.paginateLinks = paginateLinks;
        },

        SET_PRODUCT_ITEM(state, productItem) {
            state.productItem = productItem;
        },

        CREATE_PRODUCT_ITEM_ON_LIST(state, productItem) {
            state.productItems.unshift(productItem);
        },

        CREATE_PRODUCT_ITEM(state, productItem) {
            state.productItem = productItem;
        },

        UPDATE_PRODUCT_ITEM_ON_LIST(state, productItem) {
            let index = state.productItems.findIndex(item => item.id === productItem.id);
            state.productItems.splice(index, 1, productItem);
        },

        UPDATE_PRODUCT_ITEM(state, productItem) {
            state.productItem = productItem;
        },

        DELETE_PRODUCT_ITEM_ON_LIST(state, productId) {
            let index = state.productItems.findIndex(item => item.id === productId);
            state.productItems.splice(index, 1);
        },

        DELETE_PRODUCT_ITEM(state) {
            state.productItem = {};
        },

        RESET_PRODUCT_ITEMS(state) {
            state.productItems = [];
        },

        RESET_PRODUCT_ITEM(state) {
            state.productItem = {};
        },
    },
    actions: {
        commitSetProductItems: async ({commit}, responseData) => {
            await commit('SET_PRODUCT_ITEMS', responseData.data);
        },

        commitSetPaginateLinks: async ({commit}, responseData) => {
            await commit('SET_PAGINATE_LINKS', responseData.meta);
        },

        commitSetProductItem: async ({commit}, responseData) => {
            await commit('SET_PRODUCT_ITEM', responseData.data)
        },

        commitCreateProductItemOnList: async ({commit}, responseData) => {
            await commit('CREATE_PRODUCT_ITEM_ON_LIST', responseData.data);
        },

        commitCreateProductItem: async ({commit}, responseData) => {
            await commit('CREATE_PRODUCT_ITEM', responseData.data);
        },

        commitUpdateProductItemOnList: async ({commit}, responseData) => {
            await commit('UPDATE_PRODUCT_ITEM_ON_LIST', responseData.data);
        },

        commitUpdateProductItem: async ({commit}, responseData) => {
            await commit('UPDATE_PRODUCT_ITEM', responseData.data);
        },

        commitDeleteProductItemOnList: async ({commit}, productId) => {
            await commit('DELETE_PRODUCT_ITEM_ON_LIST', productId);
        },

        commitDeleteProductItem: async ({commit}) => {
            await commit('DELETE_PRODUCT_ITEM');
        },

        commitResetProductItems: async ({commit}) => {
            await commit('RESET_PRODUCT_ITEMS');
        },

        commitResetProductItem: async ({commit}) => {
            await commit('RESET_PRODUCT_ITEM');
        },

        // get list of productItem categories by api call.
        getProductItems: async ({dispatch}, params = {}) => {
            const path = `v1/product-items`;

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetProductItems', response.data);
                dispatch('commitSetPaginateLinks', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // get single productItem category by api call.
        getProductItem: async ({dispatch}, paramObj) => {
            const path = `v1/product-items/${paramObj.id}`;
            const params = paramObj.params ?? {};

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetProductItem', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // create new productItem category on list by api call.
        postProductItemOnList: async ({dispatch}, data) => {
            const path = `v1/product-items`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateProductItemOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // create new productItem category by api call.
        postProductItem: async ({dispatch}, data) => {
            const path = `v1/product-items`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateProductItem', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing productItem category on list by api call.
        putProductItemOnList: async ({dispatch}, dataObj) => {
            const path = `v1/product-items/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateProductItemOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing productItem category by api call.
        putProductItem: async ({dispatch}, dataObj) => {
            const path = `v1/product-items/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateProductItem', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular productItem category on list by api call.
        deleteProductItemOnList: async ({dispatch}, id) => {
            const path = `v1/product-items/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteProductItemOnList', id);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular productItem category by api call.
        deleteProductItem: async ({dispatch}, id) => {
            const path = `v1/product-items/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteProductItem', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // reset productItem  state.
        resetProductItems: async ({dispatch}) => {
            dispatch('commitResetProductItems');
        },

        // reset productItem  state.
        resetProductItem: async ({dispatch}) => {
            dispatch('commitResetProductItem')
        },

    },
};
