import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {
        vendors: [],
        paginateLinks: {},
        vendor: {},
    },

    getters: {
        vendors: (state) => {
            return state.vendors;
        },

        paginateLinks: (state) => {
            return state.paginateLinks;
        },

        vendor: (state) => {
            return state.vendor;
        },
    },
    mutations: {
        SET_VENDORS(state, vendors) {
            state.vendors = vendors;
        },

        SET_PAGINATE_LINKS(state, paginateLinks) {
            state.paginateLinks = paginateLinks;
        },

        SET_VENDOR(state, vendor) {
            state.vendor = vendor;
        },

        CREATE_VENDOR_ON_LIST(state, vendor) {
            state.vendors.unshift(vendor);
        },

        CREATE_VENDOR(state, vendor) {
            state.vendor = vendor;
        },

        UPDATE_VENDOR_ON_LIST(state, vendor) {
            let index = state.vendors.findIndex(item => item.id === vendor.id);
            state.vendors.splice(index, 1, vendor);
        },

        UPDATE_VENDOR(state, vendor) {
            state.vendor = vendor;
        },

        DELETE_VENDOR_ON_LIST(state, vendorId) {
            let index = state.vendors.findIndex(item => item.id === vendorId);
            state.vendors.splice(index, 1);
        },

        DELETE_VENDOR(state) {
            state.vendor = {};
        },

        RESET_VENDORS(state) {
            state.vendors = [];
        },

        RESET_VENDOR(state) {
            state.vendor = {};
        },
    },
    actions: {
        commitSetVendors: async ({commit}, responseData) => {
            await commit('SET_VENDORS', responseData.data);
        },

        commitSetPaginateLinks: async ({commit}, responseData) => {
            await commit('SET_PAGINATE_LINKS', responseData.meta);
        },

        commitSetVendor: async ({commit}, responseData) => {
            await commit('SET_VENDOR', responseData.data)
        },

        commitCreateVendorOnList: async ({commit}, responseData) => {
            await commit('CREATE_VENDOR_ON_LIST', responseData.data);
        },

        commitCreateVendor: async ({commit}, responseData) => {
            await commit('CREATE_VENDOR', responseData.data);
        },

        commitUpdateVendorOnList: async ({commit}, responseData) => {
            await commit('UPDATE_VENDOR_ON_LIST', responseData.data);
        },

        commitUpdateVendor: async ({commit}, responseData) => {
            await commit('UPDATE_VENDOR', responseData.data);
        },

        commitDeleteVendorOnList: async ({commit}, vendorId) => {
            await commit('DELETE_VENDOR_ON_LIST', vendorId);
        },

        commitDeleteVendor: async ({commit}) => {
            await commit('DELETE_VENDOR');
        },

        commitResetVendors: async ({commit}) => {
            await commit('RESET_VENDORS');
        },

        commitResetVendor: async ({commit}) => {
            await commit('RESET_VENDOR');
        },

        // get list of vendor by api call.
        getVendors: async ({dispatch}, params = {}) => {
            const path = `v1/vendors`;

            return  axios.get(path, {params: params}).then(response => {
                dispatch('commitSetVendors', response.data);
                dispatch('commitSetPaginateLinks', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // get single vendor by api call.
        getVendor: async ({dispatch}, paramObj) => {
            const path = `v1/vendors/${paramObj.id}`;
            const params = paramObj.params ?? {};

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetVendor', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // create new vendor on list by api call.
        postVendorOnList: async ({dispatch}, data) => {
            const path = `v1/vendors`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateVendorOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // create new vendor by api call.
        postVendor: async ({dispatch}, data) => {
            const path = `v1/vendors`;

            return axios.post(path, data).then((response) => {

                dispatch('commitCreateVendor', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing vendor on list by api call.
        putVendorOnList: async ({dispatch}, dataObj) => {
            const path = `v1/vendors/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateVendorOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing vendor by api call.
        putVendor: async ({dispatch}, dataObj) => {
            const path = `v1/vendors/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateVendor', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular vendor on list by api call.
        deleteVendorOnList: async ({dispatch}, id) => {
            const path = `v1/vendors/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteVendorOnList', id);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular vendor by api call.
        deleteVendor: async ({dispatch}, id) => {
            const path = `v1/vendors/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteVendor', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // reset vendors state.
        resetVendors: async ({dispatch}) => {
            dispatch('commitResetVendors');
        },

        // reset vendor state.
        resetVendor: async ({dispatch}) => {
            dispatch('commitResetVendor')
        },

    },
};
