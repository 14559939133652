import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {
        customerCares: [],
        paginateLinks: {},
        customerCare: {},
    },

    getters: {
        customerCares: (state) => {
            return state.customerCares;
        },

        paginateLinks: (state) => {
            return state.paginateLinks;
        },

        customerCare: (state) => {
            return state.customerCare;
        },
    },
    mutations: {
        SET_CUSTOMER_CARES(state, customerCares) {
            state.customerCares = customerCares;
        },

        SET_PAGINATE_LINKS(state, paginateLinks) {
            state.paginateLinks = paginateLinks;
        },

        SET_CUSTOMER_CARE(state, customerCare) {
            state.customerCare = customerCare;
        },

        CREATE_CUSTOMER_CARE_ON_LIST(state, customerCare) {
            state.customerCares.unshift(customerCare);
        },

        CREATE_CUSTOMER_CARE(state, customerCare) {
            state.customerCare = customerCare;
        },

        UPDATE_CUSTOMER_CARE_ON_LIST(state, customerCare) {
            let index = state.customerCares.findIndex(item => item.id === customerCare.id);
            state.customerCares.splice(index, 1, customerCare);
        },

        UPDATE_CUSTOMER_CARE(state, customerCare) {
            state.customerCare = customerCare;
        },

        DELETE_CUSTOMER_CARE_ON_LIST(state, customerCareId) {
            let index = state.customerCares.findIndex(item => item.id === customerCareId);
            state.customerCares.splice(index, 1);
        },

        DELETE_CUSTOMER_CARE(state) {
            state.customerCare = {};
        },

        RESET_CUSTOMER_CARES(state) {
            state.customerCares = [];
        },

        RESET_CUSTOMER_CARE(state) {
            state.customerCare = {};
        },
    },
    actions: {
        commitSetCustomerCares: async ({commit}, responseData) => {
            await commit('SET_CUSTOMER_CARES', responseData.data);
        },
       
        commitSetPaginateLinks: async ({commit}, responseData) => {
            await commit('SET_PAGINATE_LINKS', responseData.meta);
        },

        commitSetCustomerCare: async ({commit}, responseData) => {
            await commit('SET_CUSTOMER_CARE', responseData.data)
        },

        commitCreateCustomerCareOnList: async ({commit}, responseData) => {
            await commit('CREATE_CUSTOMER_CARE_ON_LIST', responseData.data);
        },

        commitCreateCustomerCare: async ({commit}, responseData) => {
            await commit('CREATE_CUSTOMER_CARE', responseData.data);
        },

        commitUpdateCustomerCareOnList: async ({commit}, responseData) => {
            await commit('UPDATE_CUSTOMER_CARE_ON_LIST', responseData.data);
        },

        commitUpdateCustomerCare: async ({commit}, responseData) => {
            await commit('UPDATE_CUSTOMER_CARE', responseData.data);
        },

        commitDeleteCustomerCareOnList: async ({commit}, customerCareId) => {
            await commit('DELETE_CUSTOMER_CARE_ON_LIST', customerCareId);
        },

        commitDeleteCustomerCare: async ({commit}) => {
            await commit('DELETE_CUSTOMER_CARE');
        },

        commitResetCustomerCares: async ({commit}) => {
            await commit('RESET_CUSTOMER_CARES');
        },

        commitResetCustomerCare: async ({commit}) => {
            await commit('RESET_CUSTOMER_CARE');
        },

        // get list of customerCare by api call.
        getCustomerCares: async ({dispatch}, params = {}) => {
            const path = `v1/customer-cares`;

            return  axios.get(path, {params: params}).then(response => {
                dispatch('commitSetCustomerCares', response.data);
                dispatch('commitSetPaginateLinks', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // get single customerCare by api call.
        getCustomerCare: async ({dispatch}, paramObj) => {
            const path = `v1/customer-cares/${paramObj.id}`;
            const params = paramObj.params ?? {};

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetCustomerCare', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // create new customerCare on list by api call.
        postCustomerCareOnList: async ({dispatch}, data) => {
            const path = `v1/customer-cares`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateCustomerCareOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // create new customerCare by api call.
        postCustomerCare: async ({dispatch}, data) => {
            const path = `v1/customer-cares`;

            return axios.post(path, data).then((response) => {

                dispatch('commitCreateCustomerCare', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing customerCare on list by api call.
        putCustomerCareOnList: async ({dispatch}, dataObj) => {
            const path = `v1/customer-cares/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateCustomerCareOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing customerCare by api call.
        putCustomerCare: async ({dispatch}, dataObj) => {
            const path = `v1/customer-cares/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateCustomerCare', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular customerCare on list by api call.
        deleteCustomerCareOnList: async ({dispatch}, id) => {
            const path = `v1/customer-cares/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteCustomerCareOnList', id);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular customerCare by api call.
        deleteCustomerCare: async ({dispatch}, id) => {
            const path = `v1/customer-cares/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteCustomerCare', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // reset customerCares state.
        resetCustomerCares: async ({dispatch}) => {
            dispatch('commitResetCustomerCares');
        },

        // reset customerCare state.
        resetCustomerCare: async ({dispatch}) => {
            dispatch('commitResetCustomerCare')
        },

    },
};
