import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {
        callRequestMessages: [],
        paginateLinks: {},
        callRequestMessage: {},
    },

    getters: {
        callRequestMessages: (state) =>
        {
            return state.callRequestMessages;
        },

        paginateLinks: (state) =>
        {
            return state.paginateLinks;
        },

        callRequestMessage: (state) =>
        {
            return state.callRequestMessage;
        },
    },
    mutations: {
        SET_CALL_REQUEST_MESSAGES (state, callRequestMessages)
        {
            state.callRequestMessages = callRequestMessages;
        },

        SET_PAGINATE_LINKS (state, paginateLinks)
        {
            state.paginateLinks = paginateLinks;
        },

        SET_CALL_REQUEST_MESSAGE (state, callRequestMessage)
        {
            state.callRequestMessage = callRequestMessage;
        },

        CREATE_CALL_REQUEST_MESSAGE_ON_LIST (state, callRequestMessage)
        {
            state.callRequestMessages.unshift(callRequestMessage);
        },

        CREATE_CALL_REQUEST_MESSAGE (state, callRequestMessage)
        {
            state.callRequestMessage = callRequestMessage;
        },

        UPDATE_CALL_REQUEST_MESSAGE_ON_LIST (state, callRequestMessage)
        {
            let index = state.callRequestMessages.findIndex(item => item.id === callRequestMessage.id);
            state.callRequestMessages.splice(index, 1, callRequestMessage);
        },

        UPDATE_CALL_REQUEST_MESSAGE (state, callRequestMessage)
        {
            state.callRequestMessage = callRequestMessage;
        },

        DELETE_CALL_REQUEST_MESSAGE_ON_LIST (state, callRequestMessageId)
        {
            let index = state.callRequestMessages.findIndex(item => item.id === callRequestMessageId);
            state.callRequestMessages.splice(index, 1);
        },

        DELETE_CALL_REQUEST_MESSAGE (state)
        {
            state.callRequestMessage = {};
        },

        RESET_CALL_REQUEST_MESSAGES (state)
        {
            state.callRequestMessages = [];
        },

        RESET_CALL_REQUEST_MESSAGE (state)
        {
            state.callRequestMessage = {};
        },
    },
    actions: {
        commitSetCallRequestMessages: async ({ commit }, responseData) =>
        {
            await commit('SET_CALL_REQUEST_MESSAGES', responseData.data);
        },

        commitSetPaginateLinks: async ({ commit }, responseData) =>
        {
            await commit('SET_PAGINATE_LINKS', responseData.meta);
        },

        commitSetCallRequestMessage: async ({ commit }, responseData) =>
        {
            await commit('SET_CALL_REQUEST_MESSAGE', responseData.data)
        },

        commitCreateCallRequestMessageOnList: async ({ commit }, responseData) =>
        {
            await commit('CREATE_CALL_REQUEST_MESSAGE_ON_LIST', responseData.data);
        },

        commitCreateCallRequestMessage: async ({ commit }, responseData) =>
        {
            await commit('CREATE_CALL_REQUEST_MESSAGE', responseData.data);
        },

        commitUpdateCallRequestMessageOnList: async ({ commit }, responseData) =>
        {
            await commit('UPDATE_CALL_REQUEST_MESSAGE_ON_LIST', responseData.data);
        },

        commitUpdateCallRequestMessage: async ({ commit }, responseData) =>
        {
            await commit('UPDATE_CALL_REQUEST_MESSAGE', responseData.data);
        },

        commitDeleteCallRequestMessageOnList: async ({ commit }, callRequestMessageId) =>
        {
            await commit('DELETE_CALL_REQUEST_MESSAGE_ON_LIST', callRequestMessageId);
        },

        commitDeleteCallRequestMessage: async ({ commit }) =>
        {
            await commit('DELETE_CALL_REQUEST_MESSAGE');
        },

        commitResetCallRequestMessages: async ({ commit }) =>
        {
            await commit('RESET_CALL_REQUEST_MESSAGES');
        },

        commitResetCallRequestMessage: async ({ commit }) =>
        {
            await commit('RESET_CALL_REQUEST_MESSAGE');
        },

        // get list of callRequestMessage by api call.
        getCallRequestMessages: async ({ dispatch }, params = {}) =>
        {
            const path = `v1/call-request-messages`;

            return axios.get(path, { params: params }).then(response =>
            {
                dispatch('commitSetCallRequestMessages', response.data);
                dispatch('commitSetPaginateLinks', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error =>
            {
                return responseErrorHandler(error);
            });
        },

        // get single callRequestMessage by api call.
        getCallRequestMessage: async ({ dispatch }, paramObj) =>
        {
            const path = `v1/call-request-messages/${paramObj.id}`;
            const params = paramObj.params ?? {};

            return axios.get(path, { params: params }).then(response =>
            {
                dispatch('commitSetCallRequestMessage', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error =>
            {
                return responseErrorHandler(error);
            });
        },

        // create new callRequestMessage on list by api call.
        postCallRequestMessageOnList: async ({ dispatch }, data) =>
        {
            const path = `v1/call-request-messages`;

            return axios.post(path, data).then((response) =>
            {
                dispatch('commitCreateCallRequestMessageOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) =>
            {
                return responseErrorHandler(error);
            });
        },

        // create new callRequestMessage by api call.
        postCallRequestMessage: async ({ dispatch }, data) =>
        {
            const path = `v1/call-request-messages`;

            return axios.post(path, data).then((response) =>
            {

                dispatch('commitCreateCallRequestMessage', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) =>
            {
                return responseErrorHandler(error);
            });
        },

        // update single existing callRequestMessage on list by api call.
        putCallRequestMessageOnList: async ({ dispatch }, dataObj) =>
        {
            const path = `v1/call-request-messages/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) =>
            {
                dispatch('commitUpdateCallRequestMessageOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) =>
            {
                return responseErrorHandler(error);
            });
        },

        // update single existing callRequestMessage by api call.
        putCallRequestMessage: async ({ dispatch }, dataObj) =>
        {
            const path = `v1/call-request-messages/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) =>
            {
                dispatch('commitUpdateCallRequestMessage', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) =>
            {
                return responseErrorHandler(error);
            });
        },

        // delete a particular callRequestMessage on list by api call.
        deleteCallRequestMessageOnList: async ({ dispatch }, id) =>
        {
            const path = `v1/call-request-messages/${id}`;

            return axios.delete(path).then((response) =>
            {
                dispatch('commitDeleteCallRequestMessageOnList', id);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) =>
            {
                return responseErrorHandler(error);
            });
        },

        // delete a particular callRequestMessage by api call.
        deleteCallRequestMessage: async ({ dispatch }, id) =>
        {
            const path = `v1/call-request-messages/${id}`;

            return axios.delete(path).then((response) =>
            {
                dispatch('commitDeleteCallRequestMessage', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) =>
            {
                return responseErrorHandler(error);
            });
        },

        // reset CallRequestMessages state.
        resetCallRequestMessages: async ({ dispatch }) =>
        {
            dispatch('commitResetCallRequestMessages');
        },

        // reset callRequestMessage state.
        resetCallRequestMessage: async ({ dispatch }) =>
        {
            dispatch('commitResetCallRequestMessage')
        },

    },
};
