import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {
        adminTaskHistories: [],
        paginateLinks: {},
        adminTaskHistory: {},
    },

    getters: {
        adminTaskHistories: (state) => {
            return state.adminTaskHistories;
        },

        paginateLinks: (state) => {
            return state.paginateLinks;
        },

        adminTaskHistory: (state) => {
            return state.adminTaskHistory;
        },
    },
    mutations: {
        SET_ADMIN_TASK_HISTORIES(state, adminTaskHistories) {
            state.adminTaskHistories = adminTaskHistories;
        },

        SET_PAGINATE_LINKS(state, paginateLinks) {
            state.paginateLinks = paginateLinks;
        },

        SET_ADMIN_TASK_HISTORY(state, adminTaskHistory) {
            state.adminTaskHistory = adminTaskHistory;
        },

        CREATE_ADMIN_TASK_HISTORY_ON_LIST(state, adminTaskHistory) {
            state.adminTaskHistories.unshift(adminTaskHistory);
        },

        CREATE_ADMIN_TASK_HISTORY(state, adminTaskHistory) {
            state.adminTaskHistory = adminTaskHistory;
        },

        UPDATE_ADMIN_TASK_HISTORY_ON_LIST(state, adminTaskHistory) {
            let index = state.adminTaskHistories.findIndex(item => item.id === adminTaskHistory.id);
            state.adminTaskHistories.splice(index, 1, adminTaskHistory);
        },

        UPDATE_ADMIN_TASK_HISTORY(state, adminTaskHistory) {
            state.adminTaskHistory = adminTaskHistory;
        },

        DELETE_ADMIN_TASK_HISTORY_ON_LIST(state, adminTaskHistoryId) {
            let index = state.adminTaskHistories.findIndex(item => item.id === adminTaskHistoryId);
            state.adminTaskHistories.splice(index, 1);
        },

        DELETE_ADMIN_TASK_HISTORY(state) {
            state.adminTaskHistory = {};
        },

        RESET_ADMIN_TASK_HISTORIES(state) {
            state.adminTaskHistories = [];
        },

        RESET_ADMIN_TASK_HISTORY(state) {
            state.adminTaskHistory = {};
        },
    },
    actions: {
        commitSetAdminTaskHistories: async ({commit}, responseData) => {
            await commit('SET_ADMIN_TASK_HISTORIES', responseData.data);
        },

        commitSetPaginateLinks: async ({commit}, responseData) => {
            await commit('SET_PAGINATE_LINKS', responseData.meta);
        },

        commitSetAdminTaskHistory: async ({commit}, responseData) => {
            await commit('SET_ADMIN_TASK_HISTORY', responseData.data)
        },

        commitCreateAdminTaskHistoryOnList: async ({commit}, responseData) => {
            await commit('CREATE_ADMIN_TASK_HISTORY_ON_LIST', responseData.data);
        },

        commitCreateAdminTaskHistory: async ({commit}, responseData) => {
            await commit('CREATE_ADMIN_TASK_HISTORY', responseData.data);
        },

        commitUpdateAdminTaskHistoryOnList: async ({commit}, responseData) => {
            await commit('UPDATE_ADMIN_TASK_HISTORY_ON_LIST', responseData.data);
        },

        commitUpdateAdminTaskHistory: async ({commit}, responseData) => {
            await commit('UPDATE_ADMIN_TASK_HISTORY', responseData.data);
        },

        commitDeleteAdminTaskHistoryOnList: async ({commit}, adminTaskHistoryId) => {
            await commit('DELETE_ADMIN_TASK_HISTORY_ON_LIST', adminTaskHistoryId);
        },

        commitDeleteAdminTaskHistory: async ({commit}) => {
            await commit('DELETE_ADMIN_TASK_HISTORY');
        },

        commitResetAdminTaskHistories: async ({commit}) => {
            await commit('RESET_ADMIN_TASK_HISTORIES');
        },

        commitResetAdminTaskHistory: async ({commit}) => {
            await commit('RESET_ADMIN_TASK_HISTORY');
        },

        // get list of adminTaskHistory by api call.
        getAdminTaskHistories: async ({dispatch}, params = {}) => {
            const path = `v1/admin-task-histories`;

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetAdminTaskHistories', response.data);
                dispatch('commitSetPaginateLinks', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // get single adminTaskHistory by api call.
        getAdminTaskHistory: async ({dispatch}, paramObj) => {
            const path = `v1/admin-task-histories/${paramObj.id}`;
            const params = paramObj.params ?? {};

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetAdminTaskHistory', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // create new adminTaskHistory on list by api call.
        postAdminTaskHistoryOnList: async ({dispatch}, data) => {
            const path = `v1/admin-task-histories`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateAdminTaskHistoryOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // create new adminTaskHistory by api call.
        postAdminTaskHistory: async ({dispatch}, data) => {
            const path = `v1/admin-task-histories`;

            return axios.post(path, data).then((response) => {

                dispatch('commitCreateAdminTaskHistory', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing adminTaskHistory on list by api call.
        putAdminTaskHistoryOnList: async ({dispatch}, dataObj) => {
            const path = `v1/admin-task-histories/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateAdminTaskHistoryOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing adminTaskHistory by api call.
        putAdminTaskHistory: async ({dispatch}, dataObj) => {
            const path = `v1/admin-task-histories/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateAdminTaskHistory', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular adminTaskHistory on list by api call.
        deleteAdminTaskHistoryOnList: async ({dispatch}, id) => {
            const path = `v1/admin-task-histories/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteAdminTaskHistoryOnList', id);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular adminTaskHistory by api call.
        deleteAdminTaskHistory: async ({dispatch}, id) => {
            const path = `v1/admin-task-histories/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteAdminTaskHistory', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // reset adminTaskHistories state.
        resetAdminTaskHistories: async ({dispatch}) => {
            dispatch('commitResetAdminTaskHistories');
        },

        // reset adminTaskHistory state.
        resetAdminTaskHistory: async ({dispatch}) => {
            dispatch('commitResetAdminTaskHistory')
        },

    },
};
