import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {
        customerFeedbacks: [],
        paginateLinks: {},
        customerFeedback: {},
    },

    getters: {
        customerFeedbacks: (state) => {
            return state.customerFeedbacks;
        },

        paginateLinks: (state) => {
            return state.paginateLinks;
        },

        customerFeedback: (state) => {
            return state.customerFeedback;
        },
    },

    mutations: {
        SET_CUSTOMER_FEEDBACKS(state, customerFeedbacks) {
            state.customerFeedbacks = customerFeedbacks;
        },

        SET_PAGINATE_LINKS(state, paginateLinks) {
            state.paginateLinks = paginateLinks;
        },

        SET_CUSTOMER_FEEDBACK(state, customerFeedback) {
            state.customerFeedback = customerFeedback;
        },

        CREATE_CUSTOMER_FEEDBACK_ON_LIST(state, customerFeedback) {
            state.customerFeedbacks.unshift(customerFeedback);
        },

        CREATE_CUSTOMER_FEEDBACK(state, customerFeedback) {
            state.customerFeedback = customerFeedback;
        },

        UPDATE_CUSTOMER_FEEDBACK_ON_LIST(state, customerFeedback) {
            let index = state.customerFeedbacks.findIndex(item => item.id === customerFeedback.id);
            state.customerFeedbacks.splice(index, 1, customerFeedback);
        },

        UPDATE_CUSTOMER_FEEDBACK(state, customerFeedback) {
            state.customerFeedback = customerFeedback;
        },

        DELETE_CUSTOMER_FEEDBACK_ON_LIST(state, customerFeedbackId) {
            let index = state.customerFeedbacks.findIndex(item => item.id === customerFeedbackId);
            state.customerFeedbacks.splice(index, 1);
        },

        DELETE_CUSTOMER_FEEDBACK(state) {
            state.customerFeedback = {};
        },

        RESET_CUSTOMER_FEEDBACKS(state) {
            state.customerFeedbacks = [];
        },

        RESET_CUSTOMER_FEEDBACK(state) {
            state.customerFeedback = {};
        },
    },

    actions: {
        commitSetCustomerFeedbacks: async ({commit}, responseData) => {
            await commit('SET_CUSTOMER_FEEDBACKS', responseData.data);
        },

        commitSetPaginateLinks: async ({commit}, responseData) => {
            await commit('SET_PAGINATE_LINKS', responseData.meta);
        },

        commitSetCustomerFeedback: async ({commit}, responseData) => {
            await commit('SET_CUSTOMER_FEEDBACK', responseData.data)
        },

        commitCreateCustomerFeedbackOnList: async ({commit}, responseData) => {
            await commit('CREATE_CUSTOMER_FEEDBACK_ON_LIST', responseData.data);
        },

        commitCreateCustomerFeedback: async ({commit}, responseData) => {
            await commit('CREATE_CUSTOMER_FEEDBACK', responseData.data);
        },

        commitUpdateCustomerFeedbackOnList: async ({commit}, responseData) => {
            await commit('UPDATE_CUSTOMER_FEEDBACK_ON_LIST', responseData.data);
        },

        commitUpdateCustomerFeedback: async ({commit}, responseData) => {
            await commit('UPDATE_CUSTOMER_FEEDBACK', responseData.data);
        },

        commitDeleteCustomerFeedbackOnList: async ({commit}, customerFeedbackId) => {
            await commit('DELETE_CUSTOMER_FEEDBACK_ON_LIST', customerFeedbackId);
        },

        commitDeleteCustomerFeedback: async ({commit}) => {
            await commit('DELETE_CUSTOMER_FEEDBACK');
        },

        commitResetCustomerFeedbacks: async ({commit}) => {
            await commit('RESET_CUSTOMER_FEEDBACKS');
        },

        commitResetCustomerFeedback: async ({commit}) => {
            await commit('RESET_CUSTOMER_FEEDBACK');
        },

        // get list of customer feedback by api call.
        getCustomerFeedbacks: async ({dispatch}, params = {}) => {
            const path = `v1/customer-feedbacks`;

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetCustomerFeedbacks', response.data);
                dispatch('commitSetPaginateLinks', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // get single customer feedback by api call.
        getCustomerFeedback: async ({dispatch}, paramObj) => {
            const path = `v1/customer-feedbacks/${paramObj.id}`;
            const params = paramObj.params ?? {};

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetCustomerFeedback', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // create new customer feedback on list by api call.
        postCustomerFeedbackOnList: async ({dispatch}, data) => {
            const path = `v1/customer-feedbacks`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateCustomerFeedbackOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // create new customer feedback by api call.
        postCustomerFeedback: async ({dispatch}, data) => {
            const path = `v1/customer-feedbacks`;

            return axios.post(path, data).then((response) => {

                dispatch('commitCreateCustomerFeedback', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing customer feedback on list by api call.
        putCustomerFeedbackOnList: async ({dispatch}, dataObj) => {
            const path = `v1/customer-feedbacks/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateCustomerFeedbackOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing customer feedback by api call.
        putCustomerFeedback: async ({dispatch}, dataObj) => {
            const path = `v1/customer-feedbacks/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateCustomerFeedback', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular customer feedback on list by api call.
        deleteCustomerFeedbackOnList: async ({dispatch}, id) => {
            const path = `v1/customer-feedbacks/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteCustomerFeedbackOnList', id);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular customer feedback by api call.
        deleteCustomerFeedback: async ({dispatch}, id) => {
            const path = `v1/customer-feedbacks/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteCustomerFeedback', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // reset customer feedbacks state.
        resetCustomerFeedbacks: async ({dispatch}) => {
            dispatch('commitResetCustomerFeedbacks');
        },

        // reset customer feedback state.
        resetCustomerFeedback: async ({dispatch}) => {
            dispatch('commitResetCustomerFeedback')
        },


        // customer-feedback-request-email 
        postCustomerFeedbackRequestEmail: async (_, paramObj) => {
            const path = `v1/customer-feedbacks/send-share-feedback-request-notify/${paramObj.id}`;
            const params = paramObj.params ?? {};
            return axios.post(path, params).then((response) => {
                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);

            });
        },

    },
};