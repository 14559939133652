import axios from "axios"
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,
    actions: {
        async postForgotPassword(_, data) {
            const path = `v1/forgot-password`;

            return await axios.post(path, data).then((response) => {
                return {
                    message: response.data.data.message,
                    type: response.data.data.status,
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });

        }
    }
}