import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {
        tags: [],
        paginateLinks: {},
        tag: {},
    },

    getters: {
        tags: (state) => {
            return state.tags;
        },

        paginateLinks: (state) => {
            return state.paginateLinks;
        },

        tag: (state) => {
            return state.tag;
        },
    },
    mutations: {
        SET_TAGS(state, tags) {
            state.tags = tags;
        },

        SET_PAGINATE_LINKS(state, paginateLinks) {
            state.paginateLinks = paginateLinks;
        },

        SET_TAG(state, tag) {
            state.tag = tag;
        },

        CREATE_TAG_ON_LIST(state, tag) {
            state.tags.unshift(tag);
        },

        CREATE_TAG(state, tag) {
            state.tag = tag;
        },

        UPDATE_TAG_ON_LIST(state, tag) {
            let index = state.tags.findIndex(item => item.id === tag.id);
            state.tags.splice(index, 1, tag);
        },

        UPDATE_TAG(state, tag) {
            state.tag = tag;
        },

        DELETE_TAG_ON_LIST(state, tagId) {
            let index = state.tags.findIndex(item => item.id === tagId);
            state.tags.splice(index, 1);
        },

        DELETE_TAG(state) {
            state.tag = {};
        },

        RESET_TAGS(state) {
            state.tags = [];
        },

        RESET_TAG(state) {
            state.tag = {};
        },
    },
    actions: {
        commitSetTags: async ({commit}, responseData) => {
            await commit('SET_TAGS', responseData.data);
        },

        commitSetPaginateLinks: async ({commit}, responseData) => {
            await commit('SET_PAGINATE_LINKS', responseData.meta);
        },

        commitSetTag: async ({commit}, responseData) => {
            await commit('SET_TAG', responseData.data)
        },

        commitCreateTagOnList: async ({commit}, responseData) => {
            await commit('CREATE_TAG_ON_LIST', responseData.data);
        },

        commitCreateTag: async ({commit}, responseData) => {
            await commit('CREATE_TAG', responseData.data);
        },

        commitUpdateTagOnList: async ({commit}, responseData) => {
            await commit('UPDATE_TAG_ON_LIST', responseData.data);
        },

        commitUpdateTag: async ({commit}, responseData) => {
            await commit('UPDATE_TAG', responseData.data);
        },

        commitDeleteTagOnList: async ({commit}, tagId) => {
            await commit('DELETE_TAG_ON_LIST', tagId);
        },

        commitDeleteTag: async ({commit}) => {
            await commit('DELETE_TAG');
        },

        commitResetTags: async ({commit}) => {
            await commit('RESET_TAGS');
        },

        commitResetTag: async ({commit}) => {
            await commit('RESET_TAG');
        },

        // get list of tag by api call.
        getTags: async ({dispatch}, params = {}) => {
            const path = `v1/tags`;

            return  axios.get(path, {params: params}).then(response => {
                dispatch('commitSetTags', response.data);
                dispatch('commitSetPaginateLinks', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // get single tag by api call.
        getTag: async ({dispatch}, paramObj) => {
            const path = `v1/tags/${paramObj.id}`;
            const params = paramObj.params ?? {};

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetTag', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // create new tag on list by api call.
        postTagOnList: async ({dispatch}, data) => {
            const path = `v1/tags`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateTagOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // create new tag by api call.
        postTag: async ({dispatch}, data) => {
            const path = `v1/tags`;

            return axios.post(path, data).then((response) => {

                dispatch('commitCreateTag', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // create new tag list by api call.
        postTagStoreList: async (_, data) => {
            const path = `v1/tags/store-list`;

            return axios.post(path, data).then((response) => {
                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                    response: response,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing tag on list by api call.
        putTagOnList: async ({dispatch}, dataObj) => {
            const path = `v1/tags/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateTagOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing tag by api call.
        putTag: async ({dispatch}, dataObj) => {
            const path = `v1/tags/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateTag', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular tag on list by api call.
        deleteTagOnList: async ({dispatch}, id) => {
            const path = `v1/tags/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteTagOnList', id);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular tag by api call.
        deleteTag: async ({dispatch}, id) => {
            const path = `v1/tags/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteTag', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // reset tags state.
        resetTags: async ({dispatch}) => {
            dispatch('commitResetTags');
        },

        // reset tag state.
        resetTag: async ({dispatch}) => {
            dispatch('commitResetTag')
        },

    },
};
