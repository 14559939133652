import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {
        appointmentHistories: [],
        paginateLinks: {},
        appointmentHistory: {},
    },

    getters: {
        appointmentHistories: (state) => {
            return state.appointmentHistories;
        },

        paginateLinks: (state) => {
            return state.paginateLinks;
        },

        appointmentHistory: (state) => {
            return state.appointmentHistory;
        },
    },
    mutations: {
        SET_APPOINTMENT_HISTORIES(state, appointmentHistories) {
            state.appointmentHistories = appointmentHistories;
        },

        SET_PAGINATE_LINKS(state, paginateLinks) {
            state.paginateLinks = paginateLinks;
        },

        SET_APPOINTMENT_HISTORY(state, appointmentHistory) {
            state.appointmentHistory = appointmentHistory;
        },

        CREATE_APPOINTMENT_HISTORY_ON_LIST(state, appointmentHistory) {
            state.appointmentHistories.unshift(appointmentHistory);
        },

        CREATE_APPOINTMENT_HISTORY(state, appointmentHistory) {
            state.appointmentHistory = appointmentHistory;
        },

        UPDATE_APPOINTMENT_HISTORY_ON_LIST(state, appointmentHistory) {
            let index = state.appointmentHistories.findIndex(item => item.id === appointmentHistory.id);
            state.appointmentHistories.splice(index, 1, appointmentHistory);
        },

        UPDATE_APPOINTMENT_HISTORY(state, appointmentHistory) {
            state.appointmentHistory = appointmentHistory;
        },

        DELETE_APPOINTMENT_HISTORY_ON_LIST(state, appointmentHistoryId) {
            let index = state.appointmentHistories.findIndex(item => item.id === appointmentHistoryId);
            state.appointmentHistories.splice(index, 1);
        },

        DELETE_APPOINTMENT_HISTORY(state) {
            state.appointmentHistory = {};
        },

        RESET_APPOINTMENT_HISTORIES(state) {
            state.appointmentHistories = [];
        },

        RESET_APPOINTMENT_HISTORY(state) {
            state.appointmentHistory = {};
        },
    },
    actions: {
        commitSetAppointmentHistories: async ({commit}, responseData) => {
            await commit('SET_APPOINTMENT_HISTORIES', responseData.data);
        },

        commitSetPaginateLinks: async ({commit}, responseData) => {
            await commit('SET_PAGINATE_LINKS', responseData.meta);
        },

        commitSetAppointmentHistory: async ({commit}, responseData) => {
            await commit('SET_APPOINTMENT_HISTORY', responseData.data)
        },

        commitCreateAppointmentHistoryOnList: async ({commit}, responseData) => {
            await commit('CREATE_APPOINTMENT_HISTORY_ON_LIST', responseData.data);
        },

        commitCreateAppointmentHistory: async ({commit}, responseData) => {
            await commit('CREATE_APPOINTMENT_HISTORY', responseData.data);
        },

        commitUpdateAppointmentHistoryOnList: async ({commit}, responseData) => {
            await commit('UPDATE_APPOINTMENT_HISTORY_ON_LIST', responseData.data);
        },

        commitUpdateAppointmentHistory: async ({commit}, responseData) => {
            await commit('UPDATE_APPOINTMENT_HISTORY', responseData.data);
        },

        commitDeleteAppointmentHistoryOnList: async ({commit}, appointmentHistoryId) => {
            await commit('DELETE_APPOINTMENT_HISTORY_ON_LIST', appointmentHistoryId);
        },

        commitDeleteAppointmentHistory: async ({commit}) => {
            await commit('DELETE_APPOINTMENT_HISTORY');
        },

        commitResetAppointmentHistories: async ({commit}) => {
            await commit('RESET_APPOINTMENT_HISTORIES');
        },

        commitResetAppointmentHistory: async ({commit}) => {
            await commit('RESET_APPOINTMENT_HISTORY');
        },

        // get list of appointmentHistory by api call.
        getAppointmentHistories: async ({dispatch}, params = {}) => {
            const path = `v1/appointment-histories`;

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetAppointmentHistories', response.data);
                dispatch('commitSetPaginateLinks', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // get single appointmentHistory by api call.
        getAppointmentHistory: async ({dispatch}, paramObj) => {
            const path = `v1/appointment-histories/${paramObj.id}`;
            const params = paramObj.params ?? {};

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetAppointmentHistory', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // create new appointmentHistory on list by api call.
        postAppointmentHistoryOnList: async ({dispatch}, data) => {
            const path = `v1/appointment-histories`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateAppointmentHistoryOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // create new appointmentHistory by api call.
        postAppointmentHistory: async ({dispatch}, data) => {
            const path = `v1/appointment-histories`;

            return axios.post(path, data).then((response) => {

                dispatch('commitCreateAppointmentHistory', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing appointmentHistory on list by api call.
        putAppointmentHistoryOnList: async ({dispatch}, dataObj) => {
            const path = `v1/appointment-histories/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateAppointmentHistoryOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing appointmentHistory by api call.
        putAppointmentHistory: async ({dispatch}, dataObj) => {
            const path = `v1/appointment-histories/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateAppointmentHistory', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular appointmentHistory on list by api call.
        deleteAppointmentHistoryOnList: async ({dispatch}, id) => {
            const path = `v1/appointment-histories/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteAppointmentHistoryOnList', id);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular appointmentHistory by api call.
        deleteAppointmentHistory: async ({dispatch}, id) => {
            const path = `v1/appointment-histories/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteAppointmentHistory', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // reset appointmentHistories state.
        resetAppointmentHistories: async ({dispatch}) => {
            dispatch('commitResetAppointmentHistories');
        },

        // reset appointmentHistory state.
        resetAppointmentHistory: async ({dispatch}) => {
            dispatch('commitResetAppointmentHistory')
        },

    },
};
