import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {
        technicianCommissionPayments: [],
        paginateLinks: {},
        technicianCommissionPayment: {},
    },

    getters: {
        technicianCommissionPayments: (state) => {
            return state.technicianCommissionPayments;
        },

        paginateLinks: (state) => {
            return state.paginateLinks;
        },

        technicianCommissionPayment: (state) => {
            return state.technicianCommissionPayment;
        },
    },
    mutations: {
        SET_TECHNICIAN_COMMISSION_PAYMENTS(state, technicianCommissionPayments) {
            state.technicianCommissionPayments = technicianCommissionPayments;
        },

        SET_PAGINATE_LINKS(state, paginateLinks) {
            state.paginateLinks = paginateLinks;
        },

        SET_TECHNICIAN_COMMISSION_PAYMENT(state, technicianCommissionPayment) {
            state.technicianCommissionPayment = technicianCommissionPayment;
        },

        CREATE_TECHNICIAN_COMMISSION_PAYMENT_ON_LIST(state, technicianCommissionPayment) {
            state.technicianCommissionPayments.unshift(technicianCommissionPayment);
        },

        CREATE_TECHNICIAN_COMMISSION_PAYMENT(state, technicianCommissionPayment) {
            state.technicianCommissionPayment = technicianCommissionPayment;
        },

        UPDATE_TECHNICIAN_COMMISSION_PAYMENT_ON_LIST(state, technicianCommissionPayment) {
            let index = state.technicianCommissionPayments.findIndex(item => item.id === technicianCommissionPayment.id);
            state.technicianCommissionPayments.splice(index, 1, technicianCommissionPayment);
        },

        UPDATE_TECHNICIAN_COMMISSION_PAYMENT(state, technicianCommissionPayment) {
            state.technicianCommissionPayment = technicianCommissionPayment;
        },

        DELETE_TECHNICIAN_COMMISSION_PAYMENT_ON_LIST(state, technicianCommissionPaymentId) {
            let index = state.technicianCommissionPayments.findIndex(item => item.id === technicianCommissionPaymentId);
            state.technicianCommissionPayments.splice(index, 1);
        },

        DELETE_TECHNICIAN_COMMISSION_PAYMENT(state) {
            state.technicianCommissionPayment = {};
        },

        RESET_TECHNICIAN_COMMISSION_PAYMENTS(state) {
            state.technicianCommissionPayments = [];
        },

        RESET_TECHNICIAN_COMMISSION_PAYMENT(state) {
            state.technicianCommissionPayment = {};
        },
    },
    actions: {
        commitSetTechnicianCommissionPayments: async ({commit}, responseData) => {
            await commit('SET_TECHNICIAN_COMMISSION_PAYMENTS', responseData.data);
        },

        commitSetPaginateLinks: async ({commit}, responseData) => {
            await commit('SET_PAGINATE_LINKS', responseData.meta);
        },

        commitSetTechnicianCommissionPayment: async ({commit}, responseData) => {
            await commit('SET_TECHNICIAN_COMMISSION_PAYMENT', responseData.data)
        },

        commitCreateTechnicianCommissionPaymentOnList: async ({commit}, responseData) => {
            await commit('CREATE_TECHNICIAN_COMMISSION_PAYMENT_ON_LIST', responseData.data);
        },

        commitCreateTechnicianCommissionPayment: async ({commit}, responseData) => {
            await commit('CREATE_TECHNICIAN_COMMISSION_PAYMENT', responseData.data);
        },

        commitUpdateTechnicianCommissionPaymentOnList: async ({commit}, responseData) => {
            await commit('UPDATE_TECHNICIAN_COMMISSION_PAYMENT_ON_LIST', responseData.data);
        },

        commitUpdateTechnicianCommissionPayment: async ({commit}, responseData) => {
            await commit('UPDATE_TECHNICIAN_COMMISSION_PAYMENT', responseData.data);
        },

        commitDeleteTechnicianCommissionPaymentOnList: async ({commit}, technicianCommissionPaymentId) => {
            await commit('DELETE_TECHNICIAN_COMMISSION_PAYMENT_ON_LIST', technicianCommissionPaymentId);
        },

        commitDeleteTechnicianCommissionPayment: async ({commit}) => {
            await commit('DELETE_TECHNICIAN_COMMISSION_PAYMENT');
        },

        commitResetTechnicianCommissionPayments: async ({commit}) => {
            await commit('RESET_TECHNICIAN_COMMISSION_PAYMENTS');
        },

        commitResetTechnicianCommissionPayment: async ({commit}) => {
            await commit('RESET_TECHNICIAN_COMMISSION_PAYMENT');
        },

        // get list of technicianCommissionPayment by api call.
        getTechnicianCommissionPayments: async ({dispatch}, params = {}) => {
            const path = `v1/technician-commission-payments`;

            return  axios.get(path, {params: params}).then(response => {
                dispatch('commitSetTechnicianCommissionPayments', response.data);
                dispatch('commitSetPaginateLinks', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // get single technicianCommissionPayment by api call.
        getTechnicianCommissionPayment: async ({dispatch}, paramObj) => {
            const path = `v1/technician-commission-payments/${paramObj.id}`;
            const params = paramObj.params ?? {};

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetTechnicianCommissionPayment', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // create new technicianCommissionPayment on list by api call.
        postTechnicianCommissionPaymentOnList: async ({dispatch}, data) => {
            const path = `v1/technician-commission-payments`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateTechnicianCommissionPaymentOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // create new technicianCommissionPayment by api call.
        postTechnicianCommissionPayment: async ({dispatch}, data) => {
            const path = `v1/technician-commission-payments`;

            return axios.post(path, data).then((response) => {

                dispatch('commitCreateTechnicianCommissionPayment', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing technicianCommissionPayment on list by api call.
        putTechnicianCommissionPaymentOnList: async ({dispatch}, dataObj) => {
            const path = `v1/technician-commission-payments/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateTechnicianCommissionPaymentOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing technicianCommissionPayment by api call.
        putTechnicianCommissionPayment: async ({dispatch}, dataObj) => {
            const path = `v1/technician-commission-payments/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateTechnicianCommissionPayment', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular technicianCommissionPayment on list by api call.
        deleteTechnicianCommissionPaymentOnList: async ({dispatch}, id) => {
            const path = `v1/technician-commission-payments/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteTechnicianCommissionPaymentOnList', id);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular technicianCommissionPayment by api call.
        deleteTechnicianCommissionPayment: async ({dispatch}, id) => {
            const path = `v1/technician-commission-payments/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteTechnicianCommissionPayment', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // reset technicianCommissionPayments state.
        resetTechnicianCommissionPayments: async ({dispatch}) => {
            dispatch('commitResetTechnicianCommissionPayments');
        },

        // reset technicianCommissionPayment state.
        resetTechnicianCommissionPayment: async ({dispatch}) => {
            dispatch('commitResetTechnicianCommissionPayment')
        },

    },
};
