import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {
        superAdmins: [],
        paginateLinks: {},
        superAdmin: {},
    },

    getters: {
        superAdmins: (state) => {
            return state.superAdmins;
        },

        paginateLinks: (state) => {
            return state.paginateLinks;
        },

        superAdmin: (state) => {
            return state.superAdmin;
        },
    },
    mutations: {
        SET_SUPER_ADMINS(state, superAdmins) {
            state.superAdmins = superAdmins;
        },

        SET_PAGINATE_LINKS(state, paginateLinks) {
            state.paginateLinks = paginateLinks;
        },

        SET_SUPER_ADMIN(state, superAdmin) {
            state.superAdmin = superAdmin;
        },

        CREATE_SUPER_ADMIN_ON_LIST(state, superAdmin) {
            state.superAdmins.unshift(superAdmin);
        },

        CREATE_SUPER_ADMIN(state, superAdmin) {
            state.superAdmin = superAdmin;
        },

        UPDATE_SUPER_ADMIN_ON_LIST(state, superAdmin) {
            let index = state.superAdmins.findIndex(item => item.id === superAdmin.id);
            state.superAdmins.splice(index, 1, superAdmin);
        },

        UPDATE_SUPER_ADMIN(state, superAdmin) {
            state.superAdmin = superAdmin;
        },

        DELETE_SUPER_ADMIN_ON_LIST(state, superAdminId) {
            let index = state.superAdmins.findIndex(item => item.id === superAdminId);
            state.superAdmins.splice(index, 1);
        },

        DELETE_SUPER_ADMIN(state) {
            state.superAdmin = {};
        },

        RESET_SUPER_ADMINS(state) {
            state.superAdmins = [];
        },

        RESET_SUPER_ADMIN(state) {
            state.superAdmin = {};
        },
    },
    actions: {
        commitSetSuperAdmins: async ({commit}, responseData) => {
            await commit('SET_SUPER_ADMINS', responseData.data);
        },

        commitSetPaginateLinks: async ({commit}, responseData) => {
            await commit('SET_PAGINATE_LINKS', responseData.meta);
        },

        commitSetSuperAdmin: async ({commit}, responseData) => {
            await commit('SET_SUPER_ADMIN', responseData.data)
        },

        commitCreateSuperAdminOnList: async ({commit}, responseData) => {
            await commit('CREATE_SUPER_ADMIN_ON_LIST', responseData.data);
        },

        commitCreateSuperAdmin: async ({commit}, responseData) => {
            await commit('CREATE_SUPER_ADMIN', responseData.data);
        },

        commitUpdateSuperAdminOnList: async ({commit}, responseData) => {
            await commit('UPDATE_SUPER_ADMIN_ON_LIST', responseData.data);
        },

        commitUpdateSuperAdmin: async ({commit}, responseData) => {
            await commit('UPDATE_SUPER_ADMIN', responseData.data);
        },

        commitDeleteSuperAdminOnList: async ({commit}, superAdminId) => {
            await commit('DELETE_SUPER_ADMIN_ON_LIST', superAdminId);
        },

        commitDeleteSuperAdmin: async ({commit}) => {
            await commit('DELETE_SUPER_ADMIN');
        },

        commitResetSuperAdmins: async ({commit}) => {
            await commit('RESET_SUPER_ADMINS');
        },

        commitResetSuperAdmin: async ({commit}) => {
            await commit('RESET_SUPER_ADMIN');
        },

        // get list of super admin by api call.
        getSuperAdmins: async ({dispatch}, params = {}) => {
            const path = `v1/super-admins`;

            return  axios.get(path, {params: params}).then(response => {
                dispatch('commitSetSuperAdmins', response.data);
                dispatch('commitSetPaginateLinks', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // get single super admin by api call.
        getSuperAdmin: async ({dispatch}, paramObj) => {
            const path = `v1/super-admins/${paramObj.id}`;
            const params = paramObj.params ?? {};

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetSuperAdmin', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // create new super admin on list by api call.
        postSuperAdminOnList: async ({dispatch}, data) => {
            const path = `v1/super-admins`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateSuperAdminOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // create new super admin by api call.
        postSuperAdmin: async ({dispatch}, data) => {
            const path = `v1/super-admins`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateSuperAdmin', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing super admin on list by api call.
        putSuperAdminOnList: async ({dispatch}, dataObj) => {
            const path = `v1/super-admins/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateSuperAdminOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing super admin by api call.
        putSuperAdmin: async ({dispatch}, dataObj) => {
            const path = `v1/super-admins/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateSuperAdmin', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular super admin on list by api call.
        deleteSuperAdminOnList: async ({dispatch}, id) => {
            const path = `v1/super-admins/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteSuperAdminOnList', id);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular super admin by api call.
        deleteSuperAdmin: async ({dispatch}, id) => {
            const path = `v1/super-admins/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteSuperAdmin', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // reset superAdmins state.
        resetSuperAdmins: async ({dispatch}) => {
            dispatch('commitResetSuperAdmins');
        },

        // reset superAdmin state.
        resetSuperAdmin: async ({dispatch}) => {
            dispatch('commitResetSuperAdmin')
        },

    },
};
