function middlewarePipeline (context, middleware, index) {

    const nextMiddleware = middleware[index];
    const permissionGates = context.permissionGates;

    if(!nextMiddleware){
        return context.next;
    }

    return () => {
        const nextPipeline = middlewarePipeline(context, middleware, index + 1);

        nextMiddleware({ ...context, next: nextPipeline, permissionGates: permissionGates });
    };
}

export default middlewarePipeline;