import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {
        smsTemplates: [],
        paginateLinks: {},
        smsTemplate: {},
    },

    getters: {
        smsTemplates: (state) => {
            return state.smsTemplates;
        },

        paginateLinks: (state) => {
            return state.paginateLinks;
        },

        smsTemplate: (state) => {
            return state.smsTemplate;
        },
    },
    mutations: {
        SET_SMS_TEMPLATES(state, smsTemplates) {
            state.smsTemplates = smsTemplates;
        },

        SET_PAGINATE_LINKS(state, paginateLinks) {
            state.paginateLinks = paginateLinks;
        },

        SET_SMS_TEMPLATE(state, smsTemplate) {
            state.smsTemplate = smsTemplate;
        },

        CREATE_SMS_TEMPLATE_ON_LIST(state, smsTemplate) {
            state.smsTemplates.unshift(smsTemplate);
        },

        CREATE_SMS_TEMPLATE(state, smsTemplate) {
            state.smsTemplate = smsTemplate;
        },

        UPDATE_SMS_TEMPLATE_ON_LIST(state, smsTemplate) {
            let index = state.smsTemplates.findIndex(item => item.id === smsTemplate.id);
            state.smsTemplates.splice(index, 1, smsTemplate);
        },

        UPDATE_SMS_TEMPLATE(state, smsTemplate) {
            state.smsTemplate = smsTemplate;
        },

        DELETE_SMS_TEMPLATE_ON_LIST(state, roleId) {
            let index = state.smsTemplates.findIndex(item => item.id === roleId);
            state.smsTemplates.splice(index, 1);
        },

        DELETE_SMS_TEMPLATE(state) {
            state.smsTemplate = {};
        },

        RESET_SMS_TEMPLATES(state) {
            state.smsTemplates = [];
        },

        RESET_SMS_TEMPLATE(state) {
            state.smsTemplate = {};
        },
    },
    actions: {
        commitSetSmsTemplates: async ({commit}, responseData) => {
            await commit('SET_SMS_TEMPLATES', responseData.data);
        },

        commitSetPaginateLinks: async ({commit}, responseData) => {
            await commit('SET_PAGINATE_LINKS', responseData.meta);
        },

        commitSetSmsTemplate: async ({commit}, responseData) => {
            await commit('SET_SMS_TEMPLATE', responseData.data)
        },

        commitCreateSmsTemplateOnList: async ({commit}, responseData) => {
            await commit('CREATE_SMS_TEMPLATE_ON_LIST', responseData.data);
        },

        commitCreateSmsTemplate: async ({commit}, responseData) => {
            await commit('CREATE_SMS_TEMPLATE', responseData.data);
        },

        commitUpdateSmsTemplateOnList: async ({commit}, responseData) => {
            await commit('UPDATE_SMS_TEMPLATE_ON_LIST', responseData.data);
        },

        commitUpdateSmsTemplate: async ({commit}, responseData) => {
            await commit('UPDATE_SMS_TEMPLATE', responseData.data);
        },

        commitDeleteSmsTemplateOnList: async ({commit}, roleId) => {
            await commit('DELETE_SMS_TEMPLATE_ON_LIST', roleId);
        },

        commitDeleteSmsTemplate: async ({commit}) => {
            await commit('DELETE_SMS_TEMPLATE');
        },

        commitResetSmsTemplates: async ({commit}) => {
            await commit('RESET_SMS_TEMPLATES');
        },

        commitResetSmsTemplate: async ({commit}) => {
            await commit('RESET_SMS_TEMPLATE');
        },

        // get list of smsTemplate by api call.
        getSmsTemplates: async ({dispatch}, params = {}) => {
            const path = `v1/sms-templates`;

            return  axios.get(path, {params: params}).then(response => {
                dispatch('commitSetSmsTemplates', response.data);
                dispatch('commitSetPaginateLinks', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // get single smsTemplate by api call.
        getSmsTemplate: async ({dispatch}, paramObj) => {
            const path = `v1/sms-templates/${paramObj.id}`;
            const params = paramObj.params ?? {};

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetSmsTemplate', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // create new smsTemplate on list by api call.
        postSmsTemplateOnList: async ({dispatch}, data) => {
            const path = `v1/sms-templates`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateSmsTemplateOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // create new smsTemplate by api call.
        postSmsTemplate: async ({dispatch}, data) => {
            const path = `v1/sms-templates`;

            return axios.post(path, data).then((response) => {

                dispatch('commitCreateSmsTemplate', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing smsTemplate on list by api call.
        putSmsTemplateOnList: async ({dispatch}, dataObj) => {
            const path = `v1/sms-templates/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateSmsTemplateOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing smsTemplate by api call.
        putSmsTemplate: async ({dispatch}, dataObj) => {
            const path = `v1/sms-templates/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateSmsTemplate', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular smsTemplate on list by api call.
        deleteSmsTemplateOnList: async ({dispatch}, id) => {
            const path = `v1/sms-templates/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteSmsTemplateOnList', id);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular smsTemplate by api call.
        deleteSmsTemplate: async ({dispatch}, id) => {
            const path = `v1/sms-templates/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteSmsTemplate', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // reset smsTemplates state.
        resetSmsTemplates: async ({dispatch}) => {
            dispatch('commitResetSmsTemplates');
        },

        // reset smsTemplate state.
        resetSmsTemplate: async ({dispatch}) => {
            dispatch('commitResetSmsTemplate')
        },

    },
};
