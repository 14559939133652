import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {
        leaveApplications: [],
        paginateLinks: {},
        leaveApplication: {},
    },

    getters: {
        leaveApplications: (state) =>
        {
            return state.leaveApplications;
        },

        paginateLinks: (state) =>
        {
            return state.paginateLinks;
        },

        leaveApplication: (state) =>
        {
            return state.leaveApplication;
        },
    },
    mutations: {
        SET_LEAVE_APPLICATIONS (state, leaveApplications)
        {
            state.leaveApplications = leaveApplications;
        },

        SET_PAGINATE_LINKS (state, paginateLinks)
        {
            state.paginateLinks = paginateLinks;
        },

        SET_LEAVE_APPLICATION (state, leaveApplication)
        {
            state.leaveApplication = leaveApplication;
        },

      
        UPDATE_LEAVE_APPLICATION_ON_LIST (state, leaveApplication)
        {
            let index = state.leaveApplications.findIndex(item => item.id === leaveApplication.id);
            state.leaveApplications.splice(index, 1, leaveApplication);
        },

        UPDATE_LEAVE_APPLICATION (state, leaveApplication)
        {
            state.leaveApplication = leaveApplication;
        },

        DELETE_LEAVE_APPLICATION_ON_LIST (state, leaveApplicationId)
        {
            let index = state.leaveApplications.findIndex(item => item.id === leaveApplicationId);
            state.leaveApplications.splice(index, 1);
        },

        DELETE_LEAVE_APPLICATION (state)
        {
            state.leaveApplication = {};
        },

        // RESET_VENDORS (state)
        // {
        //     state.leave-applications = [];
        // },

        // RESET_VENDOR (state)
        // {
        //     state.vendor = {};
        // },
    },
    actions: {
        commitSetLeaveApplications: async ({ commit }, responseData) =>
        {
            await commit('SET_LEAVE_APPLICATIONS', responseData.data);
        },

        commitSetPaginateLinks: async ({ commit }, responseData) =>
        {
            await commit('SET_PAGINATE_LINKS', responseData.meta);
        },

        commitSetVendor: async ({ commit }, responseData) =>
        {
            await commit('SET_LEAVE_APPLICATION', responseData.data)
        },

        commitCreateVendorOnList: async ({ commit }, responseData) =>
        {
            await commit('CREATE_VENDOR_ON_LIST', responseData.data);
        },

        commitCreateVendor: async ({ commit }, responseData) =>
        {
            await commit('CREATE_VENDOR', responseData.data);
        },

        commitUpdateLeaveApplicationOnList: async ({ commit }, responseData) =>
        {
            await commit('UPDATE_LEAVE_APPLICATION_ON_LIST', responseData.data);
        },

        commitUpdateLeaveApplication: async ({ commit }, responseData) =>
        {
            await commit('UPDATE_LEAVE_APPLICATION', responseData.data);
        },

        commitDeleteLeaveApplicationOnList: async ({ commit }, leaveApplicationId) =>
        {
            await commit('DELETE_LEAVE_APPLICATION_ON_LIST', leaveApplicationId);
        },

        commitDeleteLeaveApplication: async ({ commit }) =>
        {
            await commit('DELETE_LEAVE_APPLICATION');
        },

        // commitResetVendors: async ({ commit }) =>
        // {
        //     await commit('RESET_VENDORS');
        // },

        // commitResetVendor: async ({ commit }) =>
        // {
        //     await commit('RESET_VENDOR');
        // },

        // get list of LeaveApplications by api call.
        getLeaveApplications: async ({ dispatch }, params = {}) =>
        {
            const path = `v1/leave-applications`;

            return axios.get(path, { params: params }).then(response =>
            {
                dispatch('commitSetLeaveApplications', response.data);
                dispatch('commitSetPaginateLinks', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error =>
            {
                return responseErrorHandler(error);
            });
        },

        // get single LeaveApplication by api call.
        getLeaveApplication: async ({ dispatch }, paramObj) =>
        {
            const path = `v1/leave-applications/${paramObj.id}`;
            const params = paramObj.params ?? {};

            return axios.get(path, { params: params }).then(response =>
            {
                dispatch('commitSetVendor', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error =>
            {
                return responseErrorHandler(error);
            });
        },

        // // create new vendor on list by api call.
        // postVendorOnList: async ({ dispatch }, data) =>
        // {
        //     const path = `v1/leave-applications`;

        //     return axios.post(path, data).then((response) =>
        //     {
        //         dispatch('commitCreateVendorOnList', response.data);

        //         return {
        //             message: '',
        //             type: 'success',
        //             status: response.status,
        //         };
        //     }).catch((error) =>
        //     {
        //         return responseErrorHandler(error);
        //     });
        // },

        // // create new vendor by api call.
        // postVendor: async ({ dispatch }, data) =>
        // {
        //     const path = `v1/leave-applications`;

        //     return axios.post(path, data).then((response) =>
        //     {

        //         dispatch('commitCreateVendor', response.data);

        //         return {
        //             message: '',
        //             type: 'success',
        //             status: response.status,
        //         };
        //     }).catch((error) =>
        //     {
        //         return responseErrorHandler(error);
        //     });
        // },

        // update single existing LeaveApplication on list by api call.
        putLeaveApplicationOnList: async ({ dispatch }, dataObj) =>
        {
            const path = `v1/leave-applications/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) =>
            {
                dispatch('commitUpdateLeaveApplicationOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) =>
            {
                return responseErrorHandler(error);
            });
        },

        // update single existing LeaveApplication by api call.
        putLeaveApplication: async ({ dispatch }, dataObj) =>
        {
            const path = `v1/leave-applications/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) =>
            {
                dispatch('commitUpdateLeaveApplication', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) =>
            {
                return responseErrorHandler(error);
            });
        },

        // delete a particular LeaveApplication on list by api call.
        deleteLeaveApplicationOnList: async ({ dispatch }, id) =>
        {
            const path = `v1/leave-applications/${id}`;

            return axios.delete(path).then((response) =>
            {
                dispatch('commitDeleteLeaveApplicationOnList', id);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) =>
            {
                return responseErrorHandler(error);
            });
        },

        // delete a particular LeaveApplication by api call.
        deleteLeaveApplication: async ({ dispatch }, id) =>
        {
            const path = `v1/leave-applications/${id}`;

            return axios.delete(path).then((response) =>
            {
                dispatch('commitDeleteLeaveApplication', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) =>
            {
                return responseErrorHandler(error);
            });
        },

        // // reset leave-applications state.
        // resetVendors: async ({ dispatch }) =>
        // {
        //     dispatch('commitResetVendors');
        // },

        // // reset vendor state.
        // resetVendor: async ({ dispatch }) =>
        // {
        //     dispatch('commitResetVendor')
        // },

    },
};
