import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {
        orderPayments: [],
        paginateLinks: {},
        orderPayment: {},
    },

    getters: {
        orderPayments: (state) => {
            return state.orderPayments;
        },

        paginateLinks: (state) => {
            return state.paginateLinks;
        },

        orderPayment: (state) => {
            return state.orderPayment;
        },
    },
    mutations: {
        SET_ORDER_PAYMENTS(state, orderPayments) {
            state.orderPayments = orderPayments;
        },

        SET_PAGINATE_LINKS(state, paginateLinks) {
            state.paginateLinks = paginateLinks;
        },

        SET_ORDER_PAYMENT(state, orderPayment) {
            state.orderPayment = orderPayment;
        },

        CREATE_ORDER_PAYMENT_ON_LIST(state, orderPayment) {
            state.orderPayments.unshift(orderPayment);
        },

        CREATE_ORDER_PAYMENT(state, orderPayment) {
            state.orderPayment = orderPayment;
        },

        UPDATE_ORDER_PAYMENT_ON_LIST(state, orderPayment) {
            let index = state.orderPayments.findIndex(item => item.id === orderPayment.id);
            state.orderPayments.splice(index, 1, orderPayment);
        },

        UPDATE_ORDER_PAYMENT(state, orderPayment) {
            state.orderPayment = orderPayment;
        },

        DELETE_ORDER_PAYMENT_ON_LIST(state, orderPaymentId) {
            let index = state.orderPayments.findIndex(item => item.id === orderPaymentId);
            state.orderPayments.splice(index, 1);
        },

        DELETE_ORDER_PAYMENT(state) {
            state.orderPayment = {};
        },

        RESET_ORDER_PAYMENTS(state) {
            state.orderPayments = [];
        },

        RESET_ORDER_PAYMENT(state) {
            state.orderPayment = {};
        },
    },
    actions: {
        commitSetOrderPayments: async ({commit}, responseData) => {
            await commit('SET_ORDER_PAYMENTS', responseData.data);
        },

        commitSetPaginateLinks: async ({commit}, responseData) => {
            await commit('SET_PAGINATE_LINKS', responseData.meta);
        },

        commitSetOrderPayment: async ({commit}, responseData) => {
            await commit('SET_ORDER_PAYMENT', responseData.data)
        },

        commitCreateOrderPaymentOnList: async ({commit}, responseData) => {
            await commit('CREATE_ORDER_PAYMENT_ON_LIST', responseData.data);
        },

        commitCreateOrderPayment: async ({commit}, responseData) => {
            await commit('CREATE_ORDER_PAYMENT', responseData.data);
        },

        commitUpdateOrderPaymentOnList: async ({commit}, responseData) => {
            await commit('UPDATE_ORDER_PAYMENT_ON_LIST', responseData.data);
        },

        commitUpdateOrderPayment: async ({commit}, responseData) => {
            await commit('UPDATE_ORDER_PAYMENT', responseData.data);
        },

        commitDeleteOrderPaymentOnList: async ({commit}, orderPaymentId) => {
            await commit('DELETE_ORDER_PAYMENT_ON_LIST', orderPaymentId);
        },

        commitDeleteOrderPayment: async ({commit}) => {
            await commit('DELETE_ORDER_PAYMENT');
        },

        commitResetOrderPayments: async ({commit}) => {
            await commit('RESET_ORDER_PAYMENTS');
        },

        commitResetOrderPayment: async ({commit}) => {
            await commit('RESET_ORDER_PAYMENT');
        },

        // get list of orderPayment by api call.
        getOrderPayments: async ({dispatch}, params = {}) => {
            const path = `v1/order-payments`;

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetOrderPayments', response.data);
                dispatch('commitSetPaginateLinks', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // get single orderPayment by api call.
        getOrderPayment: async ({dispatch}, paramObj) => {
            const path = `v1/order-payments/${paramObj.id}`;
            const params = paramObj.params ?? {};

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetOrderPayment', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // create new orderPayment on list by api call.
        postOrderPaymentOnList: async ({dispatch}, data) => {
            const path = `v1/order-payments`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateOrderPaymentOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // create new orderPayment by api call.
        postOrderPayment: async ({dispatch}, data) => {
            const path = `v1/order-payments`;

            return axios.post(path, data).then((response) => {

                dispatch('commitCreateOrderPayment', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing orderPayment on list by api call.
        putOrderPaymentOnList: async ({dispatch}, dataObj) => {
            const path = `v1/order-payments/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateOrderPaymentOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing orderPayment by api call.
        putOrderPayment: async ({dispatch}, dataObj) => {
            const path = `v1/order-payments/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateOrderPayment', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular orderPayment on list by api call.
        deleteOrderPaymentOnList: async ({dispatch}, id) => {
            const path = `v1/order-payments/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteOrderPaymentOnList', id);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular orderPayment by api call.
        deleteOrderPayment: async ({dispatch}, id) => {
            const path = `v1/order-payments/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteOrderPayment', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // reset orderPayments state.
        resetOrderPayments: async ({dispatch}) => {
            dispatch('commitResetOrderPayments');
        },

        // reset orderPayment state.
        resetOrderPayment: async ({dispatch}) => {
            dispatch('commitResetOrderPayment')
        },

    },
};
