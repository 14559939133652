import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {
        franchiseePayments: [],
        paginateLinks: {},
        franchiseePayment: {},
    },

    getters: {
        franchiseePayments: (state) => {
            return state.franchiseePayments;
        },

        paginateLinks: (state) => {
            return state.paginateLinks;
        },

        franchiseePayment: (state) => {
            return state.franchiseePayment;
        },
    },
    mutations: {
        SET_FRANCHISEE_PAYMENTS(state, franchiseePayments) {
            state.franchiseePayments = franchiseePayments;
        },

        SET_PAGINATE_LINKS(state, paginateLinks) {
            state.paginateLinks = paginateLinks;
        },

        SET_FRANCHISEE_PAYMENT(state, franchiseePayment) {
            state.franchiseePayment = franchiseePayment;
        },

        CREATE_FRANCHISEE_PAYMENT_ON_LIST(state, franchiseePayment) {
            state.franchiseePayments.unshift(franchiseePayment);
        },

        CREATE_FRANCHISEE_PAYMENT(state, franchiseePayment) {
            state.franchiseePayment = franchiseePayment;
        },

        UPDATE_FRANCHISEE_PAYMENT_ON_LIST(state, franchiseePayment) {
            let index = state.franchiseePayments.findIndex(item => item.id === franchiseePayment.id);
            state.franchiseePayments.splice(index, 1, franchiseePayment);
        },

        UPDATE_FRANCHISEE_PAYMENT(state, franchiseePayment) {
            state.franchiseePayment = franchiseePayment;
        },

        DELETE_FRANCHISEE_PAYMENT_ON_LIST(state, FRANCHISEE_PAYMENTId) {
            let index = state.franchiseePayments.findIndex(item => item.id === FRANCHISEE_PAYMENTId);
            state.franchiseePayments.splice(index, 1);
        },

        DELETE_FRANCHISEE_PAYMENT(state) {
            state.franchiseePayment = {};
        },

        RESET_FRANCHISEE_PAYMENTS(state) {
            state.franchiseePayments = [];
        },

        RESET_FRANCHISEE_PAYMENT(state) {
            state.franchiseePayment = {};
        },
    },
    actions: {
        commitSetFranchiseePayments: async ({commit}, responseData) => {
            await commit('SET_FRANCHISEE_PAYMENTS', responseData.data);
        },

        commitSetPaginateLinks: async ({commit}, responseData) => {
            await commit('SET_PAGINATE_LINKS', responseData.meta);
        },

        commitSetFranchiseePayment: async ({commit}, responseData) => {
            await commit('SET_FRANCHISEE_PAYMENT', responseData.data)
        },

        commitCreateFranchiseePaymentOnList: async ({commit}, responseData) => {
            await commit('CREATE_FRANCHISEE_PAYMENT_ON_LIST', responseData.data);
        },

        commitCreateFranchiseePayment: async ({commit}, responseData) => {
            await commit('CREATE_FRANCHISEE_PAYMENT', responseData.data);
        },

        commitUpdateFranchiseePaymentOnList: async ({commit}, responseData) => {
            await commit('UPDATE_FRANCHISEE_PAYMENT_ON_LIST', responseData.data);
        },

        commitUpdateFranchiseePayment: async ({commit}, responseData) => {
            await commit('UPDATE_FRANCHISEE_PAYMENT', responseData.data);
        },

        commitDeleteFranchiseePaymentOnList: async ({commit}, FRANCHISEE_PAYMENTId) => {
            await commit('DELETE_FRANCHISEE_PAYMENT_ON_LIST', FRANCHISEE_PAYMENTId);
        },

        commitDeleteFranchiseePayment: async ({commit}) => {
            await commit('DELETE_FRANCHISEE_PAYMENT');
        },

        commitResetFranchiseePayments: async ({commit}) => {
            await commit('RESET_FRANCHISEE_PAYMENTS');
        },

        commitResetFranchiseePayment: async ({commit}) => {
            await commit('RESET_FRANCHISEE_PAYMENT');
        },

        // get list of franchiseePayment by api call.
        getFranchiseePayments: async ({dispatch}, params = {}) => {
            const path = `v1/franchisee-payments`;

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetFranchiseePayments', response.data);
                dispatch('commitSetPaginateLinks', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // get single franchiseePayment by api call.
        getFranchiseePayment: async ({dispatch}, paramObj) => {
            const path = `v1/franchisee-payments/${paramObj.id}`;
            const params = paramObj.params ?? {};

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetFranchiseePayment', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // create new franchiseePayment on list by api call.
        postFranchiseePaymentOnList: async ({dispatch}, data) => {
            const path = `v1/franchisee-payments`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateFranchiseePaymentOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // create new franchiseePayment by api call.
        postFranchiseePayment: async ({dispatch}, data) => {
            const path = `v1/franchisee-payments`;

            return axios.post(path, data).then((response) => {

                dispatch('commitCreateFranchiseePayment', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing franchiseePayment on list by api call.
        putFranchiseePaymentOnList: async ({dispatch}, dataObj) => {
            const path = `v1/franchisee-payments/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateFranchiseePaymentOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing franchiseePayment by api call.
        putFranchiseePayment: async ({dispatch}, dataObj) => {
            const path = `v1/franchisee-payments/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateFranchiseePayment', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular franchiseePayment on list by api call.
        deleteFranchiseePaymentOnList: async ({dispatch}, id) => {
            const path = `v1/franchisee-payments/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteFranchiseePaymentOnList', id);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular franchiseePayment by api call.
        deleteFranchiseePayment: async ({dispatch}, id) => {
            const path = `v1/franchisee-payments/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteFranchiseePayment', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // reset franchiseePayments state.
        resetFranchiseePayments: async ({dispatch}) => {
            dispatch('commitResetFranchiseePayments');
        },

        // reset franchiseePayment state.
        resetFranchiseePayment: async ({dispatch}) => {
            dispatch('commitResetFranchiseePayment')
        },

    },
};
