import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {
        serviceTips: [],
        paginateLinks: {},
        serviceTip: {},
    },

    getters: {
        serviceTips: (state) => {
            return state.serviceTips;
        },

        paginateLinks: (state) => {
            return state.paginateLinks;
        },

        serviceTip: (state) => {
            return state.serviceTip;
        },
    },

    mutations: {
        SET_SERVICE_TIPS(state, serviceTips) {
            state.serviceTips = serviceTips;
        },

        SET_PAGINATE_LINKS(state, paginateLinks) {
            state.paginateLinks = paginateLinks;
        },

        SET_SERVICE_TIP(state, serviceTip) {
            state.serviceTip = serviceTip;
        },

        CREATE_SERVICE_TIP_ON_LIST(state, serviceTip) {
            state.serviceTips.unshift(serviceTip);
        },

        CREATE_SERVICE_TIP(state, serviceTip) {
            state.serviceTip = serviceTip;
        },

        UPDATE_SERVICE_TIP_ON_LIST(state, serviceTip) {
            let index = state.serviceTips.findIndex(item => item.id === serviceTip.id);
            state.serviceTips.splice(index, 1, serviceTip);
        },

        UPDATE_SERVICE_TIP(state, serviceTip) {
            state.serviceTip = serviceTip;
        },

        DELETE_SERVICE_TIP_ON_LIST(state, serviceTipId) {
            let index = state.serviceTips.findIndex(item => item.id === serviceTipId);
            state.serviceTips.splice(index, 1);
        },

        DELETE_SERVICE_TIP(state) {
            state.serviceTip = {};
        },

        RESET_SERVICE_TIPS(state) {
            state.serviceTips = [];
        },

        RESET_SERVICE_TIP(state) {
            state.serviceTip = {};
        },
    },
    actions: {
        commitSetServiceTips: async ({commit}, responseData) => {
            await commit('SET_SERVICE_TIPS', responseData.data);
        },

        commitSetPaginateLinks: async ({commit}, responseData) => {
            await commit('SET_PAGINATE_LINKS', responseData.meta);
        },

        commitSetServiceTip: async ({commit}, responseData) => {
            await commit('SET_SERVICE_TIP', responseData.data)
        },

        commitCreateServiceTipOnList: async ({commit}, responseData) => {
            await commit('CREATE_SERVICE_TIP_ON_LIST', responseData.data);
        },

        commitCreateServiceTip: async ({commit}, responseData) => {
            await commit('CREATE_SERVICE_TIP', responseData.data);
        },

        commitUpdateServiceTipOnList: async ({commit}, responseData) => {
            await commit('UPDATE_SERVICE_TIP_ON_LIST', responseData.data);
        },

        commitUpdateServiceTip: async ({commit}, responseData) => {
            await commit('UPDATE_SERVICE_TIP', responseData.data);
        },

        commitDeleteServiceTipOnList: async ({commit}, serviceTipId) => {
            await commit('DELETE_SERVICE_TIP_ON_LIST', serviceTipId);
        },

        commitDeleteServiceTip: async ({commit}) => {
            await commit('DELETE_SERVICE_TIP');
        },

        commitResetServiceTips: async ({commit}) => {
            await commit('RESET_SERVICE_TIPS');
        },

        commitResetServiceTip: async ({commit}) => {
            await commit('RESET_SERVICE_TIP');
        },

        // get list of service tips by api call.
        getServiceTips: async ({dispatch}, params = {}) => {
            const path = `v1/service-tips`;

            return  axios.get(path, {params: params}).then(response => {
                dispatch('commitSetServiceTips', response.data);
                dispatch('commitSetPaginateLinks', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },
        // get single service tip by api call.
        getServiceTip: async ({dispatch}, paramObj) => {
            const path = `v1/service-tips/${paramObj.id}`;
            const params = paramObj.params ?? {};

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetServiceTip', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },
        // create new service tip on list by api call.
        postServiceTipOnList: async ({dispatch}, data) => {
            const path = `v1/service-tips`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateServiceTipOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // create new service tip by api call.
        postServiceTip: async ({dispatch}, data) => {
            const path = `v1/service-tips`;

            return axios.post(path, data).then((response) => {

                dispatch('commitCreateServiceTip', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing service tip on list by api call.
        putServiceTipOnList: async ({dispatch}, dataObj) => {
            const path = `v1/service-tips/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateServiceTipOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing service tip by api call.
        putServiceTip: async ({dispatch}, dataObj) => {
            const path = `v1/service-tips/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateServiceTip', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular service tip on list by api call.
        deleteServiceTipOnList: async ({dispatch}, id) => {
            const path = `v1/service-tips/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteServiceTipOnList', id);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular service tip by api call.
        deleteServiceTip: async ({dispatch}, id) => {
            const path = `v1/service-tips/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteServiceTip', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // reset service tips state.
        resetServiceTips: async ({dispatch}) => {
            dispatch('commitResetServiceTips');
        },

        // reset service tip state.
        resetServiceTip: async ({dispatch}) => {
            dispatch('commitResetServiceTip')
        },
    },


    };