import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {
        refundNotes: [],
        paginateLinks: {},
        refundNote: {},
    },

    getters: {
        refundNotes: (state) => {
            return state.refundNotes;
        },

        paginateLinks: (state) => {
            return state.paginateLinks;
        },

        refundNote: (state) => {
            return state.refundNote;
        },
    },
    mutations: {
        SET_REFUND_NOTES(state, refundNotes) {
            state.refundNotes = refundNotes;
        },

        SET_PAGINATE_LINKS(state, paginateLinks) {
            state.paginateLinks = paginateLinks;
        },

        SET_REFUND_NOTE(state, refundNote) {
            state.refundNote = refundNote;
        },

        CREATE_REFUND_NOTE_ON_LIST(state, refundNote) {
            state.refundNotes.unshift(refundNote);
        },

        CREATE_REFUND_NOTE(state, refundNote) {
            state.refundNote = refundNote;
        },

        UPDATE_REFUND_NOTE_ON_LIST(state, refundNote) {
            let index = state.refundNotes.findIndex(item => item.id === refundNote.id);
            state.refundNotes.splice(index, 1, refundNote);
        },

        UPDATE_REFUND_NOTE(state, refundNote) {
            state.refundNote = refundNote;
        },

        DELETE_REFUND_NOTE_ON_LIST(state, refundNoteId) {
            let index = state.refundNotes.findIndex(item => item.id === refundNoteId);
            state.refundNotes.splice(index, 1);
        },

        DELETE_REFUND_NOTE(state) {
            state.refundNote = {};
        },

        RESET_REFUND_NOTES(state) {
            state.refundNotes = [];
        },

        RESET_REFUND_NOTE(state) {
            state.refundNote = {};
        },
    },
    actions: {
        commitSetRefundNotes: async ({commit}, responseData) => {
            await commit('SET_REFUND_NOTES', responseData.data);
        },

        commitSetPaginateLinks: async ({commit}, responseData) => {
            await commit('SET_PAGINATE_LINKS', responseData.meta);
        },

        commitSetRefundNote: async ({commit}, responseData) => {
            await commit('SET_REFUND_NOTE', responseData.data)
        },

        commitCreateRefundNoteOnList: async ({commit}, responseData) => {
            await commit('CREATE_REFUND_NOTE_ON_LIST', responseData.data);
        },

        commitCreateRefundNote: async ({commit}, responseData) => {
            await commit('CREATE_REFUND_NOTE', responseData.data);
        },

        commitUpdateRefundNoteOnList: async ({commit}, responseData) => {
            await commit('UPDATE_REFUND_NOTE_ON_LIST', responseData.data);
        },

        commitUpdateRefundNote: async ({commit}, responseData) => {
            await commit('UPDATE_REFUND_NOTE', responseData.data);
        },

        commitDeleteRefundNoteOnList: async ({commit}, refundNoteId) => {
            await commit('DELETE_REFUND_NOTE_ON_LIST', refundNoteId);
        },

        commitDeleteRefundNote: async ({commit}) => {
            await commit('DELETE_REFUND_NOTE');
        },

        commitResetRefundNotes: async ({commit}) => {
            await commit('RESET_REFUND_NOTES');
        },

        commitResetRefundNote: async ({commit}) => {
            await commit('RESET_REFUND_NOTE');
        },

        // get list of refundNote by api call.
        getRefundNotes: async ({dispatch}, params = {}) => {
            const path = `v1/refund-notes`;

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetRefundNotes', response.data);
                dispatch('commitSetPaginateLinks', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // get single refundNote by api call.
        getRefundNote: async ({dispatch}, paramObj) => {
            const path = `v1/refund-notes/${paramObj.id}`;
            const params = paramObj.params ?? {};

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetRefundNote', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // create new refundNote on list by api call.
        postRefundNoteOnList: async ({dispatch}, data) => {
            const path = `v1/refund-notes`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateRefundNoteOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // create new refundNote by api call.
        postRefundNote: async ({dispatch}, data) => {
            const path = `v1/refund-notes`;

            return axios.post(path, data).then((response) => {

                dispatch('commitCreateRefundNote', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing refundNote on list by api call.
        putRefundNoteOnList: async ({dispatch}, dataObj) => {
            const path = `v1/refund-notes/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateRefundNoteOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing refundNote by api call.
        putRefundNote: async ({dispatch}, dataObj) => {
            const path = `v1/refund-notes/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateRefundNote', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular refundNote on list by api call.
        deleteRefundNoteOnList: async ({dispatch}, id) => {
            const path = `v1/refund-notes/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteRefundNoteOnList', id);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular refundNote by api call.
        deleteRefundNote: async ({dispatch}, id) => {
            const path = `v1/refund-notes/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteRefundNote', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // reset refundNotes state.
        resetRefundNotes: async ({dispatch}) => {
            dispatch('commitResetRefundNotes');
        },

        // reset orderNote state.
        resetRefundNote: async ({dispatch}) => {
            dispatch('commitResetRefundNote')
        },

    },
};
