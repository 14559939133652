import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {
        customerOpinions: [],
        paginateLinks: {},
        customerOpinion: {},
    },

    getters: {
        customerOpinions: (state) => {
            return state.customerOpinions;
        },

        paginateLinks: (state) => {
            return state.paginateLinks;
        },

        customerOpinion: (state) => {
            return state.customerOpinion;
        },
    },

    mutations: {
        SET_CUSTOMER_OPINIONS(state, customerOpinions) {
            state.customerOpinions = customerOpinions;
        },

        SET_PAGINATE_LINKS(state, paginateLinks) {
            state.paginateLinks = paginateLinks;
        },

        SET_CUSTOMER_OPINION(state, customerOpinion) {
            state.customerOpinion = customerOpinion;
        },

        CREATE_CUSTOMER_OPINION_ON_LIST(state, customerOpinion) {
            state.customerOpinions.unshift(customerOpinion);
        },

        CREATE_CUSTOMER_OPINION(state, customerOpinion) {
            state.customerOpinion = customerOpinion;
        },

        UPDATE_CUSTOMER_OPINION_ON_LIST(state, customerOpinion) {
            let index = state.customerOpinions.findIndex(item => item.id === customerOpinion.id);
            state.customerOpinions.splice(index, 1, customerOpinion);
        },

        UPDATE_CUSTOMER_OPINION(state, customerOpinion) {
            state.customerOpinion = customerOpinion;
        },

        DELETE_CUSTOMER_OPINION_ON_LIST(state, customerOpinionId) {
            let index = state.customerOpinions.findIndex(item => item.id === customerOpinionId);
            state.customerOpinions.splice(index, 1);
        },

        DELETE_CUSTOMER_OPINION(state) {
            state.customerOpinion = {};
        },

        RESET_CUSTOMER_OPINIONS(state) {
            state.customerOpinions = [];
        },

        RESET_CUSTOMER_OPINION(state) {
            state.customerOpinion = {};
        },
    },

    actions: {
        commitSetCustomerOpinions: async ({commit}, responseData) => {
            await commit('SET_CUSTOMER_OPINIONS', responseData.data);
        },

        commitSetPaginateLinks: async ({commit}, responseData) => {
            await commit('SET_PAGINATE_LINKS', responseData.meta);
        },

        commitSetCustomerOpinion: async ({commit}, responseData) => {
            await commit('SET_CUSTOMER_OPINION', responseData.data)
        },

        commitCreateCustomerOpinionOnList: async ({commit}, responseData) => {
            await commit('CREATE_CUSTOMER_OPINION_ON_LIST', responseData.data);
        },

        commitCreateCustomerOpinion: async ({commit}, responseData) => {
            await commit('CREATE_CUSTOMER_OPINION', responseData.data);
        },

        commitUpdateCustomerOpinionOnList: async ({commit}, responseData) => {
            await commit('UPDATE_CUSTOMER_OPINION_ON_LIST', responseData.data);
        },

        commitUpdateCustomerOpinion: async ({commit}, responseData) => {
            await commit('UPDATE_CUSTOMER_OPINION', responseData.data);
        },

        commitDeleteCustomerOpinionOnList: async ({commit}, customerOpinionId) => {
            await commit('DELETE_CUSTOMER_OPINION_ON_LIST', customerOpinionId);
        },

        commitDeleteCustomerOpinion: async ({commit}) => {
            await commit('DELETE_CUSTOMER_OPINION');
        },

        commitResetCustomerOpinions: async ({commit}) => {
            await commit('RESET_CUSTOMER_OPINIONS');
        },

        commitResetCustomerOpinion: async ({commit}) => {
            await commit('RESET_CUSTOMER_OPINION');
        },

        // get list of customer opinion by api call.
        getCustomerOpinions: async ({dispatch}, params = {}) => {
            const path = `v1/customer-opinions`;

            return  axios.get(path, {params: params}).then(response => {
                dispatch('commitSetCustomerOpinions', response.data);
                dispatch('commitSetPaginateLinks', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // get single customer opinion by api call.
        getCustomerOpinion: async ({dispatch}, paramObj) => {
            const path = `v1/customer-opinions/${paramObj.id}`;
            const params = paramObj.params ?? {};

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetCustomerOpinion', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // create new customer opinion on list by api call.
        postCustomerOpinionOnList: async ({dispatch}, data) => {
            const path = `v1/customer-opinions`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateCustomerOpinionOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // create new customer opinion by api call.
        postCustomerOpinion: async ({dispatch}, data) => {
            const path = `v1/customer-opinions`;

            return axios.post(path, data).then((response) => {

                dispatch('commitCreateCustomerOpinion', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing customer opinion on list by api call.
        putCustomerOpinionOnList: async ({dispatch}, dataObj) => {
            const path = `v1/customer-opinions/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateCustomerOpinionOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing customer opinion by api call.
        putCustomerOpinion: async ({dispatch}, dataObj) => {
            const path = `v1/customer-opinions/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateCustomerOpinion', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular customer opinion on list by api call.
        deleteCustomerOpinionOnList: async ({dispatch}, id) => {
            const path = `v1/customer-opinions/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteCustomerOpinionOnList', id);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular customer opinion by api call.
        deleteCustomerOpinion: async ({dispatch}, id) => {
            const path = `v1/customer-opinions/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteCustomerOpinion', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // reset customer opinions state.
        resetCustomerOpinions: async ({dispatch}) => {
            dispatch('commitResetCustomerOpinions');
        },

        // reset customer opinion state.
        resetCustomerOpinion: async ({dispatch}) => {
            dispatch('commitResetCustomerOpinion')
        },


        // customer-opinion-request-email 
        postCustomerOpinionRequestEmail: async (_, paramObj) =>
        {
            const path = `v1/customer-opinions/send-share-opinion-request-notify/${paramObj.id}`;
            const params = paramObj.params ?? {};
            return axios.post(path, params).then((response) =>
            {
                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) =>
            {
                return responseErrorHandler(error);

            });
        },

    },
};