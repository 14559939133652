import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {
        managers: [],
        paginateLinks: {},
        manager: {},
    },

    getters: {
        managers: (state) => {
            return state.managers;
        },

        paginateLinks: (state) => {
            return state.paginateLinks;
        },

        manager: (state) => {
            return state.manager;
        },
    },
    mutations: {
        SET_MANAGERS(state, managers) {
            state.managers = managers;
        },

        SET_PAGINATE_LINKS(state, paginateLinks) {
            state.paginateLinks = paginateLinks;
        },

        SET_MANAGER(state, manager) {
            state.manager = manager;
        },

        CREATE_MANAGER_ON_LIST(state, manager) {
            state.managers.unshift(manager);
        },

        CREATE_MANAGER(state, manager) {
            state.manager = manager;
        },

        UPDATE_MANAGER_ON_LIST(state, manager) {
            let index = state.managers.findIndex(item => item.id === manager.id);
            state.managers.splice(index, 1, manager);
        },

        UPDATE_MANAGER(state, manager) {
            state.manager = manager;
        },

        DELETE_MANAGER_ON_LIST(state, managerId) {
            let index = state.managers.findIndex(item => item.id === managerId);
            state.managers.splice(index, 1);
        },

        DELETE_MANAGER(state) {
            state.manager = {};
        },

        RESET_MANAGERS(state) {
            state.managers = [];
        },

        RESET_MANAGER(state) {
            state.manager = {};
        },
    },
    actions: {
        commitSetManagers: async ({commit}, responseData) => {
            await commit('SET_MANAGERS', responseData.data);
        },

        commitSetPaginateLinks: async ({commit}, responseData) => {
            await commit('SET_PAGINATE_LINKS', responseData.meta);
        },

        commitSetManager: async ({commit}, responseData) => {
            await commit('SET_MANAGER', responseData.data)
        },

        commitCreateManagerOnList: async ({commit}, responseData) => {
            await commit('CREATE_MANAGER_ON_LIST', responseData.data);
        },

        commitCreateManager: async ({commit}, responseData) => {
            await commit('CREATE_MANAGER', responseData.data);
        },

        commitUpdateManagerOnList: async ({commit}, responseData) => {
            await commit('UPDATE_MANAGER_ON_LIST', responseData.data);
        },

        commitUpdateManager: async ({commit}, responseData) => {
            await commit('UPDATE_MANAGER', responseData.data);
        },

        commitDeleteManagerOnList: async ({commit}, managerId) => {
            await commit('DELETE_MANAGER_ON_LIST', managerId);
        },

        commitDeleteManager: async ({commit}) => {
            await commit('DELETE_MANAGER');
        },

        commitResetManagers: async ({commit}) => {
            await commit('RESET_MANAGERS');
        },

        commitResetManager: async ({commit}) => {
            await commit('RESET_MANAGER');
        },

        // get list of manager by api call.
        getManagers: async ({dispatch}, params = {}) => {
            const path = `v1/managers`;

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetManagers', response.data);
                dispatch('commitSetPaginateLinks', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // get single manager by api call.
        getManager: async ({dispatch}, paramObj) => {
            const path = `v1/managers/${paramObj.id}`;
            const params = paramObj.params ?? {};

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetManager', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // create new manager on list by api call.
        postManagerOnList: async ({dispatch}, data) => {
            const path = `v1/managers`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateManagerOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // create new manager by api call.
        postManager: async ({dispatch}, data) => {
            const path = `v1/managers`;

            return axios.post(path, data).then((response) => {

                dispatch('commitCreateManager', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing manager on list by api call.
        putManagerOnList: async ({dispatch}, dataObj) => {
            const path = `v1/managers/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateManagerOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing manager by api call.
        putManager: async ({dispatch}, dataObj) => {
            const path = `v1/managers/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateManager', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular manager on list by api call.
        deleteManagerOnList: async ({dispatch}, id) => {
            const path = `v1/managers/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteManagerOnList', id);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular manager by api call.
        deleteManager: async ({dispatch}, id) => {
            const path = `v1/managers/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteManager', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // reset managers state.
        resetManagers: async ({dispatch}) => {
            dispatch('commitResetManagers');
        },

        // reset manager state.
        resetManager: async ({dispatch}) => {
            dispatch('commitResetManager')
        },

    },
};
