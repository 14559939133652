import axios from "axios";
import responseErrorHandler from  "@/store/helper/responseErrorHandler";

export default {
    namespaced:true,

    state: {
        scamCategories: [],
        paginateLinks: {},
        scamCategory: {},
    },

    getters: {
        scamCategories: (state) => {
            return state.scamCategories;
        },

        paginateLinks: (state) => {
            return state.paginateLinks;
        },

        scamCategory: (state) => {
            return state.scamCategory;
        },
    },

    mutations: {
        SET_SCAM_CATEGORIES(state, scamCategories) {
            state.scamCategories = scamCategories;
        },

        SET_PAGINATE_LINKS(state, paginateLinks) {
            state.paginateLinks = paginateLinks;
        },

        SET_SCAM_CATEGORY(state, scamCategory) {
            state.scamCategory = scamCategory;
        },

        CREATE_SCAM_CATEGORY_ON_LIST(state, scamCategory) {
            state.scamCategories.unshift(scamCategory);
        },

        CREATE_SCAM_CATEGORY(state, scamCategory) {
            state.scamCategory = scamCategory;
        },

        UPDATE_SCAM_CATEGORY_ON_LIST(state, scamCategory) {
            let index = state.scamCategories.findIndex(item => item.id === scamCategory.id);
            state.scamCategories.splice(index, 1, scamCategory);
        },

        UPDATE_SCAM_CATEGORY(state, scamCategory) {
            state.scamCategory = scamCategory;
        },

        DELETE_SCAM_CATEGORY_ON_LIST(state, scamCategoryId) {
            let index = state.scamCategories.findIndex(item => item.id === scamCategoryId);
            state.scamCategories.splice(index, 1);
        },

        DELETE_SCAM_CATEGORY(state) {
            state.scamCategory = {};
        },

        RESET_SCAM_CATEGORIES(state) {
            state.scamCategories = [];
        },

        RESET_SCAM_CATEGORY(state) {
            state.scamCategory = {};
        },
    },

    actions: {
        commitSetScamCategories: async ({commit}, responseData) => {
            await commit('SET_SCAM_CATEGORIES', responseData.data);
        },

        commitSetPaginateLinks: async ({commit}, responseData) => {
            await commit('SET_PAGINATE_LINKS', responseData.meta);
        },

        commitSetScamCategory: async ({commit}, responseData) => {
            await commit('SET_SCAM_CATEGORY', responseData.data)
        },

        commitCreateScamCategoryOnList: async ({commit}, responseData) => {
            await commit('CREATE_SCAM_CATEGORY_ON_LIST', responseData.data);
        },

        commitCreateScamCategory: async ({commit}, responseData) => {
            await commit('CREATE_SCAM_CATEGORY', responseData.data);
        },

        commitUpdateScamCategoryOnList: async ({commit}, responseData) => {
            await commit('UPDATE_SCAM_CATEGORY_ON_LIST', responseData.data);
        },

        commitUpdateScamCategory: async ({commit}, responseData) => {
            await commit('UPDATE_SCAM_CATEGORY', responseData.data);
        },

        commitDeleteScamCategoryOnList: async ({commit}, ScamCategoryId) => {
            await commit('DELETE_SCAM_CATEGORY_ON_LIST', ScamCategoryId);
        },

        commitDeleteScamCategory: async ({commit}) => {
            await commit('DELETE_SCAM_CATEGORY');
        },

        commitResetScamCategories: async ({commit}) => {
            await commit('RESET_SCAM_CATEGORIES');
        },

        commitResetScamCategory: async ({commit}) => {
            await commit('RESET_SCAM_CATEGORY');
        },

        // get list of post categories by api call.
        getScamCategories: async ({dispatch}, params = {}) => {
            const path = `v1/post-categories`;

            return  axios.get(path, {params: params}).then(response => {
                dispatch('commitSetScamCategories', response.data);
                dispatch('commitSetPaginateLinks', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },
        // get single post category by api call.
        getScamCategory: async ({dispatch}, paramObj) => {
            const path = `v1/post-categories/${paramObj.id}`;
            const params = paramObj.params ?? {};

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetScamCategory', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },
        // create new post category on list by api call.
        postScamCategoryOnList: async ({dispatch}, data) => {
            const path = `v1/post-categories`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateScamCategoryOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // create new post category by api call.
        postScamCategory: async ({dispatch}, data) => {
            const path = `v1/post-categories`;

            return axios.post(path, data).then((response) => {

                dispatch('commitCreateScamCategory', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing post category on list by api call.
        putScamCategoryOnList: async ({dispatch}, dataObj) => {
            const path = `v1/post-categories/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateScamCategoryOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing post category by api call.
        putScamCategory: async ({dispatch}, dataObj) => {
            const path = `v1/post-categories/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateScamCategory', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular post category on list by api call.
        deleteScamCategoryOnList: async ({dispatch}, id) => {
            const path = `v1/post-categories/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteScamCategoryOnList', id);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular post category by api call.
        deleteScamCategory: async ({dispatch}, id) => {
            const path = `v1/post-categories/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteScamCategory', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // reset post categories state.
        resetScamCategories: async ({dispatch}) => {
            dispatch('commitResetScamCategories');
        },

        // reset post category state.
        resetScamCategory: async ({dispatch}) => {
            dispatch('commitResetScamCategory')
        },
    },


};