import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {
        franchisees: [],
        paginateLinks: {},
        franchisee: {},
    },

    getters: {
        franchisees: (state) => {
            return state.franchisees;
        },

        paginateLinks: (state) => {
            return state.paginateLinks;
        },

        franchisee: (state) => {
            return state.franchisee;
        },
    },
    mutations: {
        SET_FRANCHISEES(state, franchisees) {
            state.franchisees = franchisees;
        },

        SET_PAGINATE_LINKS(state, paginateLinks) {
            state.paginateLinks = paginateLinks;
        },

        SET_FRANCHISEE(state, franchisee) {
            state.franchisee = franchisee;
        },

        CREATE_FRANCHISEE_ON_LIST(state, franchisee) {
            state.franchisees.unshift(franchisee);
        },

        CREATE_FRANCHISEE(state, franchisee) {
            state.franchisee = franchisee;
        },

        UPDATE_FRANCHISEE_ON_LIST(state, franchisee) {
            let index = state.franchisees.findIndex(item => item.id === franchisee.id);
            state.franchisees.splice(index, 1, franchisee);
        },

        UPDATE_FRANCHISEE(state, franchisee) {
            state.franchisee = franchisee;
        },

        DELETE_FRANCHISEE_ON_LIST(state, franchiseeId) {
            let index = state.franchisees.findIndex(item => item.id === franchiseeId);
            state.franchisees.splice(index, 1);
        },

        DELETE_FRANCHISEE(state) {
            state.franchisee = {};
        },

        RESET_FRANCHISEES(state) {
            state.franchisees = [];
        },

        RESET_FRANCHISEE(state) {
            state.franchisee = {};
        },
    },
    actions: {
        commitSetFranchisees: async ({commit}, responseData) => {
            await commit('SET_FRANCHISEES', responseData.data);
        },

        commitSetPaginateLinks: async ({commit}, responseData) => {
            await commit('SET_PAGINATE_LINKS', responseData.meta);
        },

        commitSetFranchisee: async ({commit}, responseData) => {
            await commit('SET_FRANCHISEE', responseData.data)
        },

        commitCreateFranchiseeOnList: async ({commit}, responseData) => {
            await commit('CREATE_FRANCHISEE_ON_LIST', responseData.data);
        },

        commitCreateFranchisee: async ({commit}, responseData) => {
            await commit('CREATE_FRANCHISEE', responseData.data);
        },

        commitUpdateFranchiseeOnList: async ({commit}, responseData) => {
            await commit('UPDATE_FRANCHISEE_ON_LIST', responseData.data);
        },

        commitUpdateFranchisee: async ({commit}, responseData) => {
            await commit('UPDATE_FRANCHISEE', responseData.data);
        },

        commitDeleteFranchiseeOnList: async ({commit}, franchiseeId) => {
            await commit('DELETE_FRANCHISEE_ON_LIST', franchiseeId);
        },

        commitDeleteFranchisee: async ({commit}) => {
            await commit('DELETE_FRANCHISEE');
        },

        commitResetFranchisees: async ({commit}) => {
            await commit('RESET_FRANCHISEES');
        },

        commitResetFranchisee: async ({commit}) => {
            await commit('RESET_FRANCHISEE');
        },

        // get list of franchisee by api call.
        getFranchisees: async ({dispatch}, params = {}) => {
            const path = `v1/franchisees`;

            return  axios.get(path, {params: params}).then(response => {
                dispatch('commitSetFranchisees', response.data);
                dispatch('commitSetPaginateLinks', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // get single franchisee by api call.
        getFranchisee: async ({dispatch}, paramObj) => {
            const path = `v1/franchisees/${paramObj.id}`;
            const params = paramObj.params ?? {};

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetFranchisee', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // create new franchisee on list by api call.
        postFranchiseeOnList: async ({dispatch}, data) => {
            const path = `v1/franchisees`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateFranchiseeOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // create new franchisee by api call.
        postFranchisee: async ({dispatch}, data) => {
            const path = `v1/franchisees`;

            return axios.post(path, data).then((response) => {

                dispatch('commitCreateFranchisee', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing franchisee on list by api call.
        putFranchiseeOnList: async ({dispatch}, dataObj) => {
            const path = `v1/franchisees/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateFranchiseeOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing franchisee by api call.
        putFranchisee: async ({dispatch}, dataObj) => {
            const path = `v1/franchisees/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateFranchisee', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular franchisee on list by api call.
        deleteFranchiseeOnList: async ({dispatch}, id) => {
            const path = `v1/franchisees/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteFranchiseeOnList', id);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular franchisee by api call.
        deleteFranchisee: async ({dispatch}, id) => {
            const path = `v1/franchisees/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteFranchisee', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // reset franchisees state.
        resetFranchisees: async ({dispatch}) => {
            dispatch('commitResetFranchisees');
        },

        // reset franchisee state.
        resetFranchisee: async ({dispatch}) => {
            dispatch('commitResetFranchisee')
        },

    },
};
