import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {
        orderJobNotes: [],
        paginateLinks: {},
        orderJobNote: {},
    },

    getters: {
        orderJobNotes: (state) => {
            return state.orderJobNotes;
        },

        paginateLinks: (state) => {
            return state.paginateLinks;
        },

        orderJobNote: (state) => {
            return state.orderJobNote;
        },
    },
    mutations: {
        SET_ORDER_JOB_NOTES(state, orderJobNotes) {
            state.orderJobNotes = orderJobNotes;
        },

        SET_PAGINATE_LINKS(state, paginateLinks) {
            state.paginateLinks = paginateLinks;
        },

        SET_ORDER_JOB_NOTE(state, orderJobNote) {
            state.orderJobNote = orderJobNote;
        },

        CREATE_ORDER_JOB_NOTE_ON_LIST(state, orderJobNote) {
            state.orderJobNotes.unshift(orderJobNote);
        },

        CREATE_ORDER_JOB_NOTE(state, orderJobNote) {
            state.orderJobNote = orderJobNote;
        },

        UPDATE_ORDER_JOB_NOTE_ON_LIST(state, orderJobNote) {
            let index = state.orderJobNotes.findIndex(item => item.id === orderJobNote.id);
            state.orderJobNotes.splice(index, 1, orderJobNote);
        },

        UPDATE_ORDER_JOB_NOTE(state, orderJobNote) {
            state.orderJobNote = orderJobNote;
        },

        DELETE_ORDER_JOB_NOTE_ON_LIST(state, orderCreatorId) {
            let index = state.orderJobNotes.findIndex(item => item.id === orderCreatorId);
            state.orderJobNotes.splice(index, 1);
        },

        DELETE_ORDER_JOB_NOTE(state) {
            state.orderJobNote = {};
        },

        RESET_ORDER_JOB_NOTES(state) {
            state.orderJobNotes = [];
        },

        RESET_ORDER_JOB_NOTE(state) {
            state.orderJobNote = {};
        },
    },
    actions: {
        commitSetOrderJobNotes: async ({commit}, responseData) => {
            await commit('SET_ORDER_JOB_NOTES', responseData.data);
        },

        commitSetPaginateLinks: async ({commit}, responseData) => {
            await commit('SET_PAGINATE_LINKS', responseData.meta);
        },

        commitSetOrderJobNote: async ({commit}, responseData) => {
            await commit('SET_ORDER_JOB_NOTE', responseData.data)
        },

        commitCreateOrderJobNoteOnList: async ({commit}, responseData) => {
            await commit('CREATE_ORDER_JOB_NOTE_ON_LIST', responseData.data);
        },

        commitCreateOrderJobNote: async ({commit}, responseData) => {
            await commit('CREATE_ORDER_JOB_NOTE', responseData.data);
        },

        commitUpdateOrderJobNoteOnList: async ({commit}, responseData) => {
            await commit('UPDATE_ORDER_JOB_NOTE_ON_LIST', responseData.data);
        },

        commitUpdateOrderJobNote: async ({commit}, responseData) => {
            await commit('UPDATE_ORDER_JOB_NOTE', responseData.data);
        },

        commitDeleteOrderJobNoteOnList: async ({commit}, orderCreatorId) => {
            await commit('DELETE_ORDER_JOB_NOTE_ON_LIST', orderCreatorId);
        },

        commitDeleteOrderJobNote: async ({commit}) => {
            await commit('DELETE_ORDER_JOB_NOTE');
        },

        commitResetOrderJobNotes: async ({commit}) => {
            await commit('RESET_ORDER_JOB_NOTES');
        },

        commitResetOrderJobNote: async ({commit}) => {
            await commit('RESET_ORDER_JOB_NOTE');
        },

        // get list of orderJobNote by api call.
        getOrderJobNotes: async ({dispatch}, params = {}) => {
            const path = `v1/order-job-notes`;

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetOrderJobNotes', response.data);
                dispatch('commitSetPaginateLinks', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // get single orderJobNote by api call.
        getOrderJobNote: async ({dispatch}, paramObj) => {
            const path = `v1/order-job-notes/${paramObj.id}`;
            const params = paramObj.params ?? {};

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetOrderJobNote', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // create new orderJobNote on list by api call.
        postOrderJobNoteOnList: async ({dispatch}, data) => {
            const path = `v1/order-job-notes`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateOrderJobNoteOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // create new orderJobNote by api call.
        postOrderJobNote: async ({dispatch}, data) => {
            const path = `v1/order-job-notes`;

            return axios.post(path, data).then((response) => {

                dispatch('commitCreateOrderJobNote', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing orderJobNote on list by api call.
        putOrderJobNoteOnList: async ({dispatch}, dataObj) => {
            const path = `v1/order-job-notes/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateOrderJobNoteOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing orderJobNote by api call.
        putOrderJobNote: async ({dispatch}, dataObj) => {
            const path = `v1/order-job-notes/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateOrderJobNote', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular orderJobNote on list by api call.
        deleteOrderJobNoteOnList: async ({dispatch}, id) => {
            const path = `v1/order-job-notes/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteOrderJobNoteOnList', id);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular orderJobNote by api call.
        deleteOrderJobNote: async ({dispatch}, id) => {
            const path = `v1/order-job-notes/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteOrderJobNote', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // reset orderJobNotes state.
        resetOrderJobNotes: async ({dispatch}) => {
            dispatch('commitResetOrderJobNotes');
        },

        // reset orderJobNote state.
        resetOrderJobNote: async ({dispatch}) => {
            dispatch('commitResetOrderJobNote')
        },

    },
};
