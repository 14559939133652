import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store';

import appRoutes from "@/router/appRoutes";
import middlewarePipeline from "@/router/helper/middlewarePipeline";
import auth from "@/router/middleware/auth";
import guest from "@/router/middleware/guest";


const routes = [
  ...appRoutes,

  {
    path: '/',
    name: 'appLogin',
    props: true,
    component: () => import(/* webpackChunkName: "appLogin" */ '@/views/backEnd/auth/LogIn.vue'),
    meta: {
      title: `Login`,
      middleware: [
        guest,
      ],
    },
  },

  {
    path: '/tech-tracker',
    name: 'techTracker',
    component: () => import(/* webpackChunkName: "Tech-Tracker" */ '../views/backEnd/users/technicians/TechnicianTracker.vue')
  },
  {
    path:'/orders/checkout',
    name:'app.orders.checkout',
    component:()=>import('../views/backEnd/Orders/OrderCheckout'),
  },

  // account-expense-create
  {
    path: '/account/expense/create',
    name: 'app.account.expense.create',
    component: () => import(/* webpackChunkName: "incomeCreate" */ '../views/backEnd/AccountAndFinance/Account/expenses/ExpenseCreate.vue')
  },

  // account-expense-list
  {
    path: '/account/expense/list',
    name: 'app.account.expense.list',
    component: () => import(/* webpackChunkName: "expenseList" */ '../views/backEnd/AccountAndFinance/Account/expenses/ExpenseList.vue')
  },

  // account-expense-edit
  {
    path: '/account/expense/:id/edit',
    name: 'app.account.expense.edit',
    component: () => import(/* webpackChunkName: "expenseEdit" */ '../views/backEnd/AccountAndFinance/Account/expenses/ExpenseEdit.vue')
  },

  //cash tracking
  {
    path: '/account/finance/payments/cash-collections',
    name: 'appAccountFinancePaymentCashCollections',
    component: () => import('@/views/backEnd/AccountAndFinance/CashTracking/CashTracking.vue')
  },
  // credited payment
  {
    path: '/app/credited-payment',
    name: 'app.payment.credited-payment',
    component: () => import('@/views/backEnd/payments/CreditedPayment')
  },


  {
    path: '/settings/order',
    name: 'settings.order',
    component: () => import(/* webpackChunkName: "orderSetting" */ '../views/backEnd/settings/order/OrderSetting.vue')
  },


  {
    path: '/settings/payment',
    name: 'settings.payment',
    component: () => import(/* webpackChunkName: "paymentSetting" */ '../views/backEnd/settings/payment/PaymentSetting.vue')
  },


  {
    path: '/account/expense/category/create',
    name: 'app.expense.category.create',
    component: () => import(/* webpackChunkName: "incomeCreate" */ '../views/backEnd/AccountAndFinance/Account/ExpenseCategory/ExpenseCategoryCreate')
  },
  {
    path: '/account/expense/category/:id/edit',
    name: 'app.expense.category.edit',
    component: () => import(/* webpackChunkName: "incomeCreate" */ '../views/backEnd/AccountAndFinance/Account/ExpenseCategory/ExpenseCategoryEdit')
  },
  {
    path: '/account/expense/category/list',
    name: 'app.expense.category.list',
    component: () => import(/* webpackChunkName: "incomeCreate" */ '../views/backEnd/AccountAndFinance/Account/ExpenseCategory/ExpenseCategoryList')
  },
  // expense category end
  //****setting section ends ****
  {
    path: '/account/expense/type/list',
    name: 'app.expense.type.list',
    component: () => import(/* webpackChunkName: "expenseTypes" */ '../views/backEnd/settings/ExpenseType/ExpenseTypeList')
  },
  {
    path: '/account/expense/type/create',
    name: 'app.expense.type.create',
    component: () => import(/* webpackChunkName: "expenseCreate" */ '../views/backEnd/settings/ExpenseType/ExpenseTypeCreate')
  },
  {
    path: '/account/expense/type/:id/edit',
    name: 'app.expense.type.edit',
    component: () => import(/* webpackChunkName: "expenseCreate" */ '../views/backEnd/settings/ExpenseType/ExpenseTypeEdit')
  },

  {
    path: '/forbidden',
    name: '403',
    component: () => import(/* webpackChunkName: "403" */ '@/views/global/errors/403'),
    meta: {
      middleware: [
        auth
      ],
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: () => import(/* webpackChunkName: "404 page" */ '../views/global/NotFound/NotFound.vue')
  },



];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) =>
{
  if (to.meta.title) {
    document.title = `${to.meta.title} | ${process.env.VUE_APP_NAME}`;
  }

 
  if (from.name != "appLogin" && from.name != "403" && from.name != "appForgotPassword" && from.name != "appResetPassword" ) {
    store.commit('SET_PREVIOUS_ROUTE', {name:from?.name ?? 'appDashboard',params:from.params});
  }

  if (!to.meta.middleware) return next();

  const middleware = to.meta.middleware;
  const permissionGates = to.meta.permissionGates ?? [];

  const context = { to, from, next, store, permissionGates };

  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
    permissionGates: permissionGates
  });
});

export default router;
