import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";
export default {
    namespaced: true,
    state: {
        appointmentConsents: [],
        paginateLinks: {},
        appointmentConsent: {},
    },
    getters: {
        appointmentConsents: (state) =>
        {
            return state.appointmentConsents;
        },
        paginateLinks: (state) =>
        {
            return state.paginateLinks;
        },
        appointmentConsent: (state) =>
        {
            return state.appointmentConsent;
        },
    },
    mutations: {
        SET_APPOINTMENT_CONSENTS (state, appointmentConsents)
        {
            state.appointmentConsents = appointmentConsents;
        },
        SET_PAGINATE_LINKS (state, paginateLinks)
        {
            state.paginateLinks = paginateLinks;
        },

        DELETE_APPOINTMENT_CONSENT_ON_LIST (state, appointmentConsentId)
        {
            let index = state.appointmentConsents.findIndex(item => item.id === appointmentConsentId);
            state.appointmentConsents.splice(index, 1);
            // state.paginateLinks.from = state.paginateLinks.total > 0 ? 1 : 0;
            // // state.paginateLinks.last_page = state.paginateLinks.last_page - 1;
            // state.paginateLinks.to = state.paginateLinks.to - 1;
            // state.paginateLinks.total = state.paginateLinks.total - 1;

        },

        RESET_APPOINTMENT_CONSENTS (state)
        {
            state.appointmentConsents = [];
            state.paginateLinks = {};
        },
    },
    actions: {
        commitSetAppointmentConsents: ({ commit }, responseData) =>
        {
            commit('SET_APPOINTMENT_CONSENTS', responseData.data)
        },
        commitSetPaginateLinks: async ({ commit }, responseData) =>
        {
            await commit('SET_PAGINATE_LINKS', responseData.meta)
        },
        commitDeleteAppointmentConsentOnList: async ({ commit }, appointmentConsentId) =>
        {
            await commit('DELETE_APPOINTMENT_CONSENT_ON_LIST', appointmentConsentId);
        },

        commitResetAppointmentConsents: async ({ commit }) =>
        {
            await commit('RESET_APPOINTMENT_CONSENTS');
        },


        // get list of Appointment Consents by api call
        getAppointmentConsents: ({ dispatch }, params) =>
        {
            const path = `v1/appointment-consents`;
            return axios.get(path, { params: params }).then(response =>
            {
                dispatch('commitSetAppointmentConsents', response.data);
                dispatch('commitSetPaginateLinks', response.data);
            }).catch(error =>
            {
                return responseErrorHandler(error);
            })
        },
        // delete a particular appointment consent on list by api call.
        deleteAppointmentConsentOnList: async ({ dispatch }, id) =>
        {
            const path = `v1/appointment-consents/${id}`;

            return axios.delete(path).then((response) =>
            {
                dispatch('commitDeleteAppointmentConsentOnList', id);



                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) =>
            {
                return responseErrorHandler(error);
            });
        },







        //  reset Appointment Consents 
        resetAppointmentConsents: async ({ dispatch }) =>
        {
            dispatch('commitResetAppointmentConsents');
        },
    },
};
