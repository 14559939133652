import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {
        employees: [],
        paginateLinks: {},
        employee: {},
    },

    getters: {
        employees: (state) => {
            return state.employees;
        },

        paginateLinks: (state) => {
            return state.paginateLinks;
        },

        employee: (state) => {
            return state.employee;
        },
    },
    mutations: {
        SET_EMPLOYEES(state, employees) {
            state.employees = employees;
        },

        SET_PAGINATE_LINKS(state, paginateLinks) {
            state.paginateLinks = paginateLinks;
        },

        SET_EMPLOYEE(state, employee) {
            state.employee = employee;
        },

        CREATE_EMPLOYEE_ON_LIST(state, employee) {
            state.employees.unshift(employee);
        },

        CREATE_EMPLOYEE(state, employee) {
            state.employee = employee;
        },

        UPDATE_EMPLOYEE_ON_LIST(state, employee) {
            let index = state.employees.findIndex(item => item.id === employee.id);
            state.employees.splice(index, 1, employee);
        },

        UPDATE_EMPLOYEE(state, employee) {
            state.employee = employee;
        },

        DELETE_EMPLOYEE_ON_LIST(state, employeeId) {
            let index = state.employees.findIndex(item => item.id === employeeId);
            state.employees.splice(index, 1);
        },

        DELETE_EMPLOYEE(state) {
            state.employee = {};
        },

        RESET_EMPLOYEES(state) {
            state.employees = [];
        },

        RESET_EMPLOYEE(state) {
            state.employee = {};
        },
    },
    actions: {
        commitSetEmployees: async ({commit}, responseData) => {
            await commit('SET_EMPLOYEES', responseData.data);
        },

        commitSetPaginateLinks: async ({commit}, responseData) => {
            await commit('SET_PAGINATE_LINKS', responseData.meta);
        },

        commitSetEmployee: async ({commit}, responseData) => {
            await commit('SET_EMPLOYEE', responseData.data)
        },

        commitCreateEmployeeOnList: async ({commit}, responseData) => {
            await commit('CREATE_EMPLOYEE_ON_LIST', responseData.data);
        },

        commitCreateEmployee: async ({commit}, responseData) => {
            await commit('CREATE_EMPLOYEE', responseData.data);
        },

        commitUpdateEmployeeOnList: async ({commit}, responseData) => {
            await commit('UPDATE_EMPLOYEE_ON_LIST', responseData.data);
        },

        commitUpdateEmployee: async ({commit}, responseData) => {
            await commit('UPDATE_EMPLOYEE', responseData.data);
        },

        commitDeleteEmployeeOnList: async ({commit}, employeeId) => {
            await commit('DELETE_EMPLOYEE_ON_LIST', employeeId);
        },

        commitDeleteEmployee: async ({commit}) => {
            await commit('DELETE_EMPLOYEE');
        },

        commitResetEmployees: async ({commit}) => {
            await commit('RESET_EMPLOYEES');
        },

        commitResetEmployee: async ({commit}) => {
            await commit('RESET_EMPLOYEE');
        },

        // get list of employee by api call.
        getEmployees: async ({dispatch}, params) => {
            const path = `v1/employees`;

            return  axios.get(path, {params: params}).then(response => {
                dispatch('commitSetEmployees', response.data);
                dispatch('commitSetPaginateLinks', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // get single employee by api call.
        getEmployee: async ({dispatch}, paramObj) => {
            const path = `v1/employees/${paramObj.id}`;
            const params = paramObj.params ?? {};

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetEmployee', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // create new employee on list by api call.
        postEmployeeOnList: async ({dispatch}, data) => {
            const path = `v1/employees`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateEmployeeOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // create new employee by api call.
        postEmployee: async ({dispatch}, data) => {
            const path = `v1/employees`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateEmployee', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing employee on list by api call.
        putEmployeeOnList: async ({dispatch}, dataObj) => {
            const path = `v1/employees/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateEmployeeOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing employee by api call.
        putEmployee: async ({dispatch}, dataObj) => {
            const path = `v1/employees/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateEmployee', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular employee on list by api call.
        deleteEmployeeOnList: async ({dispatch}, id) => {
            const path = `v1/employees/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteEmployeeOnList', id);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular employee by api call.
        deleteEmployee: async ({dispatch}, id) => {
            const path = `v1/employees/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteEmployee', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update employee avatar by api call.
        putAvatar: async (_, dataObj) =>
        {
        
            const path = `v1/employees/${dataObj.id}/avatar`;
            const data = dataObj.data ?? {};

            return axios.put(path, data).then((response) =>
            {
               
                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) =>
            {
                return responseErrorHandler(error);
            });
        },

        // reset employees state.
        resetEmployees: async ({dispatch}) => {
            dispatch('commitResetEmployees');
        },

        // reset employee state.
        resetEmployee: async ({dispatch}) => {
            dispatch('commitResetEmployee')
        },

    },
};
