import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {
        warehouses: [],
        paginateLinks: {},
        warehouse: {},
    },

    getters: {
        warehouses: (state) => {
            return state.warehouses;
        },

        paginateLinks: (state) => {
            return state.paginateLinks;
        },

        warehouse: (state) => {
            return state.warehouse;
        },
    },
    mutations: {
        SET_WAREHOUSES(state, warehouses) {
            state.warehouses = warehouses;
        },

        SET_PAGINATE_LINKS(state, paginateLinks) {
            state.paginateLinks = paginateLinks;
        },

        SET_WAREHOUSE(state, warehouse) {
            state.warehouse = warehouse;
        },

        CREATE_WAREHOUSE_ON_LIST(state, warehouse) {
            state.warehouses.unshift(warehouse);
        },

        CREATE_WAREHOUSE(state, warehouse) {
            state.warehouse = warehouse;
        },

        UPDATE_WAREHOUSE_ON_LIST(state, warehouse) {
            let index = state.warehouses.findIndex(item => item.id === warehouse.id);
            state.warehouses.splice(index, 1, warehouse);
        },

        UPDATE_WAREHOUSE(state, warehouse) {
            state.warehouse = warehouse;
        },

        DELETE_WAREHOUSE_ON_LIST(state, warehouseId) {
            let index = state.warehouses.findIndex(item => item.id === warehouseId);
            state.warehouses.splice(index, 1);
        },

        DELETE_WAREHOUSE(state) {
            state.warehouse = {};
        },

        RESET_WAREHOUSES(state) {
            state.warehouses = [];
        },

        RESET_WAREHOUSE(state) {
            state.warehouse = {};
        },
    },
    actions: {
        commitSetWarehouses: async ({commit}, responseData) => {
            await commit('SET_WAREHOUSES', responseData.data);
        },

        commitSetPaginateLinks: async ({commit}, responseData) => {
            await commit('SET_PAGINATE_LINKS', responseData.meta);
        },

        commitSetWarehouse: async ({commit}, responseData) => {
            await commit('SET_WAREHOUSE', responseData.data)
        },

        commitCreateWarehouseOnList: async ({commit}, responseData) => {
            await commit('CREATE_WAREHOUSE_ON_LIST', responseData.data);
        },

        commitCreateWarehouse: async ({commit}, responseData) => {
            await commit('CREATE_WAREHOUSE', responseData.data);
        },

        commitUpdateWarehouseOnList: async ({commit}, responseData) => {
            await commit('UPDATE_WAREHOUSE_ON_LIST', responseData.data);
        },

        commitUpdateWarehouse: async ({commit}, responseData) => {
            await commit('UPDATE_WAREHOUSE', responseData.data);
        },

        commitDeleteWarehouseOnList: async ({commit}, warehouseId) => {
            await commit('DELETE_WAREHOUSE_ON_LIST', warehouseId);
        },

        commitDeleteWarehouse: async ({commit}) => {
            await commit('DELETE_WAREHOUSE');
        },

        commitResetWarehouses: async ({commit}) => {
            await commit('RESET_WAREHOUSES');
        },

        commitResetWarehouse: async ({commit}) => {
            await commit('RESET_WAREHOUSE');
        },

        // get list of warehouse by api call.
        getWarehouses: async ({dispatch}, params = {}) => {
            const path = `v1/warehouses`;

            return  axios.get(path, {params: params}).then(response => {
                dispatch('commitSetWarehouses', response.data);
                dispatch('commitSetPaginateLinks', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // get single warehouse by api call.
        getWarehouse: async ({dispatch}, paramObj) => {
            const path = `v1/warehouses/${paramObj.id}`;
            const params = paramObj.params ?? {};

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetWarehouse', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // create new warehouse on list by api call.
        postWarehouseOnList: async ({dispatch}, data) => {
            const path = `v1/warehouses`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateWarehouseOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // create new warehouse by api call.
        postWarehouse: async ({dispatch}, data) => {
            const path = `v1/warehouses`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateWarehouse', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing warehouse on list by api call.
        putWarehouseOnList: async ({dispatch}, dataObj) => {
            const path = `v1/warehouses/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateWarehouseOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing warehouse by api call.
        putWarehouse: async ({dispatch}, dataObj) => {
            const path = `v1/warehouses/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateWarehouse', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular warehouse on list by api call.
        deleteWarehouseOnList: async ({dispatch}, id) => {
            const path = `v1/warehouses/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteWarehouseOnList', id);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular warehouse by api call.
        deleteWarehouse: async ({dispatch}, id) => {
            const path = `v1/warehouses/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteWarehouse', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // reset warehouses state.
        resetWarehouses: async ({dispatch}) => {
            dispatch('commitResetWarehouses');
        },

        // reset warehouse state.
        resetWarehouse: async ({dispatch}) => {
            dispatch('commitResetWarehouse')
        },

    },
};
