import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {
        coupons: [],
        paginateLinks: {},
        coupon: {},
    },

    getters: {
        coupons: (state) =>
        {
            return state.coupons;
        },

        paginateLinks: (state) =>
        {
            return state.paginateLinks;
        },

        coupon: (state) =>
        {
            return state.coupon;
        },
    },
    mutations: {
        SET_COUPONS (state, coupons)
        {
            state.coupons = coupons;
        },

        SET_PAGINATE_LINKS (state, paginateLinks)
        {
            state.paginateLinks = paginateLinks;
        },

        SET_COUPON (state, coupon)
        {
            state.coupon = coupon;
        },

        CREATE_COUPON_ON_LIST (state, coupon)
        {
            state.coupons.unshift(coupon);
        },

        CREATE_COUPON (state, coupon)
        {
            state.coupon = coupon;
        },

        UPDATE_COUPON_ON_LIST (state, coupon)
        {
            let index = state.coupons.findIndex(item => item.id === coupon.id);
            state.coupons.splice(index, 1, coupon);
        },

        UPDATE_COUPON (state, coupon)
        {
            state.coupon = coupon;
        },

        DELETE_COUPON_ON_LIST (state, couponId)
        {
            let index = state.coupons.findIndex(item => item.id === couponId);
            state.coupons.splice(index, 1);
        },

        DELETE_COUPON (state)
        {
            state.coupon = {};
        },

        RESET_COUPONS (state)
        {
            state.coupons = [];
        },

        RESET_COUPON (state)
        {
            state.coupon = {};
        },
    },
    actions: {
        commitSetCoupons: async ({ commit }, responseData) =>
        {
            await commit('SET_COUPONS', responseData.data);
        },

        commitSetPaginateLinks: async ({ commit }, responseData) =>
        {
            await commit('SET_PAGINATE_LINKS', responseData.meta);
        },

        commitSetCoupon: async ({ commit }, responseData) =>
        {
            await commit('SET_COUPON', responseData.data)
        },

        commitCreateCouponOnList: async ({ commit }, responseData) =>
        {
            await commit('CREATE_COUPON_ON_LIST', responseData.data);
        },

        commitCreateCoupon: async ({ commit }, responseData) =>
        {
            await commit('CREATE_COUPON', responseData.data);
        },

        commitUpdateCouponOnList: async ({ commit }, responseData) =>
        {
            await commit('UPDATE_COUPON_ON_LIST', responseData.data);
        },

        commitUpdateCoupon: async ({ commit }, responseData) =>
        {
            await commit('UPDATE_COUPON', responseData.data);
        },

        commitDeleteCouponOnList: async ({ commit }, couponId) =>
        {
            await commit('DELETE_COUPON_ON_LIST', couponId);
        },

        commitDeleteCoupon: async ({ commit }) =>
        {
            await commit('DELETE_COUPON');
        },

        commitResetCoupons: async ({ commit }) =>
        {
            await commit('RESET_COUPONS');
        },

        commitResetCoupon: async ({ commit }) =>
        {
            await commit('RESET_COUPON');
        },

        // get list of coupon by api call.
        getCoupons: async ({ dispatch }, params = {}) =>
        {
            const path = `v1/coupons`;

            return axios.get(path, { params: params }).then(response =>
            {

                dispatch('commitSetCoupons', response.data);
                dispatch('commitSetPaginateLinks', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error =>
            {
                return responseErrorHandler(error);
            });
        },
        // get list of coupon by api call.
        getAvailableValidCoupons: async ({ dispatch }, params = {}) =>
        {
            const path = `v1/coupons/available-valid-coupons`;

            return axios.get(path, { params: params }).then(response =>
            {

                dispatch('commitSetCoupons', response.data);
                // dispatch('commitSetPaginateLinks', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error =>
            {
                return responseErrorHandler(error);
            });
        },

        // get single coupon by api call.
        getCoupon: async ({ dispatch }, paramObj) =>
        {
            const path = `v1/coupons/${paramObj.id}`;
            const params = paramObj.params ?? {};

            return axios.get(path, { params: params }).then(response =>
            {
                dispatch('commitSetCoupon', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error =>
            {
                return responseErrorHandler(error);
            });
        },

        // create new coupon on list by api call.
        postCouponOnList: async ({ dispatch }, data) =>
        {
            const path = `v1/coupons`;

            return axios.post(path, data).then((response) =>
            {
                dispatch('commitCreateCouponOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) =>
            {
                return responseErrorHandler(error);
            });
        },

        // create new coupon by api call.
        postCoupon: async ({ dispatch }, data) =>
        {
            const path = `v1/coupons`;

            return axios.post(path, data).then((response) =>
            {

                dispatch('commitCreateCoupon', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) =>
            {
                return responseErrorHandler(error);
            });
        },

        // update single existing coupon on list by api call.
        putCouponOnList: async ({ dispatch }, dataObj) =>
        {
            const path = `v1/coupons/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) =>
            {
                dispatch('commitUpdateCouponOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) =>
            {
                return responseErrorHandler(error);
            });
        },

        // update single existing coupon by api call.
        putCoupon: async ({ dispatch }, dataObj) =>
        {
            const path = `v1/coupons/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) =>
            {
                dispatch('commitUpdateCoupon', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) =>
            {
                return responseErrorHandler(error);
            });
        },

        // delete a particular coupon on list by api call.
        deleteCouponOnList: async ({ dispatch }, id) =>
        {
            const path = `v1/coupons/${id}`;

            return axios.delete(path).then((response) =>
            {
                dispatch('commitDeleteCouponOnList', id);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) =>
            {
                return responseErrorHandler(error);
            });
        },

        // delete a particular coupon by api call.
        deleteCoupon: async ({ dispatch }, id) =>
        {
            const path = `v1/coupons/${id}`;

            return axios.delete(path).then((response) =>
            {
                dispatch('commitDeleteCoupon', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) =>
            {
                return responseErrorHandler(error);
            });
        },
        sendMarketingNotify: async (_, dataObj) =>
        {
            const path = `v1/coupons/send-marketing-notify/${dataObj.id}`;

            return axios.post(path, dataObj.data).then((response) =>
            {
                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) =>
            {
                return responseErrorHandler(error);
            });
        },
        couponPreview: async (_, dataObj) =>
        {
            const path = `v1/coupons/marketing-notify-preview/${dataObj.id}`;

            return axios.post(path, dataObj.data).then((response) =>
            {
                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                    data: response.data,
                };
            }).catch((error) =>
            {
                return responseErrorHandler(error);
            });
        },

        // reset coupons state.
        resetCoupons: async ({ dispatch }) =>
        {
            dispatch('commitResetCoupons');
        },

        // reset coupon state.
        resetCoupon: async ({ dispatch }) =>
        {
            dispatch('commitResetCoupon')
        },

    },
};
