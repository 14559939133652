import axios from "axios";
import store from '@/store';

store.subscribe(mutation => {

    if (mutation.type === 'appAuthLogin/SET_AUTH_TOKEN') {
        if (mutation.payload) {
            let tokenType = mutation.payload.token_type;
            let accessToken = mutation.payload.access_token;

            axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`;

            localStorage.setItem('authToken', JSON.stringify(mutation.payload));

        } else {
            axios.defaults.headers.common['Authorization'] = null;
            localStorage.removeItem('authToken');
        }
    }

    if (mutation.type === 'appAuthLogin/SET_AUTH_USER') {
        if (mutation.payload) {
            localStorage.setItem('authUser', JSON.stringify(mutation.payload));

        } else {
            localStorage.removeItem('authUser');
        }
    }

    if (mutation.type === 'appAuthLogin/SET_AUTH_FRANCHISEE') {
        if (mutation.payload) {
            localStorage.setItem('authFranchisee', JSON.stringify(mutation.payload));

        } else {
            localStorage.removeItem('authFranchisee');
        }
    }

    if (mutation.type === 'appAuthLogin/SET_AUTH_PERMISSIONS') {
        if (mutation.payload) {
            localStorage.setItem('authPermissions', JSON.stringify(mutation.payload));

        } else {
            localStorage.removeItem('authPermissions');
        }
    }
});


