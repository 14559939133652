import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {
        blogTags: [],
        paginateLinks: {},
        blogTag: {},
    },

    getters: {
        blogTags: (state) => {
            return state.blogTags;
        },

        paginateLinks: (state) => {
            return state.paginateLinks;
        },

        blogTag: (state) => {
            return state.blogTag;
        },
    },

    mutations: {
        SET_BLOG_TAGS(state, blogTags) {
            state.blogTags = blogTags;
        },

        SET_PAGINATE_LINKS(state, paginateLinks) {
            state.paginateLinks = paginateLinks;
        },

        SET_BLOG_TAG(state, blogTag) {
            state.blogTag = blogTag;
        },

        CREATE_BLOG_TAG_ON_LIST(state, blogTag) {
            state.blogTags.unshift(blogTag);
        },

        CREATE_BLOG_TAG(state, blogTag) {
            state.blogTag = blogTag;
        },

        UPDATE_BLOG_TAG_ON_LIST(state, blogTag) {
            let index = state.blogTags.findIndex(item => item.id === blogTag.id);
            state.blogTags.splice(index, 1, blogTag);
        },

        UPDATE_BLOG_TAG(state, blogTag) {
            state.blogTag = blogTag;
        },

        DELETE_BLOG_TAG_ON_LIST(state, blogTagId) {
            let index = state.blogTags.findIndex(item => item.id === blogTagId);
            state.blogTags.splice(index, 1);
        },

        DELETE_BLOG_TAG(state) {
            state.blogTag = {};
        },

        RESET_BLOG_TAGS(state) {
            state.blogTags = [];
        },

        RESET_BLOG_TAG(state) {
            state.blogTag = {};
        },
    },
    actions: {
        commitSetBlogTags: async ({commit}, responseData) => {
            await commit('SET_BLOG_TAGS', responseData.data);
        },

        commitSetPaginateLinks: async ({commit}, responseData) => {
            await commit('SET_PAGINATE_LINKS', responseData.meta);
        },

        commitSetBlogTag: async ({commit}, responseData) => {
            await commit('SET_BLOG_TAG', responseData.data)
        },

        commitCreateBlogTagOnList: async ({commit}, responseData) => {
            await commit('CREATE_BLOG_TAG_ON_LIST', responseData.data);
        },

        commitCreateBlogTag: async ({commit}, responseData) => {
            await commit('CREATE_BLOG_TAG', responseData.data);
        },

        commitUpdateBlogTagOnList: async ({commit}, responseData) => {
            await commit('UPDATE_BLOG_TAG_ON_LIST', responseData.data);
        },

        commitUpdateBlogTag: async ({commit}, responseData) => {
            await commit('UPDATE_BLOG_TAG', responseData.data);
        },

        commitDeleteBlogTagOnList: async ({commit}, blogTagId) => {
            await commit('DELETE_BLOG_TAG_ON_LIST', blogTagId);
        },

        commitDeleteBlogTag: async ({commit}) => {
            await commit('DELETE_BLOG_TAG');
        },

        commitResetBlogTags: async ({commit}) => {
            await commit('RESET_BLOG_TAGS');
        },

        commitResetBlogTag: async ({commit}) => {
            await commit('RESET_BLOG_TAG');
        },

        // get list of blog tags by api call.
        getBlogTags: async ({dispatch}, params = {}) => {
            const path = `v1/blog-tags`;

            return  axios.get(path, {params: params}).then(response => {
                dispatch('commitSetBlogTags', response.data);
                dispatch('commitSetPaginateLinks', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },
        // get single blog tag by api call.
        getBlogTag: async ({dispatch}, paramObj) => {
            const path = `v1/blog-tags/${paramObj.id}`;
            const params = paramObj.params ?? {};

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetBlogTag', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },
        // create new blog tag on list by api call.
        postBlogTagOnList: async ({dispatch}, data) => {
            const path = `v1/blog-tags`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateBlogTagOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // create new blog tag by api call.
        postBlogTag: async ({dispatch}, data) => {
            const path = `v1/blog-tags`;

            return axios.post(path, data).then((response) => {

                dispatch('commitCreateBlogTag', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing blog tag on list by api call.
        putBlogTagOnList: async ({dispatch}, dataObj) => {
            const path = `v1/blog-tags/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateBlogTagOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing blog tag by api call.
        putBlogTag: async ({dispatch}, dataObj) => {
            const path = `v1/blog-tags/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateBlogTag', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular blog tag on list by api call.
        deleteBlogTagOnList: async ({dispatch}, id) => {
            const path = `v1/blog-tags/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteBlogTagOnList', id);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular blog tag by api call.
        deleteBlogTag: async ({dispatch}, id) => {
            const path = `v1/blog-tags/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteBlogTag', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // reset blog tags state.
        resetBlogTags: async ({dispatch}) => {
            dispatch('commitResetBlogTags');
        },

        // reset blog tag state.
        resetBlogTag: async ({dispatch}) => {
            dispatch('commitResetBlogTag')
        },
    },


};