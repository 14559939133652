import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {
        productCategories: [],
        paginateLinks: {},
        productCategory: {},
    },

    getters: {
        productCategories: (state) => {
            return state.productCategories;
        },

        paginateLinks: (state) => {
            return state.paginateLinks;
        },

        productCategory: (state) => {
            return state.productCategory;
        },
    },
    mutations: {
        SET_PRODUCT_CATEGORIES(state, productCategories) {
            state.productCategories = productCategories;
        },

        SET_PAGINATE_LINKS(state, paginateLinks) {
            state.paginateLinks = paginateLinks;
        },

        SET_PRODUCT_CATEGORY(state, productCategory) {
            state.productCategory = productCategory;
        },

        CREATE_PRODUCT_CATEGORY_ON_LIST(state, productCategory) {
            state.productCategories.unshift(productCategory);
        },

        CREATE_PRODUCT_CATEGORY(state, productCategory) {
            state.productCategory = productCategory;
        },

        UPDATE_PRODUCT_CATEGORY_ON_LIST(state, productCategory) {
            let index = state.productCategories.findIndex(item => item.id === productCategory.id);
            state.productCategories.splice(index, 1, productCategory);
        },

        UPDATE_PRODUCT_CATEGORY(state, productCategory) {
            state.productCategory = productCategory;
        },

        DELETE_PRODUCT_CATEGORY_ON_LIST(state, productCategoryId) {
            let index = state.productCategories.findIndex(item => item.id === productCategoryId);
            state.productCategories.splice(index, 1);
        },

        DELETE_PRODUCT_CATEGORY(state) {
            state.productCategory = {};
        },

        RESET_PRODUCT_CATEGORIES(state) {
            state.productCategories = [];
        },

        RESET_PRODUCT_CATEGORY(state) {
            state.productCategory = {};
        },
    },
    actions: {
        commitSetProductCategories: async ({commit}, responseData) => {
            await commit('SET_PRODUCT_CATEGORIES', responseData.data);
        },

        commitSetPaginateLinks: async ({commit}, responseData) => {
            await commit('SET_PAGINATE_LINKS', responseData.meta);
        },

        commitSetProductCategory: async ({commit}, responseData) => {
            await commit('SET_PRODUCT_CATEGORY', responseData.data)
        },

        commitCreateProductCategoryOnList: async ({commit}, responseData) => {
            await commit('CREATE_PRODUCT_CATEGORY_ON_LIST', responseData.data);
        },

        commitCreateProductCategory: async ({commit}, responseData) => {
            await commit('CREATE_PRODUCT_CATEGORY', responseData.data);
        },

        commitUpdateProductCategoryOnList: async ({commit}, responseData) => {
            await commit('UPDATE_PRODUCT_CATEGORY_ON_LIST', responseData.data);
        },

        commitUpdateProductCategory: async ({commit}, responseData) => {
            await commit('UPDATE_PRODUCT_CATEGORY', responseData.data);
        },

        commitDeleteProductCategoryOnList: async ({commit}, productCategoryId) => {
            await commit('DELETE_PRODUCT_CATEGORY_ON_LIST', productCategoryId);
        },

        commitDeleteProductCategory: async ({commit}) => {
            await commit('DELETE_PRODUCT_CATEGORY');
        },

        commitResetProductCategories: async ({commit}) => {
            await commit('RESET_PRODUCT_CATEGORIES');
        },

        commitResetProductCategory: async ({commit}) => {
            await commit('RESET_PRODUCT_CATEGORY');
        },

        // get list of product categories by api call.
        getProductCategories: async ({dispatch}, params = {}) => {
            const path = `v1/product-categories`;

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetProductCategories', response.data);
                dispatch('commitSetPaginateLinks', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // get single product category by api call.
        getProductCategory: async ({dispatch}, paramObj) => {
            const path = `v1/product-categories/${paramObj.id}`;
            const params = paramObj.params ?? {};

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetProductCategory', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // create new product category on list by api call.
        postProductCategoryOnList: async ({dispatch}, data) => {
            const path = `v1/product-categories`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateProductCategoryOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // create new product category by api call.
        postProductCategory: async ({dispatch}, data) => {
            const path = `v1/product-categories`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateProductCategory', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing product category on list by api call.
        putProductCategoryOnList: async ({dispatch}, dataObj) => {
            const path = `v1/product-categories/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateProductCategoryOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing product category by api call.
        putProductCategory: async ({dispatch}, dataObj) => {
            const path = `v1/product-categories/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateProductCategory', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular product category on list by api call.
        deleteProductCategoryOnList: async ({dispatch}, id) => {
            const path = `v1/product-categories/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteProductCategoryOnList', id);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular product category by api call.
        deleteProductCategory: async ({dispatch}, id) => {
            const path = `v1/product-categories/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteProductCategory', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // reset product categories state.
        resetProductCategories: async ({dispatch}) => {
            dispatch('commitResetProductCategories');
        },

        // reset product category state.
        resetProductCategory: async ({dispatch}) => {
            dispatch('commitResetProductCategory')
        },

    },
};
