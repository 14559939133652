import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {
        productItemTransactions: [],
        paginateLinks: {},
        productItemTransaction: {},


    },

    getters: {
        productItemTransactions: (state) => {
            return state.productItemTransactions;
        },

        paginateLinks: (state) => {
            return state.paginateLinks;
        },

        productItemTransaction: (state) => {
            return state.productItemTransaction;
        },

    },
    mutations: {
        SET_PRODUCT_ITEM_TRANSACTIONS(state, productItemTransactions) {
            state.productItemTransactions = productItemTransactions;
        },


        SET_PAGINATE_LINKS(state, paginateLinks) {
            state.paginateLinks = paginateLinks;
        },

        SET_PRODUCT_ITEM_TRANSACTION(state, productItemTransaction) {
            state.productItemTransaction = productItemTransaction;
        },

        CREATE_PRODUCT_ITEM_TRANSACTION_ON_LIST(state, productItemTransaction) {
            state.productItemTransactions.unshift(productItemTransaction);
        },

        CREATE_PRODUCT_ITEM_TRANSACTION(state, productItemTransaction) {
            state.productItemTransaction = productItemTransaction;
        },

        UPDATE_PRODUCT_ITEM_TRANSACTION_ON_LIST(state, productItemTransaction) {
            let index = state.productItemTransactions.findIndex(item => item.id === productItemTransaction.id);
            state.productItemTransactions.splice(index, 1, productItemTransaction);
        },

        UPDATE_PRODUCT_ITEM_TRANSACTION(state, productItemTransaction) {
            state.productItemTransaction = productItemTransaction;
        },

        DELETE_PRODUCT_ITEM_TRANSACTION_ON_LIST(state, productItemTransactionId) {
            let index = state.productItemTransactions.findIndex(item => item.id === productItemTransactionId);
            state.productItemTransactions.splice(index, 1);
        },

        DELETE_PRODUCT_ITEM_TRANSACTION(state) {
            state.productItemTransaction = {};
        },

        RESET_PRODUCT_ITEM_TRANSACTIONS(state) {
            state.productItemTransactions = [];
        },

        RESET_PRODUCT_ITEM_TRANSACTION(state) {
            state.productItemTransaction = {};
        },
    },
    actions: {
        commitSetProductItemTransactions: async ({commit}, responseData) => {
            await commit('SET_PRODUCT_ITEM_TRANSACTIONS', responseData.data);
        },

        commitSetPaginateLinks: async ({commit}, responseData) => {
            await commit('SET_PAGINATE_LINKS', responseData.meta);
        },

        commitSetProductItemTransaction: async ({commit}, responseData) => {
            await commit('SET_PRODUCT_ITEM_TRANSACTION', responseData.data)
        },

        commitCreateProductItemTransactionOnList: async ({commit}, responseData) => {
            await commit('CREATE_PRODUCT_ITEM_TRANSACTION_ON_LIST', responseData.data);
        },

        commitCreateProductItemTransaction: async ({commit}, responseData) => {
            await commit('CREATE_PRODUCT_ITEM_TRANSACTION', responseData.data);
        },

        commitUpdateProductItemTransactionOnList: async ({commit}, responseData) => {
            await commit('UPDATE_PRODUCT_ITEM_TRANSACTION_ON_LIST', responseData.data);
        },

        commitUpdateProductItemTransaction: async ({commit}, responseData) => {
            await commit('UPDATE_PRODUCT_ITEM_TRANSACTION', responseData.data);
        },

        commitDeleteProductItemTransactionOnList: async ({commit}, productItemTransactionId) => {
            await commit('DELETE_PRODUCT_ITEM_TRANSACTION_ON_LIST', productItemTransactionId);
        },

        commitDeleteProductItemTransaction: async ({commit}) => {
            await commit('DELETE_PRODUCT_ITEM_TRANSACTION');
        },

        commitResetProductItemTransactions: async ({commit}) => {
            await commit('RESET_PRODUCT_ITEM_TRANSACTIONS');
        },

        commitResetProductItemTransaction: async ({commit}) => {
            await commit('RESET_PRODUCT_ITEM_TRANSACTION');
        },

        // get list of productItemTransaction by api call.
        getProductItemTransactions: async ({dispatch}, params = {}) => {
            const path = `v1/product-item-transactions`;

            return axios.get(path, {params: params}).then(response => {

                dispatch('commitSetProductItemTransactions', response.data);
                dispatch('commitSetPaginateLinks', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },


        // get single productItemTransaction by api call.
        getProductItemTransaction: async ({dispatch}, paramObj) => {
            const path = `v1/product-item-transactions/${paramObj.id}`;
            const params = paramObj.params ?? {};

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetProductItemTransaction', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // create new productItemTransaction on list by api call.
        postProductItemTransactionOnList: async ({dispatch}, data) => {
            const path = `v1/product-item-transactions`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateProductItemTransactionOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // create new productItemTransaction by api call.
        postProductItemTransaction: async ({dispatch}, data) => {
            const path = `v1/product-item-transactions`;
            return axios.post(path, data).then((response) => {
                dispatch('commitCreateProductItemTransaction', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing productItemTransaction on list by api call.
        putProductItemTransactionOnList: async ({dispatch}, dataObj) => {
            const path = `v1/product-item-transactions/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateProductItemTransactionOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing productItemTransaction by api call.
        putProductItemTransaction: async ({dispatch}, dataObj) => {
            const path = `v1/product-item-transactions/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateProductItemTransaction', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular productItemTransaction on list by api call.
        deleteProductItemTransactionOnList: async ({dispatch}, id) => {
            const path = `v1/product-item-transactions/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteProductItemTransactionOnList', id);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular productItemTransaction by api call.
        deleteProductItemTransaction: async ({dispatch}, id) => {
            const path = `v1/product-item-transactions/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteProductItemTransaction', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },
        postProductItemTransactionStoreList: async ({dispatch}, data) => {
            const path = `v1/product-item-transactions/store-list`;

            return axios.post(path, data).then((response) => {

                dispatch('commitCreateProductItemTransaction', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // reset productItemTransactions state.
        resetProductItemTransactions: async ({dispatch}) => {
            dispatch('commitResetProductItemTransactions');
        },

        // reset productItemTransaction state.
        resetProductItemTransaction: async ({dispatch}) => {
            dispatch('commitResetProductItemTransaction')
        },

    },
};
