import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {
        expenseCategories: [],
        paginateLinks: {},
        expenseCategory: {},
    },

    getters: {
        expenseCategories: (state) => {
            return state.expenseCategories;
        },

        paginateLinks: (state) => {
            return state.paginateLinks;
        },

        expenseCategory: (state) => {
            return state.expenseCategory;
        },
    },
    mutations: {
        SET_EXPENSE_CATEGORIES(state, expenseCategories) {
            state.expenseCategories = expenseCategories;
        },

        SET_PAGINATE_LINKS(state, paginateLinks) {
            state.paginateLinks = paginateLinks;
        },

        SET_EXPENSE_CATEGORY(state, expenseCategory) {
            state.expenseCategory = expenseCategory;
        },

        CREATE_EXPENSE_CATEGORY_ON_LIST(state, expenseCategory) {
            state.expenseCategories.unshift(expenseCategory);
        },

        CREATE_EXPENSE_CATEGORY(state, expenseCategory) {
            state.expenseCategory = expenseCategory;
        },

        UPDATE_EXPENSE_CATEGORY_ON_LIST(state, expenseCategory) {
            let index = state.expenseCategories.findIndex(item => item.id === expenseCategory.id);
            state.expenseCategories.splice(index, 1, expenseCategory);
        },

        UPDATE_EXPENSE_CATEGORY(state, expenseCategory) {
            state.expenseCategory = expenseCategory;
        },

        DELETE_EXPENSE_CATEGORY_ON_LIST(state, expenseCategoryId) {
            let index = state.expenseCategories.findIndex(item => item.id === expenseCategoryId);
            state.expenseCategories.splice(index, 1);
        },

        DELETE_EXPENSE_CATEGORY(state) {
            state.expenseCategory = {};
        },

        RESET_EXPENSE_CATEGORIES(state) {
            state.expenseCategories = [];
        },

        RESET_EXPENSE_CATEGORY(state) {
            state.expenseCategory = {};
        },
    },
    actions: {
        commitSetExpenseCategories: async ({commit}, responseData) => {
            await commit('SET_EXPENSE_CATEGORIES', responseData.data);
        },

        commitSetPaginateLinks: async ({commit}, responseData) => {
            await commit('SET_PAGINATE_LINKS', responseData.meta);
        },

        commitSetExpenseCategory: async ({commit}, responseData) => {
            await commit('SET_EXPENSE_CATEGORY', responseData.data)
        },

        commitCreateExpenseCategoryOnList: async ({commit}, responseData) => {
            await commit('CREATE_EXPENSE_CATEGORY_ON_LIST', responseData.data);
        },

        commitCreateExpenseCategory: async ({commit}, responseData) => {
            await commit('CREATE_EXPENSE_CATEGORY', responseData.data);
        },

        commitUpdateExpenseCategoryOnList: async ({commit}, responseData) => {
            await commit('UPDATE_EXPENSE_CATEGORY_ON_LIST', responseData.data);
        },

        commitUpdateExpenseCategory: async ({commit}, responseData) => {
            await commit('UPDATE_EXPENSE_CATEGORY', responseData.data);
        },

        commitDeleteExpenseCategoryOnList: async ({commit}, expenseCategoryId) => {
            await commit('DELETE_EXPENSE_CATEGORY_ON_LIST', expenseCategoryId);
        },

        commitDeleteExpenseCategory: async ({commit}) => {
            await commit('DELETE_EXPENSE_CATEGORY');
        },

        commitResetExpenseCategories: async ({commit}) => {
            await commit('RESET_EXPENSE_CATEGORIES');
        },

        commitResetExpenseCategory: async ({commit}) => {
            await commit('RESET_EXPENSE_CATEGORY');
        },

        // get list of expense category by api call.
        getExpenseCategories: async ({dispatch}, params = {}) => {
            const path = `v1/expense-categories`;

            return  axios.get(path, {params: params}).then(response => {
                dispatch('commitSetExpenseCategories', response.data);
                dispatch('commitSetPaginateLinks', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // get single expense category by api call.
        getExpenseCategory: async ({dispatch}, paramObj) => {
            const path = `v1/expense-categories/${paramObj.id}`;
            const params = paramObj.params ?? {};

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetExpenseCategory', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // create new expense category on list by api call.
        postExpenseCategoryOnList: async ({dispatch}, data) => {
            const path = `v1/expense-categories`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateExpenseCategoryOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // create new expense category by api call.
        postExpenseCategory: async ({dispatch}, data) => {
            const path = `v1/expense-categories`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateExpenseCategory', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing expense category on list by api call.
        putExpenseCategoryOnList: async ({dispatch}, dataObj) => {
            const path = `v1/expense-categories/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateExpenseCategoryOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing expense category by api call.
        putExpenseCategory: async ({dispatch}, dataObj) => {
            const path = `v1/expense-categories/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateExpenseCategory', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular expense category on list by api call.
        deleteExpenseCategoryOnList: async ({dispatch}, id) => {
            const path = `v1/expense-categories/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteExpenseCategoryOnList', id);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular expense category by api call.
        deleteExpenseCategory: async ({dispatch}, id) => {
            const path = `v1/expense-categories/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteExpenseCategory', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // reset expense categories state.
        resetExpenseCategories: async ({dispatch}) => {
            dispatch('commitResetExpenseCategories');
        },

        // reset expense category state.
        resetExpenseCategory: async ({dispatch}) => {
            dispatch('commitResetExpenseCategory')
        },

    },
};
