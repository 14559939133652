import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {
        creditedPayments: [],
        paginateLinks: {},
        creditedPayment: {},
    },

    getters: {
        creditedPayments: (state) => {
            return state.creditedPayments;
        },

        paginateLinks: (state) => {
            return state.paginateLinks;
        },

        creditedPayment: (state) => {
            return state.creditedPayment;
        },
    },
    mutations: {
        SET_CREDITED_PAYMENTS(state, creditedPayments) {
            state.creditedPayments = creditedPayments;
        },

        SET_PAGINATE_LINKS(state, paginateLinks) {
            state.paginateLinks = paginateLinks;
        },

        SET_CREDITED_PAYMENT(state, creditedPayment) {
            state.creditedPayment = creditedPayment;
        },

        CREATE_CREDITED_PAYMENT_ON_LIST(state, creditedPayment) {
            state.creditedPayments.unshift(creditedPayment);
        },

        CREATE_CREDITED_PAYMENT(state, creditedPayment) {
            state.creditedPayment = creditedPayment;
        },

        UPDATE_CREDITED_PAYMENT_ON_LIST(state, creditedPayment) {
            let index = state.creditedPayments.findIndex(item => item.id === creditedPayment.id);
            state.creditedPayments.splice(index, 1, creditedPayment);
        },

        UPDATE_CREDITED_PAYMENT(state, creditedPayment) {
            state.creditedPayment = creditedPayment;
        },

        DELETE_CREDITED_PAYMENT_ON_LIST(state, creditedPaymentId) {
            let index = state.creditedPayments.findIndex(item => item.id === creditedPaymentId);
            state.creditedPayments.splice(index, 1);
        },

        DELETE_CREDITED_PAYMENT(state) {
            state.creditedPayment = {};
        },

        RESET_CREDITED_PAYMENTS(state) {
            state.creditedPayments = [];
        },

        RESET_CREDITED_PAYMENT(state) {
            state.creditedPayment = {};
        },
    },
    actions: {
        commitSetCreditedPayments: async ({commit}, responseData) => {
            await commit('SET_CREDITED_PAYMENTS', responseData.data);
        },

        commitSetPaginateLinks: async ({commit}, responseData) => {
            await commit('SET_PAGINATE_LINKS', responseData.meta);
        },

        commitSetCreditedPayment: async ({commit}, responseData) => {
            await commit('SET_CREDITED_PAYMENT', responseData.data)
        },

        commitCreateCreditedPaymentOnList: async ({commit}, responseData) => {
            await commit('CREATE_CREDITED_PAYMENT_ON_LIST', responseData.data);
        },

        commitCreateCreditedPayment: async ({commit}, responseData) => {
            await commit('CREATE_CREDITED_PAYMENT', responseData.data);
        },

        commitUpdateCreditedPaymentOnList: async ({commit}, responseData) => {
            await commit('UPDATE_CREDITED_PAYMENT_ON_LIST', responseData.data);
        },

        commitUpdateCreditedPayment: async ({commit}, responseData) => {
            await commit('UPDATE_CREDITED_PAYMENT', responseData.data);
        },

        commitDeleteCreditedPaymentOnList: async ({commit}, creditedPaymentId) => {
            await commit('DELETE_CREDITED_PAYMENT_ON_LIST', creditedPaymentId);
        },

        commitDeleteCreditedPayment: async ({commit}) => {
            await commit('DELETE_CREDITED_PAYMENT');
        },

        commitResetCreditedPayments: async ({commit}) => {
            await commit('RESET_CREDITED_PAYMENTS');
        },

        commitResetCreditedPayment: async ({commit}) => {
            await commit('RESET_CREDITED_PAYMENT');
        },

        // get list of creditedPayment by api call.
        getCreditedPayments: async ({dispatch}, params = {}) => {
            const path = `v1/credited-payments`;

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetCreditedPayments', response.data);
                dispatch('commitSetPaginateLinks', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // get single creditedPayment by api call.
        getCreditedPayment: async ({dispatch}, paramObj) => {
            const path = `v1/credited-payments/${paramObj.id}`;
            const params = paramObj.params ?? {};

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetCreditedPayment', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // create new creditedPayment on list by api call.
        postCreditedPaymentOnList: async ({dispatch}, data) => {
            const path = `v1/credited-payments`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateCreditedPaymentOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // create new creditedPayment by api call.
        postCreditedPayment: async ({dispatch}, data) => {
            const path = `v1/credited-payments`;

            return axios.post(path, data).then((response) => {

                dispatch('commitCreateCreditedPayment', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing creditedPayment on list by api call.
        putCreditedPaymentOnList: async ({dispatch}, dataObj) => {
            const path = `v1/credited-payments/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateCreditedPaymentOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing creditedPayment by api call.
        putCreditedPayment: async ({dispatch}, dataObj) => {
            const path = `v1/credited-payments/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateCreditedPayment', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular creditedPayment on list by api call.
        deleteCreditedPaymentOnList: async ({dispatch}, id) => {
            const path = `v1/credited-payments/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteCreditedPaymentOnList', id);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular creditedPayment by api call.
        deleteCreditedPayment: async ({dispatch}, id) => {
            const path = `v1/credited-payments/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteCreditedPayment', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // reset creditedPayments state.
        resetCreditedPayments: async ({dispatch}) => {
            dispatch('commitResetCreditedPayments');
        },

        // reset creditedPayment state.
        resetCreditedPayment: async ({dispatch}) => {
            dispatch('commitResetCreditedPayment')
        },

    },
};
