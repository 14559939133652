import axios from "axios";
import responseErrorHandler  from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {
        scams: [],
        paginateLinks: {},
        scam: {},
    },

    getters: {
        scams: (state) => {
            return state.scams;
        },

        paginateLinks: (state) => {
            return state.paginateLinks;
        },

        scam: (state) => {
            return state.scam;
        },
    },

    mutations: {
        SET_SCAMS(state, scams) {
            state.scams = scams;
        },

        SET_PAGINATE_LINKS(state, paginateLinks) {
            state.paginateLinks = paginateLinks;
        },

        SET_SCAM(state, scam) {
            state.scam = scam;
        },

        CREATE_SCAM_ON_LIST(state, scam) {
            state.scams.unshift(scam);
        },

        CREATE_SCAM(state, scam) {
            state.scam = scam;
        },

        UPDATE_SCAM_ON_LIST(state, scam) {
            let index = state.scams.findIndex(item => item.id === scam.id);
            state.scams.splice(index, 1, scam);
        },

        UPDATE_SCAM(state, scam) {
            state.scam = scam;
        },

        DELETE_SCAM_ON_LIST(state, blogId) {
            let index = state.scams.findIndex(item => item.id === blogId);
            state.scams.splice(index, 1);
        },

        DELETE_SCAM(state) {
            state.scam = {};
        },

        RESET_SCAMS(state) {
            state.scams = [];
        },

        RESET_SCAM(state) {
            state.scam = {};
        },
    },

    actions: {
        commitSetScams: async ({commit}, responseData) => {
            await commit('SET_SCAMS', responseData.data);
        },

        commitSetPaginateLinks: async ({commit}, responseData) => {
            await commit('SET_PAGINATE_LINKS', responseData.meta);
        },

        commitSetScam: async ({commit}, responseData) => {
            await commit('SET_SCAM', responseData.data)
        },

        commitCreateScamOnList: async ({commit}, responseData) => {
            await commit('CREATE_SCAM_ON_LIST', responseData.data);
        },

        commitCreateScam: async ({commit}, responseData) => {
            await commit('CREATE_SCAM', responseData.data);
        },

        commitUpdateScamOnList: async ({commit}, responseData) => {
            await commit('UPDATE_SCAM_ON_LIST', responseData.data);
        },

        commitUpdateScam: async ({commit}, responseData) => {
            await commit('UPDATE_SCAM', responseData.data);
        },

        commitDeleteScamOnList: async ({commit}, blogId) => {
            await commit('DELETE_SCAM_ON_LIST', blogId);
        },

        commitDeleteScam: async ({commit}) => {
            await commit('DELETE_SCAM');
        },

        commitResetScams: async ({commit}) => {
            await commit('RESET_SCAMS');
        },

        commitResetScam: async ({commit}) => {
            await commit('RESET_SCAM');
        },

        // get list of blog by api call.
        getScams: async ({dispatch}, params = {}) => {
            const path = `v1/posts`;

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetScams', response.data);
                dispatch('commitSetPaginateLinks', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // get single blog by api call.
        getScam: async ({dispatch}, paramObj) => {
            const path = `v1/posts/${paramObj.id}`;
            const params = paramObj.params ?? {};

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetScam', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // create new blog on list by api call.
        postScamOnList: async ({dispatch}, data) => {
            const path = `v1/posts`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateScamOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // create new blog by api call.
        postScam: async ({dispatch}, data) => {
            const path = `v1/posts`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateScam', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing blog on list by api call.
        putScamOnList: async ({dispatch}, dataObj) => {
            const path = `v1/posts/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateScamOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing blog by api call.
        putScam: async ({dispatch}, dataObj) => {
            const path = `v1/posts/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateScam', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular blog on list by api call.
        deleteScamOnList: async ({dispatch}, id) => {
            const path = `v1/posts/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteScamOnList', id);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular blog by api call.
        deleteScam: async ({dispatch}, id) => {
            const path = `v1/posts/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteScam', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        putImage: async (_, dataObj) =>
        {

            const path = `v1/posts/${dataObj.id}/image`;
            const data = dataObj.data ?? {};

            return axios.put(path, data).then((response) =>
            {

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) =>
            {
                return responseErrorHandler(error);
            });
        },

        // reset blog state.
        resetScams: async ({dispatch}) => {
            dispatch('commitResetScams');
        },

        // reset blog state.
        resetScam: async ({dispatch}) => {
            dispatch('commitResetScam')
        },

    },

};