import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {
        onSiteTechnicians: [],
        paginateLinks: {},
        onSiteTechnician: {},
    },

    getters: {
        onSiteTechnicians: (state) => {
            return state.onSiteTechnicians;
        },

        paginateLinks: (state) => {
            return state.paginateLinks;
        },

        onSiteTechnician: (state) => {
            return state.onSiteTechnician;
        },
    },
    mutations: {
        SET_ON_SITE_TECHNICIANS(state, onSiteTechnicians) {
            state.onSiteTechnicians = onSiteTechnicians;
        },

        SET_PAGINATE_LINKS(state, paginateLinks) {
            state.paginateLinks = paginateLinks;
        },

        SET_ON_SITE_TECHNICIAN(state, onSiteTechnician) {
            state.onSiteTechnician = onSiteTechnician;
        },

        CREATE_ON_SITE_TECHNICIAN_ON_LIST(state, onSiteTechnician) {
            state.onSiteTechnicians.unshift(onSiteTechnician);
        },

        CREATE_ON_SITE_TECHNICIAN(state, onSiteTechnician) {
            state.onSiteTechnician = onSiteTechnician;
        },

        UPDATE_ON_SITE_TECHNICIAN_ON_LIST(state, onSiteTechnician) {
            let index = state.onSiteTechnicians.findIndex(item => item.id === onSiteTechnician.id);
            state.onSiteTechnicians.splice(index, 1, onSiteTechnician);
        },

        UPDATE_ON_SITE_TECHNICIAN(state, onSiteTechnician) {
            state.onSiteTechnician = onSiteTechnician;
        },

        DELETE_ON_SITE_TECHNICIAN_ON_LIST(state, onSiteTechnicianId) {
            let index = state.onSiteTechnicians.findIndex(item => item.id === onSiteTechnicianId);
            state.onSiteTechnicians.splice(index, 1);
        },

        DELETE_ON_SITE_TECHNICIAN(state) {
            state.onSiteTechnician = {};
        },

        RESET_ON_SITE_TECHNICIANS(state) {
            state.onSiteTechnicians = [];
        },

        RESET_ON_SITE_TECHNICIAN(state) {
            state.onSiteTechnician = {};
        },
    },
    actions: {
        commitSetOnSiteTechnicians: async ({commit}, responseData) => {
            await commit('SET_ON_SITE_TECHNICIANS', responseData.data);
        },

        commitSetPaginateLinks: async ({commit}, responseData) => {
            await commit('SET_PAGINATE_LINKS', responseData.meta);
        },

        commitSetOnSiteTechnician: async ({commit}, responseData) => {
            await commit('SET_ON_SITE_TECHNICIAN', responseData.data)
        },

        commitCreateOnSiteTechnicianOnList: async ({commit}, responseData) => {
            await commit('CREATE_ON_SITE_TECHNICIAN_ON_LIST', responseData.data);
        },

        commitCreateOnSiteTechnician: async ({commit}, responseData) => {
            await commit('CREATE_ON_SITE_TECHNICIAN', responseData.data);
        },

        commitUpdateOnSiteTechnicianOnList: async ({commit}, responseData) => {
            await commit('UPDATE_ON_SITE_TECHNICIAN_ON_LIST', responseData.data);
        },

        commitUpdateOnSiteTechnician: async ({commit}, responseData) => {
            await commit('UPDATE_ON_SITE_TECHNICIAN', responseData.data);
        },

        commitDeleteOnSiteTechnicianOnList: async ({commit}, onSiteTechnicianId) => {
            await commit('DELETE_ON_SITE_TECHNICIAN_ON_LIST', onSiteTechnicianId);
        },

        commitDeleteOnSiteTechnician: async ({commit}) => {
            await commit('DELETE_ON_SITE_TECHNICIAN');
        },

        commitResetOnSiteTechnicians: async ({commit}) => {
            await commit('RESET_ON_SITE_TECHNICIANS');
        },

        commitResetOnSiteTechnician: async ({commit}) => {
            await commit('RESET_ON_SITE_TECHNICIAN');
        },

        // get list of onSiteTechnician by api call.
        getOnSiteTechnicians: async ({dispatch}, params = {}) => {
            const path = `v1/on-site-technicians`;

            return  axios.get(path, {params: params}).then(response => {
                dispatch('commitSetOnSiteTechnicians', response.data);
                dispatch('commitSetPaginateLinks', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // get single onSiteTechnician by api call.
        getOnSiteTechnician: async ({dispatch}, paramObj) => {
            const path = `v1/on-site-technicians/${paramObj.id}`;
            const params = paramObj.params ?? {};

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetOnSiteTechnician', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // create new onSiteTechnician on list by api call.
        postOnSiteTechnicianOnList: async ({dispatch}, data) => {
            const path = `v1/on-site-technicians`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateOnSiteTechnicianOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // create new onSiteTechnician by api call.
        postOnSiteTechnician: async ({dispatch}, data) => {
            const path = `v1/on-site-technicians`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateOnSiteTechnician', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing onSiteTechnician on list by api call.
        putOnSiteTechnicianOnList: async ({dispatch}, dataObj) => {
            const path = `v1/on-site-technicians/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateOnSiteTechnicianOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing onSiteTechnician by api call.
        putOnSiteTechnician: async ({dispatch}, dataObj) => {
            const path = `v1/on-site-technicians/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateOnSiteTechnician', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular onSiteTechnician on list by api call.
        deleteOnSiteTechnicianOnList: async ({dispatch}, id) => {
            const path = `v1/on-site-technicians/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteOnSiteTechnicianOnList', id);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular onSiteTechnician by api call.
        deleteOnSiteTechnician: async ({dispatch}, id) => {
            const path = `v1/on-site-technicians/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteOnSiteTechnician', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // reset onSiteTechnicians state.
        resetOnSiteTechnicians: async ({dispatch}) => {
            dispatch('commitResetOnSiteTechnicians');
        },

        // reset onSiteTechnician state.
        resetOnSiteTechnician: async ({dispatch}) => {
            dispatch('commitResetOnSiteTechnician')
        },

    },
};
