import axios from "axios";
import store from "@/store";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {
        authUser  : {
            id           : null,
            firstName    : null,
            lastName     : null,
            email        : null,
            role         : null,
            avatar       : null,
        },

        authFranchisee: {
            id: null,
        },

        authToken : {
            accessToken  : null,
            tokenType    : null,
        },

        authPermissions  : [],
    },
    getters: {
        isAuthenticated: (state) => {
            return !!(state.authToken.accessToken && state.authUser.id);
        },

        authUser: (state) => {
            return state.authUser;
        },

        authFranchisee: (state) => {
            return state.authFranchisee;
        },

        authToken: (state) => {
            return state.authToken;
        },

        authPermissions: (state) => {
            return state.authPermissions;
        },
    },
    mutations: {
        SET_AUTH_USER: (state, authUser) => {
            state.authUser.id           = authUser.id;
            state.authUser.firstName    = authUser.first_name;
            state.authUser.lastName     = authUser.last_name;
            state.authUser.email        = authUser.email;
            state.authUser.role         = authUser.role;
            state.authUser.avatar       = authUser.avatar;
        },

        SET_AUTH_FRANCHISEE: (state, authFranchisee) => {
            state.authFranchisee.id = authFranchisee.id;
        },

        SET_AUTH_TOKEN: (state, authToken) => {
            state.authToken.accessToken = authToken.access_token;
            state.authToken.tokenType   = authToken.token_type;
        },

        SET_AUTH_PERMISSIONS: (state, authPermissions) => {
            state.authPermissions = authPermissions;
        },
    },
    actions: {
        logIn: async ({ dispatch }, credentials) => {
            const path = `v1/login-token`;

            return await axios.post(path, credentials).then((response) => {
                dispatch("commitAuthAttempt", response.data);

                return {
                    message: "Login Successful",
                    type: "success",
                    status: response.status,
                };
            }).catch((error) => {
                dispatch("commitFailedAuthAttempt");

                return responseErrorHandler(error);
            });
        },

        commitAuthAttempt: async ({commit}, responseData) => {
            if (responseData.data.user) {
                await commit('SET_AUTH_USER', responseData.data.user);
            }

            if (responseData.data.franchisee) {
                await commit('SET_AUTH_FRANCHISEE', responseData.data.franchisee);
            }

            if (responseData.data.token) {
                await commit('SET_AUTH_TOKEN', responseData.data.token);
            }

            if (responseData.data.permissions) {
                await commit('SET_AUTH_PERMISSIONS', responseData.data.permissions);
            }
        },

        commitAuthAttemptFromLocalStorage: async () => {
            if (localStorage.getItem('authUser') !== null) {
                await store.commit('appAuthLogin/SET_AUTH_USER', JSON.parse(localStorage.getItem('authUser')));
            }

            if (localStorage.getItem('authFranchisee') !== null) {
                await store.commit('appAuthLogin/SET_AUTH_FRANCHISEE', JSON.parse(localStorage.getItem('authFranchisee')));
            }

            if (localStorage.getItem('authToken') !== null) {
                await store.commit('appAuthLogin/SET_AUTH_TOKEN', JSON.parse(localStorage.getItem('authToken')));
            }

            if (localStorage.getItem('authPermissions') !== null) {
                await store.commit('appAuthLogin/SET_AUTH_PERMISSIONS', JSON.parse(localStorage.getItem('authPermissions')));
            }
        },

        commitFailedAuthAttempt: async ({commit}) => {
            await commit('SET_AUTH_USER', { id : null, first_name : null, last_name : null, email : null, role : null, avatar: null });
            await commit('SET_AUTH_FRANCHISEE', { id : null });
            await commit('SET_AUTH_TOKEN', { access_token: null, token_type: null});
            await commit('SET_AUTH_PERMISSIONS', []);
        },
    },
};
