import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {
        partners: [],
        paginateLinks: {},
        partner: {},
    },

    getters: {
        partners: (state) => {
            return state.partners;
        },

        paginateLinks: (state) => {
            return state.paginateLinks;
        },

        partner: (state) => {
            return state.partner;
        },
    },
    mutations: {
        SET_PARTNERS(state, partners) {
            state.partners = partners;
        },

        SET_PAGINATE_LINKS(state, paginateLinks) {
            state.paginateLinks = paginateLinks;
        },

        SET_PARTNER(state, partner) {
            state.partner = partner;
        },

        CREATE_PARTNER_ON_LIST(state, partner) {
            state.partners.unshift(partner);
        },

        CREATE_PARTNER(state, partner) {
            state.partner = partner;
        },

        UPDATE_PARTNER_ON_LIST(state, partner) {
            let index = state.partners.findIndex(item => item.id === partner.id);
            state.partners.splice(index, 1, partner);
        },

        UPDATE_PARTNER(state, partner) {
            state.partner = partner;
        },

        DELETE_PARTNER_ON_LIST(state, partnerId) {
            let index = state.partners.findIndex(item => item.id === partnerId);
            state.partners.splice(index, 1);
        },

        DELETE_PARTNER(state) {
            state.partner = {};
        },

        RESET_PARTNERS(state) {
            state.partners = [];
        },

        RESET_PARTNER(state) {
            state.partners = {};
        },
    },
    actions: {
        commitSetPartners: async ({commit}, responseData) => {
            await commit('SET_PARTNERS', responseData.data);
        },

        commitSetPaginateLinks: async ({commit}, responseData) => {
            await commit('SET_PAGINATE_LINKS', responseData.meta);
        },

        commitSetPartner: async ({commit}, responseData) => {
            await commit('SET_PARTNER', responseData.data)
        },

        commitCreatePartnerOnList: async ({commit}, responseData) => {
            await commit('CREATE_PARTNER_ON_LIST', responseData.data);
        },

        commitCreatePartner: async ({commit}, responseData) => {
            await commit('CREATE_PARTNER', responseData.data);
        },

        commitUpdatePartnerOnList: async ({commit}, responseData) => {
            await commit('UPDATE_PARTNER_ON_LIST', responseData.data);
        },

        commitUpdatePartner: async ({commit}, responseData) => {
            await commit('UPDATE_PARTNER', responseData.data);
        },

        commitDeletePartnerOnList: async ({commit}, partnerId) => {
            await commit('DELETE_PARTNER_ON_LIST', partnerId);
        },

        commitDeletePartner: async ({commit}) => {
            await commit('DELETE_PARTNER');
        },

        commitResetPartners: async ({commit}) => {
            await commit('RESET_PARTNERS');
        },

        commitResetPartner: async ({commit}) => {
            await commit('RESET_PARTNER');
        },

        // get list of partner by api call.
        getPartners: async ({dispatch}, params = {}) => {
            const path = `v1/partners`;

            return  axios.get(path, {params: params}).then(response => {
                dispatch('commitSetPartners', response.data);
                dispatch('commitSetPaginateLinks', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // get single partner by api call.
        getPartner: async ({dispatch}, paramObj) => {
            const path = `v1/partners/${paramObj.id}`;
            const params = paramObj.params ?? {};

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetPartner', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // create new partner on list by api call.
        postPartnerOnList: async ({dispatch}, data) => {
            const path = `v1/partners`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreatePartnerOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // create new partner by api call.
        postPartner: async ({dispatch}, data) => {
            const path = `v1/partners`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreatePartner', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing partner on list by api call.
        putPartnerOnList: async ({dispatch}, dataObj) => {
            const path = `v1/partners/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdatePartnerOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing partner by api call.
        putPartner: async ({dispatch}, dataObj) => {
            const path = `v1/partners/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdatePartner', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular partner on list by api call.
        deletePartnerOnList: async ({dispatch}, id) => {
            const path = `v1/partners/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeletePartnerOnList', id);

                return {
                    message: 'Partner deleted successfully',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular partner by api call.
        deletePartner: async ({dispatch}, id) => {
            const path = `v1/partners/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeletePartner', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // reset partners state.
        resetPartners: async ({dispatch}) => {
            dispatch('commitResetPartners');
        },

        // reset partner state.
        resetPartner: async ({dispatch}) => {
            dispatch('commitResetPartner')
        },

    },
};
