import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {
        admins: [],
        paginateLinks: {},
        admin: {},
    },

    getters: {
        admins: (state) => {
            return state.admins;
        },

        paginateLinks: (state) => {
            return state.paginateLinks;
        },

        admin: (state) => {
            return state.admin;
        },
    },
    mutations: {
        SET_ADMINS(state, admins) {
            state.admins = admins;
        },

        SET_PAGINATE_LINKS(state, paginateLinks) {
            state.paginateLinks = paginateLinks;
        },

        SET_ADMIN(state, admin) {
            state.admin = admin;
        },

        CREATE_ADMIN_ON_LIST(state, admin) {
            state.admins.unshift(admin);
        },

        CREATE_ADMIN(state, admin) {
            state.admin = admin;
        },

        UPDATE_ADMIN_ON_LIST(state, admin) {
            let index = state.admins.findIndex(item => item.id === admin.id);
            state.admins.splice(index, 1, admin);
        },

        UPDATE_ADMIN(state, admin) {
            state.admin = admin;
        },

        DELETE_ADMIN_ON_LIST(state, adminId) {
            let index = state.admins.findIndex(item => item.id === adminId);
            state.admins.splice(index, 1);
        },

        DELETE_ADMIN(state) {
            state.admin = {};
        },

        RESET_ADMINS(state) {
            state.admins = [];
        },

        RESET_ADMIN(state) {
            state.admin = {};
        },
    },
    actions: {
        commitSetAdmins: async ({commit}, responseData) => {
            await commit('SET_ADMINS', responseData.data);
        },

        commitSetPaginateLinks: async ({commit}, responseData) => {
            await commit('SET_PAGINATE_LINKS', responseData.meta);
        },

        commitSetAdmin: async ({commit}, responseData) => {
            await commit('SET_ADMIN', responseData.data)
        },

        commitCreateAdminOnList: async ({commit}, responseData) => {
            await commit('CREATE_ADMIN_ON_LIST', responseData.data);
        },

        commitCreateAdmin: async ({commit}, responseData) => {
            await commit('CREATE_ADMIN', responseData.data);
        },

        commitUpdateAdminOnList: async ({commit}, responseData) => {
            await commit('UPDATE_ADMIN_ON_LIST', responseData.data);
        },

        commitUpdateAdmin: async ({commit}, responseData) => {
            await commit('UPDATE_ADMIN', responseData.data);
        },

        commitDeleteAdminOnList: async ({commit}, adminId) => {
            await commit('DELETE_ADMIN_ON_LIST', adminId);
        },

        commitDeleteAdmin: async ({commit}) => {
            await commit('DELETE_ADMIN');
        },

        commitResetAdmins: async ({commit}) => {
            await commit('RESET_ADMINS');
        },

        commitResetAdmin: async ({commit}) => {
            await commit('RESET_ADMIN');
        },

        // get list of admin by api call.
        getAdmins: async ({dispatch}, params = {}) => {
            const path = `v1/admins`;

            return  axios.get(path, {params: params}).then(response => {
                dispatch('commitSetAdmins', response.data);
                dispatch('commitSetPaginateLinks', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // get single admin by api call.
        getAdmin: async ({dispatch}, paramObj) => {
            const path = `v1/admins/${paramObj.id}`;
            const params = paramObj.params ?? {};

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetAdmin', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // create new admin on list by api call.
        postAdminOnList: async ({dispatch}, data) => {
            const path = `v1/admins`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateAdminOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // create new admin by api call.
        postAdmin: async ({dispatch}, data) => {
            const path = `v1/admins`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateAdmin', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing admin on list by api call.
        putAdminOnList: async ({dispatch}, dataObj) => {
            const path = `v1/admins/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateAdminOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing admin by api call.
        putAdmin: async ({dispatch}, dataObj) => {
            const path = `v1/admins/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateAdmin', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular admin on list by api call.
        deleteAdminOnList: async ({dispatch}, id) => {
            const path = `v1/admins/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteAdminOnList', id);

                return {
                    message: 'Admin deleted successfully',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular admin by api call.
        deleteAdmin: async ({dispatch}, id) => {
            const path = `v1/admins/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteAdmin', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // reset admins state.
        resetAdmins: async ({dispatch}) => {
            dispatch('commitResetAdmins');
        },

        // reset admin state.
        resetAdmin: async ({dispatch}) => {
            dispatch('commitResetAdmin')
        },

    },
};
