import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {

        panelBaseWeeklyAppointmentsReport: {},
        panelBaseMonthlyAppointmentsReport: {},
        panelBaseTodayAppointmentsReport: {},
        panelBaseYearlyAppointmentsReport: [],
        todayCreatedAppointments:[],
        todayCreatedOrders:[],
        todayOngoingAppointments:[],


    },

    getters: {

        panelBaseWeeklyAppointmentsReport: (state) =>
        {
            return state.panelBaseWeeklyAppointmentsReport;
        },
        panelBaseMonthlyAppointmentsReport: (state) =>
        {
            return state.panelBaseMonthlyAppointmentsReport;
        },
        panelBaseTodayAppointmentsReport: (state) =>
        {
            return state.panelBaseTodayAppointmentsReport;
        },
        panelBaseYearlyAppointmentsReport: (state) =>
        {
            return state.panelBaseYearlyAppointmentsReport;
        },
        todayCreatedAppointments: (state) =>
        {
            return state.todayCreatedAppointments;
        },
        todayOngoingAppointments: (state) =>
        {
            return state.todayOngoingAppointments;
        },
        todayCreatedOrders: (state) =>
        {
            return state.todayCreatedOrders;
        },
    },
    mutations: {

        SET_PANELBASE_WEEKLY_APPOINTMENTSREPORT (state, panelBaseWeeklyAppointmentsReport)
        {
            state.panelBaseWeeklyAppointmentsReport = panelBaseWeeklyAppointmentsReport;
        },
        SET_PANELBASE_MONTHLY_APPOINTMENTSREPORT (state, panelBaseMonthlyAppointmentsReport)
        {
            state.panelBaseMonthlyAppointmentsReport = panelBaseMonthlyAppointmentsReport;
        },
        SET_PANELBASE_TODAY_APPOINTMENTSREPORT (state, panelBaseTodayAppointmentsReport)
        {
            state.panelBaseTodayAppointmentsReport = panelBaseTodayAppointmentsReport;
        },
        SET_PANELBASE_YEARLY_APPOINTMENTSREPORT (state, panelBaseYearlyAppointmentsReport)
        {
            state.panelBaseYearlyAppointmentsReport = panelBaseYearlyAppointmentsReport;
        },
        SET_TODAY_CREATED_APPOINTMENTS (state, todayCreatedAppointments)
        {
            state.todayCreatedAppointments = todayCreatedAppointments;
        },
        SET_TODAY_ONGOING_APPOINTMENTS (state, todayOngoingAppointments)
        {
            state.todayOngoingAppointments = todayOngoingAppointments;
        },
        SET_TODAY_CREATED_ORDERS (state, todayCreatedOrders)
        {
            state.todayCreatedOrders = todayCreatedOrders;
        },


    },
    actions: {

        commitSetPnaelBaseWeeklyAppointemntsReport: async ({ commit }, responseData) =>
        {
            await commit('SET_PANELBASE_WEEKLY_APPOINTMENTSREPORT', responseData.data);
        },
        commitSetPnaelBaseMonthlyAppointemntsReport: async ({ commit }, responseData) =>
        {
            await commit('SET_PANELBASE_MONTHLY_APPOINTMENTSREPORT', responseData.data);
        },
        commitSetPnaelBaseTodayAppointemntsReport: async ({ commit }, responseData) =>
        {
            await commit('SET_PANELBASE_TODAY_APPOINTMENTSREPORT', responseData.data);
        },
        commitSetPnaelBaseYearlyAppointemntsReport: async ({ commit }, responseData) =>
        {
            await commit('SET_PANELBASE_YEARLY_APPOINTMENTSREPORT', responseData.data);
        },
        commitSetTodayCreatedAppointments: async ({ commit }, responseData) =>
        {
            await commit('SET_TODAY_CREATED_APPOINTMENTS', responseData.data);
        },
        commitSetTodayOngoingAppointments: async ({ commit }, responseData) =>
        {
            await commit('SET_TODAY_ONGOING_APPOINTMENTS', responseData.data);
        },
        commitSetTodayCreatedOrders: async ({ commit }, responseData) =>
        {
            await commit('SET_TODAY_CREATED_ORDERS', responseData.data);
        },



        // // get list of appointment by api call.
        // getAppointments: async ({dispatch}, params = {}) => {
        //     const path = `v1/appointments`;

        //     return  axios.get(path, {params: params}).then(response => {
        //         dispatch('commitSetAppointments', response.data);
        //         dispatch('commitSetPaginateLinks', response.data);

        //         return {
        //             message: '',
        //             type: 'success',
        //             status: response.status,
        //         };
        //     }).catch(error => {
        //         return responseErrorHandler(error);
        //     });
        // },


        // get getPanelBaseWeeklyAppointmentsReport by api call.
        getPanelBaseWeeklyAppointmentsReport: async ({ dispatch } ,params = {}) =>
        {
            const path = `v1/dashboard/reports/appointments/panels-based`;

            return axios.get(path, {params: params}).then(response =>
            {
               
                dispatch('commitSetPnaelBaseWeeklyAppointemntsReport', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error =>
            {
                return responseErrorHandler(error);
            });
        },
        // get getPanelBaseMonthlyAppointmentsReport by api call.
        getPanelBaseMonthlyAppointmentsReport: async ({ dispatch } ,params = {}) =>
        {
            const path = `v1/dashboard/reports/appointments/monthly-average`;

            return axios.get(path, {params: params}).then(response =>
            {
               
                dispatch('commitSetPnaelBaseMonthlyAppointemntsReport', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error =>
            {
                return responseErrorHandler(error);
            });
        },
        // get getPanelBaseTodayAppointmentsReport by api call.
        getPanelBaseTodayAppointmentsReport: async ({ dispatch } ,params = {}) =>
        {
            const path = `v1/dashboard/reports/appointments/panels-based`;

            return axios.get(path, {params: params}).then(response =>
            {
               
                dispatch('commitSetPnaelBaseTodayAppointemntsReport', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error =>
            {
                return responseErrorHandler(error);
            });
        },
        // get getPanelBaseYearlyAppointmentsReport by api call.
        getPanelBaseYearlyAppointmentsReport: async ({ dispatch } ,params = {}) =>
        {
            const path = `v1/dashboard/reports/appointments/year-monthly`;

            return axios.get(path, {params: params}).then(response =>
            {
               
                dispatch('commitSetPnaelBaseYearlyAppointemntsReport', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error =>
            {
                return responseErrorHandler(error);
            });
        },
        // get getTodayCreatedAppointemnts by api call.
        getTodayCreatedAppointemnts: async ({ dispatch } = {}) =>
        {
            const path = `v1/dashboard/today-appointment`;

            return axios.get(path).then(response =>
            {
               
                dispatch('commitSetTodayCreatedAppointments', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error =>
            {
                return responseErrorHandler(error);
            });
        },
        // get getTodayOngoingAppointemnts by api call.
        getTodayOngoingAppointemnts: async ({ dispatch } = {}) =>
        {
            const path = `v1/dashboard/ongoing-appointment`;

            return axios.get(path).then(response =>
            {
               
                dispatch('commitSetTodayOngoingAppointments', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error =>
            {
                return responseErrorHandler(error);
            });
        },
        
        // get TodayCreatedOrder by api call.
        getTodayCreatedOrder: async ({ dispatch },params = {}) =>
        {
            const path = `v1/dashboard/today-orders`;

            return axios.get(path, {params: params}).then(response =>
            {
               
                dispatch('commitSetTodayCreatedOrders', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error =>
            {
                return responseErrorHandler(error);
            });
        },

    },
};
