import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {
        technicianAppointments: [],
        paginateLinks: {},
        technicianAppointment: {},
    },

    getters: {
        technicianAppointments: (state) => {
            return state.technicianAppointments;
        },

        paginateLinks: (state) => {
            return state.paginateLinks;
        },

        technicianAppointment: (state) => {
            return state.technicianAppointment;
        },
    },
    mutations: {
        SET_TECHNICIAN_APPOINTMENTS(state, technicianAppointments) {
            state.technicianAppointments = technicianAppointments;
        },

        SET_PAGINATE_LINKS(state, paginateLinks) {
            state.paginateLinks = paginateLinks;
        },

        SET_TECHNICIAN_APPOINTMENT(state, technicianAppointment) {
            state.technicianAppointment = technicianAppointment;
        },

        CREATE_TECHNICIAN_APPOINTMENT_ON_LIST(state, technicianAppointment) {
            state.technicianAppointments.unshift(technicianAppointment);
        },

        CREATE_TECHNICIAN_APPOINTMENT(state, technicianAppointment) {
            state.technicianAppointment = technicianAppointment;
        },

        UPDATE_TECHNICIAN_APPOINTMENT_ON_LIST(state, technicianAppointment) {
            let index = state.technicianAppointments.findIndex(item => item.id === technicianAppointment.id);
            state.technicianAppointments.splice(index, 1, technicianAppointment);
        },

        UPDATE_TECHNICIAN_APPOINTMENT(state, technicianAppointment) {
            state.technicianAppointment = technicianAppointment;
        },

        DELETE_TECHNICIAN_APPOINTMENT_ON_LIST(state, technicianAppointmentId) {
            let index = state.technicianAppointments.findIndex(item => item.id === technicianAppointmentId);
            state.technicianAppointments.splice(index, 1);
        },

        DELETE_TECHNICIAN_APPOINTMENT(state) {
            state.technicianAppointment = {};
        },

        RESET_TECHNICIAN_APPOINTMENTS(state) {
            state.technicianAppointments = [];
        },

        RESET_TECHNICIAN_APPOINTMENT(state) {
            state.technicianAppointment = {};
        },
    },
    actions: {
        commitSetTechnicianAppointments: async ({commit}, responseData) => {
            await commit('SET_TECHNICIAN_APPOINTMENTS', responseData.data);
        },
       
        commitSetPaginateLinks: async ({commit}, responseData) => {
            await commit('SET_PAGINATE_LINKS', responseData.meta);
        },

        commitSetTechnicianAppointment: async ({commit}, responseData) => {
            await commit('SET_TECHNICIAN_APPOINTMENT', responseData.data)
        },

        commitCreateTechnicianAppointmentOnList: async ({commit}, responseData) => {
            await commit('CREATE_TECHNICIAN_APPOINTMENT_ON_LIST', responseData.data);
        },

        commitCreateTechnicianAppointment: async ({commit}, responseData) => {
            await commit('CREATE_TECHNICIAN_APPOINTMENT', responseData.data);
        },

        commitUpdateTechnicianAppointmentOnList: async ({commit}, responseData) => {
            await commit('UPDATE_TECHNICIAN_APPOINTMENT_ON_LIST', responseData.data);
        },

        commitUpdateTechnicianAppointment: async ({commit}, responseData) => {
            await commit('UPDATE_TECHNICIAN_APPOINTMENT', responseData.data);
        },

        commitDeleteTechnicianAppointmentOnList: async ({commit}, technicianAppointmentId) => {
            await commit('DELETE_TECHNICIAN_APPOINTMENT_ON_LIST', technicianAppointmentId);
        },

        commitDeleteTechnicianAppointment: async ({commit}) => {
            await commit('DELETE_TECHNICIAN_APPOINTMENT');
        },

        commitResetTechnicianAppointments: async ({commit}) => {
            await commit('RESET_TECHNICIAN_APPOINTMENTS');
        },

        commitResetTechnicianAppointment: async ({commit}) => {
            await commit('RESET_TECHNICIAN_APPOINTMENT');
        },

        // get list of technicianAppointment by api call.
        getTechnicianAppointments: async ({dispatch}, params = {}) => {
            const path = `v1/technician-appointments`;

            return  axios.get(path, {params: params}).then(response => {
                dispatch('commitSetTechnicianAppointments', response.data);
                dispatch('commitSetPaginateLinks', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // get single technicianAppointment by api call.
        getTechnicianAppointment: async ({dispatch}, paramObj) => {
            const path = `v1/technician-appointments/${paramObj.id}`;
            const params = paramObj.params ?? {};

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetTechnicianAppointment', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // create new technicianAppointment on list by api call.
        postTechnicianAppointmentOnList: async ({dispatch}, data) => {
            const path = `v1/technician-appointments`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateTechnicianAppointmentOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // create new technicianAppointment by api call.
        postTechnicianAppointment: async ({dispatch}, data) => {
            const path = `v1/technician-appointments`;

            return axios.post(path, data).then((response) => {

                dispatch('commitCreateTechnicianAppointment', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing technicianAppointment on list by api call.
        putTechnicianAppointmentOnList: async ({dispatch}, dataObj) => {
            const path = `v1/technician-appointments/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateTechnicianAppointmentOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing technicianAppointment by api call.
        putTechnicianAppointment: async ({dispatch}, dataObj) => {
            const path = `v1/technician-appointments/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateTechnicianAppointment', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular technicianAppointment on list by api call.
        deleteTechnicianAppointmentOnList: async ({dispatch}, id) => {
            const path = `v1/technician-appointments/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteTechnicianAppointmentOnList', id);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular technicianAppointment by api call.
        deleteTechnicianAppointment: async ({dispatch}, id) => {
            const path = `v1/technician-appointments/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteTechnicianAppointment', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // reset technicianAppointments state.
        resetTechnicianAppointments: async ({dispatch}) => {
            dispatch('commitResetTechnicianAppointments');
        },

        // reset technicianAppointment state.
        resetTechnicianAppointment: async ({dispatch}) => {
            dispatch('commitResetTechnicianAppointment')
        },

    },
};
