import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {
        labUsers: [],
        paginateLinks: {},
        labUser: {},
    },

    getters: {
        labUsers: (state) => {
            return state.labUsers;
        },

        paginateLinks: (state) => {
            return state.paginateLinks;
        },

        labUser: (state) => {
            return state.labUser;
        },
    },
    mutations: {
        SET_LAB_USERS(state, labUsers) {
            state.labUsers = labUsers;
        },

        SET_PAGINATE_LINKS(state, paginateLinks) {
            state.paginateLinks = paginateLinks;
        },

        SET_LAB_USER(state, labUser) {
            state.labUser = labUser;
        },

        CREATE_LAB_USER_ON_LIST(state, labUser) {
            state.labUsers.unshift(labUser);
        },

        CREATE_LAB_USER(state, labUser) {
            state.labUser = labUser;
        },

        UPDATE_LAB_USER_ON_LIST(state, labUser) {
            let index = state.labUsers.findIndex(item => item.id === labUser.id);
            state.labUsers.splice(index, 1, labUser);
        },

        UPDATE_LAB_USER(state, labUser) {
            state.labUser = labUser;
        },

        DELETE_LAB_USER_ON_LIST(state, labUserId) {
            let index = state.labUsers.findIndex(item => item.id === labUserId);
            state.labUsers.splice(index, 1);
        },

        DELETE_LAB_USER(state) {
            state.labUser = {};
        },

        RESET_LAB_USERS(state) {
            state.labUsers = [];
        },

        RESET_LAB_USER(state) {
            state.labUser = {};
        },
    },
    actions: {
        commitSetLabUsers: async ({commit}, responseData) => {
            await commit('SET_LAB_USERS', responseData.data);
        },

        commitSetPaginateLinks: async ({commit}, responseData) => {
            await commit('SET_PAGINATE_LINKS', responseData.meta);
        },

        commitSetLabUser: async ({commit}, responseData) => {
            await commit('SET_LAB_USER', responseData.data)
        },

        commitCreateLabUserOnList: async ({commit}, responseData) => {
            await commit('CREATE_LAB_USER_ON_LIST', responseData.data);
        },

        commitCreateLabUser: async ({commit}, responseData) => {
            await commit('CREATE_LAB_USER', responseData.data);
        },

        commitUpdateLabUserOnList: async ({commit}, responseData) => {
            await commit('UPDATE_LAB_USER_ON_LIST', responseData.data);
        },

        commitUpdateLabUser: async ({commit}, responseData) => {
            await commit('UPDATE_LAB_USER', responseData.data);
        },

        commitDeleteLabUserOnList: async ({commit}, labUserId) => {
            await commit('DELETE_LAB_USER_ON_LIST', labUserId);
        },

        commitDeleteLabUser: async ({commit}) => {
            await commit('DELETE_LAB_USER');
        },

        commitResetLabUsers: async ({commit}) => {
            await commit('RESET_LAB_USERS');
        },

        commitResetLabUser: async ({commit}) => {
            await commit('RESET_LAB_USER');
        },

        // get list of labUser by api call.
        getLabUsers: async ({dispatch}, params) => {
            const path = `v1/lab-users`;

            return  axios.get(path, {params: params}).then(response => {
                dispatch('commitSetLabUsers', response.data);
                dispatch('commitSetPaginateLinks', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // get single labUser by api call.
        getLabUser: async ({dispatch}, paramObj) => {
            const path = `v1/lab-users/${paramObj.id}`;
            const params = paramObj.params ?? {};

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetLabUser', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // create new labUser on list by api call.
        postLabUserOnList: async ({dispatch}, data) => {
            const path = `v1/lab-users`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateLabUserOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // create new labUser by api call.
        postLabUser: async ({dispatch}, data) => {
            const path = `v1/lab-users`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateLabUser', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing labUser on list by api call.
        putLabUserOnList: async ({dispatch}, dataObj) => {
            const path = `v1/lab-users/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateLabUserOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing labUser by api call.
        putLabUser: async ({dispatch}, dataObj) => {
            const path = `v1/lab-users/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateLabUser', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular labUser on list by api call.
        deleteLabUserOnList: async ({dispatch}, id) => {
            const path = `v1/lab-users/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteLabUserOnList', id);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular labUser by api call.
        deleteLabUser: async ({dispatch}, id) => {
            const path = `v1/lab-users/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteLabUser', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // reset labUsers state.
        resetLabUsers: async ({dispatch}) => {
            dispatch('commitResetLabUsers');
        },

        // reset labUser state.
        resetLabUser: async ({dispatch}) => {
            dispatch('commitResetLabUser')
        },

    },
};
