import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {
        refunds: [],
        paginateLinks: {},
        refund: {},
    },

    getters: {
        refunds: (state) => {
            return state.refunds;
        },

        paginateLinks: (state) => {
            return state.paginateLinks;
        },

        refund: (state) => {
            return state.refund;
        },
    },
    mutations: {
        SET_REFUNDS(state, refunds) {
            state.refunds = refunds;
        },

        SET_PAGINATE_LINKS(state, paginateLinks) {
            state.paginateLinks = paginateLinks;
        },

        SET_REFUND(state, refund) {
            state.refund = refund;
        },

        CREATE_REFUND_ON_LIST(state, refund) {
            state.refunds.unshift(refund);
        },

        CREATE_REFUND(state, refund) {
            state.refund = refund;
        },

        UPDATE_REFUND_ON_LIST(state, refund) {
            let index = state.refunds.findIndex(item => item.id === refund.id);
            state.refunds.splice(index, 1, refund);
        },

        UPDATE_REFUND(state, refund) {
            state.refund = refund;
        },

        DELETE_REFUND_ON_LIST(state, refundId) {
            let index = state.refunds.findIndex(item => item.id === refundId);
            state.refunds.splice(index, 1);
        },

        DELETE_REFUND(state) {
            state.refund = {};
        },

        RESET_refunds(state) {
            state.refunds = [];
        },

        RESET_REFUND(state) {
            state.refund = {};
        },
    },
    actions: {
        commitSetRefunds: async ({commit}, responseData) => {
            await commit('SET_REFUNDS', responseData.data);
        },

        commitSetPaginateLinks: async ({commit}, responseData) => {
            await commit('SET_PAGINATE_LINKS', responseData.meta);
        },

        commitSetRefund: async ({commit}, responseData) => {
            await commit('SET_REFUND', responseData.data)
        },

        commitCreateRefundOnList: async ({commit}, responseData) => {
            await commit('CREATE_REFUND_ON_LIST', responseData.data);
        },

        commitCreateRefund: async ({commit}, responseData) => {
            await commit('CREATE_REFUND', responseData.data);
        },

        commitUpdateRefundOnList: async ({commit}, responseData) => {
            await commit('UPDATE_REFUND_ON_LIST', responseData.data);
        },

        commitUpdateRefund: async ({commit}, responseData) => {
            await commit('UPDATE_REFUND', responseData.data);
        },

        commitDeleteRefundOnList: async ({commit}, refundId) => {
            await commit('DELETE_REFUND_ON_LIST', refundId);
        },

        commitDeleteRefund: async ({commit}) => {
            await commit('DELETE_REFUND');
        },

        commitResetRefunds: async ({commit}) => {
            await commit('RESET_REFUNDS');
        },

        commitResetRefund: async ({commit}) => {
            await commit('RESET_REFUND');
        },

        // get list of refund by api call.
        getRefunds: async ({dispatch}, params = {}) => {
            const path = `v1/refunds`;

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetRefunds', response.data);
                dispatch('commitSetPaginateLinks', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // get single refund by api call.
        getRefund: async ({dispatch}, paramObj) => {
            const path = `v1/refunds/${paramObj.id}`;
            const params = paramObj.params ?? {};

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetRefund', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // create new refund on list by api call.
        postRefundOnList: async ({dispatch}, data) => {
            const path = `v1/refunds`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateRefundOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // create new refund by api call.
        postRefund: async ({dispatch}, data) => {
            const path = `v1/refunds`;

            return axios.post(path, data).then((response) => {

                dispatch('commitCreateRefund', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing refund on list by api call.
        putRefundOnList: async ({dispatch}, dataObj) => {
            const path = `v1/refunds/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateRefundOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing refund by api call.
        putRefund: async ({dispatch}, dataObj) => {
            const path = `v1/refunds/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateRefund', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular refund on list by api call.
        deleteRefundOnList: async ({dispatch}, id) => {
            const path = `v1/refunds/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteRefundOnList', id);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular refund by api call.
        deleteRefund: async ({dispatch}, id) => {
            const path = `v1/refunds/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteRefund', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // reset refunds state.
        resetRefunds: async ({dispatch}) => {
            dispatch('commitResetRefunds');
        },

        // reset refund state.
        resetRefund: async ({dispatch}) => {
            dispatch('commitResetRefund')
        },

    },
};
