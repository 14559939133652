import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {
        orderNotes: [],
        paginateLinks: {},
        orderNote: {},
    },

    getters: {
        orderNotes: (state) => {
            return state.orderNotes;
        },

        paginateLinks: (state) => {
            return state.paginateLinks;
        },

        orderNote: (state) => {
            return state.orderNote;
        },
    },
    mutations: {
        SET_ORDER_NOTES(state, orderNotes) {
            state.orderNotes = orderNotes;
        },

        SET_PAGINATE_LINKS(state, paginateLinks) {
            state.paginateLinks = paginateLinks;
        },

        SET_ORDER_NOTE(state, orderNote) {
            state.orderNote = orderNote;
        },

        CREATE_ORDER_NOTE_ON_LIST(state, orderNote) {
            state.orderNotes.unshift(orderNote);
        },

        CREATE_ORDER_NOTE(state, orderNote) {
            state.orderNote = orderNote;
        },

        UPDATE_ORDER_NOTE_ON_LIST(state, orderNote) {
            let index = state.orderNotes.findIndex(item => item.id === orderNote.id);
            state.orderNotes.splice(index, 1, orderNote);
        },

        UPDATE_ORDER_NOTE(state, orderNote) {
            state.orderNote = orderNote;
        },

        DELETE_ORDER_NOTE_ON_LIST(state, orderNoteId) {
            let index = state.orderNotes.findIndex(item => item.id === orderNoteId);
            state.orderNotes.splice(index, 1);
        },

        DELETE_ORDER_NOTE(state) {
            state.orderNote = {};
        },

        RESET_ORDER_NOTES(state) {
            state.orderNotes = [];
        },

        RESET_ORDER_NOTE(state) {
            state.orderNote = {};
        },
    },
    actions: {
        commitSetOrderNotes: async ({ commit }, responseData) => {
            await commit('SET_ORDER_NOTES', responseData.data);
        },

        commitSetPaginateLinks: async ({ commit }, responseData) => {
            await commit('SET_PAGINATE_LINKS', responseData.meta);
        },

        commitSetOrderNote: async ({ commit }, responseData) => {
            await commit('SET_ORDER_NOTE', responseData.data)
        },

        commitCreateOrderNoteOnList: async ({ commit }, responseData) => {
            await commit('CREATE_ORDER_NOTE_ON_LIST', responseData.data);
        },

        commitCreateOrderNote: async ({ commit }, responseData) => {
            await commit('CREATE_ORDER_NOTE', responseData.data);
        },

        commitUpdateOrderNoteOnList: async ({ commit }, responseData) => {
            await commit('UPDATE_ORDER_NOTE_ON_LIST', responseData.data);
        },

        commitUpdateOrderNote: async ({ commit }, responseData) => {
            await commit('UPDATE_ORDER_NOTE', responseData.data);
        },

        commitDeleteOrderNoteOnList: async ({ commit }, orderNoteId) => {
            await commit('DELETE_ORDER_NOTE_ON_LIST', orderNoteId);
        },

        commitDeleteOrderNote: async ({ commit }) => {
            await commit('DELETE_ORDER_NOTE');
        },

        commitResetOrderNotes: async ({ commit }) => {
            await commit('RESET_ORDER_NOTES');
        },

        commitResetOrderNote: async ({ commit }) => {
            await commit('RESET_ORDER_NOTE');
        },

        // get list of orderNote by api call.
        getOrderNotes: async ({ dispatch }, params = {}) => {
            const path = `v1/order-notes`;

            return axios.get(path, { params: params }).then(response => {
                dispatch('commitSetOrderNotes', response.data);
                dispatch('commitSetPaginateLinks', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // get single orderNote by api call.
        getOrderNote: async ({ dispatch }, paramObj) => {
            const path = `v1/order-notes/${paramObj.id}`;
            const params = paramObj.params ?? {};

            return axios.get(path, { params: params }).then(response => {
                dispatch('commitSetOrderNote', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // create new orderNote on list by api call.
        postOrderNoteOnList: async ({ dispatch }, data) => {
            const path = `v1/order-notes`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateOrderNoteOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // create new orderNote by api call.
        postOrderNote: async ({ dispatch }, data) => {
            const path = `v1/order-notes`;

            return axios.post(path, data).then((response) => {

                dispatch('commitCreateOrderNote', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing orderNote on list by api call.
        putOrderNoteOnList: async ({ dispatch }, dataObj) => {
            const path = `v1/order-notes/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateOrderNoteOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing orderNote by api call.
        putOrderNote: async ({ dispatch }, dataObj) => {
            const path = `v1/order-notes/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateOrderNote', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular orderNote on list by api call.
        deleteOrderNoteOnList: async ({ dispatch }, id) => {
            const path = `v1/order-notes/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteOrderNoteOnList', id);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular orderNote by api call.
        deleteOrderNote: async ({ dispatch }, id) => {
            const path = `v1/order-notes/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteOrderNote', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // reset orderNotes state.
        resetOrderNotes: async ({ dispatch }) => {
            dispatch('commitResetOrderNotes');
        },

        // reset orderNote state.
        resetOrderNote: async ({ dispatch }) => {
            dispatch('commitResetOrderNote')
        },

        // order note creation notify email
        postOrderNoteCreationNotifyEmail: async (_, paramObj) => {
            const path = `v1/order-notes/send-creation-notify/${paramObj.id}`;
            const params = paramObj.params ?? {};
            return axios.post(path, params).then((response) => {
                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                    data: response.data.data
                };
            }).catch((error) => {
                return responseErrorHandler(error);

            });
        },

    },
};
