import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {
        followUps: [],
        paginateLinks: {},
        followUp: {},
    },

    getters: {
        followUps: (state) => {
            return state.followUps;
        },

        paginateLinks: (state) => {
            return state.paginateLinks;
        },

        followUp: (state) => {
            return state.followUp;
        },
    },

    mutations: {
        SET_FOLLOW_UPS(state, followUps) {
            state.followUps = followUps;
        },

        SET_PAGINATE_LINKS(state, paginateLinks) {
            state.paginateLinks = paginateLinks;
        },

        SET_FOLLOW_UP(state, followUp) {
            state.followUp = followUp;
        },

        CREATE_FOLLOW_UP_ON_LIST(state, followUp) {
            state.followUps.unshift(followUp);
        },

        CREATE_FOLLOW_UP(state, followUp) {
            state.followUp = followUp;
        },

        UPDATE_FOLLOW_UP_ON_LIST(state, followUp) {
            let index = state.followUps.findIndex(item => item.id === followUp.id);
            state.followUps.splice(index, 1, followUp);
        },

        UPDATE_FOLLOW_UP(state, followUp) {
            state.followUp = followUp;
        },

        DELETE_FOLLOW_UP_ON_LIST(state, blogId) {
            let index = state.followUps.findIndex(item => item.id === blogId);
            state.followUps.splice(index, 1);
        },

        DELETE_FOLLOW_UP(state) {
            state.followUp = {};
        },

        RESET_FOLLOW_UPS(state) {
            state.followUps = [];
        },

        RESET_FOLLOW_UP(state) {
            state.followUp = {};
        },
    },

    actions: {
        commitSetFollowUps: async ({commit}, responseData) => {
            await commit('SET_FOLLOW_UPS', responseData.data);
        },

        commitSetPaginateLinks: async ({commit}, responseData) => {
            await commit('SET_PAGINATE_LINKS', responseData.meta);
        },

        commitSetFollowUp: async ({commit}, responseData) => {
            await commit('SET_FOLLOW_UP', responseData.data)
        },

        commitCreateFollowUpOnList: async ({commit}, responseData) => {
            await commit('CREATE_FOLLOW_UP_ON_LIST', responseData.data);
        },

        commitCreateFollowUp: async ({commit}, responseData) => {
            await commit('CREATE_FOLLOW_UP', responseData.data);
        },

        commitUpdateFollowUpOnList: async ({commit}, responseData) => {
            await commit('UPDATE_FOLLOW_UP_ON_LIST', responseData.data);
        },

        commitUpdateFollowUp: async ({commit}, responseData) => {
            await commit('UPDATE_FOLLOW_UP', responseData.data);
        },

        commitDeleteFollowUpOnList: async ({commit}, blogId) => {
            await commit('DELETE_FOLLOW_UP_ON_LIST', blogId);
        },

        commitDeleteFollowUp: async ({commit}) => {
            await commit('DELETE_FOLLOW_UP');
        },

        commitResetFollowUps: async ({commit}) => {
            await commit('RESET_FOLLOW_UPS');
        },

        commitResetFollowUp: async ({commit}) => {
            await commit('RESET_FOLLOW_UP');
        },

        // get list of follow up by api call.
        getFollowUps: async ({dispatch}, params = {}) => {
            const path = `v1/follow-ups`;

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetFollowUps', response.data);
                dispatch('commitSetPaginateLinks', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // get single follow up by api call.
        getFollowUp: async ({dispatch}, paramObj) => {
            const path = `v1/follow-ups/${paramObj.id}`;
            const params = paramObj.params ?? {};

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetFollowUp', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // create new follow up on list by api call.
        postFollowUpOnList: async ({dispatch}, data) => {
            const path = `v1/follow-ups`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateFollowUpOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // create new follow up by api call.
        postFollowUp: async ({dispatch}, data) => {
            const path = `v1/follow-ups`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateFollowUp', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing follow up on list by api call.
        putFollowUpOnList: async ({dispatch}, dataObj) => {
            const path = `v1/follow-ups/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateFollowUpOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing follow up by api call.
        putFollowUp: async ({dispatch}, dataObj) => {
            const path = `v1/follow-ups/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateFollowUp', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular blog on list by api call.
        deleteFollowUpOnList: async ({dispatch}, id) => {
            const path = `v1/follow-ups/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteFollowUpOnList', id);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular follow ups by api call.
        deleteFollowUp: async ({dispatch}, id) => {
            const path = `v1/follow-ups/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteFollowUp', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // reset follow ups state.
        resetFollowUps: async ({dispatch}) => {
            dispatch('commitResetFollowUps');
        },

        // reset follow ups state.
        resetFollowUp: async ({dispatch}) => {
            dispatch('commitResetFollowUp')
        },

    },

};