import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {
        productLicenses: [],
        paginateLinks: {},
        productLicense: {},
    },

    getters: {
        productLicenses: (state) => {
            return state.productLicenses;
        },

        paginateLinks: (state) => {
            return state.paginateLinks;
        },

        productLicense: (state) => {
            return state.productLicense;
        },
    },
    mutations: {
        SET_PRODUCT_LICENSES(state, productLicenses) {
            state.productLicenses = productLicenses;
        },

        SET_PAGINATE_LINKS(state, paginateLinks) {
            state.paginateLinks = paginateLinks;
        },

        SET_PRODUCT_LICENSE(state, productLicense) {
            state.productLicense = productLicense;
        },

        CREATE_PRODUCT_LICENSE_ON_LIST(state, productLicense) {
            state.productLicenses.unshift(productLicense);
        },

        CREATE_PRODUCT_LICENSE(state, productLicense) {
            state.productLicense = productLicense;
        },

        UPDATE_PRODUCT_LICENSE_ON_LIST(state, productLicense) {
            let index = state.productLicenses.findIndex(item => item.id === productLicense.id);
            state.productLicenses.splice(index, 1, productLicense);
        },

        UPDATE_PRODUCT_LICENSE(state, productLicense) {
            state.productLicense = productLicense;
        },

        DELETE_PRODUCT_LICENSE_ON_LIST(state, productLicenseId) {
            let index = state.productLicenses.findIndex(item => item.id === productLicenseId);
            state.productLicenses.splice(index, 1);
        },

        DELETE_PRODUCT_LICENSE(state) {
            state.productLicense = {};
        },

        RESET_PRODUCT_LICENSES(state) {
            state.productLicenses = [];
        },

        RESET_PRODUCT_LICENSE(state) {
            state.productLicense = {};
        },
    },
    actions: {
        commitSetProductLicenses: async ({commit}, responseData) => {
            await commit('SET_PRODUCT_LICENSES', responseData.data);
        },

        commitSetPaginateLinks: async ({commit}, responseData) => {
            await commit('SET_PAGINATE_LINKS', responseData.meta);
        },

        commitSetProductLicense: async ({commit}, responseData) => {
            await commit('SET_PRODUCT_LICENSE', responseData.data)
        },

        commitCreateProductLicenseOnList: async ({commit}, responseData) => {
            await commit('CREATE_PRODUCT_LICENSE_ON_LIST', responseData.data);
        },

        commitCreateProductLicense: async ({commit}, responseData) => {
            await commit('CREATE_PRODUCT_LICENSE', responseData.data);
        },

        commitUpdateProductLicenseOnList: async ({commit}, responseData) => {
            await commit('UPDATE_PRODUCT_LICENSE_ON_LIST', responseData.data);
        },

        commitUpdateProductLicense: async ({commit}, responseData) => {
            await commit('UPDATE_PRODUCT_LICENSE', responseData.data);
        },

        commitDeleteProductLicenseOnList: async ({commit}, productLicenseId) => {
            await commit('DELETE_PRODUCT_LICENSE_ON_LIST', productLicenseId);
        },

        commitDeleteProductLicense: async ({commit}) => {
            await commit('DELETE_PRODUCT_LICENSE');
        },

        commitResetProductLicenses: async ({commit}) => {
            await commit('RESET_PRODUCT_LICENSES');
        },

        commitResetProductLicense: async ({commit}) => {
            await commit('RESET_PRODUCT_LICENSE');
        },

        // get list of productLicense by api call.
        getProductLicenses: async ({dispatch}, params = {}) => {
            const path = `v1/product-licenses`;

            return  axios.get(path, {params: params}).then(response => {
                dispatch('commitSetProductLicenses', response.data);
                dispatch('commitSetPaginateLinks', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // get single productLicense by api call.
        getProductLicense: async ({dispatch}, paramObj) => {
            const path = `v1/product-licenses/${paramObj.id}`;
            const params = paramObj.params ?? {};

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetProductLicense', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // create new productLicense on list by api call.
        postProductLicenseOnList: async ({dispatch}, data) => {
            const path = `v1/product-licenses`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateProductLicenseOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // create new productLicense by api call.
        postProductLicense: async ({dispatch}, data) => {
            const path = `v1/product-licenses`;

            return axios.post(path, data).then((response) => {

                dispatch('commitCreateProductLicense', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing productLicense on list by api call.
        putProductLicenseOnList: async ({dispatch}, dataObj) => {
            const path = `v1/product-licenses/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateProductLicenseOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing productLicense by api call.
        putProductLicense: async ({dispatch}, dataObj) => {
            const path = `v1/product-licenses/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateProductLicense', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular productLicense on list by api call.
        deleteProductLicenseOnList: async ({dispatch}, id) => {
            const path = `v1/product-licenses/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteProductLicenseOnList', id);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular productLicense by api call.
        deleteProductLicense: async ({dispatch}, id) => {
            const path = `v1/product-licenses/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteProductLicense', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // reset productLicenses state.
        resetProductLicenses: async ({dispatch}) => {
            dispatch('commitResetProductLicenses');
        },

        // reset productLicense state.
        resetProductLicense: async ({dispatch}) => {
            dispatch('commitResetProductLicense')
        },

        // create new product license list by api call.
        postProductLicenseUpdateList: async (_, data) => {
            const path = `v1/product-licenses/update-list`;

            return axios.put(path, data).then((response) => {

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

    },
};
