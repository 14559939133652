import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {
        storeManagers: [],
        paginateLinks: {},
        storeManager: {},
    },

    getters: {
        storeManagers: (state) => {
            return state.storeManagers;
        },

        paginateLinks: (state) => {
            return state.paginateLinks;
        },

        storeManager: (state) => {
            return state.storeManager;
        },
    },
    mutations: {
        SET_STORE_MANAGERS(state, storeManagers) {
            state.storeManagers = storeManagers;
        },

        SET_PAGINATE_LINKS(state, paginateLinks) {
            state.paginateLinks = paginateLinks;
        },

        SET_STORE_MANAGER(state, storeManager) {
            state.storeManager = storeManager;
        },

        CREATE_STORE_MANAGER_ON_LIST(state, storeManager) {
            state.storeManagers.unshift(storeManager);
        },

        CREATE_STORE_MANAGER(state, storeManager) {
            state.storeManager = storeManager;
        },

        UPDATE_STORE_MANAGER_ON_LIST(state, storeManager) {
            let index = state.storeManagers.findIndex(item => item.id === storeManager.id);
            state.storeManagers.splice(index, 1, storeManager);
        },

        UPDATE_STORE_MANAGER(state, storeManager) {
            state.storeManager = storeManager;
        },

        DELETE_STORE_MANAGER_ON_LIST(state, storeManagerId) {
            let index = state.storeManagers.findIndex(item => item.id === storeManagerId);
            state.storeManagers.splice(index, 1);
        },

        DELETE_STORE_MANAGER(state) {
            state.storeManager = {};
        },

        RESET_STORE_MANAGERS(state) {
            state.storeManagers = [];
        },

        RESET_STORE_MANAGER(state) {
            state.storeManager = {};
        },
    },
    actions: {
        commitSetStoreManagers: async ({commit}, responseData) => {
            await commit('SET_STORE_MANAGERS', responseData.data);
        },

        commitSetPaginateLinks: async ({commit}, responseData) => {
            await commit('SET_PAGINATE_LINKS', responseData.meta);
        },

        commitSetStoreManager: async ({commit}, responseData) => {
            await commit('SET_STORE_MANAGER', responseData.data)
        },

        commitCreateStoreManagerOnList: async ({commit}, responseData) => {
            await commit('CREATE_STORE_MANAGER_ON_LIST', responseData.data);
        },

        commitCreateStoreManager: async ({commit}, responseData) => {
            await commit('CREATE_STORE_MANAGER', responseData.data);
        },

        commitUpdateStoreManagerOnList: async ({commit}, responseData) => {
            await commit('UPDATE_STORE_MANAGER_ON_LIST', responseData.data);
        },

        commitUpdateStoreManager: async ({commit}, responseData) => {
            await commit('UPDATE_STORE_MANAGER', responseData.data);
        },

        commitDeleteStoreManagerOnList: async ({commit}, storeManagerId) => {
            await commit('DELETE_STORE_MANAGER_ON_LIST', storeManagerId);
        },

        commitDeleteStoreManager: async ({commit}) => {
            await commit('DELETE_STORE_MANAGER');
        },

        commitResetStoreManagers: async ({commit}) => {
            await commit('RESET_STORE_MANAGERS');
        },

        commitResetStoreManager: async ({commit}) => {
            await commit('RESET_STORE_MANAGER');
        },

        // get list of store manager by api call.
        getStoreManagers: async ({dispatch}, params = {}) => {
            const path = `v1/store-managers`;

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetStoreManagers', response.data);
                dispatch('commitSetPaginateLinks', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // get single store manager by api call.
        getStoreManager: async ({dispatch}, paramObj) => {
            const path = `v1/store-managers/${paramObj.id}`;
            const params = paramObj.params ?? {};

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetStoreManager', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // create new store-manager on list by api call.
        postStoreManagerOnList: async ({dispatch}, data) => {
            const path = `v1/store-managers`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateStoreManagerOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // create new store manager by api call.
        postStoreManager: async ({dispatch}, data) => {
            const path = `v1/store-managers`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateStoreManager', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing store manager on list by api call.
        putStoreManagerOnList: async ({dispatch}, dataObj) => {
            const path = `v1/store-managers/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateStoreManagerOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing store manager by api call.
        putStoreManager: async ({dispatch}, dataObj) => {
            const path = `v1/store-managers/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateStoreManager', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular store manager on list by api call.
        deleteStoreManagerOnList: async ({dispatch}, id) => {
            const path = `v1/store-managers/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteStoreManagerOnList', id);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular store manager by api call.
        deleteStoreManager: async ({dispatch}, id) => {
            const path = `v1/store-managers/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteStoreManager', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // reset store managers state.
        resetStoreManagers: async ({dispatch}) => {
            dispatch('commitResetStoreManagers');
        },

        // reset store manager state.
        resetStoreManager: async ({dispatch}) => {
            dispatch('commitResetStoreManager')
        },

    },
};
