import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {
        franchiseeTechnicians: [],
        paginateLinks: {},
        franchiseeTechnician: {},
    },

    getters: {
        franchiseeTechnicians: (state) => {
            return state.franchiseeTechnicians;
        },

        paginateLinks: (state) => {
            return state.paginateLinks;
        },

        franchiseeTechnician: (state) => {
            return state.franchiseeTechnician;
        },
    },
    mutations: {
        SET_FRANCHISEE_TECHNICIANS(state, franchiseeTechnicians) {
            state.franchiseeTechnicians = franchiseeTechnicians;
        },

        SET_PAGINATE_LINKS(state, paginateLinks) {
            state.paginateLinks = paginateLinks;
        },

        SET_FRANCHISEE_TECHNICIAN(state, franchiseeTechnician) {
            state.franchiseeTechnician = franchiseeTechnician;
        },

        CREATE_FRANCHISEE_TECHNICIAN_ON_LIST(state, franchiseeTechnician) {
            state.franchiseeTechnicians.unshift(franchiseeTechnician);
        },

        CREATE_FRANCHISEE_TECHNICIAN(state, franchiseeTechnician) {
            state.franchiseeTechnician = franchiseeTechnician;
        },

        UPDATE_FRANCHISEE_TECHNICIAN_ON_LIST(state, franchiseeTechnician) {
            let index = state.franchiseeTechnicians.findIndex(item => item.id === franchiseeTechnician.id);
            state.franchiseeTechnicians.splice(index, 1, franchiseeTechnician);
        },

        UPDATE_FRANCHISEE_TECHNICIAN(state, franchiseeTechnician) {
            state.franchiseeTechnician = franchiseeTechnician;
        },

        DELETE_FRANCHISEE_TECHNICIAN_ON_LIST(state, FRANCHISEE_TECHNICIANId) {
            let index = state.franchiseeTechnicians.findIndex(item => item.id === FRANCHISEE_TECHNICIANId);
            state.franchiseeTechnicians.splice(index, 1);
        },

        DELETE_FRANCHISEE_TECHNICIAN(state) {
            state.franchiseeTechnician = {};
        },

        RESET_FRANCHISEE_TECHNICIANS(state) {
            state.franchiseeTechnicians = [];
        },

        RESET_FRANCHISEE_TECHNICIAN(state) {
            state.franchiseeTechnician = {};
        },
    },
    actions: {
        commitSetFranchiseeTechnicians: async ({commit}, responseData) => {
            await commit('SET_FRANCHISEE_TECHNICIANS', responseData.data);
        },

        commitSetPaginateLinks: async ({commit}, responseData) => {
            await commit('SET_PAGINATE_LINKS', responseData.meta);
        },

        commitSetFranchiseeTechnician: async ({commit}, responseData) => {
            await commit('SET_FRANCHISEE_TECHNICIAN', responseData.data)
        },

        commitCreateFranchiseeTechnicianOnList: async ({commit}, responseData) => {
            await commit('CREATE_FRANCHISEE_TECHNICIAN_ON_LIST', responseData.data);
        },

        commitCreateFranchiseeTechnician: async ({commit}, responseData) => {
            await commit('CREATE_FRANCHISEE_TECHNICIAN', responseData.data);
        },

        commitUpdateFranchiseeTechnicianOnList: async ({commit}, responseData) => {
            await commit('UPDATE_FRANCHISEE_TECHNICIAN_ON_LIST', responseData.data);
        },

        commitUpdateFranchiseeTechnician: async ({commit}, responseData) => {
            await commit('UPDATE_FRANCHISEE_TECHNICIAN', responseData.data);
        },

        commitDeleteFranchiseeTechnicianOnList: async ({commit}, FRANCHISEE_TECHNICIANId) => {
            await commit('DELETE_FRANCHISEE_TECHNICIAN_ON_LIST', FRANCHISEE_TECHNICIANId);
        },

        commitDeleteFranchiseeTechnician: async ({commit}) => {
            await commit('DELETE_FRANCHISEE_TECHNICIAN');
        },

        commitResetFranchiseeTechnicians: async ({commit}) => {
            await commit('RESET_FRANCHISEE_TECHNICIANS');
        },

        commitResetFranchiseeTechnician: async ({commit}) => {
            await commit('RESET_FRANCHISEE_TECHNICIAN');
        },

        // get list of franchiseeTechnician by api call.
        getFranchiseeTechnicians: async ({dispatch}, params = {}) => {
            const path = `v1/franchisee-technicians`;

            return  axios.get(path, {params: params}).then(response => {
                dispatch('commitSetFranchiseeTechnicians', response.data);
                dispatch('commitSetPaginateLinks', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // get single franchiseeTechnician by api call.
        getFranchiseeTechnician: async ({dispatch}, paramObj) => {
            const path = `v1/franchisee-technicians/${paramObj.id}`;
            const params = paramObj.params ?? {};

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetFranchiseeTechnician', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // create new franchiseeTechnician on list by api call.
        postFranchiseeTechnicianOnList: async ({dispatch}, data) => {
            const path = `v1/franchisee-technicians`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateFranchiseeTechnicianOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // create new franchiseeTechnician by api call.
        postFranchiseeTechnician: async ({dispatch}, data) => {
            const path = `v1/franchisee-technicians`;

            return axios.post(path, data).then((response) => {

                dispatch('commitCreateFranchiseeTechnician', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing franchiseeTechnician on list by api call.
        putFranchiseeTechnicianOnList: async ({dispatch}, dataObj) => {
            const path = `v1/franchisee-technicians/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateFranchiseeTechnicianOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing franchiseeTechnician by api call.
        putFranchiseeTechnician: async ({dispatch}, dataObj) => {
            const path = `v1/franchisee-technicians/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateFranchiseeTechnician', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular franchiseeTechnician on list by api call.
        deleteFranchiseeTechnicianOnList: async ({dispatch}, id) => {
            const path = `v1/franchisee-technicians/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteFranchiseeTechnicianOnList', id);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular franchiseeTechnician by api call.
        deleteFranchiseeTechnician: async ({dispatch}, id) => {
            const path = `v1/franchisee-technicians/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteFranchiseeTechnician', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // reset franchiseeTechnicians state.
        resetFranchiseeTechnicians: async ({dispatch}) => {
            dispatch('commitResetFranchiseeTechnicians');
        },

        // reset franchiseeTechnician state.
        resetFranchiseeTechnician: async ({dispatch}) => {
            dispatch('commitResetFranchiseeTechnician')
        },

    },
};
