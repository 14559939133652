import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,
    state: {
        poolOfTechnicians: [],
        paginateLinks: {},
        poolOfTechnician: {},
    },
    getters: {
        poolOfTechnicians: (state) => {
            return state.poolOfTechnicians;
        },

        paginateLinks: (state) => {
            return state.paginateLinks;
        },

        poolOfTechnician: (state) => {
            return state.poolOfTechnician;
        },
    },
    mutations: {
        SET_POOL_OF_TECHNICIANS(state, poolOfTechnicians) {
            state.poolOfTechnicians = poolOfTechnicians;
        },

        SET_PAGINATE_LINKS(state, paginateLinks) {
            state.paginateLinks = paginateLinks;
        },

        SET_POOL_OF_TECHNICIAN(state, poolOfTechnician) {
            state.poolOfTechnician = poolOfTechnician;
        },

        CREATE_POOL_OF_TECHNICIAN_ON_LIST(state, poolOfTechnician) {
            state.poolOfTechnicians.unshift(poolOfTechnician);
        },

        CREATE_POOL_OF_TECHNICIAN(state, poolOfTechnician) {
            state.poolOfTechnician = poolOfTechnician;
        },

        UPDATE_POOL_OF_TECHNICIAN_ON_LIST(state, poolOfTechnician) {
            let index = state.poolOfTechnicians.findIndex(item => item.id === poolOfTechnician.id);
            state.poolOfTechnicians.splice(index, 1, poolOfTechnician);
        },

        UPDATE_POOL_OF_TECHNICIAN(state, poolOfTechnician) {
            state.poolOfTechnician = poolOfTechnician;
        },

        DELETE_POOL_OF_TECHNICIAN_ON_LIST(state, poolOfTechnicianId) {
            let index = state.poolOfTechnicians.findIndex(item => item.id === poolOfTechnicianId);
            state.poolOfTechnicians.splice(index, 1);
        },

        DELETE_POOL_OF_TECHNICIAN(state) {
            state.poolOfTechnician = {};
        },

        RESET_POOL_OF_TECHNICIANS(state) {
            state.poolOfTechnicians = [];
        },

        RESET_POOL_OF_TECHNICIAN(state) {
            state.poolOfTechnician = {};
        },
    },
    actions: {
        commitSetPoolOfTechnicians: async ({commit}, responseData) => {
            await commit('SET_POOL_OF_TECHNICIANS', responseData.data);
        },

        commitSetPaginateLinks: async ({commit}, responseData) => {
            await commit('SET_PAGINATE_LINKS', responseData.meta);
        },

        commitSetPoolOfTechnician: async ({commit}, responseData) => {
            await commit('SET_POOL_OF_TECHNICIAN', responseData.data)
        },

        commitCreatePoolOfTechnicianOnList: async ({commit}, responseData) => {
            await commit('CREATE_POOL_OF_TECHNICIAN_ON_LIST', responseData.data);
        },

        commitCreatePoolOfTechnician: async ({commit}, responseData) => {
            await commit('CREATE_POOL_OF_TECHNICIAN', responseData.data);
        },

        commitUpdatePoolOfTechnicianOnList: async ({commit}, responseData) => {
            await commit('UPDATE_POOL_OF_TECHNICIAN_ON_LIST', responseData.data);
        },

        commitUpdatePoolOfTechnician: async ({commit}, responseData) => {
            await commit('UPDATE_POOL_OF_TECHNICIAN', responseData.data);
        },

        commitDeletePoolOfTechnicianOnList: async ({commit}, poolOfTechnicianId) => {
            await commit('DELETE_POOL_OF_TECHNICIAN_ON_LIST', poolOfTechnicianId);
        },

        commitDeletePoolOfTechnician: async ({commit}) => {
            await commit('DELETE_POOL_OF_TECHNICIAN');
        },

        commitResetPoolOfTechnicians: async ({commit}) => {
            await commit('RESET_POOL_OF_TECHNICIANS');
        },

        commitResetPoolOfTechnician: async ({commit}) => {
            await commit('RESET_POOL_OF_TECHNICIAN');
        },

        // get list of poolOfTechnician by api call.
        async getPoolOfTechnicians({dispatch}, params) {
            const path = `v1/pool-of-technicians`;

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetPoolOfTechnicians', response.data);
                dispatch('commitSetPaginateLinks', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // get single poolOfTechnician by api call.
        async getPoolOfTechnician({dispatch}, paramObj) {
            const path = `v1/pool-of-technicians/${paramObj.id}`;
            const params = paramObj.params ?? {};

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetPoolOfTechnician', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // create new poolOfTechnician on list by api call.
        async postPoolOfTechnicianOnList({dispatch}, data) {
            const path = `v1/pool-of-technicians`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreatePoolOfTechnicianOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // create new poolOfTechnician by api call.
        async postPoolOfTechnician({dispatch}, data) {
            const path = `v1/pool-of-technicians`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreatePoolOfTechnician', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing poolOfTechnician on list by api call.
        async putPoolOfTechnicianOnList({dispatch}, dataObj) {
            const path = `v1/pool-of-technicians/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdatePoolOfTechnicianOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing poolOfTechnician by api call.
        async putPoolOfTechnician({dispatch}, dataObj) {
            const path = `v1/pool-of-technicians/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdatePoolOfTechnician', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular poolOfTechnician on list by api call.
        async deletePoolOfTechnicianOnList({dispatch}, id) {
            const path = `v1/pool-of-technicians/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeletePoolOfTechnicianOnList', id);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular poolOfTechnician by api call.
        async deletePoolOfTechnician({dispatch}, id) {
            const path = `v1/pool-of-technicians/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeletePoolOfTechnician', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update poolOfTechnician avatar by api call.
        async putAvatar(_, dataObj) {

            const path = `v1/pool-of-technicians/${dataObj.id}/avatar`;
            const data = dataObj.data ?? {};

            return axios.put(path, data).then((response) => {

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // reset poolOfTechnicians state.
        resetPoolOfTechnicians: async ({dispatch}) => {
            dispatch('commitResetPoolOfTechnicians');
        },

        // reset poolOfTechnician state.
        resetPoolOfTechnician: async ({dispatch}) => {
            dispatch('commitResetPoolOfTechnician')
        },

        // create new poolOfTechnician by api call.
        async removePoolTechnicianFromAppointment(_, data) {
            const path = `v1/pool-of-technicians/appointment-remove`;

            return axios.post(path, data).then((response) => {

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                    data: response.data.data
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // post  poolOfTechnician on appointment api call.
        async addPoolTechnicianToAppointment(_, dataObj) {
            const path = `v1/pool-of-technicians/appointment/${dataObj.id}`;
            const data = dataObj.data;

            return axios.post(path, data).then((response) => {

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                    data: response.data.data
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },
    }
}

