import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {
        appointmentPaymentCollections: [],
        paginateLinks: {},
        appointmentPaymentCollection: {},
    },

    getters: {
        appointmentPaymentCollections: (state) => {
            return state.appointmentPaymentCollections;
        },

        paginateLinks: (state) => {
            return state.paginateLinks;
        },

        appointmentPaymentCollection: (state) => {
            return state.appointmentPaymentCollection;
        },
    },
    mutations: {
        SET_APPOINTMENT_PAYMENT_COLLECTIONS(state, appointmentPaymentCollections) {
            state.appointmentPaymentCollections = appointmentPaymentCollections;
        },

        SET_PAGINATE_LINKS(state, paginateLinks) {
            state.paginateLinks = paginateLinks;
        },

        SET_APPOINTMENT_PAYMENT_COLLECTION(state, appointmentPaymentCollection) {
            state.appointmentPaymentCollection = appointmentPaymentCollection;
        },

        CREATE_APPOINTMENT_PAYMENT_COLLECTION_ON_LIST(state, appointmentPaymentCollection) {
            state.appointmentPaymentCollections.unshift(appointmentPaymentCollection);
        },

        CREATE_APPOINTMENT_PAYMENT_COLLECTION(state, appointmentPaymentCollection) {
            state.appointmentPaymentCollection = appointmentPaymentCollection;
        },

        UPDATE_APPOINTMENT_PAYMENT_COLLECTION_ON_LIST(state, appointmentPaymentCollection) {
            let index = state.appointmentPaymentCollections.findIndex(item => item.id === appointmentPaymentCollection.id);
            state.appointmentPaymentCollections.splice(index, 1, appointmentPaymentCollection);
        },

        UPDATE_APPOINTMENT_PAYMENT_COLLECTION(state, appointmentPaymentCollection) {
            state.appointmentPaymentCollection = appointmentPaymentCollection;
        },

        DELETE_APPOINTMENT_PAYMENT_COLLECTION_ON_LIST(state, orderCreatorId) {
            let index = state.appointmentPaymentCollections.findIndex(item => item.id === orderCreatorId);
            state.appointmentPaymentCollections.splice(index, 1);
        },

        DELETE_APPOINTMENT_PAYMENT_COLLECTION(state) {
            state.appointmentPaymentCollection = {};
        },

        RESET_APPOINTMENT_PAYMENT_COLLECTIONS(state) {
            state.appointmentPaymentCollections = [];
        },

        RESET_APPOINTMENT_PAYMENT_COLLECTION(state) {
            state.appointmentPaymentCollection = {};
        },
    },
    actions: {
        commitSetAppointmentPaymentCollections: async ({commit}, responseData) => {
            await commit('SET_APPOINTMENT_PAYMENT_COLLECTIONS', responseData.data);
        },

        commitSetPaginateLinks: async ({commit}, responseData) => {
            await commit('SET_PAGINATE_LINKS', responseData.meta);
        },

        commitSetAppointmentPaymentCollection: async ({commit}, responseData) => {
            await commit('SET_APPOINTMENT_PAYMENT_COLLECTION', responseData.data)
        },

        commitCreateAppointmentPaymentCollectionOnList: async ({commit}, responseData) => {
            await commit('CREATE_APPOINTMENT_PAYMENT_COLLECTION_ON_LIST', responseData.data);
        },

        commitCreateAppointmentPaymentCollection: async ({commit}, responseData) => {
            await commit('CREATE_APPOINTMENT_PAYMENT_COLLECTION', responseData.data);
        },

        commitUpdateAppointmentPaymentCollectionOnList: async ({commit}, responseData) => {
            await commit('UPDATE_APPOINTMENT_PAYMENT_COLLECTION_ON_LIST', responseData.data);
        },

        commitUpdateAppointmentPaymentCollection: async ({commit}, responseData) => {
            await commit('UPDATE_APPOINTMENT_PAYMENT_COLLECTION', responseData.data);
        },

        commitDeleteAppointmentPaymentCollectionOnList: async ({commit}, orderCreatorId) => {
            await commit('DELETE_APPOINTMENT_PAYMENT_COLLECTION_ON_LIST', orderCreatorId);
        },

        commitDeleteAppointmentPaymentCollection: async ({commit}) => {
            await commit('DELETE_APPOINTMENT_PAYMENT_COLLECTION');
        },

        commitResetappointmentPaymentCollections: async ({commit}) => {
            await commit('RESET_APPOINTMENT_PAYMENT_COLLECTIONS');
        },

        commitResetAppointmentPaymentCollection: async ({commit}) => {
            await commit('RESET_APPOINTMENT_PAYMENT_COLLECTION');
        },

        // get list of appointmentPaymentCollection by api call.
        getAppointmentPaymentCollections: async ({dispatch}, params = {}) => {
            const path = `v1/appointment-payment-collections`;

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetAppointmentPaymentCollections', response.data);
                dispatch('commitSetPaginateLinks', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // get single appointmentPaymentCollection by api call.
        getAppointmentPaymentCollection: async ({dispatch}, paramObj) => {
            const path = `v1/appointment-payment-collections/${paramObj.id}`;
            const params = paramObj.params ?? {};

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetAppointmentPaymentCollection', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // create new appointmentPaymentCollection on list by api call.
        postAppointmentPaymentCollectionOnList: async ({dispatch}, data) => {
            const path = `v1/appointment-payment-collections`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateAppointmentPaymentCollectionOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // create new appointmentPaymentCollection by api call.
        postAppointmentPaymentCollection: async ({dispatch}, data) => {
            const path = `v1/appointment-payment-collections`;

            return axios.post(path, data).then((response) => {

                dispatch('commitCreateAppointmentPaymentCollection', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing appointmentPaymentCollection on list by api call.
        putAppointmentPaymentCollectionOnList: async ({dispatch}, dataObj) => {
            const path = `v1/appointment-payment-collections/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateAppointmentPaymentCollectionOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing appointmentPaymentCollection by api call.
        putAppointmentPaymentCollection: async ({dispatch}, dataObj) => {
            const path = `v1/appointment-payment-collections/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateAppointmentPaymentCollection', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular appointmentPaymentCollection on list by api call.
        deleteAppointmentPaymentCollectionOnList: async ({dispatch}, id) => {
            const path = `v1/appointment-payment-collections/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteAppointmentPaymentCollectionOnList', id);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular appointmentPaymentCollection by api call.
        deleteAppointmentPaymentCollection: async ({dispatch}, id) => {
            const path = `v1/appointment-payment-collections/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteAppointmentPaymentCollection', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // reset appointmentPaymentCollections state.
        resetAppointmentPaymentCollections: async ({dispatch}) => {
            dispatch('commitResetAppointmentPaymentCollections');
        },

        // reset appointmentPaymentCollection state.
        resetAppointmentPaymentCollection: async ({dispatch}) => {
            dispatch('commitResetAppointmentPaymentCollection')
        },

    },
};
