import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {

    },

    getters: {

    },
    mutations: {

    },
    actions: {


        // create new bank account by api call.
        paymentCreate: async ( _ , data) =>
        {
            const path = `v1/payment-gateways/payments/create`;

            return axios.post(path, data).then((response) =>
            {

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                    data:response.data.data
                };
            }).catch((error) =>
            {
                return responseErrorHandler(error);
            });
        },

        // payment create by token
        paymentCreateByToken: async ( _ , data) =>
        {
            const path = `v1/payment-gateways/payments/create-by-token`;

            return axios.post(path, data).then((response) =>
            {

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                    data:response.data.data
                };
            }).catch((error) =>
            {
                return responseErrorHandler(error);
            });
        },
        // create card token by api call.
        cardTokenCreate: async ( _ , data) =>
        {
            const path = `v1/payment-gateways/card-tokens/create`;

            return axios.post(path, data).then((response) =>
            {

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                    data:response.data.data
                };
            }).catch((error) =>
            {
                return responseErrorHandler(error);
            });
        },
        authorizationCreateByToken: async ( _ , data) =>
        {
            const path = `v1/payment-gateways/authorizations/create-by-token`;

            return axios.post(path, data).then((response) =>
            {

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                    data:response.data.data
                };
            }).catch((error) =>
            {
                return responseErrorHandler(error);
            });
        },
        authorizationReversed: async ( _ , data) =>
        {
            const path = `v1/payment-gateways/authorizations/reversed`;

            return axios.post(path, data).then((response) =>
            {

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                    data:response.data
                };
            }).catch((error) =>
            {
                return responseErrorHandler(error);
            });
        },
        paymentCapture: async ( _ , data) =>
        {
            const path = `v1/payment-gateways/payments/capture`;

            return axios.post(path, data).then((response) =>
            {

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                    data:response.data.data
                };
            }).catch((error) =>
            {
                return responseErrorHandler(error);
            });
        },
        refundCreate: async (_, dataObj) =>
        {
            const path = `v1/payment-gateways/refunds/create`;
            
            return axios.post(path, dataObj).then((response) =>
            {
                // dispatch('commitSetPayment', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                    data: response.data.data
                };
            }).catch((error) =>
            {
                return responseErrorHandler(error);
            });
        },

        authorizationFind: async ( _ , data) =>
        {
            const path = `v1/payment-gateways/authorizations/find`;

            return axios.post(path, data).then((response) =>
            {
                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                    data:response.data.data
                };
            }).catch((error) =>
            {
                return responseErrorHandler(error);
            });
        },

        paymentFind: async ( _ , data) =>
        {
            const path = `v1/payment-gateways/payments/find`;

            return axios.post(path, data).then((response) =>
            {
                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                    data:response.data.data
                };
            }).catch((error) =>
            {
                return responseErrorHandler(error);
            });
        },
        refundFind: async ( _ , data) =>
        {
            const path = `v1/payment-gateways/refunds/find`;

            return axios.post(path, data).then((response) =>
            {
                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                    data:response.data.data
                };
            }).catch((error) =>
            {
                return responseErrorHandler(error);
            });
        },


    },
};
