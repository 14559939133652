<template>
<div class="full-screen-loader d-none" id="fullScreenLoader">
    <div class="loader">
        <div class="lds-spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
</div>
<div v-if="notFound">
    <NotFound />
</div>
<router-view v-else />
</template>

<script>
import NotFound from "@/views/global/NotFound/NotFound";
import {

    mapGetters
} from "vuex";

export default {
    components: {
        NotFound
    },
    computed: {
        ...mapGetters({
            notFound: 'notFound',
        }),
    },


}
</script>

<style src="../node_modules/vue-multiselect/dist/vue-multiselect.css"></style><style>
/* global css */

:root {
    --primary-color: #5a8dee;
}
.progress .progress-bar {
    -webkit-animation-duration: 2s, 1s;
    animation-duration: 2s, 1s;
    -webkit-animation-timing-function: cubic-bezier(0, 0, 0.2, 1), linear;
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1), linear;
    -webkit-animation-delay: 0s, 0s;
    animation-delay: 0s, 0s;
    -webkit-animation-iteration-count: 1, infinite;
    animation-iteration-count: 1, infinite;
    -webkit-animation-direction: normal, normal;
    animation-direction: normal, normal;
    -webkit-animation-fill-mode: none, none;
    animation-fill-mode: none, none;
    -webkit-animation-play-state: running, running;
    animation-play-state: running, running;
    -webkit-animation-name: animateBar, progress-bar-stripes;
    animation-name: animateBar, progress-bar-stripes;
}

/* disable click event */
.disable-event{
    pointer-events: none !important;
    color: rgb(196, 189, 189);
}
/* loader css starts */

.full-screen {
    position: relative;
}

.loader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 1000000;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(6px);
    -webkit-backdrop-filter: blur(5px);
}

/* Add the blur effect */

.lds-spinner {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-spinner div {
    transform-origin: 40px 40px;
    animation: lds-spinner 1.2s linear infinite;
}

.lds-spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 37px;
    width: 6px;
    height: 18px;
    border-radius: 20%;
    background: #fff;
}

.lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
}

.lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
}

.lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
}

.lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
}

.lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
}

.lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
}

.lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
}

.lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
}

.lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
}

.lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
}

.lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
}

.lds-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
}

@keyframes lds-spinner {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

/* loader css ends */

/* table sorting icon css starts */
.sorting-up-button {
    top: 6px;
    right: 0;
    cursor: pointer;
    color: #b3c0ce !important;
}

.sorting-down-button {
    bottom: 6px;
    right: 0;
    cursor: pointer;
}

.sorting-up-button>i {
    color: #b3c0ce !important;
}

.sorting-down-button>i {
    color: #b3c0ce !important;
}

.sorting-up-button>i:hover {
    color: #5a8dee !important;
}

.sorting-down-button>i:hover {
    color: #5a8dee !important;
}

/* table sorting icon css ends */

/* bootstrap input field color change on focus */
.form-control:focus {
    color: #475f7b;
    background-color: #ffffff;
    border: 1px solid #5a8dee !important;
    outline: 0;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1);
}

.theme-color {
    color: #5a8dee;
}

.theme-bg-color {
    background-color: #5a8dee;
}

::placeholder {
    color: #b0b0b0 !important;
}

/*multi select border color change on focus starts */

.multiselect__tags:focus-within {
    border-color: #5a8dee;
}

.multiselect__tags {
    overflow: hidden !important;
    min-height: 0px !important;
    max-height: 38px !important;
    border-color: #dfe3e7;
}

/* select tag css */
.multiselect__option--selected.multiselect__option--highlight {
    background: #5a8dee !important;
    color: #ffff !important;
}

.multiselect__option--highlight {
    background: #5a8dee;
    outline: none;
    color: #fff;
}
/* .multiselect__option{
  text-transform: capitalize !important;
} */



/* .multiselect__input, .multiselect__single{
  text-transform: capitalize !important;
} */

/* 
.multiselect__option--selected {
    background: #f3f3f3;
    color: #fff;
    font-weight: 700;
} */

/*multi select border color change on focus ends */

/* color functionality of vue date picker package */

.v3dp__datepicker {
    --popout-bg-color: var(--vdp-bg-color, #fff);
    --box-shadow: var(--vdp-box-shadow,
            0 4px 10px 0 rgba(128, 144, 160, 0.1),
            0 0 1px 0 rgba(128, 144, 160, 0.81));
    --text-color: var(--vdp-text-color, #000000);
    --border-radius: var(--vdp-border-radius, 3px);
    --heading-size: var(--vdp-heading-size, 2.5em);
    --heading-weight: var(--vdp-heading-weight, bold);
    --heading-hover-color: var(--vdp-heading-hover-color, #eeeeee);
    --arrow-color: var(--vdp-arrow-color, currentColor);
    --elem-color: var(--vdp-elem-color, currentColor);
    --elem-disabled-color: var(--vdp-disabled-color, #d5d9e0);
    --elem-hover-color: var(--vdp-hover-color, #fff);
    --elem-hover-bg-color: #7ba1e7 !important;
    --elem-selected-color: var(--vdp-selected-color, #fff);
    --elem-selected-bg-color: #5a8dee !important;
    --elem-font-size: var(--vdp-elem-font-size, 0.8em);
    --elem-border-radius: var(--vdp-elem-border-radius, 3px);
    --divider-color: var(--vdp-divider-color, var(--elem-disabled-color));
    position: relative;
}

.v3dp__clearable {
    display: inline;
    position: relative;
    left: 0 !important;
    cursor: pointer;
}

.v3dp__popout {
    /* bottom: 0 !important; */
}

.v3dp__input_wrapper {
    display: flex !important;
}

.v3dp__input_wrapper>input {
    cursor: pointer !important;
}

.date-picker-wrapper:focus-within {
    border-color: #5a8dee;
    cursor: pointer;
}

/* full height google map css */
.vue-map {
    width: 100%;
    height: 100%;
    min-height: 80vh !important;
}

/* table responsive css  */

/* quil editor  */
.ql-editor {
    height: 150px !important;
}

/* toad msg  */
.v-toast__item {
    opacity: 1;
    min-height: 8px !important;
}

.v-toast__item .v-toast__text {
    padding: 8px 1em !important;
}

.v-toast {
    z-index: 10000000000 !important;
    pointer-events: none;
}

/* edit delete icon for data show table */
.view-edit-delete-icon {
    color: #b3c0ce;
    transition: all 0.2s;
}

.view-edit-delete-icon:hover {
    color: #5a8dee;
}

/*custom tooltip start*/
[data-custom-tooltip] {
    position: relative;
    cursor: default;
}

[data-custom-tooltip]::after {
    position: absolute;
    width: 140px;
    left: calc(50% - 70px);
    content: attr(data-custom-tooltip);
    bottom: 125%;
    text-align: center;
    box-sizing: border-box;
    color: #fff;
    background: rgba(0, 0, 0, 0.75);
    padding: 4px 2px;
    border-radius: 10px;
    font-size: 0.9rem;
    font-weight: 500;

    visibility: hidden;
    opacity: 0;

    transition: opacity 0.3s, transform 0.2s;
}

[data-custom-tooltip]:hover::after {
    opacity: 1;
    visibility: visible;
    transform: translateY(0px);
}

[data-custom-tooltip]::before {
    border: solid;
    border-color: #111 transparent;
    border-color: rgba(0, 0, 0, 0.8) transparent;
    border-width: 0.4em 0.4em 0 0.4em;

    bottom: 1em;
    top: -5px;
    content: "";
    display: block;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    z-index: 99;
    visibility: hidden;
    opacity: 0;
    /* transform: translateY(10px); */
    transition: opacity 0.3s, transform 0.2s;
}

[data-custom-tooltip]:hover:before {
    opacity: 1;
    visibility: visible;
}

/*custom tooltip end*/
.text-editor .ql-editor {
  height: 55vh !important;
}
</style>
