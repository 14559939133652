import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {
        bankAccounts: [],
        paginateLinks: {},
        bankAccount: {},
    },

    getters: {
        bankAccounts: (state) => {
            return state.bankAccounts;
        },

        paginateLinks: (state) => {
            return state.paginateLinks;
        },

        bankAccount: (state) => {
            return state.bankAccount;
        },
    },
    mutations: {
        SET_BANK_ACCOUNTS(state, bankAccounts) {
            state.bankAccounts = bankAccounts;
        },

        SET_PAGINATE_LINKS(state, paginateLinks) {
            state.paginateLinks = paginateLinks;
        },

        SET_BANK_ACCOUNT(state, bankAccount) {
            state.bankAccount = bankAccount;
        },

        CREATE_BANK_ACCOUNT_ON_LIST(state, bankAccount) {
            state.bankAccounts.unshift(bankAccount);
        },

        CREATE_BANK_ACCOUNT(state, bankAccount) {
            state.bankAccount = bankAccount;
        },

        UPDATE_BANK_ACCOUNT_ON_LIST(state, bankAccount) {
            let index = state.bankAccounts.findIndex(item => item.id === bankAccount.id);
            state.bankAccounts.splice(index, 1, bankAccount);
        },

        UPDATE_BANK_ACCOUNT(state, bankAccount) {
            state.bankAccount = bankAccount;
        },

        DELETE_BANK_ACCOUNT_ON_LIST(state, bankAccountId) {
            let index = state.bankAccounts.findIndex(item => item.id === bankAccountId);
            state.bankAccounts.splice(index, 1);
        },

        DELETE_BANK_ACCOUNT(state) {
            state.bankAccount = {};
        },

        RESET_BANK_ACCOUNTS(state) {
            state.bankAccounts = [];
        },

        RESET_BANK_ACCOUNT(state) {
            state.bankAccount = {};
        },
    },
    actions: {
        commitSetBankAccounts: async ({commit}, responseData) => {
            await commit('SET_BANK_ACCOUNTS', responseData.data);
        },

        commitSetPaginateLinks: async ({commit}, responseData) => {
            await commit('SET_PAGINATE_LINKS', responseData.meta);
        },

        commitSetBankAccount: async ({commit}, responseData) => {
            await commit('SET_BANK_ACCOUNT', responseData.data)
        },

        commitCreateBankAccountOnList: async ({commit}, responseData) => {
            await commit('CREATE_BANK_ACCOUNT_ON_LIST', responseData.data);
        },

        commitCreateBankAccount: async ({commit}, responseData) => {
            await commit('CREATE_BANK_ACCOUNT', responseData.data);
        },

        commitUpdateBankAccountOnList: async ({commit}, responseData) => {
            await commit('UPDATE_BANK_ACCOUNT_ON_LIST', responseData.data);
        },

        commitUpdateBankAccount: async ({commit}, responseData) => {
            await commit('UPDATE_BANK_ACCOUNT', responseData.data);
        },

        commitDeleteBankAccountOnList: async ({commit}, bankAccountId) => {
            await commit('DELETE_BANK_ACCOUNT_ON_LIST', bankAccountId);
        },

        commitDeleteBankAccount: async ({commit}) => {
            await commit('DELETE_BANK_ACCOUNT');
        },

        commitResetBankAccounts: async ({commit}) => {
            await commit('RESET_BANK_ACCOUNTS');
        },

        commitResetBankAccount: async ({commit}) => {
            await commit('RESET_BANK_ACCOUNT');
        },

        // get list of bank account by api call.
        getBankAccounts: async ({dispatch}, params = {}) => {
            const path = `v1/bank-accounts`;

            return  axios.get(path, {params: params}).then(response => {
                dispatch('commitSetBankAccounts', response.data);
                dispatch('commitSetPaginateLinks', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // get single bank account by api call.
        getBankAccount: async ({dispatch}, paramObj) => {
            const path = `v1/bank-accounts/${paramObj.id}`;
            const params = paramObj.params ?? {};

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetBankAccount', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // create new bank account on list by api call.
        postBankAccountOnList: async ({dispatch}, data) => {
            const path = `v1/bank-accounts`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateBankAccountOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // create new bank account by api call.
        postBankAccount: async ({dispatch}, data) => {
            const path = `v1/bank-accounts`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateBankAccount', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing bank account on list by api call.
        putBankAccountOnList: async ({dispatch}, dataObj) => {
            const path = `v1/bank-accounts/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateBankAccountOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing bank account by api call.
        putBankAccount: async ({dispatch}, dataObj) => {
            const path = `v1/bank-accounts/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateBankAccount', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular bank account on list by api call.
        deleteBankAccountOnList: async ({dispatch}, id) => {
            const path = `v1/bank-accounts/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteBankAccountOnList', id);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular bank account by api call.
        deleteBankAccount: async ({dispatch}, id) => {
            const path = `v1/bank-accounts/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteBankAccount', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // reset bank accounts state.
        resetBankAccounts: async ({dispatch}) => {
            dispatch('commitResetBankAccounts');
        },

        // reset bank account state.
        resetBankAccount: async ({dispatch}) => {
            dispatch('commitResetBankAccount')
        },

    },
};
