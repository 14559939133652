import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {
        orderCreators: [],
        paginateLinks: {},
        orderCreator: {},
    },

    getters: {
        orderCreators: (state) => {
            return state.orderCreators;
        },

        paginateLinks: (state) => {
            return state.paginateLinks;
        },

        orderCreator: (state) => {
            return state.orderCreator;
        },
    },
    mutations: {
        SET_ORDER_CREATORS(state, orderCreators) {
            state.orderCreators = orderCreators;
        },

        SET_PAGINATE_LINKS(state, paginateLinks) {
            state.paginateLinks = paginateLinks;
        },

        SET_ORDER_CREATOR(state, orderCreator) {
            state.orderCreator = orderCreator;
        },

        CREATE_ORDER_CREATOR_ON_LIST(state, orderCreator) {
            state.orderCreators.unshift(orderCreator);
        },

        CREATE_ORDER_CREATOR(state, orderCreator) {
            state.orderCreator = orderCreator;
        },

        UPDATE_ORDER_CREATOR_ON_LIST(state, orderCreator) {
            let index = state.orderCreators.findIndex(item => item.id === orderCreator.id);
            state.orderCreators.splice(index, 1, orderCreator);
        },

        UPDATE_ORDER_CREATOR(state, orderCreator) {
            state.orderCreator = orderCreator;
        },

        DELETE_ORDER_CREATOR_ON_LIST(state, orderCreatorId) {
            let index = state.orderCreators.findIndex(item => item.id === orderCreatorId);
            state.orderCreators.splice(index, 1);
        },

        DELETE_ORDER_CREATOR(state) {
            state.orderCreator = {};
        },

        RESET_ORDER_CREATORS(state) {
            state.orderCreators = [];
        },

        RESET_ORDER_CREATOR(state) {
            state.orderCreator = {};
        },
    },
    actions: {
        commitSetOrderCreators: async ({commit}, responseData) => {
            await commit('SET_ORDER_CREATORS', responseData.data);
        },

        commitSetPaginateLinks: async ({commit}, responseData) => {
            await commit('SET_PAGINATE_LINKS', responseData.meta);
        },

        commitSetOrderCreator: async ({commit}, responseData) => {
            await commit('SET_ORDER_CREATOR', responseData.data)
        },

        commitCreateOrderCreatorOnList: async ({commit}, responseData) => {
            await commit('CREATE_ORDER_CREATOR_ON_LIST', responseData.data);
        },

        commitCreateOrderCreator: async ({commit}, responseData) => {
            await commit('CREATE_ORDER_CREATOR', responseData.data);
        },

        commitUpdateOrderCreatorOnList: async ({commit}, responseData) => {
            await commit('UPDATE_ORDER_CREATOR_ON_LIST', responseData.data);
        },

        commitUpdateOrderCreator: async ({commit}, responseData) => {
            await commit('UPDATE_ORDER_CREATOR', responseData.data);
        },

        commitDeleteOrderCreatorOnList: async ({commit}, orderCreatorId) => {
            await commit('DELETE_ORDER_CREATOR_ON_LIST', orderCreatorId);
        },

        commitDeleteOrderCreator: async ({commit}) => {
            await commit('DELETE_ORDER_CREATOR');
        },

        commitResetOrderCreators: async ({commit}) => {
            await commit('RESET_ORDER_CREATORS');
        },

        commitResetOrderCreator: async ({commit}) => {
            await commit('RESET_ORDER_CREATOR');
        },

        // get list of orderCreator by api call.
        getOrderCreators: async ({dispatch}, params = {}) => {
            const path = `v1/order-creators`;

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetOrderCreators', response.data);
                dispatch('commitSetPaginateLinks', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // get single orderCreator by api call.
        getOrderCreator: async ({dispatch}, paramObj) => {
            const path = `v1/order-creators/${paramObj.id}`;
            const params = paramObj.params ?? {};

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetOrderCreator', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // create new orderCreator on list by api call.
        postOrderCreatorOnList: async ({dispatch}, data) => {
            const path = `v1/order-creators`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateOrderCreatorOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // create new orderCreator by api call.
        postOrderCreator: async ({dispatch}, data) => {
            const path = `v1/order-creators`;

            return axios.post(path, data).then((response) => {

                dispatch('commitCreateOrderCreator', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing orderCreator on list by api call.
        putOrderCreatorOnList: async ({dispatch}, dataObj) => {
            const path = `v1/order-creators/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateOrderCreatorOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing orderCreator by api call.
        putOrderCreator: async ({dispatch}, dataObj) => {
            const path = `v1/order-creators/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateOrderCreator', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular orderCreator on list by api call.
        deleteOrderCreatorOnList: async ({dispatch}, id) => {
            const path = `v1/order-creators/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteOrderCreatorOnList', id);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular orderCreator by api call.
        deleteOrderCreator: async ({dispatch}, id) => {
            const path = `v1/order-creators/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteOrderCreator', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // reset orderCreators state.
        resetOrderCreators: async ({dispatch}) => {
            dispatch('commitResetOrderCreators');
        },

        // reset orderCreator state.
        resetOrderCreator: async ({dispatch}) => {
            dispatch('commitResetOrderCreator')
        },

    },
};
