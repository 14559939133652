import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {
        expenses: [],
        paginateLinks: {},
        expense: {},
    },

    getters: {
        expenses: (state) => {
            return state.expenses;
        },

        paginateLinks: (state) => {
            return state.paginateLinks;
        },

        expense: (state) => {
            return state.expense;
        },
    },
    mutations: {
        SET_EXPENSES(state, expenses) {
            state.expenses = expenses;
        },

        SET_PAGINATE_LINKS(state, paginateLinks) {
            state.paginateLinks = paginateLinks;
        },

        SET_EXPENSE(state, expense) {
            state.expense = expense;
        },

        CREATE_EXPENSE_ON_LIST(state, expense) {
            state.expenses.unshift(expense);
        },

        CREATE_EXPENSE(state, expense) {
            state.expense = expense;
        },

        UPDATE_EXPENSE_ON_LIST(state, expense) {
            let index = state.expenses.findIndex(item => item.id === expense.id);
            state.expenses.splice(index, 1, expense);
        },

        UPDATE_EXPENSE(state, expense) {
            state.expense = expense;
        },

        DELETE_EXPENSE_ON_LIST(state, expenseId) {
            let index = state.expenses.findIndex(item => item.id === expenseId);
            state.expenses.splice(index, 1);
        },

        DELETE_EXPENSE(state) {
            state.expense = {};
        },

        RESET_EXPENSES(state) {
            state.expenses = [];
        },

        RESET_EXPENSE(state) {
            state.expense = {};
        },
    },
    actions: {
        commitSetExpenses: async ({commit}, responseData) => {
            await commit('SET_EXPENSES', responseData.data);
        },

        commitSetPaginateLinks: async ({commit}, responseData) => {
            await commit('SET_PAGINATE_LINKS', responseData.meta);
        },

        commitSetExpense: async ({commit}, responseData) => {
            await commit('SET_EXPENSE', responseData.data)
        },

        commitCreateExpenseOnList: async ({commit}, responseData) => {
            await commit('CREATE_EXPENSE_ON_LIST', responseData.data);
        },

        commitCreateExpense: async ({commit}, responseData) => {
            await commit('CREATE_EXPENSE', responseData.data);
        },

        commitUpdateExpenseOnList: async ({commit}, responseData) => {
            await commit('UPDATE_EXPENSE_ON_LIST', responseData.data);
        },

        commitUpdateExpense: async ({commit}, responseData) => {
            await commit('UPDATE_EXPENSE', responseData.data);
        },

        commitDeleteExpenseOnList: async ({commit}, expenseId) => {
            await commit('DELETE_EXPENSE_ON_LIST', expenseId);
        },

        commitDeleteExpense: async ({commit}) => {
            await commit('DELETE_EXPENSE');
        },

        commitResetExpenses: async ({commit}) => {
            await commit('RESET_EXPENSES');
        },

        commitResetExpense: async ({commit}) => {
            await commit('RESET_EXPENSE');
        },

        // get list of expense by api call.
        getExpenses: async ({dispatch}, params = {}) => {
            const path = `v1/expenses`;

            return  axios.get(path, {params: params}).then(response => {
                dispatch('commitSetExpenses', response.data);
                dispatch('commitSetPaginateLinks', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // get single expense by api call.
        getExpense: async ({dispatch}, paramObj) => {
            const path = `v1/expenses/${paramObj.id}`;
            const params = paramObj.params ?? {};

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetExpense', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // create new expense on list by api call.
        postExpenseOnList: async ({dispatch}, data) => {
            const path = `v1/expenses`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateExpenseOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // create new expense by api call.
        postExpense: async ({dispatch}, data) => {
            const path = `v1/expenses`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateExpense', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing expense on list by api call.
        putExpenseOnList: async ({dispatch}, dataObj) => {
            const path = `v1/expenses/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateExpenseOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing expense by api call.
        putExpense: async ({dispatch}, dataObj) => {
            const path = `v1/expenses/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateExpense', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular expense on list by api call.
        deleteExpenseOnList: async ({dispatch}, id) => {
            const path = `v1/expenses/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteExpenseOnList', id);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular expense by api call.
        deleteExpense: async ({dispatch}, id) => {
            const path = `v1/expenses/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteExpense', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // reset expenses state.
        resetExpenses: async ({dispatch}) => {
            dispatch('commitResetExpenses');
        },

        // reset expense state.
        resetExpense: async ({dispatch}) => {
            dispatch('commitResetExpense')
        },

    },
};
