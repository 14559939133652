import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {
        emailTemplates: [],
        paginateLinks: {},
        emailTemplate: {},
    },

    getters: {
        emailTemplates: (state) => {
            return state.emailTemplates;
        },

        paginateLinks: (state) => {
            return state.paginateLinks;
        },

        emailTemplate: (state) => {
            return state.emailTemplate;
        },
    },
    mutations: {
        SET_EMAIL_TEMPLATES(state, emailTemplates) {
            state.emailTemplates = emailTemplates;
        },

        SET_PAGINATE_LINKS(state, paginateLinks) {
            state.paginateLinks = paginateLinks;
        },

        SET_EMAIL_TEMPLATE(state, emailTemplate) {
            state.emailTemplate = emailTemplate;
        },

        CREATE_EMAIL_TEMPLATE_ON_LIST(state, emailTemplate) {
            state.emailTemplates.unshift(emailTemplate);
        },

        CREATE_EMAIL_TEMPLATE(state, emailTemplate) {
            state.emailTemplate = emailTemplate;
        },

        UPDATE_EMAIL_TEMPLATE_ON_LIST(state, emailTemplate) {
            let index = state.emailTemplates.findIndex(item => item.id === emailTemplate.id);
            state.emailTemplates.splice(index, 1, emailTemplate);
        },

        UPDATE_EMAIL_TEMPLATE(state, emailTemplate) {
            state.emailTemplate = emailTemplate;
        },

        DELETE_EMAIL_TEMPLATE_ON_LIST(state, roleId) {
            let index = state.emailTemplates.findIndex(item => item.id === roleId);
            state.emailTemplates.splice(index, 1);
        },

        DELETE_EMAIL_TEMPLATE(state) {
            state.emailTemplate = {};
        },

        RESET_EMAIL_TEMPLATES(state) {
            state.emailTemplates = [];
        },

        RESET_EMAIL_TEMPLATE(state) {
            state.emailTemplate = {};
        },
    },
    actions: {
        commitSetEmailTemplates: async ({commit}, responseData) => {
            await commit('SET_EMAIL_TEMPLATES', responseData.data);
        },

        commitSetPaginateLinks: async ({commit}, responseData) => {
            await commit('SET_PAGINATE_LINKS', responseData.meta);
        },

        commitSetEmailTemplate: async ({commit}, responseData) => {
            await commit('SET_EMAIL_TEMPLATE', responseData.data)
        },

        commitCreateEmailTemplateOnList: async ({commit}, responseData) => {
            await commit('CREATE_EMAIL_TEMPLATE_ON_LIST', responseData.data);
        },

        commitCreateEmailTemplate: async ({commit}, responseData) => {
            await commit('CREATE_EMAIL_TEMPLATE', responseData.data);
        },

        commitUpdateEmailTemplateOnList: async ({commit}, responseData) => {
            await commit('UPDATE_EMAIL_TEMPLATE_ON_LIST', responseData.data);
        },

        commitUpdateEmailTemplate: async ({commit}, responseData) => {
            await commit('UPDATE_EMAIL_TEMPLATE', responseData.data);
        },

        commitDeleteEmailTemplateOnList: async ({commit}, roleId) => {
            await commit('DELETE_EMAIL_TEMPLATE_ON_LIST', roleId);
        },

        commitDeleteEmailTemplate: async ({commit}) => {
            await commit('DELETE_EMAIL_TEMPLATE');
        },

        commitResetEmailTemplates: async ({commit}) => {
            await commit('RESET_EMAIL_TEMPLATES');
        },

        commitResetEmailTemplate: async ({commit}) => {
            await commit('RESET_EMAIL_TEMPLATE');
        },

        // get list of emailTemplate by api call.
        getEmailTemplates: async ({dispatch}, params = {}) => {
            const path = `v1/email-templates`;

            return  axios.get(path, {params: params}).then(response => {
                dispatch('commitSetEmailTemplates', response.data);
                dispatch('commitSetPaginateLinks', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // get single emailTemplate by api call.
        getEmailTemplate: async ({dispatch}, paramObj) => {
            const path = `v1/email-templates/${paramObj.id}`;
            const params = paramObj.params ?? {};

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetEmailTemplate', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // create new emailTemplate on list by api call.
        postEmailTemplateOnList: async ({dispatch}, data) => {
            const path = `v1/email-templates`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateEmailTemplateOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // create new emailTemplate by api call.
        postEmailTemplate: async ({dispatch}, data) => {
            const path = `v1/email-templates`;

            return axios.post(path, data).then((response) => {

                dispatch('commitCreateEmailTemplate', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing emailTemplate on list by api call.
        putEmailTemplateOnList: async ({dispatch}, dataObj) => {
            const path = `v1/email-templates/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateEmailTemplateOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing emailTemplate by api call.
        putEmailTemplate: async ({dispatch}, dataObj) => {
            const path = `v1/email-templates/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateEmailTemplate', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular emailTemplate on list by api call.
        deleteEmailTemplateOnList: async ({dispatch}, id) => {
            const path = `v1/email-templates/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteEmailTemplateOnList', id);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular emailTemplate by api call.
        deleteEmailTemplate: async ({dispatch}, id) => {
            const path = `v1/email-templates/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteEmailTemplate', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // reset emailTemplates state.
        resetEmailTemplates: async ({dispatch}) => {
            dispatch('commitResetEmailTemplates');
        },

        // reset emailTemplate state.
        resetEmailTemplate: async ({dispatch}) => {
            dispatch('commitResetEmailTemplate')
        },

    },
};
