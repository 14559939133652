import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {},

    getters: {},

    mutations: {},

    actions: {
        smsMarketingSendNotification: async (_, data) => {
            const path = `v1/sms-marketing/send-sms-notification`;
            return axios.post(path, data).then((response) => {
                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },
        sendSingleSms: async (_, data) => {
            const path = `v1/sms-gateways/send`;
            return axios.post(path, data).then((response) => {
                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },
        sendMultipleSms: async (_, data) => {
            const path = `v1/sms-gateways/send-multiple`;
            return axios.post(path, data).then((response) => {
                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        }
    },
};
