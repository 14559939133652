import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,
    state: {
        services: [],
        paginateLinks: {},
        service: {},
    },
    getters: {
        services: (state) => {
            return state.services;
        },
        paginateLinks: (state) => {
            return state.paginateLinks;
        },
        service: (state) => {
            return state.service;
        },
    },
    mutations: {
        SET_SERVICES(state, services) {
            state.services = services;
        },
        SET_PAGINATE_LINKS(state, paginateLinks) {
            state.paginateLinks = paginateLinks;
        },
        SET_SERVICE(state, service) {
            state.service = service;
        },
        CREATE_SERVICE(state, service) {
            state.service = service;
        },
        UPDATE_SERVICE(state, service) {
            state.service = service;
        },
        RESET_SERVICES(state) {
            state.services = [];
            state.paginateLinks = {};
        },
        DELETE_SERVICE(state) {
            state.service = {};
        },
        DELETE_SERVICE_ON_LIST(state, serviceId) {
            let index = state.services.findIndex(item => item.id === serviceId);
            state.services.splice(index, 1);
        }
    },
    actions: {
        commitSetServices: ({commit}, responseData) => {
            commit('SET_SERVICES', responseData.data)
        },
        commitSetPaginateLinks: async ({commit}, responseData) => {
            await commit('SET_PAGINATE_LINKS', responseData.meta)
        },
        commitSetService: async ({commit}, responseData) => {
            await commit('SET_SERVICE', responseData.data)
        },
        commitCreateService: async ({commit}, responseData) => {
            await commit('CREATE_SERVICE', responseData.data);
        },
        commitUpdateService: async ({commit}, responseData) => {
            await commit('UPDATE_SERVICE', responseData.data);
        },
        commitResetServices: async ({commit}) => {
            await commit('RESET_SERVICES');
        },
        commitDeleteService: async ({commit}) => {
            await commit('DELETE_SERVICE');
        },

        commitDeleteServiceOnList: async ({commit}, serviceId) => {
            await commit('DELETE_SERVICE_ON_LIST', serviceId);
        },

        // get list of service by api call
        getServices: ({dispatch}, params) => {
            const path = `v1/services`;
            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetServices', response.data);
                dispatch('commitSetPaginateLinks', response.data);
            }).catch(error => {
                return responseErrorHandler(error);
            })
        },
        // get a single service by api call
        getService: async ({dispatch}, paramObj) => {
            const path = `v1/services/${paramObj.id}`;
            const params = paramObj.params ?? {};
            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetService', response.data);
                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },
        // create new service  by api call.
        postService: async ({dispatch}, data) => {
            const path = `v1/services`;
            console.log(dispatch);
            return axios.post(path, data).then((response) => {
                dispatch('commitCreateService', response.data);
                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },
        // update single existing service  by api call.
        putService: async ({dispatch}, dataObj) => {
            const path = `v1/services/${dataObj.id}`;
            const data = dataObj.data;
            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateService', response.data);
                return {
                    message: 'Updated successfully',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular orderCreator by api call.
        deleteService: async ({dispatch}, id) => {
            const path = `v1/service/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteService', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular service on list by api call.
        deleteServiceOnList: async ({dispatch}, id) => {
            const path = `v1/services/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteServiceOnList', id);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },
        // update image avatar by api call.
        putImage: async (_, dataObj) => {

            const path = `v1/services/${dataObj.id}/image`;
            const data = dataObj.data ?? {};

            return axios.put(path, data).then((response) => {

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },
        // reset services state.
        resetServices: async ({dispatch}) => {
            dispatch('commitResetServices');
        },
    },
};
