import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {
        orderProcessors: [],
        paginateLinks: {},
        orderProcessor: {},
    },

    getters: {
        orderProcessors: (state) => {
            return state.orderProcessors;
        },

        paginateLinks: (state) => {
            return state.paginateLinks;
        },

        orderProcessor: (state) => {
            return state.orderProcessor;
        },
    },
    mutations: {
        SET_ORDER_PROCESSORS(state, orderProcessors) {
            state.orderProcessors = orderProcessors;
        },

        SET_PAGINATE_LINKS(state, paginateLinks) {
            state.paginateLinks = paginateLinks;
        },

        SET_ORDER_PROCESSOR(state, orderProcessor) {
            state.orderProcessor = orderProcessor;
        },

        CREATE_ORDER_PROCESSOR_ON_LIST(state, orderProcessor) {
            state.orderProcessors.unshift(orderProcessor);
        },

        CREATE_ORDER_PROCESSOR(state, orderProcessor) {
            state.orderProcessor = orderProcessor;
        },

        UPDATE_ORDER_PROCESSOR_ON_LIST(state, orderProcessor) {
            let index = state.orderProcessors.findIndex(item => item.id === orderProcessor.id);
            state.orderProcessors.splice(index, 1, orderProcessor);
        },

        UPDATE_ORDER_PROCESSOR(state, orderProcessor) {
            state.orderProcessor = orderProcessor;
        },

        DELETE_ORDER_PROCESSOR_ON_LIST(state, orderProcessorId) {
            let index = state.orderProcessors.findIndex(item => item.id === orderProcessorId);
            state.orderProcessors.splice(index, 1);
        },

        DELETE_ORDER_PROCESSOR(state) {
            state.orderProcessor = {};
        },

        RESET_ORDER_PROCESSORS(state) {
            state.orderProcessors = [];
        },

        RESET_ORDER_PROCESSOR(state) {
            state.orderProcessor = {};
        },
    },
    actions: {
        commitSetOrderProcessors: async ({commit}, responseData) => {
            await commit('SET_ORDER_PROCESSORS', responseData.data);
        },

        commitSetPaginateLinks: async ({commit}, responseData) => {
            await commit('SET_PAGINATE_LINKS', responseData.meta);
        },

        commitSetOrderProcessor: async ({commit}, responseData) => {
            await commit('SET_ORDER_PROCESSOR', responseData.data)
        },

        commitCreateOrderProcessorOnList: async ({commit}, responseData) => {
            await commit('CREATE_ORDER_PROCESSOR_ON_LIST', responseData.data);
        },

        commitCreateOrderProcessor: async ({commit}, responseData) => {
            await commit('CREATE_ORDER_PROCESSOR', responseData.data);
        },

        commitUpdateOrderProcessorOnList: async ({commit}, responseData) => {
            await commit('UPDATE_ORDER_PROCESSOR_ON_LIST', responseData.data);
        },

        commitUpdateOrderProcessor: async ({commit}, responseData) => {
            await commit('UPDATE_ORDER_PROCESSOR', responseData.data);
        },

        commitDeleteOrderProcessorOnList: async ({commit}, orderProcessorId) => {
            await commit('DELETE_ORDER_PROCESSOR_ON_LIST', orderProcessorId);
        },

        commitDeleteOrderProcessor: async ({commit}) => {
            await commit('DELETE_ORDER_PROCESSOR');
        },

        commitResetOrderProcessors: async ({commit}) => {
            await commit('RESET_ORDER_PROCESSORS');
        },

        commitResetOrderProcessor: async ({commit}) => {
            await commit('RESET_ORDER_PROCESSOR');
        },

        // get list of orderProcessor by api call.
        getOrderProcessors: async ({dispatch}, params = {}) => {
            const path = `v1/order-processors`;

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetOrderProcessors', response.data);
                dispatch('commitSetPaginateLinks', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // get single orderProcessor by api call.
        getOrderProcessor: async ({dispatch}, paramObj) => {
            const path = `v1/order-processors/${paramObj.id}`;
            const params = paramObj.params ?? {};

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetOrderProcessor', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // create new orderProcessor on list by api call.
        postOrderProcessorOnList: async ({dispatch}, data) => {
            const path = `v1/order-processors`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateOrderProcessorOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // create new orderProcessor by api call.
        postOrderProcessor: async ({dispatch}, data) => {
            const path = `v1/order-processors`;

            return axios.post(path, data).then((response) => {

                dispatch('commitCreateOrderProcessor', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing orderProcessor on list by api call.
        putOrderProcessorOnList: async ({dispatch}, dataObj) => {
            const path = `v1/order-processors/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateOrderProcessorOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing orderProcessor by api call.
        putOrderProcessor: async ({dispatch}, dataObj) => {
            const path = `v1/order-processors/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateOrderProcessor', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular orderProcessor on list by api call.
        deleteOrderProcessorOnList: async ({dispatch}, id) => {
            const path = `v1/order-processors/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteOrderProcessorOnList', id);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular orderProcessor by api call.
        deleteOrderProcessor: async ({dispatch}, id) => {
            const path = `v1/order-creators/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteOrderProcessor', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // reset orderProcessors state.
        resetOrderProcessors: async ({dispatch}) => {
            dispatch('commitResetOrderProcessors');
        },

        // reset orderProcessor state.
        resetOrderProcessor: async ({dispatch}) => {
            dispatch('commitResetOrderProcessor')
        },

    },
};
