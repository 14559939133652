import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {
        orders: [],
        paginateLinks: {},
        order: {},
        editOrderTemporalInformation:{
            orderCheckoutIsActive:false,
            data:{}
        },


    },

    getters: {
        orders: (state) => {
         
            return state.orders;
        },

        paginateLinks: (state) => {
            return state.paginateLinks;
        },

        order: (state) => {
            return state.order;
        },
        editOrderTemporalInformation: (state) => {
            return state.editOrderTemporalInformation;
        },

    },
    mutations: {
        SET_ORDERS(state, orders) {
            state.orders = orders;
        },


        SET_PAGINATE_LINKS(state, paginateLinks) {
            state.paginateLinks = paginateLinks;
        },

        SET_ORDER(state, order) {
            state.order = order;
        },
        SET_EDIT_ORDER_TEMPORAL_INFORMATION(state, editOrderTemporalInformation) {
            state.editOrderTemporalInformation = editOrderTemporalInformation;
        },

        CREATE_ORDER_ON_LIST(state, order) {
            state.orders.unshift(order);
        },

        CREATE_ORDER(state, order) {
            state.order = order;
        },

        UPDATE_ORDER_ON_LIST(state, order) {
            let index = state.orders.findIndex(item => item.id === order.id);
            state.orders.splice(index, 1, order);
        },

        UPDATE_ORDER(state, order) {
            state.order = order;
        },

        DELETE_ORDER_ON_LIST(state, orderId) {
            let index = state.orders.findIndex(item => item.id === orderId);
            state.orders.splice(index, 1);
        },

        DELETE_ORDER(state) {
            state.order = {};
        },

        RESET_ORDERS(state) {
            state.orders = [];
        },

        RESET_ORDER(state) {
            state.order = {};
        },
    },
    actions: {
        commitSetOrders: async ({commit}, responseData) => {
            await commit('SET_ORDERS', responseData.data);
        },

        commitSetPaginateLinks: async ({commit}, responseData) => {
            await commit('SET_PAGINATE_LINKS', responseData.meta);
        },

        commitSetOrder: async ({commit}, responseData) => {
            await commit('SET_ORDER', responseData.data)
        },
        // commitSetEditOrderTemporalInformation: async ({commit}, responseData) => {
        //     await commit('SET_EDIT_ORDER_TEMPORAL_INFORMATION', responseData.data)
        // },

        commitCreateOrderOnList: async ({commit}, responseData) => {
            await commit('CREATE_ORDER_ON_LIST', responseData.data);
        },

        commitCreateOrder: async ({commit}, responseData) => {
            await commit('CREATE_ORDER', responseData.data);
        },

        commitUpdateOrderOnList: async ({commit}, responseData) => {
            await commit('UPDATE_ORDER_ON_LIST', responseData.data);
        },

        commitUpdateOrder: async ({commit}, responseData) => {
            await commit('UPDATE_ORDER', responseData.data);
        },

        commitDeleteOrderOnList: async ({commit}, orderId) => {
            await commit('DELETE_ORDER_ON_LIST', orderId);
        },

        commitDeleteOrder: async ({commit}) => {
            await commit('DELETE_ORDER');
        },

        commitResetOrders: async ({commit}) => {
            await commit('RESET_ORDERS');
        },

        commitResetOrder: async ({commit}) => {
            await commit('RESET_ORDER');
        },

        // get list of order by api call.
        getOrders: async ({dispatch}, params = {}) => {
            const path = `v1/orders`;

            return axios.get(path, {params: params}).then(response => {

                dispatch('commitSetOrders', response.data);
                dispatch('commitSetPaginateLinks', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },


        // get single order by api call.
        getOrder: async ({dispatch}, paramObj) => {
            const path = `v1/orders/${paramObj.id}`;
            const params = paramObj.params ?? {};

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetOrder', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // create new order on list by api call.
        postOrderOnList: async ({dispatch}, data) => {
            const path = `v1/orders`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateOrderOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // create new order by api call.
        postOrder: async ({dispatch}, data) => {
            const path = `v1/orders`;

            return axios.post(path, data).then((response) => {

                dispatch('commitCreateOrder', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing order on list by api call.
        putOrderOnList: async ({dispatch}, dataObj) => {
            const path = `v1/orders/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateOrderOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing order by api call.
        putOrder: async ({dispatch}, dataObj) => {
            const path = `v1/orders/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateOrder', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update status existing order by api call.

        putOrderStatus: async (_, dataObj) => {
            const path = `v1/orders/${dataObj.id}/status`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                return response;
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular order on list by api call.
        deleteOrderOnList: async ({dispatch}, id) => {
            const path = `v1/orders/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteOrderOnList', id);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular order by api call.
        deleteOrder: async ({dispatch}, id) => {
            const path = `v1/orders/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteOrder', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // reset orders state.
        resetOrders: async ({dispatch}) => {
            dispatch('commitResetOrders');
        },

        // reset order state.
        resetOrder: async ({dispatch}) => {
            dispatch('commitResetOrder')
        },

        preStoreOrderData: async (_, data) => {
            const path = `v1/orders/pre-store-order-data`;

            return axios.post(path, data).then((response) => {
                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                    data: response.data.data
                };
            }).catch((error) => {
                return responseErrorHandler(error);

            });
        },

        // post order invoice
        postOrderInvoice: async ({dispatch}, paramObj) => {
            const path = `v1/orders/invoice/${paramObj.uuid}`;
            const params = paramObj.params ?? {};
            return axios.post(path, params).then((response) => {
                dispatch('commitSetOrder', response.data);
                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                    data: response.data.data
                };
            }).catch((error) => {
                return responseErrorHandler(error);

            });
        },

        // order creation notify email
        postOrderCreationNotifyEmail: async (_, paramObj) => {
            const path = `v1/orders/send-creation-notify/${paramObj.id}`;
            const params = paramObj.params ?? {};
            return axios.post(path, params).then((response) => {
                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);

            });
        },

        // order status update notify email
        postOrderStatusUpdateNotifyEmail: async (_, paramObj) => {
            const path = `v1/orders/send-status-update-notify/${paramObj.id}`;
            const params = paramObj.params ?? {};
            return axios.post(path, params).then((response) => {
                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);

            });
        },

        // send payment request to customer notify email
        postOrderPaymentRequestNotifyEmail: async (_, paramObj) => {
            const path = `v1/orders/send-payment-request-customer-notify/${paramObj.id}`;
            const data = paramObj.data ?? {};
            return axios.post(path, data).then((response) => {
                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                    data: response.data.data
                };
            }).catch((error) => {
                return responseErrorHandler(error);

            });
        },

        // add product item to order by api call.
        putOrderProductItemAdd: async ({dispatch}, dataObj) => {
            const path = `v1/orders/${dataObj.id}/product-item/add`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateOrder', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // remove product item to order by api call.
        putOrderProductItemRemove: async ({dispatch}, dataObj) => {
            const path = `v1/orders/${dataObj.id}/product-item/remove`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateOrder', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update delivery charge to order by api call.
        putOrderDeliveryCharge: async ({dispatch}, dataObj) => {
            const path = `v1/orders/${dataObj.id}/order-charges/delivery-charge`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateOrder', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },


        // order discount add
        postOrderDiscountAdd: async ({dispatch}, paramObj) => {
            const path = `v1/orders/${paramObj.id}/order-charges/discount`;
            const data = paramObj.data ?? {};
            return axios.post(path, data).then((response) => {
                dispatch('commitUpdateOrder', response.data);
                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);

            });
        },
    },
};
