import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {
        eftPayments: [],
        paginateLinks: {},
        eftPayment: {},


    },

    getters: {
        eftPayments: (state) => {
            return state.eftPayments;
        },

        paginateLinks: (state) => {
            return state.paginateLinks;
        },

        eftPayment: (state) => {
            return state.eftPayment;
        },

    },
    mutations: {
        SET_EFT_PAYMENTS(state, eftPayments) {
            state.eftPayments = eftPayments;
        },


        SET_PAGINATE_LINKS(state, paginateLinks) {
            state.paginateLinks = paginateLinks;
        },

        SET_EFT_PAYMENT(state, eftPayment) {
            state.eftPayment = eftPayment;
        },

        CREATE_EFT_PAYMENT_ON_LIST(state, eftPayment) {
            state.eftPayments.unshift(eftPayment);
        },

        CREATE_EFT_PAYMENT(state, eftPayment) {
            state.eftPayment = eftPayment;
        },

        UPDATE_EFT_PAYMENT_ON_LIST(state, eftPayment) {
            let index = state.eftPayments.findIndex(item => item.id === eftPayment.id);
            state.eftPayments.splice(index, 1, eftPayment);
        },

        UPDATE_EFT_PAYMENT(state, eftPayment) {
            state.eftPayment = eftPayment;
        },

        DELETE_EFT_PAYMENT_ON_LIST(state, eftPaymentId) {
            let index = state.eftPayments.findIndex(item => item.id === eftPaymentId);
            state.eftPayments.splice(index, 1);
        },

        DELETE_EFT_PAYMENT(state) {
            state.eftPayment = {};
        },

        RESET_EFT_PAYMENTS(state) {
            state.eftPayments = [];
        },

        RESET_EFT_PAYMENT(state) {
            state.eftPayment = {};
        },
    },
    actions: {
        commitSetEftPayments: async ({commit}, responseData) => {
            await commit('SET_EFT_PAYMENTS', responseData.data);
        },

        commitSetPaginateLinks: async ({commit}, responseData) => {
            await commit('SET_PAGINATE_LINKS', responseData.meta);
        },

        commitSetEftPayment: async ({commit}, responseData) => {
            await commit('SET_EFT_PAYMENT', responseData.data)
        },

        commitCreateEftPaymentOnList: async ({commit}, responseData) => {
            await commit('CREATE_EFT_PAYMENT_ON_LIST', responseData.data);
        },

        commitCreateEftPayment: async ({commit}, responseData) => {
            await commit('CREATE_EFT_PAYMENT', responseData.data);
        },

        commitUpdateEftPaymentOnList: async ({commit}, responseData) => {
            await commit('UPDATE_EFT_PAYMENT_ON_LIST', responseData.data);
        },

        commitUpdateEftPayment: async ({commit}, responseData) => {
            await commit('UPDATE_EFT_PAYMENT', responseData.data);
        },

        commitDeleteEftPaymentOnList: async ({commit}, eftPaymentId) => {
            await commit('DELETE_EFT_PAYMENT_ON_LIST', eftPaymentId);
        },

        commitDeleteEftPayment: async ({commit}) => {
            await commit('DELETE_EFT_PAYMENT');
        },

        commitResetEftPayments: async ({commit}) => {
            await commit('RESET_EFT_PAYMENTS');
        },

        commitResetEftPayment: async ({commit}) => {
            await commit('RESET_EFT_PAYMENT');
        },

        // get list of eftPayment by api call.
        getEftPayments: async ({dispatch}, params = {}) => {
            const path = `v1/eft-payments`;

            return axios.get(path, {params: params}).then(response => {

                dispatch('commitSetEftPayments', response.data);
                dispatch('commitSetPaginateLinks', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },


        // get single eftPayment by api call.
        getEftPayment: async ({dispatch}, paramObj) => {
            const path = `v1/eft-payments/${paramObj.id}`;
            const params = paramObj.params ?? {};

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetEftPayment', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // create new eftPayment on list by api call.
        postEftPaymentOnList: async ({dispatch}, data) => {
            const path = `v1/eft-payments`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateEftPaymentOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // create new eftPayment by api call.
        postEftPayment: async ({dispatch}, data) => {
            const path = `v1/eft-payments`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateEftPayment', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing eftPayment on list by api call.
        putEftPaymentOnList: async ({dispatch}, dataObj) => {
            const path = `v1/eft-payments/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateEftPaymentOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing eftPayment by api call.
        putEftPayment: async ({dispatch}, dataObj) => {
            const path = `v1/eft-payments/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateEftPayment', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular eftPayment on list by api call.
        deleteEftPaymentOnList: async ({dispatch}, id) => {
            const path = `v1/eft-payments/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteEftPaymentOnList', id);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular eftPayment by api call.
        deleteEftPayment: async ({dispatch}, id) => {
            const path = `v1/eft-payments/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteEftPayment', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // reset eftPayments state.
        resetEftPayments: async ({dispatch}) => {
            dispatch('commitResetEftPayments');
        },

        // reset eftPayment state.
        resetEftPayment: async ({dispatch}) => {
            dispatch('commitResetEftPayment')
        },

    },
};
