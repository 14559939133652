import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {
        afterpayPayments: [],
        paginateLinks: {},
        afterpayPayment: {},


    },

    getters: {
        afterpayPayments: (state) => {
            return state.afterpayPayments;
        },

        paginateLinks: (state) => {
            return state.paginateLinks;
        },

        afterpayPayment: (state) => {
            return state.afterpayPayment;
        },

    },
    mutations: {
        SET_AFTERPAY_PAYMENTS(state, afterpayPayments) {
            state.afterpayPayments = afterpayPayments;
        },


        SET_PAGINATE_LINKS(state, paginateLinks) {
            state.paginateLinks = paginateLinks;
        },

        SET_AFTERPAY_PAYMENT(state, afterpayPayment) {
            state.afterpayPayment = afterpayPayment;
        },

        CREATE_AFTERPAY_PAYMENT_ON_LIST(state, afterpayPayment) {
            state.afterpayPayments.unshift(afterpayPayment);
        },

        CREATE_AFTERPAY_PAYMENT(state, afterpayPayment) {
            state.afterpayPayment = afterpayPayment;
        },

        UPDATE_AFTERPAY_PAYMENT_ON_LIST(state, afterpayPayment) {
            let index = state.afterpayPayments.findIndex(item => item.id === afterpayPayment.id);
            state.afterpayPayments.splice(index, 1, afterpayPayment);
        },

        UPDATE_AFTERPAY_PAYMENT(state, afterpayPayment) {
            state.afterpayPayment = afterpayPayment;
        },

        DELETE_AFTERPAY_PAYMENT_ON_LIST(state, afterpayPaymentId) {
            let index = state.afterpayPayments.findIndex(item => item.id === afterpayPaymentId);
            state.afterpayPayments.splice(index, 1);
        },

        DELETE_AFTERPAY_PAYMENT(state) {
            state.afterpayPayment = {};
        },

        RESET_AFTERPAY_PAYMENTS(state) {
            state.afterpayPayments = [];
        },

        RESET_AFTERPAY_PAYMENT(state) {
            state.afterpayPayment = {};
        },
    },
    actions: {
        commitSetAfterpayPayments: async ({commit}, responseData) => {
            await commit('SET_AFTERPAY_PAYMENTS', responseData.data);
        },

        commitSetPaginateLinks: async ({commit}, responseData) => {
            await commit('SET_PAGINATE_LINKS', responseData.meta);
        },

        commitSetAfterpayPayment: async ({commit}, responseData) => {
            await commit('SET_AFTERPAY_PAYMENT', responseData.data)
        },

        commitCreateAfterpayPaymentOnList: async ({commit}, responseData) => {
            await commit('CREATE_AFTERPAY_PAYMENT_ON_LIST', responseData.data);
        },

        commitCreateAfterpayPayment: async ({commit}, responseData) => {
            await commit('CREATE_AFTERPAY_PAYMENT', responseData.data);
        },

        commitUpdateAfterpayPaymentOnList: async ({commit}, responseData) => {
            await commit('UPDATE_AFTERPAY_PAYMENT_ON_LIST', responseData.data);
        },

        commitUpdateAfterpayPayment: async ({commit}, responseData) => {
            await commit('UPDATE_AFTERPAY_PAYMENT', responseData.data);
        },

        commitDeleteAfterpayPaymentOnList: async ({commit}, afterpayPaymentId) => {
            await commit('DELETE_AFTERPAY_PAYMENT_ON_LIST', afterpayPaymentId);
        },

        commitDeleteAfterpayPayment: async ({commit}) => {
            await commit('DELETE_AFTERPAY_PAYMENT');
        },

        commitResetAfterpayPayments: async ({commit}) => {
            await commit('RESET_AFTERPAY_PAYMENTS');
        },

        commitResetAfterpayPayment: async ({commit}) => {
            await commit('RESET_AFTERPAY_PAYMENT');
        },

        // get list of card payment by api call.
        getAfterpayPayments: async ({dispatch}, params = {}) => {
            const path = `v1/afterpay-payments`;

            return axios.get(path, {params: params}).then(response => {

                dispatch('commitSetAfterpayPayments', response.data);
                dispatch('commitSetPaginateLinks', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },


        // get single card payment by api call.
        getAfterpayPayment: async ({dispatch}, paramObj) => {
            const path = `v1/afterpay-payments/${paramObj.id}`;
            const params = paramObj.params ?? {};

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetAfterpayPayment', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // create new card payments on list by api call.
        postAfterpayPaymentOnList: async ({dispatch}, data) => {
            const path = `v1/afterpay-payments`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateAfterpayPaymentOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // create new card payments by api call.
        postAfterpayPayment: async ({dispatch}, data) => {
            const path = `v1/afterpay-payments`;

            return axios.post(path, data).then((response) => {

                dispatch('commitCreateAfterpayPayment', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing card payments on list by api call.
        putAfterpayPaymentOnList: async ({dispatch}, dataObj) => {
            const path = `v1/afterpay-payments/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateAfterpayPaymentOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing card payments by api call.
        putAfterpayPayment: async ({dispatch}, dataObj) => {
            const path = `v1/afterpay-payments/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateAfterpayPayment', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular card payments on list by api call.
        deleteAfterpayPaymentOnList: async ({dispatch}, id) => {
            const path = `v1/afterpay-payments/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteAfterpayPaymentOnList', id);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular card payments by api call.
        deleteAfterpayPayment: async ({dispatch}, id) => {
            const path = `v1/afterpay-payments/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteAfterpayPayment', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // reset card payments state.
        resetAfterpayPayments: async ({dispatch}) => {
            dispatch('commitResetAfterpayPayments');
        },

        // reset card payment state.
        resetAfterpayPayment: async ({dispatch}) => {
            dispatch('commitResetAfterpayPayment')
        },

    },
};
