import axios from "axios";
import responseErrorHandler from  "@/store/helper/responseErrorHandler";

export default {
    namespaced:true,

    state: {
        blogCategories: [],
        paginateLinks: {},
        blogCategory: {},
    },

    getters: {
        blogCategories: (state) => {
            return state.blogCategories;
        },

        paginateLinks: (state) => {
            return state.paginateLinks;
        },

        blogCategory: (state) => {
            return state.blogCategory;
        },
    },

    mutations: {
        SET_BLOG_CATEGORIES(state, blogCategories) {
            state.blogCategories = blogCategories;
        },

        SET_PAGINATE_LINKS(state, paginateLinks) {
            state.paginateLinks = paginateLinks;
        },

        SET_BLOG_CATEGORY(state, blogCategory) {
            state.blogCategory = blogCategory;
        },

        CREATE_BLOG_CATEGORY_ON_LIST(state, blogCategory) {
            state.blogCategories.unshift(blogCategory);
        },

        CREATE_BLOG_CATEGORY(state, blogCategory) {
            state.blogCategory = blogCategory;
        },

        UPDATE_BLOG_CATEGORY_ON_LIST(state, blogCategory) {
            let index = state.blogCategories.findIndex(item => item.id === blogCategory.id);
            state.blogCategories.splice(index, 1, blogCategory);
        },

        UPDATE_BLOG_CATEGORY(state, blogCategory) {
            state.blogCategory = blogCategory;
        },

        DELETE_BLOG_CATEGORY_ON_LIST(state, blogCategoryId) {
            let index = state.blogCategories.findIndex(item => item.id === blogCategoryId);
            state.blogCategories.splice(index, 1);
        },

        DELETE_BLOG_CATEGORY(state) {
            state.blogCategory = {};
        },

        RESET_BLOG_CATEGORIES(state) {
            state.blogCategories = [];
        },

        RESET_BLOG_CATEGORY(state) {
            state.blogCategory = {};
        },
    },

    actions: {
        commitSetBlogCategories: async ({commit}, responseData) => {
            await commit('SET_BLOG_CATEGORIES', responseData.data);
        },

        commitSetPaginateLinks: async ({commit}, responseData) => {
            await commit('SET_PAGINATE_LINKS', responseData.meta);
        },

        commitSetBlogCategory: async ({commit}, responseData) => {
            await commit('SET_BLOG_CATEGORY', responseData.data)
        },

        commitCreateBlogCategoryOnList: async ({commit}, responseData) => {
            await commit('CREATE_BLOG_CATEGORY_ON_LIST', responseData.data);
        },

        commitCreateBlogCategory: async ({commit}, responseData) => {
            await commit('CREATE_BLOG_CATEGORY', responseData.data);
        },

        commitUpdateBlogCategoryOnList: async ({commit}, responseData) => {
            await commit('UPDATE_BLOG_CATEGORY_ON_LIST', responseData.data);
        },

        commitUpdateBlogCategory: async ({commit}, responseData) => {
            await commit('UPDATE_BLOG_CATEGORY', responseData.data);
        },

        commitDeleteBlogCategoryOnList: async ({commit}, BlogCategoryId) => {
            await commit('DELETE_BLOG_CATEGORY_ON_LIST', BlogCategoryId);
        },

        commitDeleteBlogCategory: async ({commit}) => {
            await commit('DELETE_BLOG_CATEGORY');
        },

        commitResetBlogCategories: async ({commit}) => {
            await commit('RESET_BLOG_CATEGORIES');
        },

        commitResetBlogCategory: async ({commit}) => {
            await commit('RESET_BLOG_CATEGORY');
        },

        // get list of blog categories by api call.
        getBlogCategories: async ({dispatch}, params = {}) => {
            const path = `v1/blog-categories`;

            return  axios.get(path, {params: params}).then(response => {
                dispatch('commitSetBlogCategories', response.data);
                dispatch('commitSetPaginateLinks', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },
        // get single blog category by api call.
        getBlogCategory: async ({dispatch}, paramObj) => {
            const path = `v1/blog-categories/${paramObj.id}`;
            const params = paramObj.params ?? {};

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetBlogCategory', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },
        // create new blog category on list by api call.
        postBlogCategoryOnList: async ({dispatch}, data) => {
            const path = `v1/blog-categories`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateBlogCategoryOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // create new blog category by api call.
        postBlogCategory: async ({dispatch}, data) => {
            const path = `v1/blog-categories`;

            return axios.post(path, data).then((response) => {

                dispatch('commitCreateBlogCategory', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing blog category on list by api call.
        putBlogCategoryOnList: async ({dispatch}, dataObj) => {
            const path = `v1/blog-categories/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateBlogCategoryOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing blog category by api call.
        putBlogCategory: async ({dispatch}, dataObj) => {
            const path = `v1/blog-categories/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateBlogCategory', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular blog category on list by api call.
        deleteBlogCategoryOnList: async ({dispatch}, id) => {
            const path = `v1/blog-categories/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteBlogCategoryOnList', id);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular blog category by api call.
        deleteBlogCategory: async ({dispatch}, id) => {
            const path = `v1/blog-categories/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteBlogCategory', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // reset blog categories state.
        resetBlogCategories: async ({dispatch}) => {
            dispatch('commitResetBlogCategories');
        },

        // reset blog category state.
        resetBlogCategory: async ({dispatch}) => {
            dispatch('commitResetBlogCategory')
        },
    },


};