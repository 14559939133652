import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {

        appointmentNotes: [],
        appointmentNote: [],

    },

    getters: {
        appointmentNotes: (state) =>
        {
            return state.appointmentNotes;
        },
        appointmentNote: (state) =>
        {
            return state.appointmentNote;
        },

    },
    mutations: {
        SET_APPOINTMENT_NOTES (state, appointmentNotes)
        {
            state.appointmentNotes = appointmentNotes;
        },
        SET_APPOINTMENT_NOTE (state, appointmentNote)
        {
            state.appointmentNote = appointmentNote;
        },

    },
    actions: {

        commitSetAppointmentNotes: async ({ commit }, responseData) =>
        {
            await commit('SET_APPOINTMENT_NOTES', responseData.data);
        },
        commitSetAppointmentNote: async ({ commit }, responseData) =>
        {
            await commit('SET_APPOINTMENT_NOTE', responseData.data);
        },


        // get appointment notes by api call.
        getAppointmentNotes: async ({ dispatch }, paramObj) =>
        {
            const path = `v1/appointment-notes/`;
            const params = paramObj.params ?? {};

            return axios.get(path, { params: params }).then(response =>
            {
                dispatch('commitSetAppointmentNotes', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error =>
            {
                return responseErrorHandler(error);
            });
        },


        postAppointmentNotes: async ({ dispatch }, data) =>
        {
            const path = `v1/appointment-notes`;
            console.log(dispatch);

            return axios.post(path, data).then((response) =>
            {
                dispatch('commitSetAppointmentNote', response.data);
                return {
                    message: 'Appointment note created',
                    type: 'success',
                    status: response.status,
                    data: response.data.data
                };
            }).catch((error) =>
            {
                return responseErrorHandler(error);
            });
        },

        
        // appointment note creation notify email
        postAppointmentNoteCreationNotifyEmail: async (_, paramObj) => {
            const path = `v1/appointment-notes/send-creation-notify/${paramObj.id}`;
            const params = paramObj.params ?? {};
            return axios.post(path, params).then((response) => {
                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                    data: response.data.data
                };
            }).catch((error) => {
                return responseErrorHandler(error);

            });
         },

      


    },
};
