import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {
        expenseTypes: [],
        paginateLinks: {},
        expenseType: {},
    },

    getters: {
        expenseTypes: (state) => {
            return state.expenseTypes;
        },

        paginateLinks: (state) => {
            return state.paginateLinks;
        },

        expenseType: (state) => {
            return state.expenseType;
        },
    },
    mutations: {
        SET_EXPENSE_TYPES(state, expenseTypes) {
            state.expenseTypes = expenseTypes;
        },

        SET_PAGINATE_LINKS(state, paginateLinks) {
            state.paginateLinks = paginateLinks;
        },

        SET_EXPENSE_TYPE(state, expenseType) {
            state.expenseType = expenseType;
        },

        CREATE_EXPENSE_TYPE_ON_LIST(state, expenseType) {
            state.expenseTypes.unshift(expenseType);
        },

        CREATE_EXPENSE_TYPE(state, expenseType) {
            state.expenseType = expenseType;
        },

        UPDATE_EXPENSE_TYPE_ON_LIST(state, expenseType) {
            let index = state.expenseTypes.findIndex(item => item.id === expenseType.id);
            state.expenseTypes.splice(index, 1, expenseType);
        },

        UPDATE_EXPENSE_TYPE(state, expenseType) {
            state.expenseType = expenseType;
        },

        DELETE_EXPENSE_TYPE_ON_LIST(state, expenseTypeId) {
            let index = state.expenseTypes.findIndex(item => item.id === expenseTypeId);
            state.expenseTypes.splice(index, 1);
        },

        DELETE_EXPENSE_TYPE(state) {
            state.expenseType = {};
        },

        RESET_EXPENSE_TYPES(state) {
            state.expenseTypes = [];
        },

        RESET_EXPENSE_TYPE(state) {
            state.expenseType = {};
        },
    },
    actions: {
        commitSetExpenseTypes: async ({commit}, responseData) => {
            await commit('SET_EXPENSE_TYPES', responseData.data);
        },

        commitSetPaginateLinks: async ({commit}, responseData) => {
            await commit('SET_PAGINATE_LINKS', responseData.meta);
        },

        commitSetExpenseType: async ({commit}, responseData) => {
            await commit('SET_EXPENSE_TYPE', responseData.data)
        },

        commitCreateExpenseTypeOnList: async ({commit}, responseData) => {
            await commit('CREATE_EXPENSE_TYPE_ON_LIST', responseData.data);
        },

        commitCreateExpenseType: async ({commit}, responseData) => {
            await commit('CREATE_EXPENSE_TYPE', responseData.data);
        },

        commitUpdateExpenseTypeOnList: async ({commit}, responseData) => {
            await commit('UPDATE_EXPENSE_TYPE_ON_LIST', responseData.data);
        },

        commitUpdateExpenseType: async ({commit}, responseData) => {
            await commit('UPDATE_EXPENSE_TYPE', responseData.data);
        },

        commitDeleteExpenseTypeOnList: async ({commit}, expenseTypeId) => {
            await commit('DELETE_EXPENSE_TYPE_ON_LIST', expenseTypeId);
        },

        commitDeleteExpenseType: async ({commit}) => {
            await commit('DELETE_EXPENSE_TYPE');
        },

        commitResetExpenseTypes: async ({commit}) => {
            await commit('RESET_EXPENSE_TYPES');
        },

        commitResetExpenseType: async ({commit}) => {
            await commit('RESET_EXPENSE_TYPE');
        },

        // get list of expense type by api call.
        getExpenseTypes: async ({dispatch}, params = {}) => {
            const path = `v1/expense-types`;

            return  axios.get(path, {params: params}).then(response => {
                dispatch('commitSetExpenseTypes', response.data);
                dispatch('commitSetPaginateLinks', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // get single expense type by api call.
        getExpenseType: async ({dispatch}, paramObj) => {
            const path = `v1/expense-types/${paramObj.id}`;
            const params = paramObj.params ?? {};

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetExpenseType', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // create new expense type on list by api call.
        postExpenseTypeOnList: async ({dispatch}, data) => {
            const path = `v1/expense-types`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateExpenseTypeOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // create new expense type by api call.
        postExpenseType: async ({dispatch}, data) => {
            const path = `v1/expense-types`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateExpenseType', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing expense type on list by api call.
        putExpenseTypeOnList: async ({dispatch}, dataObj) => {
            const path = `v1/expense-types/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateExpenseTypeOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing expense type by api call.
        putExpenseType: async ({dispatch}, dataObj) => {
            const path = `v1/expense-types/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateExpenseType', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular expense type on list by api call.
        deleteExpenseTypeOnList: async ({dispatch}, id) => {
            const path = `v1/expense-types/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteExpenseTypeOnList', id);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular expense type by api call.
        deleteExpenseType: async ({dispatch}, id) => {
            const path = `v1/expense-types/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteExpenseType', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // reset expense types state.
        resetExpenseTypes: async ({dispatch}) => {
            dispatch('commitResetExpenseTypes');
        },

        // reset expense type state.
        resetExpenseType: async ({dispatch}) => {
            dispatch('commitResetExpenseType')
        },

    },
};
