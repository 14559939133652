import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {
        productItemTrackings: [],
        paginateLinks: {},
        productItemTracking: {},


    },

    getters: {
        productItemTrackings: (state) => {
            return state.productItemTrackings;
        },

        paginateLinks: (state) => {
            return state.paginateLinks;
        },

        productItemTracking: (state) => {
            return state.productItemTracking;
        },

    },
    mutations: {
        SET_PRODUCT_ITEM_TRACKINGS(state, productItemTrackings) {
            state.productItemTrackings = productItemTrackings;
        },


        SET_PAGINATE_LINKS(state, paginateLinks) {
            state.paginateLinks = paginateLinks;
        },

        SET_PRODUCT_ITEM_TRACKING(state, productItemTracking) {
            state.productItemTracking = productItemTracking;
        },

        CREATE_PRODUCT_ITEM_TRACKING_ON_LIST(state, productItemTracking) {
            state.productItemTrackings.unshift(productItemTracking);
        },

        CREATE_PRODUCT_ITEM_TRACKING(state, productItemTracking) {
            state.productItemTracking = productItemTracking;
        },

        UPDATE_PRODUCT_ITEM_TRACKING_ON_LIST(state, productItemTracking) {
            let index = state.productItemTrackings.findIndex(item => item.id === productItemTracking.id);
            state.productItemTrackings.splice(index, 1, productItemTracking);
        },

        UPDATE_PRODUCT_ITEM_TRACKING(state, productItemTracking) {
            state.productItemTracking = productItemTracking;
        },

        DELETE_PRODUCT_ITEM_TRACKING_ON_LIST(state, productItemTrackingId) {
            let index = state.productItemTrackings.findIndex(item => item.id === productItemTrackingId);
            state.productItemTrackings.splice(index, 1);
        },

        DELETE_PRODUCT_ITEM_TRACKING(state) {
            state.productItemTracking = {};
        },

        RESET_PRODUCT_ITEM_TRACKINGS(state) {
            state.productItemTrackings = [];
        },

        RESET_PRODUCT_ITEM_TRACKING(state) {
            state.productItemTracking = {};
        },
    },
    actions: {
        commitSetProductItemTrackings: async ({commit}, responseData) => {
            await commit('SET_PRODUCT_ITEM_TRACKINGS', responseData.data);
        },

        commitSetPaginateLinks: async ({commit}, responseData) => {
            await commit('SET_PAGINATE_LINKS', responseData.meta);
        },

        commitSetProductItemTracking: async ({commit}, responseData) => {
            await commit('SET_PRODUCT_ITEM_TRACKING', responseData.data)
        },

        commitCreateProductItemTrackingOnList: async ({commit}, responseData) => {
            await commit('CREATE_PRODUCT_ITEM_TRACKING_ON_LIST', responseData.data);
        },

        commitCreateProductItemTracking: async ({commit}, responseData) => {
            await commit('CREATE_PRODUCT_ITEM_TRACKING', responseData.data);
        },

        commitUpdateProductItemTrackingOnList: async ({commit}, responseData) => {
            await commit('UPDATE_PRODUCT_ITEM_TRACKING_ON_LIST', responseData.data);
        },

        commitUpdateProductItemTracking: async ({commit}, responseData) => {
            await commit('UPDATE_PRODUCT_ITEM_TRACKING', responseData.data);
        },

        commitDeleteProductItemTrackingOnList: async ({commit}, productItemTrackingId) => {
            await commit('DELETE_PRODUCT_ITEM_TRACKING_ON_LIST', productItemTrackingId);
        },

        commitDeleteProductItemTracking: async ({commit}) => {
            await commit('DELETE_PRODUCT_ITEM_TRACKING');
        },

        commitResetProductItemTrackings: async ({commit}) => {
            await commit('RESET_PRODUCT_ITEM_TRACKINGS');
        },

        commitResetProductItemTracking: async ({commit}) => {
            await commit('RESET_PRODUCT_ITEM_TRACKING');
        },

        // get list of productItemTracking by api call.
        getProductItemTrackings: async ({dispatch}, params = {}) => {
            const path = `v1/product-item-trackings`;

            return axios.get(path, {params: params}).then(response => {

                dispatch('commitSetProductItemTrackings', response.data);
                dispatch('commitSetPaginateLinks', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },


        // get single productItemTracking by api call.
        getProductItemTracking: async ({dispatch}, paramObj) => {
            const path = `v1/product-item-trackings/${paramObj.id}`;
            const params = paramObj.params ?? {};

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetProductItemTracking', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // create new productItemTracking on list by api call.
        postProductItemTrackingOnList: async ({dispatch}, data) => {
            const path = `v1/product-item-trackings`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateProductItemTrackingOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // create new productItemTracking by api call.
        postProductItemTracking: async ({dispatch}, data) => {
            const path = `v1/product-item-trackings`;
            return axios.post(path, data).then((response) => {
                dispatch('commitCreateProductItemTracking', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing productItemTracking on list by api call.
        putProductItemTrackingOnList: async ({dispatch}, dataObj) => {
            const path = `v1/product-item-trackings/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateProductItemTrackingOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing productItemTracking by api call.
        putProductItemTracking: async ({dispatch}, dataObj) => {
            const path = `v1/product-item-trackings/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateProductItemTracking', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular productItemTracking on list by api call.
        deleteProductItemTrackingOnList: async ({dispatch}, id) => {
            const path = `v1/product-item-trackings/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteProductItemTrackingOnList', id);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular productItemTracking by api call.
        deleteProductItemTracking: async ({dispatch}, id) => {
            const path = `v1/product-item-trackings/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteProductItemTracking', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },
        postProductItemTrackingStoreList: async ({dispatch}, data) => {
            const path = `v1/product-item-trackings/store-list`;

            return axios.post(path, data).then((response) => {

                dispatch('commitCreateProductItemTracking', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // reset productItemTrackings state.
        resetProductItemTrackings: async ({dispatch}) => {
            dispatch('commitResetProductItemTrackings');
        },

        // reset productItemTracking state.
        resetProductItemTracking: async ({dispatch}) => {
            dispatch('commitResetProductItemTracking')
        },

    },
};
