import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {
        productPrices: [],
        paginateLinks: {},
        productPrice: {},
    },

    getters: {
        productPrices: (state) => {
            return state.productPrices;
        },

        paginateLinks: (state) => {
            return state.paginateLinks;
        },

        productPrice: (state) => {
            return state.productPrice;
        },
    },
    mutations: {
        SET_PRODUCT_PRICES(state, productPrices) {
            state.productPrices = productPrices;
        },

        SET_PAGINATE_LINKS(state, paginateLinks) {
            state.paginateLinks = paginateLinks;
        },

        SET_PRODUCT_PRICE(state, productPrice) {
            state.productPrice = productPrice;
        },

        CREATE_PRODUCT_PRICE_ON_LIST(state, productPrice) {
            state.productPrices.unshift(productPrice);
        },

        CREATE_PRODUCT_PRICE(state, productPrice) {
            state.productPrice = productPrice;
        },

        UPDATE_PRODUCT_PRICE_ON_LIST(state, productPrice) {
            let index = state.productPrices.findIndex(item => item.id === productPrice.id);
            state.productPrices.splice(index, 1, productPrice);
        },

        UPDATE_PRODUCT_PRICE(state, productPrice) {
            state.productPrice = productPrice;
        },

        DELETE_PRODUCT_PRICE_ON_LIST(state, productPriceId) {
            let index = state.productPrices.findIndex(item => item.id === productPriceId);
            state.productPrices.splice(index, 1);
        },

        DELETE_PRODUCT_PRICE(state) {
            state.productPrice = {};
        },

        RESET_PRODUCT_PRICES(state) {
            state.productPrices = [];
        },

        RESET_PRODUCT_PRICE(state) {
            state.productPrice = {};
        },
    },
    actions: {
        commitSetProductPrices: async ({commit}, responseData) => {
            await commit('SET_PRODUCT_PRICES', responseData.data);
        },

        commitSetPaginateLinks: async ({commit}, responseData) => {
            await commit('SET_PAGINATE_LINKS', responseData.meta);
        },

        commitSetProductPrice: async ({commit}, responseData) => {
            await commit('SET_PRODUCT_PRICE', responseData.data)
        },

        commitCreateProductPriceOnList: async ({commit}, responseData) => {
            await commit('CREATE_PRODUCT_PRICE_ON_LIST', responseData.data);
        },

        commitCreateProductPrice: async ({commit}, responseData) => {
            await commit('CREATE_PRODUCT_PRICE', responseData.data);
        },

        commitUpdateProductPriceOnList: async ({commit}, responseData) => {
            await commit('UPDATE_PRODUCT_PRICE_ON_LIST', responseData.data);
        },

        commitUpdateProductPrice: async ({commit}, responseData) => {
            await commit('UPDATE_PRODUCT_PRICE', responseData.data);
        },

        commitDeleteProductPriceOnList: async ({commit}, productPriceId) => {
            await commit('DELETE_PRODUCT_PRICE_ON_LIST', productPriceId);
        },

        commitDeleteProductPrice: async ({commit}) => {
            await commit('DELETE_PRODUCT_PRICE');
        },

        commitResetProductPrices: async ({commit}) => {
            await commit('RESET_PRODUCT_PRICES');
        },

        commitResetProductPrice: async ({commit}) => {
            await commit('RESET_PRODUCT_PRICE');
        },

        // get list of productPrice by api call.
        getProductPrices: async ({dispatch}, params = {}) => {
            const path = `v1/product-prices`;

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetProductPrices', response.data);
                dispatch('commitSetPaginateLinks', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // get single productPrice by api call.
        getProductPrice: async ({dispatch}, paramObj) => {
            const path = `v1/product-prices/${paramObj.id}`;
            const params = paramObj.params ?? {};

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetProductPrice', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // create new productPrice on list by api call.
        postProductPriceOnList: async ({dispatch}, data) => {
            const path = `v1/product-prices`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateProductPriceOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // create new productPrice by api call.
        postProductPrice: async ({dispatch}, data) => {
            const path = `v1/product-prices`;

            return axios.post(path, data).then((response) => {

                dispatch('commitCreateProductPrice', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },
        // create new productPrice list by api call.
        postListProductPrice: async ({dispatch}, data) => {
            const path = `v1/product-prices/store-list`;

            return axios.post(path, data).then((response) => {

                dispatch('commitCreateProductPriceOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing productPrice on list by api call.
        putProductPriceOnList: async ({dispatch}, dataObj) => {
            const path = `v1/product-prices/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateProductPriceOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing productPrice by api call.
        putProductPrice: async ({dispatch}, dataObj) => {
            const path = `v1/product-prices/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateProductPrice', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular productPrice on list by api call.
        deleteProductPriceOnList: async ({dispatch}, id) => {
            const path = `v1/product-prices/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteProductPriceOnList', id);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular productPrice by api call.
        deleteProductPrice: async ({dispatch}, id) => {
            const path = `v1/product-prices/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteProductPrice', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // reset productPrices state.
        resetProductPrices: async ({dispatch}) => {
            dispatch('commitResetProductPrices');
        },

        // reset productPrice state.
        resetProductPrice: async ({dispatch}) => {
            dispatch('commitResetProductPrice')
        },

    },
};
