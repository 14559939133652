import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,
    state: {
        minimumAmount: {
            amount: 0.00,
            currency: 'AUD',
        },
        maximumAmount: {
            amount: 0.00,
            currency: 'AUD',
        },
        checkout: {},
        payment: {},
        payments: [],


    },

    getters: {
        minimumAmount: (state) => {
            return state.minimumAmount;
        },
        maximumAmount: (state) => {
            return state.maximumAmount
        },
        checkout: (state) => {
            return state.checkout;
        },
        payment: (state) => {
            return state.payment;
        },
        payments: (state) => {
            return state.payments;
        }
    },
    mutations: {
        SET_MINIMUM_AMOUNT (state, minimumAmount) {
            state.minimumAmount.amount = minimumAmount.amount;
            state.minimumAmount.currency = minimumAmount.currency;
        },

        SET_MAXIMUM_AMOUNT(state, maximumAmount) {
            state.maximumAmount.amount = maximumAmount.amount;
            state.maximumAmount.currency = maximumAmount.currency;
        },

        SET_CHECKOUT(state, checkout) {
            state.checkout = checkout;
        },

        SET_PAYMENT(state, payment) {
            state.payment = payment;
        },

        SET_PAYMENTS(state, payments) {
            state.payments = payments;
        }
    },
    actions: {

        commitSetMinimumAmount: async ({ commit }, responseData) =>
        {
            let data = responseData.data;
            await commit('SET_MINIMUM_AMOUNT', data.minimumAmount);
        },
        commitSetMaximumAmount: async ({ commit }, responseData) =>
        {
            let data = responseData.data;
            await commit('SET_MAXIMUM_AMOUNT', data.maximumAmount);
        },
        commitSetCheckout: async ({ commit }, responseData) =>
        {
            await commit('SET_CHECKOUT', responseData.data);
        },
        commitSetPayment: async ({ commit }, responseData) =>
        {
            await commit('SET_PAYMENT', responseData.data);
        },
        commitSetPayments: async ({ commit }, responseData) =>
        {
            await commit('SET_PAYMENTS', responseData.data);
        },

        /**
         * Use to check that the service is available and reachable.
         */

        tryPing: async () => {
            const path = `v1/afterpay-payment-gateways/ping`;

            return axios.get(path).then((response) =>
            {
                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                    data: response.data.data
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        getConfiguration: async ({ dispatch }) => {
            const path = `v1/afterpay-payment-gateways/configuration`;

            return axios.get(path).then((response) =>
            {
                dispatch('commitSetMinimumAmount', response.data);
                dispatch('commitSetMaximumAmount', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                    data: response.data.data
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        createCheckout: async ({ dispatch }, data) => {
            const path = `v1/afterpay-payment-gateways/checkouts`;

            return axios.post(path, data).then((response) =>
            {
                dispatch('commitSetCheckout', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                    data: response.data.data
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        getCheckout: async ({ dispatch }, token) => {
            const path = `v1/afterpay-payment-gateways/checkouts/${token}`;

            return axios.get(path).then((response) =>
            {
                dispatch('commitSetCheckout', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                    data: response.data.data
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        captureImmediateFullPayment: async ({ dispatch }, data) => {
            const path = `v1/afterpay-payment-gateways/payments/immediate/capture`;

            return axios.post(path, data).then((response) =>
            {
                dispatch('commitSetPayment', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                    data: response.data.data
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        authDeferredPayment: async ({ dispatch }, data) => {
            const path = `v1/afterpay-payment-gateways/payments/deferred/auth`;

            return axios.post(path, data).then((response) =>
            {
                dispatch('commitSetPayment', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                    data: response.data.data
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        captureDeferredPayment: async ({ dispatch }, dataObj) => {
            const path = `v1/afterpay-payment-gateways/payments/deferred/capture/${dataObj.id}`;
            const data = dataObj.data

            return axios.post(path, data).then((response) =>
            {
                dispatch('commitSetPayment', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                    data: response.data.data
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        voidDeferredPayment: async ({ dispatch }, dataObj) => {
            const path = `v1/afterpay-payment-gateways/payments/deferred/capture/void/${dataObj.id}`;
            const data = dataObj.data

            return axios.post(path, data).then((response) =>
            {
                dispatch('commitSetPayment', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                    data: response.data.data
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        updateShippingCourier: async ({ dispatch }, dataObj) => {
            const path = `v1/afterpay-payment-gateways/payments/courier/${dataObj.id}`;
            const data = dataObj.data

            return axios.put(path, data).then((response) =>
            {
                dispatch('commitSetPayment', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                    data: response.data.data
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        getPaymentByOrderId: async ({ dispatch }, orderId) => {
            const path = `v1/afterpay-payment-gateways/payments/order/${orderId}`;

            return axios.get(path).then((response) =>
            {
                dispatch('commitSetPayment', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                    data: response.data.data
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        getPaymentByToken: async ({ dispatch }, token) => {
            const path = `v1/afterpay-payment-gateways/payments/${token}`;

            return axios.get(path).then((response) =>
            {
                dispatch('commitSetPayment', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                    data: response.data.data
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        getPayments: async ({ dispatch }, params) => {
            const path = `v1/afterpay-payment-gateways/payments`;

            return axios.get(path, {params: params}).then((response) =>
            {
                dispatch('commitSetPayments', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                    data: response.data.data
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        createRefund: async ({ dispatch }, dataObj) => {
            const path = `v1/afterpay-payment-gateways/payments/order/${dataObj.id}/refund`;
            const data = dataObj.data

            return axios.post(path, data).then((response) =>
            {
                dispatch('commitSetPayment', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                    data: response.data.data
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        updatePaymentByOrderId: async ({ dispatch }, dataObj) => {
            const path = `v1/afterpay-payment-gateways/payments/order/${dataObj.id}`;
            const data = dataObj.data

            return axios.put(path, data).then((response) =>
            {
                dispatch('commitSetPayment', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                    data: response.data.data
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        reversalPaymentByToken: async ({ dispatch }, dataObj) => {
            const path = `v1/afterpay-payment-gateways/payments/${dataObj.token}/reversal`;
            const data = dataObj.data

            return axios.post(path, data).then((response) =>
            {
                dispatch('commitSetPayment', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                    data: response.data.data
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },





    }
}