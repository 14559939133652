import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {
        cashPayments: [],
        paginateLinks: {},
        cashPayment: {},


    },

    getters: {
        cashPayments: (state) => {
            return state.cashPayments;
        },

        paginateLinks: (state) => {
            return state.paginateLinks;
        },

        cashPayment: (state) => {
            return state.cashPayment;
        },

    },
    mutations: {
        SET_CASH_PAYMENTS(state, cashPayments) {
            state.cashPayments = cashPayments;
        },


        SET_PAGINATE_LINKS(state, paginateLinks) {
            state.paginateLinks = paginateLinks;
        },

        SET_CASH_PAYMENT(state, cashPayment) {
            state.cashPayment = cashPayment;
        },

        CREATE_CASH_PAYMENT_ON_LIST(state, cashPayment) {
            state.cashPayments.unshift(cashPayment);
        },

        CREATE_CASH_PAYMENT(state, cashPayment) {
            state.cashPayment = cashPayment;
        },

        UPDATE_CASH_PAYMENT_ON_LIST(state, cashPayment) {
            let index = state.cashPayments.findIndex(item => item.id === cashPayment.id);
            state.cashPayments.splice(index, 1, cashPayment);
        },

        UPDATE_CASH_PAYMENT(state, cashPayment) {
            state.cashPayment = cashPayment;
        },

        DELETE_CASH_PAYMENT_ON_LIST(state, cashPaymentId) {
            let index = state.cashPayments.findIndex(item => item.id === cashPaymentId);
            state.cashPayments.splice(index, 1);
        },

        DELETE_CASH_PAYMENT(state) {
            state.cashPayment = {};
        },

        RESET_CASH_PAYMENTS(state) {
            state.cashPayments = [];
        },

        RESET_CASH_PAYMENT(state) {
            state.cashPayment = {};
        },
    },
    actions: {
        commitSetCashPayments: async ({commit}, responseData) => {
            await commit('SET_CASH_PAYMENTS', responseData.data);
        },

        commitSetPaginateLinks: async ({commit}, responseData) => {
            await commit('SET_PAGINATE_LINKS', responseData.meta);
        },

        commitSetCashPayment: async ({commit}, responseData) => {
            await commit('SET_CASH_PAYMENT', responseData.data)
        },

        commitCreateCashPaymentOnList: async ({commit}, responseData) => {
            await commit('CREATE_CASH_PAYMENT_ON_LIST', responseData.data);
        },

        commitCreateCashPayment: async ({commit}, responseData) => {
            await commit('CREATE_CASH_PAYMENT', responseData.data);
        },

        commitUpdateCashPaymentOnList: async ({commit}, responseData) => {
            await commit('UPDATE_CASH_PAYMENT_ON_LIST', responseData.data);
        },

        commitUpdateCashPayment: async ({commit}, responseData) => {
            await commit('UPDATE_CASH_PAYMENT', responseData.data);
        },

        commitDeleteCashPaymentOnList: async ({commit}, cashPaymentId) => {
            await commit('DELETE_CASH_PAYMENT_ON_LIST', cashPaymentId);
        },

        commitDeleteCashPayment: async ({commit}) => {
            await commit('DELETE_CASH_PAYMENT');
        },

        commitResetCashPayments: async ({commit}) => {
            await commit('RESET_CASH_PAYMENTS');
        },

        commitResetCashPayment: async ({commit}) => {
            await commit('RESET_CASH_PAYMENT');
        },

        // get list of cashPayment by api call.
        getCashPayments: async ({dispatch}, params = {}) => {
            const path = `v1/cash-payments`;

            return axios.get(path, {params: params}).then(response => {

                dispatch('commitSetCashPayments', response.data);
                dispatch('commitSetPaginateLinks', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },


        // get single cashPayment by api call.
        getCashPayment: async ({dispatch}, paramObj) => {
            const path = `v1/cash-payments/${paramObj.id}`;
            const params = paramObj.params ?? {};

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetCashPayment', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // create new cashPayment on list by api call.
        postCashPaymentOnList: async ({dispatch}, data) => {
            const path = `v1/cash-payments`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateCashPaymentOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // create new cashPayment by api call.
        postCashPayment: async ({dispatch}, data) => {
            const path = `v1/cash-payments`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateCashPayment', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing cashPayment on list by api call.
        putCashPaymentOnList: async ({dispatch}, dataObj) => {
            const path = `v1/cash-payments/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateCashPaymentOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing cashPayment by api call.
        putCashPayment: async ({dispatch}, dataObj) => {
            const path = `v1/cash-payments/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateCashPayment', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // post send status update notify api call.
        postCashPaymentStatusUpdateNotify: async ( _ , dataObj) => {
            const path = `v1/cash-payments/send-status-update-notify/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                //dispatch('commitUpdateCashPayment', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular cashPayment on list by api call.
        deleteCashPaymentOnList: async ({dispatch}, id) => {
            const path = `v1/cash-payments/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteCashPaymentOnList', id);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular cashPayment by api call.
        deleteCashPayment: async ({dispatch}, id) => {
            const path = `v1/cash-payments/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteCashPayment', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // reset cashPayments state.
        resetCashPayments: async ({dispatch}) => {
            dispatch('commitResetCashPayments');
        },

        // reset cashPayment state.
        resetCashPayment: async ({dispatch}) => {
            dispatch('commitResetCashPayment')
        },

    },
};
