import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {
        remoteTechnicians: [],
        paginateLinks: {},
        remoteTechnician: {},
    },

    getters: {
        remoteTechnicians: (state) => {
            return state.remoteTechnicians;
        },

        paginateLinks: (state) => {
            return state.paginateLinks;
        },

        remoteTechnician: (state) => {
            return state.remoteTechnician;
        },
    },
    mutations: {
        SET_REMOTE_TECHNICIANS(state, remoteTechnicians) {
            state.remoteTechnicians = remoteTechnicians;
        },

        SET_PAGINATE_LINKS(state, paginateLinks) {
            state.paginateLinks = paginateLinks;
        },

        SET_REMOTE_TECHNICIAN(state, remoteTechnician) {
            state.remoteTechnician = remoteTechnician;
        },

        CREATE_REMOTE_TECHNICIAN_ON_LIST(state, remoteTechnician) {
            state.remoteTechnicians.unshift(remoteTechnician);
        },

        CREATE_REMOTE_TECHNICIAN(state, remoteTechnician) {
            state.remoteTechnician = remoteTechnician;
        },

        UPDATE_REMOTE_TECHNICIAN_ON_LIST(state, remoteTechnician) {
            let index = state.remoteTechnicians.findIndex(item => item.id === remoteTechnician.id);
            state.remoteTechnicians.splice(index, 1, remoteTechnician);
        },

        UPDATE_REMOTE_TECHNICIAN(state, remoteTechnician) {
            state.remoteTechnician = remoteTechnician;
        },

        DELETE_REMOTE_TECHNICIAN_ON_LIST(state, remoteTechnicianId) {
            let index = state.remoteTechnicians.findIndex(item => item.id === remoteTechnicianId);
            state.remoteTechnicians.splice(index, 1);
        },

        DELETE_REMOTE_TECHNICIAN(state) {
            state.remoteTechnician = {};
        },

        RESET_REMOTE_TECHNICIANS(state) {
            state.remoteTechnicians = [];
        },

        RESET_REMOTE_TECHNICIAN(state) {
            state.remoteTechnician = {};
        },
    },
    actions: {
        commitSetRemoteTechnicians: async ({commit}, responseData) => {
            await commit('SET_REMOTE_TECHNICIANS', responseData.data);
        },

        commitSetPaginateLinks: async ({commit}, responseData) => {
            await commit('SET_PAGINATE_LINKS', responseData.meta);
        },

        commitSetRemoteTechnician: async ({commit}, responseData) => {
            await commit('SET_REMOTE_TECHNICIAN', responseData.data)
        },

        commitCreateRemoteTechnicianOnList: async ({commit}, responseData) => {
            await commit('CREATE_REMOTE_TECHNICIAN_ON_LIST', responseData.data);
        },

        commitCreateRemoteTechnician: async ({commit}, responseData) => {
            await commit('CREATE_REMOTE_TECHNICIAN', responseData.data);
        },

        commitUpdateRemoteTechnicianOnList: async ({commit}, responseData) => {
            await commit('UPDATE_REMOTE_TECHNICIAN_ON_LIST', responseData.data);
        },

        commitUpdateRemoteTechnician: async ({commit}, responseData) => {
            await commit('UPDATE_REMOTE_TECHNICIAN', responseData.data);
        },

        commitDeleteRemoteTechnicianOnList: async ({commit}, remoteTechnicianId) => {
            await commit('DELETE_REMOTE_TECHNICIAN_ON_LIST', remoteTechnicianId);
        },

        commitDeleteRemoteTechnician: async ({commit}) => {
            await commit('DELETE_REMOTE_TECHNICIAN');
        },

        commitResetRemoteTechnicians: async ({commit}) => {
            await commit('RESET_REMOTE_TECHNICIANS');
        },

        commitResetRemoteTechnician: async ({commit}) => {
            await commit('RESET_REMOTE_TECHNICIAN');
        },

        // get list of remoteTechnician by api call.
        getRemoteTechnicians: async ({dispatch}, params = {}) => {
            const path = `v1/remote-technicians`;

            return  axios.get(path, {params: params}).then(response => {
                dispatch('commitSetRemoteTechnicians', response.data);
                dispatch('commitSetPaginateLinks', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // get single remoteTechnician by api call.
        getRemoteTechnician: async ({dispatch}, paramObj) => {
            const path = `v1/remote-technicians/${paramObj.id}`;
            const params = paramObj.params ?? {};

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetRemoteTechnician', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // create new remoteTechnician on list by api call.
        postRemoteTechnicianOnList: async ({dispatch}, data) => {
            const path = `v1/remote-technicians`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateRemoteTechnicianOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // create new remoteTechnician by api call.
        postRemoteTechnician: async ({dispatch}, data) => {
            const path = `v1/remote-technicians`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateRemoteTechnician', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing remoteTechnician on list by api call.
        putRemoteTechnicianOnList: async ({dispatch}, dataObj) => {
            const path = `v1/remote-technicians/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateRemoteTechnicianOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing remoteTechnician by api call.
        putRemoteTechnician: async ({dispatch}, dataObj) => {
            const path = `v1/remote-technicians/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateRemoteTechnician', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular remoteTechnician on list by api call.
        deleteRemoteTechnicianOnList: async ({dispatch}, id) => {
            const path = `v1/remote-technicians/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteRemoteTechnicianOnList', id);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular remoteTechnician by api call.
        deleteRemoteTechnician: async ({dispatch}, id) => {
            const path = `v1/remote-technicians/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteRemoteTechnician', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // reset remoteTechnicians state.
        resetRemoteTechnicians: async ({dispatch}) => {
            dispatch('commitResetRemoteTechnicians');
        },

        // reset remoteTechnician state.
        resetRemoteTechnician: async ({dispatch}) => {
            dispatch('commitResetRemoteTechnician')
        },

    },
};
