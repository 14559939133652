import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {
        orderCharges: [],
        paginateLinks: {},
        orderCharge: {},
    },

    getters: {
        orderCharges: (state) => {
            return state.orderCharges;
        },

        paginateLinks: (state) => {
            return state.paginateLinks;
        },

        orderCharge: (state) => {
            return state.orderCharge;
        },
    },
    mutations: {
        SET_ORDER_CHARGES(state, orderCharges) {
            state.orderCharges = orderCharges;
        },

        SET_PAGINATE_LINKS(state, paginateLinks) {
            state.paginateLinks = paginateLinks;
        },

        SET_ORDER_CHARGE(state, orderCharge) {
            state.orderCharge = orderCharge;
        },

        CREATE_ORDER_CHARGE_ON_LIST(state, orderCharge) {
            state.orderCharges.unshift(orderCharge);
        },

        CREATE_ORDER_CHARGE(state, orderCharge) {
            state.orderCharge = orderCharge;
        },

        UPDATE_ORDER_CHARGE_ON_LIST(state, orderCharge) {
            let index = state.orderCharges.findIndex(item => item.id === orderCharge.id);
            state.orderCharges.splice(index, 1, orderCharge);
        },

        UPDATE_ORDER_CHARGE(state, orderCharge) {
            state.orderCharge = orderCharge;
        },

        DELETE_ORDER_CHARGE_ON_LIST(state, orderChargeId) {
            let index = state.orderCharges.findIndex(item => item.id === orderChargeId);
            state.orderCharges.splice(index, 1);
        },

        DELETE_ORDER_CHARGE(state) {
            state.orderCharge = {};
        },

        RESET_ORDER_CHARGES(state) {
            state.orderCharges = [];
        },

        RESET_ORDER_CHARGE(state) {
            state.orderCharge = {};
        },
    },
    actions: {
        commitSetOrderCharges: async ({commit}, responseData) => {
            await commit('SET_ORDER_CHARGES', responseData.data);
        },

        commitSetPaginateLinks: async ({commit}, responseData) => {
            await commit('SET_PAGINATE_LINKS', responseData.meta);
        },

        commitSetOrderCharge: async ({commit}, responseData) => {
            await commit('SET_ORDER_CHARGE', responseData.data)
        },

        commitCreateOrderChargeOnList: async ({commit}, responseData) => {
            await commit('CREATE_ORDER_CHARGE_ON_LIST', responseData.data);
        },

        commitCreateOrderCharge: async ({commit}, responseData) => {
            await commit('CREATE_ORDER_CHARGE', responseData.data);
        },

        commitUpdateOrderChargeOnList: async ({commit}, responseData) => {
            await commit('UPDATE_ORDER_CHARGE_ON_LIST', responseData.data);
        },

        commitUpdateOrderCharge: async ({commit}, responseData) => {
            await commit('UPDATE_ORDER_CHARGE', responseData.data);
        },

        commitDeleteOrderChargeOnList: async ({commit}, orderChargeId) => {
            await commit('DELETE_ORDER_CHARGE_ON_LIST', orderChargeId);
        },

        commitDeleteOrderCharge: async ({commit}) => {
            await commit('DELETE_ORDER_CHARGE');
        },

        commitResetOrderCharges: async ({commit}) => {
            await commit('RESET_ORDER_CHARGES');
        },

        commitResetOrderCharge: async ({commit}) => {
            await commit('RESET_ORDER_CHARGE');
        },

        // get list of orderCharge by api call.
        getOrderCharges: async ({dispatch}, params = {}) => {
            const path = `v1/order-charges`;

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetOrderCharges', response.data);
                dispatch('commitSetPaginateLinks', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // get single orderCharge by api call.
        getOrderCharge: async ({dispatch}, paramObj) => {
            const path = `v1/order-charges/${paramObj.id}`;
            const params = paramObj.params ?? {};

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetOrderCharge', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // create new orderCharge on list by api call.
        postOrderChargeOnList: async ({dispatch}, data) => {
            const path = `v1/order-charges`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateOrderChargeOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // create new orderCharge by api call.
        postOrderCharge: async ({dispatch}, data) => {
            const path = `v1/order-charges`;

            return axios.post(path, data).then((response) => {

                dispatch('commitCreateOrderCharge', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing orderCharge on list by api call.
        putOrderChargeOnList: async ({dispatch}, dataObj) => {
            const path = `v1/order-charges/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateOrderChargeOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing orderCharge by api call.
        putOrderCharge: async ({dispatch}, dataObj) => {
            const path = `v1/order-charges/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateOrderCharge', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular orderCharge on list by api call.
        deleteOrderChargeOnList: async ({dispatch}, id) => {
            const path = `v1/order-charges/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteOrderChargeOnList', id);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular orderCharge by api call.
        deleteOrderCharge: async ({dispatch}, id) => {
            const path = `v1/order-charges/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteOrderCharge', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },
        postOrderChargeStoreList: async ({dispatch}, data) => {
            const path = `v1/order-charges/store-list`;

            return axios.post(path, data).then((response) => {

                dispatch('commitCreateOrderCharge', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // reset orderCharges state.
        resetOrderCharges: async ({dispatch}) => {
            dispatch('commitResetOrderCharges');
        },

        // reset orderCharge state.
        resetOrderCharge: async ({dispatch}) => {
            dispatch('commitResetOrderCharge')
        },

    },
};
