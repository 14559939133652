import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {
        technicianExtraCommissions: [],
        paginateLinks: {},
        technicianExtraCommission: {},
    },

    getters: {
        technicianExtraCommissions: (state) => {
            return state.technicianExtraCommissions;
        },

        paginateLinks: (state) => {
            return state.paginateLinks;
        },

        technicianExtraCommission: (state) => {
            return state.technicianExtraCommission;
        },
    },
    mutations: {
        SET_TECHNICIAN_EXTRA_COMMISSIONS(state, technicianExtraCommissions) {
            state.technicianExtraCommissions = technicianExtraCommissions;
        },

        SET_PAGINATE_LINKS(state, paginateLinks) {
            state.paginateLinks = paginateLinks;
        },

        SET_TECHNICIAN_EXTRA_COMMISSION(state, technicianExtraCommission) {
            state.technicianExtraCommission = technicianExtraCommission;
        },

        CREATE_TECHNICIAN_EXTRA_COMMISSION_ON_LIST(state, technicianExtraCommission) {
            state.technicianExtraCommissions.unshift(technicianExtraCommission);
        },

        CREATE_TECHNICIAN_EXTRA_COMMISSION(state, technicianExtraCommission) {
            state.technicianExtraCommission = technicianExtraCommission;
        },

        UPDATE_TECHNICIAN_EXTRA_COMMISSION_ON_LIST(state, technicianExtraCommission) {
            let index = state.technicianExtraCommissions.findIndex(item => item.id === technicianExtraCommission.id);
            state.technicianExtraCommissions.splice(index, 1, technicianExtraCommission);
        },

        UPDATE_TECHNICIAN_EXTRA_COMMISSION(state, technicianExtraCommission) {
            state.technicianExtraCommission = technicianExtraCommission;
        },

        DELETE_TECHNICIAN_EXTRA_COMMISSION_ON_LIST(state, technicianExtraCommissionId) {
            let index = state.technicianExtraCommissions.findIndex(item => item.id === technicianExtraCommissionId);
            state.technicianExtraCommissions.splice(index, 1);
        },

        DELETE_TECHNICIAN_EXTRA_COMMISSION(state) {
            state.technicianExtraCommission = {};
        },

        RESET_TECHNICIAN_EXTRA_COMMISSIONS(state) {
            state.technicianExtraCommissions = [];
        },

        RESET_TECHNICIAN_EXTRA_COMMISSION(state) {
            state.technicianExtraCommission = {};
        },
    },
    actions: {
        commitSetTechnicianExtraCommissions: async ({commit}, responseData) => {
            await commit('SET_TECHNICIAN_EXTRA_COMMISSIONS', responseData.data);
        },

        commitSetPaginateLinks: async ({commit}, responseData) => {
            await commit('SET_PAGINATE_LINKS', responseData.meta);
        },

        commitSetTechnicianExtraCommission: async ({commit}, responseData) => {
            await commit('SET_TECHNICIAN_EXTRA_COMMISSION', responseData.data)
        },

        commitCreateTechnicianExtraCommissionOnList: async ({commit}, responseData) => {
            await commit('CREATE_TECHNICIAN_EXTRA_COMMISSION_ON_LIST', responseData.data);
        },

        commitCreateTechnicianExtraCommission: async ({commit}, responseData) => {
            await commit('CREATE_TECHNICIAN_EXTRA_COMMISSION', responseData.data);
        },

        commitUpdateTechnicianExtraCommissionOnList: async ({commit}, responseData) => {
            await commit('UPDATE_TECHNICIAN_EXTRA_COMMISSION_ON_LIST', responseData.data);
        },

        commitUpdateTechnicianExtraCommission: async ({commit}, responseData) => {
            await commit('UPDATE_TECHNICIAN_EXTRA_COMMISSION', responseData.data);
        },

        commitDeleteTechnicianExtraCommissionOnList: async ({commit}, technicianExtraCommissionId) => {
            await commit('DELETE_TECHNICIAN_EXTRA_COMMISSION_ON_LIST', technicianExtraCommissionId);
        },

        commitDeleteTechnicianExtraCommission: async ({commit}) => {
            await commit('DELETE_TECHNICIAN_EXTRA_COMMISSION');
        },

        commitResetTechnicianExtraCommissions: async ({commit}) => {
            await commit('RESET_TECHNICIAN_EXTRA_COMMISSIONS');
        },

        commitResetTechnicianExtraCommission: async ({commit}) => {
            await commit('RESET_TECHNICIAN_EXTRA_COMMISSION');
        },

        // get list of technicianExtraCommission by api call.
        getTechnicianExtraCommissions: async ({dispatch}, params = {}) => {
            const path = `v1/technician-extra-commissions`;

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetTechnicianExtraCommissions', response.data);
                dispatch('commitSetPaginateLinks', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // get single technicianExtraCommission by api call.
        getTechnicianExtraCommission: async ({dispatch}, paramObj) => {
            const path = `v1/technician-extra-commissions/${paramObj.id}`;
            const params = paramObj.params ?? {};

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetTechnicianExtraCommission', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // create new technicianExtraCommission on list by api call.
        postTechnicianExtraCommissionOnList: async ({dispatch}, data) => {
            const path = `v1/technician-extra-commissions`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateTechnicianExtraCommissionOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // create new technicianExtraCommission by api call.
        postTechnicianExtraCommission: async ({dispatch}, data) => {
            const path = `v1/technician-extra-commissions`;

            return axios.post(path, data).then((response) => {

                dispatch('commitCreateTechnicianExtraCommission', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing technicianExtraCommission on list by api call.
        putTechnicianExtraCommissionOnList: async ({dispatch}, dataObj) => {
            const path = `v1/technician-extra-commissions/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateTechnicianExtraCommissionOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing technicianExtraCommission by api call.
        putTechnicianExtraCommission: async ({dispatch}, dataObj) => {
            const path = `v1/technician-extra-commissions/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateTechnicianExtraCommission', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular technicianExtraCommission on list by api call.
        deleteTechnicianExtraCommissionOnList: async ({dispatch}, id) => {
            const path = `v1/technician-extra-commissions/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteTechnicianExtraCommissionOnList', id);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular technicianExtraCommission by api call.
        deleteTechnicianExtraCommission: async ({dispatch}, id) => {
            const path = `v1/technician-extra-commissions/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteTechnicianExtraCommission', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // reset technicianExtraCommissions state.
        resetTechnicianExtraCommissions: async ({dispatch}) => {
            dispatch('commitResetTechnicianExtraCommissions');
        },

        // reset technicianExtraCommission state.
        resetTechnicianExtraCommission: async ({dispatch}) => {
            dispatch('commitResetTechnicianExtraCommission')
        },

    },
};
