import axios from "axios";
import responseErrorHandler  from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {
        blogs: [],
        paginateLinks: {},
        blog: {},
    },

    getters: {
        blogs: (state) => {
            return state.blogs;
        },

        paginateLinks: (state) => {
            return state.paginateLinks;
        },

        blog: (state) => {
            return state.blog;
        },
    },

    mutations: {
        SET_BLOGS(state, blogs) {
            state.blogs = blogs;
        },

        SET_PAGINATE_LINKS(state, paginateLinks) {
            state.paginateLinks = paginateLinks;
        },

        SET_BLOG(state, blog) {
            state.blog = blog;
        },

        CREATE_BLOG_ON_LIST(state, blog) {
            state.blogs.unshift(blog);
        },

        CREATE_BLOG(state, blog) {
            state.blog = blog;
        },

        UPDATE_BLOG_ON_LIST(state, blog) {
            let index = state.blogs.findIndex(item => item.id === blog.id);
            state.blogs.splice(index, 1, blog);
        },

        UPDATE_BLOG(state, blog) {
            state.blog = blog;
        },

        DELETE_BLOG_ON_LIST(state, blogId) {
            let index = state.blogs.findIndex(item => item.id === blogId);
            state.blogs.splice(index, 1);
        },

        DELETE_BLOG(state) {
            state.blog = {};
        },

        RESET_BLOGS(state) {
            state.blogs = [];
        },

        RESET_BLOG(state) {
            state.blog = {};
        },
    },

    actions: {
        commitSetBlogs: async ({commit}, responseData) => {
            await commit('SET_BLOGS', responseData.data);
        },

        commitSetPaginateLinks: async ({commit}, responseData) => {
            await commit('SET_PAGINATE_LINKS', responseData.meta);
        },

        commitSetBlog: async ({commit}, responseData) => {
            await commit('SET_BLOG', responseData.data)
        },

        commitCreateBlogOnList: async ({commit}, responseData) => {
            await commit('CREATE_BLOG_ON_LIST', responseData.data);
        },

        commitCreateBlog: async ({commit}, responseData) => {
            await commit('CREATE_BLOG', responseData.data);
        },

        commitUpdateBlogOnList: async ({commit}, responseData) => {
            await commit('UPDATE_BLOG_ON_LIST', responseData.data);
        },

        commitUpdateBlog: async ({commit}, responseData) => {
            await commit('UPDATE_BLOG', responseData.data);
        },

        commitDeleteBlogOnList: async ({commit}, blogId) => {
            await commit('DELETE_BLOG_ON_LIST', blogId);
        },

        commitDeleteBlog: async ({commit}) => {
            await commit('DELETE_BLOG');
        },

        commitResetBlogs: async ({commit}) => {
            await commit('RESET_BLOGS');
        },

        commitResetBlog: async ({commit}) => {
            await commit('RESET_BLOG');
        },

        // get list of blog by api call.
        getBlogs: async ({dispatch}, params = {}) => {
            const path = `v1/blogs`;

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetBlogs', response.data);
                dispatch('commitSetPaginateLinks', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // get single blog by api call.
        getBlog: async ({dispatch}, paramObj) => {
            const path = `v1/blogs/${paramObj.id}`;
            const params = paramObj.params ?? {};

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetBlog', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // create new blog on list by api call.
        postBlogOnList: async ({dispatch}, data) => {
            const path = `v1/blogs`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateBlogOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // create new blog by api call.
        postBlog: async ({dispatch}, data) => {
            const path = `v1/blogs`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateBlog', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing blog on list by api call.
        putBlogOnList: async ({dispatch}, dataObj) => {
            const path = `v1/blogs/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateBlogOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing blog by api call.
        putBlog: async ({dispatch}, dataObj) => {
            const path = `v1/blogs/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateBlog', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular blog on list by api call.
        deleteBlogOnList: async ({dispatch}, id) => {
            const path = `v1/blogs/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteBlogOnList', id);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular blog by api call.
        deleteBlog: async ({dispatch}, id) => {
            const path = `v1/blogs/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteBlog', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        putImage: async (_, dataObj) =>
        {

            const path = `v1/blogs/${dataObj.id}/image`;
            const data = dataObj.data ?? {};

            return axios.put(path, data).then((response) =>
            {

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) =>
            {
                return responseErrorHandler(error);
            });
        },

        // reset blog state.
        resetBlogs: async ({dispatch}) => {
            dispatch('commitResetBlogs');
        },

        // reset blog state.
        resetBlog: async ({dispatch}) => {
            dispatch('commitResetBlog')
        },

    },

};