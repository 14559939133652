import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {

        permissions: [],

    },

    getters: {

        permissions: (state) =>
        {
            return state.permissions;
        },
    },
    mutations: {

        SET_PERMISSIONS (state, permissions)
        {
            state.permissions = permissions;
        },


    },
    actions: {

        commitSetPermissions: async ({ commit }, responseData) =>
        {
            await commit('SET_PERMISSIONS', responseData.data);
        },





        // get list of permissions by api call.
        getPermissions: async ({ dispatch }, params = {}) =>
        {
            const path = `v1/permissions`;

            return axios.get(path, { params: params }).then(response =>
            {

                dispatch('commitSetPermissions', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error =>
            {
                return responseErrorHandler(error);
            });
        },





    },
};
