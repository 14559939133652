import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {
        userAdditionalInfos: [],
        paginateLinks: {},
        userAdditionalInfo: {},
    },

    getters: {
        userAdditionalInfos: (state) => {
            return state.userAdditionalInfos;
        },

        paginateLinks: (state) => {
            return state.paginateLinks;
        },

        userAdditionalInfo: (state) => {
            return state.userAdditionalInfo;
        },
    },
    mutations: {
        SET_USER_ADDITIONAL_INFOS(state, userAdditionalInfos) {
            state.userAdditionalInfos = userAdditionalInfos;
        },

        SET_PAGINATE_LINKS(state, paginateLinks) {
            state.paginateLinks = paginateLinks;
        },

        SET_USER_ADDITIONAL_INFO(state, userAdditionalInfo) {
            state.userAdditionalInfo = userAdditionalInfo;
        },

        CREATE_USER_ADDITIONAL_INFO_ON_LIST(state, userAdditionalInfo) {
            state.userAdditionalInfos.unshift(userAdditionalInfo);
        },

        CREATE_USER_ADDITIONAL_INFO(state, userAdditionalInfo) {
            state.userAdditionalInfo = userAdditionalInfo;
        },

        UPDATE_USER_ADDITIONAL_INFO_ON_LIST(state, userAdditionalInfo) {
            let index = state.userAdditionalInfos.findIndex(item => item.id === userAdditionalInfo.id);
            state.userAdditionalInfos.splice(index, 1, userAdditionalInfo);
        },

        UPDATE_USER_ADDITIONAL_INFO(state, userAdditionalInfo) {
            state.userAdditionalInfo = userAdditionalInfo;
        },

        DELETE_USER_ADDITIONAL_INFO_ON_LIST(state, userAdditionalInfoId) {
            let index = state.userAdditionalInfos.findIndex(item => item.id === userAdditionalInfoId);
            state.userAdditionalInfos.splice(index, 1);
        },

        DELETE_USER_ADDITIONAL_INFO(state) {
            state.userAdditionalInfo = {};
        },

        RESET_USER_ADDITIONAL_INFOS(state) {
            state.userAdditionalInfos = [];
        },

        RESET_USER_ADDITIONAL_INFO(state) {
            state.userAdditionalInfo = {};
        },
    },
    actions: {
        commitSetUserAdditionalInfos: async ({commit}, responseData) => {
            await commit('SET_USER_ADDITIONAL_INFOS', responseData.data);
        },

        commitSetPaginateLinks: async ({commit}, responseData) => {
            await commit('SET_PAGINATE_LINKS', responseData.meta);
        },

        commitSetUserAdditionalInfo: async ({commit}, responseData) => {
            await commit('SET_USER_ADDITIONAL_INFO', responseData.data)
        },

        commitCreateUserAdditionalInfoOnList: async ({commit}, responseData) => {
            await commit('CREATE_USER_ADDITIONAL_INFO_ON_LIST', responseData.data);
        },

        commitCreateUserAdditionalInfo: async ({commit}, responseData) => {
            await commit('CREATE_USER_ADDITIONAL_INFO', responseData.data);
        },

        commitUpdateUserAdditionalInfoOnList: async ({commit}, responseData) => {
            await commit('UPDATE_USER_ADDITIONAL_INFO_ON_LIST', responseData.data);
        },

        commitUpdateUserAdditionalInfo: async ({commit}, responseData) => {
            await commit('UPDATE_USER_ADDITIONAL_INFO', responseData.data);
        },

        commitDeleteUserAdditionalInfoOnList: async ({commit}, userAdditionalInfoId) => {
            await commit('DELETE_USER_ADDITIONAL_INFO_ON_LIST', userAdditionalInfoId);
        },

        commitDeleteUserAdditionalInfo: async ({commit}) => {
            await commit('DELETE_USER_ADDITIONAL_INFO');
        },

        commitResetUserAdditionalInfos: async ({commit}) => {
            await commit('RESET_USER_ADDITIONAL_INFOS');
        },

        commitResetUserAdditionalInfo: async ({commit}) => {
            await commit('RESET_USER_ADDITIONAL_INFO');
        },

        // get list of userAdditionalInfo by api call.
        getUserAdditionalInfos: async ({dispatch}, params = {}) => {
            const path = `v1/user-additional-infos`;

            return  axios.get(path, {params: params}).then(response => {
                dispatch('commitSetUserAdditionalInfos', response.data);
                dispatch('commitSetPaginateLinks', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // get single userAdditionalInfo by api call.
        getUserAdditionalInfo: async ({dispatch}, paramObj) => {
            const path = `v1/user-additional-infos/${paramObj.id}`;
            const params = paramObj.params ?? {};

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetUserAdditionalInfo', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // create new userAdditionalInfo on list by api call.
        postUserAdditionalInfoOnList: async ({dispatch}, data) => {
            const path = `v1/user-additional-infos`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateUserAdditionalInfoOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // create new userAdditionalInfo by api call.
        postUserAdditionalInfo: async ({dispatch}, data) => {
            const path = `v1/user-additional-infos`;

            return axios.post(path, data).then((response) => {

                dispatch('commitCreateUserAdditionalInfo', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing userAdditionalInfo on list by api call.
        putUserAdditionalInfoOnList: async ({dispatch}, dataObj) => {
            const path = `v1/user-additional-infos/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateUserAdditionalInfoOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing userAdditionalInfo by api call.
        putUserAdditionalInfo: async ({dispatch}, dataObj) => {
            const path = `v1/user-additional-infos/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateUserAdditionalInfo', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular userAdditionalInfo on list by api call.
        deleteUserAdditionalInfoOnList: async ({dispatch}, id) => {
            const path = `v1/user-additional-infos/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteUserAdditionalInfoOnList', id);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular userAdditionalInfo by api call.
        deleteUserAdditionalInfo: async ({dispatch}, id) => {
            const path = `v1/user-additional-infos/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteUserAdditionalInfo', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // reset userAdditionalInfos state.
        resetUserAdditionalInfos: async ({dispatch}) => {
            dispatch('commitResetUserAdditionalInfos');
        },

        // reset userAdditionalInfo state.
        resetUserAdditionalInfo: async ({dispatch}) => {
            dispatch('commitResetUserAdditionalInfo')
        },

    },
};
