import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {
        roles: [],
        paginateLinks: {},
        role: {},
    },

    getters: {
        roles: (state) => {
            return state.roles;
        },

        paginateLinks: (state) => {
            return state.paginateLinks;
        },

        role: (state) => {
            return state.role;
        },
    },
    mutations: {
        SET_ROLES(state, roles) {
            state.roles = roles;
        },

        SET_PAGINATE_LINKS(state, paginateLinks) {
            state.paginateLinks = paginateLinks;
        },

        SET_ROLE(state, role) {
            state.role = role;
        },

        CREATE_ROLE_ON_LIST(state, role) {
            state.roles.unshift(role);
        },

        CREATE_ROLE(state, role) {
            state.role = role;
        },

        UPDATE_ROLE_ON_LIST(state, role) {
            let index = state.roles.findIndex(item => item.id === role.id);
            state.roles.splice(index, 1, role);
        },

        UPDATE_ROLE(state, role) {
            state.role = role;
        },

        DELETE_ROLE_ON_LIST(state, roleId) {
            let index = state.roles.findIndex(item => item.id === roleId);
            state.roles.splice(index, 1);
        },

        DELETE_ROLE(state) {
            state.role = {};
        },

        RESET_ROLES(state) {
            state.roles = [];
        },

        RESET_ROLE(state) {
            state.role = {};
        },
    },
    actions: {
        commitSetRoles: async ({commit}, responseData) => {
            await commit('SET_ROLES', responseData.data);
        },
       
        commitSetPaginateLinks: async ({commit}, responseData) => {
            await commit('SET_PAGINATE_LINKS', responseData.meta);
        },

        commitSetRole: async ({commit}, responseData) => {
            await commit('SET_ROLE', responseData.data)
        },

        commitCreateRoleOnList: async ({commit}, responseData) => {
            await commit('CREATE_ROLE_ON_LIST', responseData.data);
        },

        commitCreateRole: async ({commit}, responseData) => {
            await commit('CREATE_ROLE', responseData.data);
        },

        commitUpdateRoleOnList: async ({commit}, responseData) => {
            await commit('UPDATE_ROLE_ON_LIST', responseData.data);
        },

        commitUpdateRole: async ({commit}, responseData) => {
            await commit('UPDATE_ROLE', responseData.data);
        },

        commitDeleteRoleOnList: async ({commit}, roleId) => {
            await commit('DELETE_ROLE_ON_LIST', roleId);
        },

        commitDeleteRole: async ({commit}) => {
            await commit('DELETE_ROLE');
        },

        commitResetRoles: async ({commit}) => {
            await commit('RESET_ROLES');
        },

        commitResetRole: async ({commit}) => {
            await commit('RESET_ROLE');
        },

        // get list of role by api call.
        getRoles: async ({dispatch}, params = {}) => {
            const path = `v1/roles`;

            return  axios.get(path, {params: params}).then(response => {
                dispatch('commitSetRoles', response.data);
                dispatch('commitSetPaginateLinks', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // get single role by api call.
        getRole: async ({dispatch}, paramObj) => {
            const path = `v1/roles/${paramObj.id}`;
            const params = paramObj.params ?? {};

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetRole', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // create new role on list by api call.
        postRoleOnList: async ({dispatch}, data) => {
            const path = `v1/roles`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateRoleOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // create new role by api call.
        postRole: async ({dispatch}, data) => {
            const path = `v1/roles`;

            return axios.post(path, data).then((response) => {

                dispatch('commitCreateRole', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing role on list by api call.
        putRoleOnList: async ({dispatch}, dataObj) => {
            const path = `v1/roles/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateRoleOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing role by api call.
        putRole: async ({dispatch}, dataObj) => {
            const path = `v1/roles/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateRole', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular role on list by api call.
        deleteRoleOnList: async ({dispatch}, id) => {
            const path = `v1/roles/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteRoleOnList', id);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular role by api call.
        deleteRole: async ({dispatch}, id) => {
            const path = `v1/roles/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteRole', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // reset roles state.
        resetRoles: async ({dispatch}) => {
            dispatch('commitResetRoles');
        },

        // reset role state.
        resetRole: async ({dispatch}) => {
            dispatch('commitResetRole')
        },

    },
};
