import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {
        adminTasks: [],
        paginateLinks: {},
        adminTask: {},
    },

    getters: {
        adminTasks: (state) => {
            return state.adminTasks;
        },

        paginateLinks: (state) => {
            return state.paginateLinks;
        },

        adminTask: (state) => {
            return state.adminTask;
        },
    },
    mutations: {
        SET_ADMIN_TASKS(state, adminTasks) {
            state.adminTasks = adminTasks;
        },

        SET_PAGINATE_LINKS(state, paginateLinks) {
            state.paginateLinks = paginateLinks;
        },

        SET_ADMIN_TASK(state, adminTask) {
            state.adminTask = adminTask;
        },

        CREATE_ADMIN_TASK_ON_LIST(state, adminTask) {
            state.adminTasks.unshift(adminTask);
        },

        CREATE_ADMIN_TASK(state, adminTask) {
            state.adminTask = adminTask;
        },

        UPDATE_ADMIN_TASK_ON_LIST(state, adminTask) {
            let index = state.adminTasks.findIndex(item => item.id === adminTask.id);
            state.adminTasks.splice(index, 1, adminTask);
        },

        UPDATE_ADMIN_TASK(state, adminTask) {
            state.adminTask = adminTask;
        },

        DELETE_ADMIN_TASK_ON_LIST(state, orderCreatorId) {
            let index = state.adminTasks.findIndex(item => item.id === orderCreatorId);
            state.adminTasks.splice(index, 1);
        },

        DELETE_ADMIN_TASK(state) {
            state.adminTask = {};
        },

        RESET_ADMIN_TASKS(state) {
            state.adminTasks = [];
        },

        RESET_ADMIN_TASK(state) {
            state.adminTask = {};
        },
    },
    actions: {
        commitSetAdminTasks: async ({commit}, responseData) => {
            await commit('SET_ADMIN_TASKS', responseData.data);
        },

        commitSetPaginateLinks: async ({commit}, responseData) => {
            await commit('SET_PAGINATE_LINKS', responseData.meta);
        },

        commitSetAdminTask: async ({commit}, responseData) => {
            await commit('SET_ADMIN_TASK', responseData.data)
        },

        commitCreateAdminTaskOnList: async ({commit}, responseData) => {
            await commit('CREATE_ADMIN_TASK_ON_LIST', responseData.data);
        },

        commitCreateAdminTask: async ({commit}, responseData) => {
            await commit('CREATE_ADMIN_TASK', responseData.data);
        },

        commitUpdateAdminTaskOnList: async ({commit}, responseData) => {
            await commit('UPDATE_ADMIN_TASK_ON_LIST', responseData.data);
        },

        commitUpdateAdminTask: async ({commit}, responseData) => {
            await commit('UPDATE_ADMIN_TASK', responseData.data);
        },

        commitDeleteAdminTaskOnList: async ({commit}, orderCreatorId) => {
            await commit('DELETE_ADMIN_TASK_ON_LIST', orderCreatorId);
        },

        commitDeleteAdminTask: async ({commit}) => {
            await commit('DELETE_ADMIN_TASK');
        },

        commitResetAdminTasks: async ({commit}) => {
            await commit('RESET_ADMIN_TASKS');
        },

        commitResetAdminTask: async ({commit}) => {
            await commit('RESET_ADMIN_TASK');
        },

        // get list of adminTask by api call.
        getAdminTasks: async ({dispatch}, params = {}) => {
            const path = `v1/admin-tasks`;

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetAdminTasks', response.data);
                dispatch('commitSetPaginateLinks', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // get single adminTask by api call.
        getAdminTask: async ({dispatch}, paramObj) => {
            const path = `v1/admin-tasks/${paramObj.id}`;
            const params = paramObj.params ?? {};

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetAdminTask', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // create new adminTask on list by api call.
        postAdminTaskOnList: async ({dispatch}, data) => {
            const path = `v1/admin-tasks`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateAdminTaskOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // create new adminTask by api call.
        postAdminTask: async ({dispatch}, data) => {
            const path = `v1/admin-tasks`;

            return axios.post(path, data).then((response) => {

                dispatch('commitCreateAdminTask', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing adminTask on list by api call.
        putAdminTaskOnList: async ({dispatch}, dataObj) => {
            const path = `v1/admin-tasks/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateAdminTaskOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing adminTask by api call.
        putAdminTask: async ({dispatch}, dataObj) => {
            const path = `v1/admin-tasks/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateAdminTask', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular adminTask on list by api call.
        deleteAdminTaskOnList: async ({dispatch}, id) => {
            const path = `v1/admin-tasks/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteAdminTaskOnList', id);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular adminTask by api call.
        deleteAdminTask: async ({dispatch}, id) => {
            const path = `v1/admin-tasks/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteAdminTask', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // reset adminTasks state.
        resetAdminTasks: async ({dispatch}) => {
            dispatch('commitResetAdminTasks');
        },

        // reset adminTask state.
        resetAdminTask: async ({dispatch}) => {
            dispatch('commitResetAdminTask')
        },

    },
};
