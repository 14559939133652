import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {
        vacancies: [],
        paginateLinks: {},
        vacancy: {},
    },

    getters: {
        vacancies: (state) => {
            return state.vacancies;
        },

        paginateLinks: (state) => {
            return state.paginateLinks;
        },

        vacancy: (state) => {
            return state.vacancy;
        },
    },
    mutations: {
        SET_VACANCIES(state, vacancies) {
            state.vacancies = vacancies;
        },

        SET_PAGINATE_LINKS(state, paginateLinks) {
            state.paginateLinks = paginateLinks;
        },

        SET_VACANCY(state, vacancy) {
            state.vacancy = vacancy;
        },

        CREATE_VACANCY_ON_LIST(state, vacancy) {
            state.vacancies.unshift(vacancy);
        },

        CREATE_VACANCY(state, vacancy) {
            state.vacancy = vacancy;
        },

        UPDATE_VACANCY_ON_LIST(state, vacancy) {
            let index = state.vacancies.findIndex(item => item.id === vacancy.id);
            state.vacancies.splice(index, 1, vacancy);
        },

        UPDATE_VACANCY(state, vacancy) {
            state.vacancy = vacancy;
        },

        DELETE_VACANCY_ON_LIST(state, vacancyId) {
            let index = state.vacancies.findIndex(item => item.id === vacancyId);
            state.vacancies.splice(index, 1);
        },

        DELETE_VACANCY(state) {
            state.vacancy = {};
        },

        RESET_VACANCIES(state) {
            state.vacancies = [];
        },

        RESET_VACANCY(state) {
            state.vacancy = {};
        },
    },
    actions: {
        commitSetVacancies: async ({commit}, responseData) => {
            await commit('SET_VACANCIES', responseData.data);
        },

        commitSetPaginateLinks: async ({commit}, responseData) => {
            await commit('SET_PAGINATE_LINKS', responseData.meta);
        },

        commitSetVacancy: async ({commit}, responseData) => {
            await commit('SET_VACANCY', responseData.data)
        },

        commitCreateVacancyOnList: async ({commit}, responseData) => {
            await commit('CREATE_VACANCY_ON_LIST', responseData.data);
        },

        commitCreateVacancy: async ({commit}, responseData) => {
            await commit('CREATE_VACANCY', responseData.data);
        },

        commitUpdateVacancyOnList: async ({commit}, responseData) => {
            await commit('UPDATE_VACANCY_ON_LIST', responseData.data);
        },

        commitUpdateVacancy: async ({commit}, responseData) => {
            await commit('UPDATE_VACANCY', responseData.data);
        },

        commitDeleteVacancyOnList: async ({commit}, vacancyId) => {
            await commit('DELETE_VACANCY_ON_LIST', vacancyId);
        },

        commitDeleteVacancy: async ({commit}) => {
            await commit('DELETE_VACANCY');
        },

        commitResetVacancies: async ({commit}) => {
            await commit('RESET_VACANCIES');
        },

        commitResetVacancy: async ({commit}) => {
            await commit('RESET_VACANCY');
        },

        // get list of vacancy by api call.
        getVacancies: async ({dispatch}, params = {}) => {
            const path = `v1/vacancies`;

            return  axios.get(path, {params: params}).then(response => {
                dispatch('commitSetVacancies', response.data);
                dispatch('commitSetPaginateLinks', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // get single vacancy by api call.
        getVacancy: async ({dispatch}, paramObj) => {
            const path = `v1/vacancies/${paramObj.id}`;
            const params = paramObj.params ?? {};

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetVacancy', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // create new vacancy on list by api call.
        postVacancyOnList: async ({dispatch}, data) => {
            const path = `v1/vacancies`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateVacancyOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // create new vacancy by api call.
        postVacancy: async ({dispatch}, data) => {
            const path = `v1/vacancies`;

            return axios.post(path, data).then((response) => {

                dispatch('commitCreateVacancy', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing vacancy on list by api call.
        putVacancyOnList: async ({dispatch}, dataObj) => {
            const path = `v1/vacancies/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateVacancyOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing vacancy by api call.
        putVacancy: async ({dispatch}, dataObj) => {
            const path = `v1/vacancies/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateVacancy', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular vacancy on list by api call.
        deleteVacancyOnList: async ({dispatch}, id) => {
            const path = `v1/vacancies/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteVacancyOnList', id);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular vacancy by api call.
        deleteVacancy: async ({dispatch}, id) => {
            const path = `v1/vacancies/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteVacancy', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // reset vacancies state.
        resetVacancies: async ({dispatch}) => {
            dispatch('commitResetVacancies');
        },

        // reset vacancy state.
        resetVacancy: async ({dispatch}) => {
            dispatch('commitResetVacancy')
        },

    },
};
