import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {
        appointments: [],
        paginateLinks: {},
        appointment: {},
        appointmentNotes: [],
        appointmentHistories: [],
        onSiteAddressDistance: {},
        appointmentsReport: {}
    },

    getters: {
        appointments: (state) => {
            return state.appointments;
        },

        paginateLinks: (state) => {
            return state.paginateLinks;
        },

        appointment: (state) => {
            return state.appointment;
        },
        appointmentNotes: (state) => {
            return state.appointmentNotes;
        },

        appointmentHistories: (state) => {
            return state.appointmentHistories;
        },

        onSiteAddressDistance: (state) => {
            return state.onSiteAddressDistance;
        },
        appointmentsReport: (state) => {
            return state.appointmentsReport;
        },

    },
    mutations: {
        SET_APPOINTMENTS(state, appointments) {
            state.appointments = appointments;
        },
        SET_APPOINTMENT_NOTE(state, appointmentNotes) {
            state.appointmentNotes = appointmentNotes;
        },

        SET_APPOINTMENT_HISTORIES(state, appointmentHistories) {
            state.appointmentHistories = appointmentHistories;
        },

        SET_PAGINATE_LINKS(state, paginateLinks) {
            state.paginateLinks = paginateLinks;
        },

        SET_APPOINTMENT(state, appointment) {
            state.appointment = appointment;
        },

        CREATE_APPOINTMENT_ON_LIST(state, appointment) {
            state.appointments.unshift(appointment);
        },

        CREATE_APPOINTMENT(state, appointment) {
            state.appointment = appointment;
        },

        UPDATE_APPOINTMENT_ON_LIST(state, appointment) {
            let index = state.appointments.findIndex(item => item.id === appointment.id);
            state.appointments.splice(index, 1, appointment);
        },
        UPDATE_APPOINTMENT_NOTE_ON_LIST(state, appointmentNote) {
            let index = state.appointmentNotes.findIndex(item => item.id === appointmentNote.id);
            state.appointmentNotes.splice(index, 1, appointmentNote);
        },

        UPDATE_APPOINTMENT(state, appointment) {
            state.appointment = appointment;
        },

        DELETE_APPOINTMENT_ON_LIST(state, appointmentId) {
            let index = state.appointments.findIndex(item => item.id === appointmentId);
            state.appointments.splice(index, 1);
        },
        DELETE_APPOINTMENT_NOTE_ON_LIST(state, appointmentNoteId) {
            let index = state.appointmentNotes.findIndex(item => item.id === appointmentNoteId);
            state.appointmentNotes.splice(index, 1);
        },

        DELETE_APPOINTMENT(state) {
            state.appointment = {};
        },

        RESET_APPOINTMENTS(state) {
            state.appointments = [];
        },

        RESET_APPOINTMENT(state) {
            state.appointment = {};
        },

        SET_ON_SITE_ADDRESS_DISTANCE(state, onSiteAddressDistance) {
            state.onSiteAddressDistance = onSiteAddressDistance;
        },
        SET_APPOINTMENTS_REPORT(state, appointmentsReport) {
            state.appointmentsReport = appointmentsReport;
        },

    },
    actions: {
        commitSetAppointments: async ({commit}, responseData) => {
            await commit('SET_APPOINTMENTS', responseData.data);
        },

        commitSetPaginateLinks: async ({commit}, responseData) => {
            await commit('SET_PAGINATE_LINKS', responseData.meta);
        },

        commitSetAppointment: async ({commit}, responseData) => {
            await commit('SET_APPOINTMENT', responseData.data)
        },

        commitCreateAppointmentOnList: async ({commit}, responseData) => {
            await commit('CREATE_APPOINTMENT_ON_LIST', responseData.data);
        },

        commitCreateAppointment: async ({commit}, responseData) => {
            await commit('CREATE_APPOINTMENT', responseData.data);
        },

        commitUpdateAppointmentOnList: async ({commit}, responseData) => {
            await commit('UPDATE_APPOINTMENT_ON_LIST', responseData.data);
        },
        commitUpdateAppointmentNoteOnList: async ({commit}, responseData) => {
            await commit('UPDATE_APPOINTMENT_NOTE_ON_LIST', responseData.data);
        },

        commitUpdateAppointment: async ({commit}, responseData) => {
            await commit('UPDATE_APPOINTMENT', responseData.data);
        },

        commitDeleteAppointmentOnList: async ({commit}, appointmentId) => {
            await commit('DELETE_APPOINTMENT_ON_LIST', appointmentId);
        },
        commitDeleteAppointmentNoteOnList: async ({commit}, appointmentNoteId) => {
            await commit('DELETE_APPOINTMENT_NOTE_ON_LIST', appointmentNoteId);
        },

        commitDeleteAppointment: async ({commit}) => {
            await commit('DELETE_APPOINTMENT');
        },

        commitResetAppointments: async ({commit}) => {
            await commit('RESET_APPOINTMENTS');
        },

        commitResetAppointment: async ({commit}) => {
            await commit('RESET_APPOINTMENT');
        },

        commitOnSiteAddressDistance: async ({commit}, responseData) => {
            await commit('SET_ON_SITE_ADDRESS_DISTANCE', responseData.data);
        },

        commitSetAppointmentNotes: async ({commit}, responseData) => {
            await commit('SET_APPOINTMENT_NOTE', responseData.data);
        },


        commitSetAppointmentHistories: async ({commit}, responseData) => {
            await commit('SET_APPOINTMENT_HISTORIES', responseData.data);
        },

        commitSetAppointmentsReport: async ({commit}, responseData) => {
            await commit('SET_APPOINTMENTS_REPORT', responseData.data);
        },


        // get list of appointment by api call.
        getAppointments: async ({dispatch}, params = {}) => {
            const path = `v1/appointments`;

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetAppointments', response.data);
                dispatch('commitSetPaginateLinks', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // get single appointment by api call.
        getAppointment: async ({dispatch}, paramObj) => {
            const path = `v1/appointments/${paramObj.id}`;
            const params = paramObj.params ?? {};

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetAppointment', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // get appointment notes by api call.
        getAppointmentNotes: async ({dispatch}, paramObj) => {
            const path = `v1/appointment-notes`;
            const params = paramObj.params ?? {};

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetAppointmentNotes', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // get appointment history by api call.
        getAppointmentHistories: async ({dispatch}, paramObj) => {
            const path = `v1/appointment-histories`;
            const params = paramObj.params ?? {};

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetAppointmentHistories', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // create new appointment on list by api call.
        postAppointmentOnList: async ({dispatch}, data) => {
            const path = `v1/appointments`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateAppointmentOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // create new appointment by api call.
        postAppointment: async ({dispatch}, data) => {
            const path = `v1/appointments`;

            return axios.post(path, data).then((response) => {

                dispatch('commitCreateAppointment', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing appointment on list by api call.
        putAppointmentOnList: async ({dispatch}, dataObj) => {
            const path = `v1/appointments/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateAppointmentOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing appointment by api call.
        putAppointment: async ({dispatch}, dataObj) => {
            const path = `v1/appointments/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateAppointment', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update appointment status update price charge calculation by api call.
        putAppointmentStatusCharge: async (_, dataObj) => {
            const path = `v1/appointments/${dataObj.id}/status-pre-update-charges`;
            const data = dataObj.data;

            return axios.post(path, data).then((response) => {

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                    data: response.data.data
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update appointment status by api call.
        putAppointmentStatus: async (_, dataObj) => {
            const path = `v1/appointments/${dataObj.id}/status`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {

                return {
                    message: response.data.data,
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular appointment on list by api call.
        deleteAppointmentOnList: async ({dispatch}, id) => {
            const path = `v1/appointments/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteAppointmentOnList', id);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular appointment by api call.
        deleteAppointment: async ({dispatch}, id) => {
            const path = `v1/appointments/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteAppointment', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // reset appointments state.
        resetAppointments: async ({dispatch}) => {
            dispatch('commitResetAppointments');
        },

        // reset appointment state.
        resetAppointment: async ({dispatch}) => {
            dispatch('commitResetAppointment')
        },

        // get the address distance between customer and service provider by api call with post request.
        postOnSiteAddressDistance: async ({dispatch}, data) => {
            const path = `v1/appointments/on-site-address-distance`;

            return axios.post(path, data).then((response) => {
                dispatch('commitOnSiteAddressDistance', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // get pre store appointment data 
        preStoreAppointmentData: async (_, data) => {
            const path = `v1/appointments/pre-store-appointment-data`;

            return axios.post(path, data).then((response) => {
                // dispatch('commitOnSiteAddressDistance', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                    data: response.data.data
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },
        appointmentCreators: async (_, data) => {
            const path = `v1/appointment-creators`;

            return axios.post(path, data).then((response) => {


                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                    data: response.data.data
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },
        postAppointmentNotes: async (_, data) => {
            const path = `v1/appointment-notes`;


            return axios.post(path, data).then((response) => {


                return {
                    message: 'Appointment note created',
                    type: 'success',
                    status: response.status,
                    data: response.data.data
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },
        postAppointmentChargesDiscount: async (_, dataObj) => {
            const path = `v1/appointments/${dataObj.id}/appointment-charges/discount`;


            return axios.post(path, dataObj.data).then((response) => {


                return {
                    message: 'Appointment note created',
                    type: 'success',
                    status: response.status,
                    data: response.data.data
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },
        // update single existing appointment note by api call.
        putAppointmentNote: async (_, dataObj) => {
            const path = `v1/appointment-notes/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },
        // update single existing appointment note on list by api call.
        putAppointmentNoteOnList: async ({dispatch}, dataObj) => {
            const path = `v1/appointment-notes/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateAppointmentNoteOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },
        // delete a particular appointment  note by api call.
        deleteAppointmentNote: async (_, id) => {
            const path = `v1/appointment-notes/${id}`;

            return axios.delete(path).then((response) => {


                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },
        // delete a particular appointment Note on list by api call.
        deleteAppointmentNoteOnList: async ({dispatch}, id) => {
            const path = `v1/appointment-notes/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteAppointmentNoteOnList', id);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },
        postAppointmentCharge: async (_, data) => {
            const path = `v1/appointment-charges/store-list`;

            return axios.post(path, data).then((response) => {


                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                    data: response.data.data
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        postAppointmentTechnician: async (_, data) => {
            const path = `v1/technician-appointments`;

            return axios.post(path, data).then((response) => {


                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                    data: response.data.data
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        postAppointmentPayments: async (_, data) => {
            const path = `v1/appointment-payments`;

            return axios.post(path, data).then((response) => {

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                    data: response.data.data
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },


        postAppointmentHistory: (_, data) => {
            const path = `v1/appointment-histories`;

            return axios.post(path, data).then((response) => {

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                    data: response.data.data
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // post appointment invoice
        postAppointmentInvoice: async ({dispatch}, paramObj) => {
            const path = `v1/appointments/invoice/${paramObj.uuid}`;
            const params = paramObj.params ?? {};
            return axios.post(path, params).then((response) => {
                dispatch('commitSetAppointment', response.data);
                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                    data: response.data.data
                };
            }).catch((error) => {
                return responseErrorHandler(error);

            });
        },

        // appointment creation notify email
        postAppointmentCreationNotifyEmail: async (_, paramObj) => {
            const path = `v1/appointments/send-creation-notify/${paramObj.appointment}`;
            const params = paramObj.params ?? {};
            return axios.post(path, params).then((response) => {
                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                    data: response.data.data
                };
            }).catch((error) => {
                return responseErrorHandler(error);

            });
        },

        // appointment status update notify email
        postAppointmentStatusUpdateNotifyEmail: async (_, paramObj) => {
            const path = `v1/appointments/send-status-update-notify/${paramObj.id}`;
            const params = paramObj.params ?? {};
            return axios.post(path, params).then((response) => {
                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                    data: response.data.data
                };
            }).catch((error) => {
                return responseErrorHandler(error);

            });
        },
        // appointment creation notify email
        postAppointmentInvoiceRequestNotifyEmail: async (_, paramObj) => {
            const path = `v1/appointments/send-invoice-request-notify/${paramObj.appointment}`;
            const data = paramObj.data ?? {};
            return axios.post(path, data).then((response) => {
                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                    data: response.data.data
                };
            }).catch((error) => {
                return responseErrorHandler(error);

            });
        },
        // send payment request to customer notify email
        postAppointmentPaymentRequestNotifyEmail: async (_, paramObj) => {
            const path = `v1/appointments/send-appointment-payment-customer-request-notify/${paramObj.id}`;
            const data = paramObj.data ?? {};
            return axios.post(path, data).then((response) => {
                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                    data: response.data.data
                };
            }).catch((error) => {
                return responseErrorHandler(error);

            });
        },

        // export appointment customers.
        postExportAppointmentCustomerList: async (_, data) => {
            const path = `v1/appointments/exports/customers`;
            const config = {responseType: 'blob'};

            return axios.post(path, data, config).then(response => {
                if (response.status === 200) {
                    const url = window.URL.createObjectURL(new Blob([response.data]))
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', "Exported Appointment Customer List.csv")
                    document.body.appendChild(link)
                    link.click()
                }

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // get list of appointmentsReport by api call.
        getAppointmentsReport: async ({dispatch}, params = {}) => {
            const path = `v1/reports`;

            return axios.get(path, {params: params}).then(response => {

                dispatch('commitSetAppointmentsReport', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // get list of getTodayOngoingAppointments by api call.
        getTodayOngoingAppointments: async (_, params = {}) => {
            const path = `v1/appointments`;

            return axios.get(path, {params: params}).then(response => {
                // dispatch('commitSetAppointments', response.data);
                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                    data: response.data,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        postAppointmentPushNotification: async (_, dataObj) => {
            const path = `v1/push-gateways/send-pool-technician-notify/${dataObj.id}`;

            return axios.post(path, dataObj).then((response) => {
                return {
                    message: response?.message ?? '',
                    type: 'success',
                    status: response.status,
                    data: response.data.data
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },
        // send payment collection request to customer notify email
        postAppointmentPaymentCollectionRequestNotifyEmail: async (_, paramObj) => {
            const path = `v1/appointments/send-appointment-payment-customer-collection-notify/${paramObj.id}`;
            const data = paramObj.data ?? {};
            return axios.post(path, data).then((response) => {
                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                    data: response.data.data
                };
            }).catch((error) => {
                return responseErrorHandler(error);

            });
        },
    },
};
