import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {
        callRequests: [],
        paginateLinks: {},
        callRequest: {},
    },

    getters: {
        callRequests: (state) =>
        {
            return state.callRequests;
        },

        paginateLinks: (state) =>
        {
            return state.paginateLinks;
        },

        callRequest: (state) =>
        {
            return state.callRequest;
        },
    },
    mutations: {
        SET_CALL_REQUESTS (state, callRequests)
        {
            state.callRequests = callRequests;
        },

        SET_PAGINATE_LINKS (state, paginateLinks)
        {
            state.paginateLinks = paginateLinks;
        },

        SET_CALL_REQUEST (state, callRequest)
        {
            state.callRequest = callRequest;
        },

        CREATE_CALL_REQUEST_ON_LIST (state, callRequest)
        {
            state.callRequests.unshift(callRequest);
        },

        CREATE_CALL_REQUEST (state, callRequest)
        {
            state.callRequest = callRequest;
        },

        UPDATE_CALL_REQUEST_ON_LIST (state, callRequest)
        {
            let index = state.callRequests.findIndex(item => item.id === callRequest.id);
            state.callRequests.splice(index, 1, callRequest);
        },

        UPDATE_CALL_REQUEST (state, callRequest)
        {
            state.callRequest = callRequest;
        },

        DELETE_CALL_REQUEST_ON_LIST (state, callRequestId)
        {
            let index = state.callRequests.findIndex(item => item.id === callRequestId);
            state.callRequests.splice(index, 1);
        },

        DELETE_CALL_REQUEST (state)
        {
            state.callRequest = {};
        },

        RESET_CALL_REQUESTS (state)
        {
            state.callRequests = [];
        },

        RESET_CALL_REQUEST (state)
        {
            state.callRequest = {};
        },
    },
    actions: {
        commitSetCallRequests: async ({ commit }, responseData) =>
        {
            await commit('SET_CALL_REQUESTS', responseData.data);
        },

        commitSetPaginateLinks: async ({ commit }, responseData) =>
        {
            await commit('SET_PAGINATE_LINKS', responseData.meta);
        },

        commitSetCallRequest: async ({ commit }, responseData) =>
        {
            await commit('SET_CALL_REQUEST', responseData.data)
        },

        commitCreateCallRequestOnList: async ({ commit }, responseData) =>
        {
            await commit('CREATE_CALL_REQUEST_ON_LIST', responseData.data);
        },

        commitCreateCallRequest: async ({ commit }, responseData) =>
        {
            await commit('CREATE_CALL_REQUEST', responseData.data);
        },

        commitUpdateCallRequestOnList: async ({ commit }, responseData) =>
        {
            await commit('UPDATE_CALL_REQUEST_ON_LIST', responseData.data);
        },

        commitUpdateCallRequest: async ({ commit }, responseData) =>
        {
            await commit('UPDATE_CALL_REQUEST', responseData.data);
        },

        commitDeleteCallRequestOnList: async ({ commit },callRequestId) =>
        {
            await commit('DELETE_CALL_REQUEST_ON_LIST', callRequestId);
        },

        commitDeleteCallRequest: async ({ commit }) =>
        {
            await commit('DELETE_CALL_REQUEST');
        },

        commitResetCallRequests: async ({ commit }) =>
        {
            await commit('RESET_CALL_REQUESTS');
        },

        commitResetCallRequest: async ({ commit }) =>
        {
            await commit('RESET_CALL_REQUEST');
        },

        // get list of call request by api call.
        getCallRequests: async ({ dispatch }, params = {}) =>
        {
            const path = `v1/call-requests`;

            return axios.get(path, { params: params }).then(response =>
            {
                dispatch('commitSetCallRequests', response.data);
                dispatch('commitSetPaginateLinks', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error =>
            {
                return responseErrorHandler(error);
            });
        },

        // get single call request by api call.
        getCallRequest: async ({ dispatch }, paramObj) =>
        {
            const path = `v1/call-requests/${paramObj.id}`;
            const params = paramObj.params ?? {};

            return axios.get(path, { params: params }).then(response =>
            {
                dispatch('commitSetCallRequest', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error =>
            {
                return responseErrorHandler(error);
            });
        },

        // create new call request on list by api call.
        postCallRequestOnList: async ({ dispatch }, data) =>
        {
            const path = `v1/call-requests`;

            return axios.post(path, data).then((response) =>
            {
                dispatch('commitCreateCallRequestOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) =>
            {
                return responseErrorHandler(error);
            });
        },

        // create new call request by api call.
        postCallRequest: async ({ dispatch }, data) =>
        {
            const path = `v1/call-requests`;

            return axios.post(path, data).then((response) =>
            {

                dispatch('commitCreateCallRequest', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) =>
            {
                return responseErrorHandler(error);
            });
        },

        // update single existing call request on list by api call.
        putCallRequestOnList: async ({ dispatch }, dataObj) =>
        {
            const path = `v1/call-requests/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) =>
            {
                dispatch('commitUpdateCallRequestOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) =>
            {
                return responseErrorHandler(error);
            });
        },

        // update single existing call request by api call.
        putCallRequest: async ({ dispatch }, dataObj) =>
        {
            const path = `v1/call-requests/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) =>
            {
                dispatch('commitUpdateCallRequest', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) =>
            {
                return responseErrorHandler(error);
            });
        },

        // delete a particular call request on list by api call.
        deleteCallRequestOnList: async ({ dispatch }, id) =>
        {
            const path = `v1/call-requests/${id}`;

            return axios.delete(path).then((response) =>
            {
                dispatch('commitDeleteCallRequestOnList', id);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) =>
            {
                return responseErrorHandler(error);
            });
        },

        // delete a particular call request by api call.
        deleteCallRequest: async ({ dispatch }, id) =>
        {
            const path = `v1/call-requests/${id}`;

            return axios.delete(path).then((response) =>
            {
                dispatch('commitDeleteCallRequest', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) =>
            {
                return responseErrorHandler(error);
            });
        },


        // send callRequest to customer notify email
        postCallRequestNotifyEmail: async (_, paramObj) =>
        {
            const path = `v1/call-requests/send-creation-notify/${paramObj.id}`;
            const data = paramObj.data ?? {};
            return axios.post(path, data).then((response) =>
            {
                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                    data: response.data.data
                };
            }).catch((error) =>
            {
                return responseErrorHandler(error);

            });
        },

        // reset call requests state.
        resetCallRequests: async ({ dispatch }) =>
        {
            dispatch('commitResetCallRequests');
        },

        // reset call request state.
        resetCallRequest: async ({ dispatch }) =>
        {
            dispatch('commitResetCallRequest')
        },

    },
};
