// core vue
import { createApp } from 'vue';
import router from '@/router';
import store from '@/store';
import App from '@/App.vue';

//code package
import axios from "axios";
import DatePicker from 'vue3-datepicker';
import VueMultiselect from 'vue-multiselect';
import VueGoogleMaps from '@fawmi/vue-google-maps';
import VueToast from 'vue-toast-notification';
import Loading from 'vue-loading-overlay';
import tooltip from "../public/app-assets/js/scripts/tooltip";
// package css
import 'vue-toast-notification/dist/theme-sugar.css';//VueToast  css
import 'vue-loading-overlay/dist/vue-loading.css'; // loader spinner
import '../public/app-assets/css/tooltip.css';
import 'qalendar/dist/style.css';
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.headers.common['Client-Secret'] = process.env.VUE_APP_CLIENT_SECRET;

require('@/store/subscriber');

store.dispatch('appAuthLogin/commitAuthAttemptFromLocalStorage').then(() => {

    const vm = createApp(App);
    vm.use(VueToast);
    vm.use(Loading);
    vm.use(store);
    vm.use(router);
    vm.use(VueGoogleMaps, {
        load: {
            key: process.env.VUE_APP_GOOGLE_MAP_API_KEY,
        },
    });

    vm.component('VueMultiselect',VueMultiselect);
    vm.component('datepicker',DatePicker);
    vm.directive("tooltip", tooltip);
    vm.mount('#app');

});
