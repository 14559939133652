import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {
        contacts: [],
        paginateLinks: {},
        contact: {},
    },

    getters: {
        contacts: (state) => {
            return state.contacts;
        },

        paginateLinks: (state) => {
            return state.paginateLinks;
        },

        contact: (state) => {
            return state.contact;
        },
    },

    mutations: {
        SET_CONTACTS(state, contacts) {
            state.contacts = contacts;
        },

       SET_PAGINATE_LINKS(state, paginateLinks) {
            state.paginateLinks = paginateLinks;
       },

        SET_CONTACT(state, contact) {
            state.contact = contact;
        },

        CREATE_CONTACT_ON_LIST(state, contact) {
            state.contacts.unshift(contact);
        },

        CREATE_CONTACT(state, contact) {
            state.contact = contact;
        },

        UPDATE_CONTACT_ON_LIST(state, contact) {
            let index = state.contacts. findIndex(item => item.id === contact.id);
            state.contacts.splice(index, 1, contact);
        },

        UPDATE_CONTACT(state, contact) {
            state.contact = contact;
        },

        DELETE_CONTACT_ON_LIST(state, contactId) {
            let index = state.contacts.findIndex(item => item.id === contactId);
            state.contacts.splice(index, 1);
        },

        DELETE_CONTACT(state) {
            state.contact = {};
        },

        RESET_CONTACTS(state) {
            state.contacts = [];
        },

        RESET_CONTACT(state) {
            state.contact = {};
        },
    },

    actions: {
        commitSetContacts: async ({commit}, responseData) => {
            await commit('SET_CONTACTS', responseData.data);
        },

        commitSetPaginateLinks: async ({commit}, responseData) => {
            await commit('SET_PAGINATE_LINKS', responseData.meta);
        },

        commitSetContact: async ({commit}, responseData) => {
            await commit('SET_CONTACT', responseData.data)
        },

        commitCreateContactOnList: async ({commit}, responseData) => {
            await commit('CREATE_CONTACT_ON_LIST', responseData.data);
        },

        commitCreateContact: async ({commit}, responseData) => {
            await commit('CREATE_CONTACT', responseData.data);
        },

        commitUpdateContactOnList: async ({commit}, responseData) => {
            await commit('UPDATE_CONTACT_ON_LIST', responseData.data);
        },

        commitUpdateContact: async ({commit}, responseData) => {
            await commit('UPDATE_CONTACT', responseData.data);
        },

        commitDeleteContactOnList: async ({commit}, contactId) => {
            await commit('DELETE_CONTACT_ON_LIST', contactId);
        },

        commitDeleteContact: async ({commit}) => {
            await commit('DELETE_CONTACT');
        },

        commitResetContacts: async ({commit}) => {
            await commit('RESET_CONTACTS');
        },

        commitResetContact: async ({commit}) => {
            await commit('RESET_CONTACT');
        },

        // get list of contact by api call.
        getContacts: async ({dispatch}, params = {}) => {
            const path = `v1/contacts`;

            return  axios.get(path, {params: params}).then(response => {
                dispatch('commitSetContacts', response.data);
                dispatch('commitSetPaginateLinks', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // get single contact by api call.
        getContact: async ({dispatch}, paramObj) => {
            const path = `v1/contacts/${paramObj.id}`;
            const params = paramObj.params ?? {};

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetContact', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // create new contact on list by api call.
        postContactOnList: async ({dispatch}, data) => {
            const path = `v1/contacts`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateContactOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // create new contact by api call.
        postContact: async ({dispatch}, data) => {
            const path = `v1/contacts`;

            return axios.post(path, data).then((response) => {

                dispatch('commitCreateContact', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing contact on list by api call.
        putContactOnList: async ({dispatch}, dataObj) => {
            const path = `v1/contacts/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateContactOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing contact by api call.
        putContact: async ({dispatch}, dataObj) => {
            const path = `v1/contacts/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateContact', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular contact on list by api call.
        deleteContactOnList: async ({dispatch}, id) => {
            const path = `v1/contacts/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteContactOnList', id);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular contact by api call.
        deleteContact: async ({dispatch}, id) => {
            const path = `v1/contacts/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteContact', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // reset contacts state.
        resetContacts: async ({dispatch}) => {
            dispatch('commitResetContacts');
        },

        // reset contact state.
        resetContact: async ({dispatch}) => {
            dispatch('commitResetContact')
        },

    },
};
