import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    actions: {
        logout: ({dispatch}) => {
            const path = `v1/logout-token`;

            return axios.post(path).then((response) => {
                dispatch('commitLogoutAttempt');
                return {
                    message: "Logout Successful",
                    type: "success",
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        commitLogoutAttempt: async ({commit}) => {
            await commit('appAuthLogin/SET_AUTH_USER', { id : null, first_name : null, last_name : null, email : null, role : null, avatar: null }, {root: true});
            await commit('appAuthLogin/SET_AUTH_FRANCHISEE', { id : null }, {root: true});
            await commit('appAuthLogin/SET_AUTH_TOKEN', { access_token: null, token_type: null}, {root: true});
            await commit('appAuthLogin/SET_AUTH_PERMISSIONS', [], {root: true});
        },
    },
};