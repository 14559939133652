import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {
        shortTextTemplates: [],
        paginateLinks: {},
        shortTextTemplate: {},
    },

    getters: {
        shortTextTemplates: (state) => {
            return state.shortTextTemplates;
        },

        paginateLinks: (state) => {
            return state.paginateLinks;
        },

        shortTextTemplate: (state) => {
            return state.shortTextTemplate;
        },
    },

    mutations: {
        SET_SHORT_TEXT_TEMPLATES(state, shortTextTemplates) {
            state.shortTextTemplates = shortTextTemplates;
        },

        SET_PAGINATE_LINKS(state, paginateLinks) {
            state.paginateLinks = paginateLinks;
        },

        SET_SHORT_TEXT_TEMPLATE(state, shortTextTemplate) {
            state.shortTextTemplate = shortTextTemplate;
        },

        CREATE_SHORT_TEXT_TEMPLATE_ON_LIST(state, shortTextTemplate) {
            state.shortTextTemplates.unshift(shortTextTemplate);
        },

        CREATE_SHORT_TEXT_TEMPLATE(state, shortTextTemplate) {
            state.shortTextTemplate = shortTextTemplate;
        },

        UPDATE_SHORT_TEXT_TEMPLATE_ON_LIST(state, shortTextTemplate) {
            let index = state.shortTextTemplates.findIndex(item => item.id === shortTextTemplate.id);
            state.shortTextTemplates.splice(index, 1, shortTextTemplate);
        },

        UPDATE_SHORT_TEXT_TEMPLATE(state, shortTextTemplate) {
            state.shortTextTemplate = shortTextTemplate;
        },

        DELETE_SHORT_TEXT_TEMPLATE_ON_LIST(state, shortTextTemplateId) {
            let index = state.shortTextTemplates.findIndex(item => item.id === shortTextTemplateId);
            state.shortTextTemplates.splice(index, 1);
        },

        DELETE_TEXT_TEMPLATE(state) {
            state.shortTextTemplate = {};
        },

        RESET_SHORT_TEXT_TEMPLATES(state) {
            state.shortTextTemplates = [];
        },

        RESET_SHORT_TEXT_TEMPLATE(state) {
            state.shortTextTemplate = {};
        },
    },

    actions: {
        commitSetShortTextTemplates: async ({commit}, responseData) => {
            await commit('SET_SHORT_TEXT_TEMPLATES', responseData.data);
        },

        commitSetPaginateLinks: async ({commit}, responseData) => {
            await commit('SET_PAGINATE_LINKS', responseData.meta);
        },

        commitSetShortTextTemplate: async ({commit}, responseData) => {
            await commit('SET_SHORT_TEXT_TEMPLATE', responseData.data)
        },

        commitCreateShortTextTemplateOnList: async ({commit}, responseData) => {
            await commit('CREATE_SHORT_TEXT_TEMPLATE_ON_LIST', responseData.data);
        },

        commitCreateShortTextTemplate: async ({commit}, responseData) => {
            await commit('CREATE_SHORT_TEXT_TEMPLATE', responseData.data);
        },

        commitUpdateShortTextTemplateOnList: async ({commit}, responseData) => {
            await commit('UPDATE_SHORT_TEXT_TEMPLATE_ON_LIST', responseData.data);
        },

        commitUpdateShortTextTemplate: async ({commit}, responseData) => {
            await commit('UPDATE_SHORT_TEXT_TEMPLATE', responseData.data);
        },

        commitDeleteShortTextTemplateOnList: async ({commit}, shortTextTemplateId) => {
            await commit('DELETE_SHORT_TEXT_TEMPLATE_ON_LIST', shortTextTemplateId);
        },

        commitDeleteShortTextTemplate: async ({commit}) => {
            await commit('DELETE_TEXT_TEMPLATE');
        },

        commitResetShortTextTemplates: async ({commit}) => {
            await commit('RESET_SHORT_TEXT_TEMPLATES');
        },

        commitResetShortTextTemplate: async ({commit}) => {
            await commit('RESET_SHORT_TEXT_TEMPLATE');
        },

        // get list of short text template by api call.
        getShortTextTemplates: async ({dispatch}, params = {}) => {
            const path = `v1/short-texts`;

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetShortTextTemplates', response.data);
                dispatch('commitSetPaginateLinks', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // get single short text template by api call.
        getShortTextTemplate: async ({dispatch}, paramObj) => {
            const path = `v1/short-texts/${paramObj.id}`;
            const params = paramObj.params ?? {};

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetShortTextTemplate', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // create new short text template on list by api call.
        postShortTextTemplateOnList: async ({dispatch}, data) => {
            const path = `v1/short-texts`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateShortTextTemplateOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // create new short text template by api call.
        postShortTextTemplate: async ({dispatch}, data) => {
            const path = `v1/short-texts`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateShortTextTemplate', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing short text template on list by api call.
        putShortTextTemplateOnList: async ({dispatch}, dataObj) => {
            const path = `v1/short-texts/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateShortTextTemplateOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing short text template by api call.
        putShortTextTemplate: async ({dispatch}, dataObj) => {
            const path = `v1/short-texts/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateShortTextTemplate', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular short text template on list by api call.
        deleteShortTextTemplateOnList: async ({dispatch}, id) => {
            const path = `v1/short-texts/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteShortTextTemplateOnList', id);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular short text template by api call.
        deleteShortTextTemplate: async ({dispatch}, id) => {
            const path = `v1/short-texts/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteShortTextTemplate', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // reset short text template state.
        resetShortTextTemplates: async ({dispatch}) => {
            dispatch('commitResetShortTextTemplates');
        },

        // reset short text template state.
        resetShortTextTemplate: async ({dispatch}) => {
            dispatch('commitResetShortTextTemplate')
        },

    },

};