import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {
        customers: [],
        paginateLinks: {},
        customer: {},
        referrerCustomer: {},
    },

    getters: {
        customers: (state) => {
            return state.customers;
        },

        paginateLinks: (state) => {
            return state.paginateLinks;
        },

        customer: (state) => {
            return state.customer;
        },
        referrerCustomer: (state) =>
        {
            return state.referrerCustomer;
        },
    },

    mutations: {
        SET_CUSTOMERS(state, customers) {
            state.customers = customers;
        },

        SET_PAGINATE_LINKS(state, paginateLinks) {
            state.paginateLinks = paginateLinks;
        },

        SET_CUSTOMER(state, customer) {
            state.customer = customer;
        },

        SET_REFERRER_CUSTOMER (state, referrerCustomer)
        {
            state.referrerCustomer = referrerCustomer;
        },

        CREATE_CUSTOMER_ON_LIST(state, customer) {
            state.customers.unshift(customer);
        },

        CREATE_CUSTOMER(state, customer) {
            state.customer = customer;
        },

        UPDATE_CUSTOMER_ON_LIST(state, customer) {
            let index = state.customers.findIndex(item => item.id === customer.id);
            state.customers.splice(index, 1, customer);
        },

        UPDATE_CUSTOMER(state, customer) {
            state.customer = customer;
        },

        DELETE_CUSTOMER_ON_LIST(state, customerId) {
            let index = state.customers.findIndex(item => item.id === customerId);
            state.customers.splice(index, 1);
        },

        DELETE_CUSTOMER(state) {
            state.customer = {};
        },

        RESET_CUSTOMERS(state) {
            state.customers = [];
        },

        RESET_CUSTOMER(state) {
            state.customer = {};
        },
    },

    actions: {
        commitSetCustomers: async ({commit}, responseData) => {
            await commit('SET_CUSTOMERS', responseData.data);
        },

        commitSetPaginateLinks: async ({commit}, responseData) => {
            await commit('SET_PAGINATE_LINKS', responseData.meta);
        },

        commitSetCustomer: async ({commit}, responseData) => {
            await commit('SET_CUSTOMER', responseData.data)
        },
        commitSetReferrerCustomer: async ({ commit }, responseData) =>
        {
            await commit('SET_REFERRER_CUSTOMER', responseData.data)
        },

        commitCreateCustomerOnList: async ({commit}, responseData) => {
            await commit('CREATE_CUSTOMER_ON_LIST', responseData.data);
        },
        commitCreateCustomer: async ({commit}, responseData) => {
            await commit('CREATE_CUSTOMER', responseData.data);
        },

        commitUpdateCustomerOnList: async ({commit}, responseData) => {
            await commit('UPDATE_CUSTOMER_ON_LIST', responseData.data);
        },
        commitUpdateCustomer: async ({commit}, responseData) => {

            await commit('UPDATE_CUSTOMER', responseData.data);
        },

        commitDeleteCustomerOnList: async ({commit}, customerId) => {
            await commit('DELETE_CUSTOMER_ON_LIST', customerId);
        },

        commitDeleteCustomer: async ({commit}) => {
            await commit('DELETE_CUSTOMER');
        },

        commitResetCustomers: async ({commit}) => {
            await commit('RESET_CUSTOMERS');
        },

        commitResetCustomer: async ({commit}) => {
            await commit('RESET_CUSTOMER');
        },

        // get list of customer by api call.
        getCustomers: async ({dispatch}, params) => {
            const path = `v1/customers`;

            return  axios.get(path, {params: params}).then(response => {
                dispatch('commitSetCustomers', response.data);
                dispatch('commitSetPaginateLinks', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // get single customer
        getCustomer: async ({dispatch}, paramObj) => {
            const path = `v1/customers/${paramObj.id}`;
            const params = paramObj.params ?? {};

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetCustomer', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // create new customer on list
        postCustomerOnList: async ({dispatch}, data) => {
            const path = `v1/customers`;
            return axios.post(path, data).then((response) => {
                dispatch('commitCreateCustomerOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // create new customer
        postCustomer: async ({dispatch}, data) => {
            const path = `v1/customers`;
            return axios.post(path, data).then((response) => {
                console.log(response.data);
                dispatch('commitCreateCustomer', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing customer on list by api call.
        putCustomerOnList: async ({dispatch}, dataObj) => {
            const path = `v1/customers/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateCustomerOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing customer by api call.
        putCustomer: async ({dispatch}, dataObj) => {
            const path = `v1/customers/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {

                dispatch('commitUpdateCustomer', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular customer on list by api call.
        deleteCustomerOnList: async ({dispatch}, id) => {
            const path = `v1/customers/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteCustomerOnList', id);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular customer by api call.
        deleteCustomer: async ({dispatch}, id) => {
            const path = `v1/customers/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteCustomer', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // reset customers
        resetCustomers: async ({dispatch}) => {
            dispatch('commitResetCustomers');
        },
        // reset customer
        resetCustomer: async ({dispatch}) => {
            dispatch('commitResetCustomer');
        },

        // get single referrer (customer)
        getReferrer: async ({dispatch}, paramObj) => {
            const path = `v1/customers/referrer/${paramObj.referrerCode}`;
            const params = paramObj.params ?? {};

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetReferrerCustomer', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // export appointment customers.
        postExportCustomerList: async (_, data) => {
            const path = `v1/customers/exports`;

            const config = { responseType:'blob'};

            return  axios.post(path, data, config).then(response => {
                if (response.status === 200) {
                    const url = window.URL.createObjectURL(new Blob([response.data]))
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download',"Exported Customer List.csv")
                    document.body.appendChild(link)
                    link.click()
                }

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        }
    },
};
