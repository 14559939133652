import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {
        blogBlogTags: [],
        paginateLinks: {},
        blogBlogTag: {},
    },

    getters: {
        blogBlogTags: (state) => {
            return state.blogBlogTags;
        },

        paginateLinks: (state) => {
            return state.paginateLinks;
        },

        blogBlogTag: (state) => {
            return state.blogBlogTag;
        },
    },
    mutations: {
        SET_BLOG_BLOG_TAGS(state, blogBlogTags) {
            state.blogBlogTags = blogBlogTags;
        },

        SET_PAGINATE_LINKS(state, paginateLinks) {
            state.paginateLinks = paginateLinks;
        },

        SET_BLOG_BLOG_TAG(state, blogBlogTag) {
            state.blogBlogTag = blogBlogTag;
        },

        CREATE_BLOG_BLOG_TAG_ON_LIST(state, blogBlogTag) {
            state.blogBlogTags.unshift(blogBlogTag);
        },

        CREATE_BLOG_BLOG_TAG(state, blogBlogTag) {
            state.blogBlogTag = blogBlogTag;
        },

        UPDATE_BLOG_BLOG_TAG_ON_LIST(state, blogBlogTag) {
            let index = state.blogBlogTags.findIndex(item => item.id === blogBlogTag.id);
            state.blogBlogTags.splice(index, 1, blogBlogTag);
        },

        UPDATE_BLOG_BLOG_TAG(state, blogBlogTag) {
            state.blogBlogTag = blogBlogTag;
        },

        DELETE_BLOG_BLOG_TAG_ON_LIST(state, blogBlogTagId) {
            let index = state.blogBlogTags.findIndex(item => item.id === blogBlogTagId);
            state.blogBlogTags.splice(index, 1);
        },

        DELETE_BLOG_BLOG_TAG(state) {
            state.blogBlogTag = {};
        },

        RESET_BLOG_BLOG_TAGS(state) {
            state.blogBlogTags = [];
        },

        RESET_BLOG_BLOG_TAG(state) {
            state.blogBlogTag = {};
        },
    },
    actions: {
        commitSetBlogBlogTags: async ({commit}, responseData) => {
            await commit('SET_BLOG_BLOG_TAGS', responseData.data);
        },

        commitSetPaginateLinks: async ({commit}, responseData) => {
            await commit('SET_PAGINATE_LINKS', responseData.meta);
        },

        commitSetBlogBlogTag: async ({commit}, responseData) => {
            await commit('SET_BLOG_BLOG_TAG', responseData.data)
        },

        commitCreateBlogBlogTagOnList: async ({commit}, responseData) => {
            await commit('CREATE_BLOG_BLOG_TAG_ON_LIST', responseData.data);
        },

        commitCreateBlogBlogTag: async ({commit}, responseData) => {
            await commit('CREATE_BLOG_BLOG_TAG', responseData.data);
        },

        commitUpdateBlogBlogTagOnList: async ({commit}, responseData) => {
            await commit('UPDATE_BLOG_BLOG_TAG_ON_LIST', responseData.data);
        },

        commitUpdateBlogBlogTag: async ({commit}, responseData) => {
            await commit('UPDATE_BLOG_BLOG_TAG', responseData.data);
        },

        commitDeleteBlogBlogTagOnList: async ({commit}, blogBlogTagId) => {
            await commit('DELETE_BLOG_BLOG_TAG_ON_LIST', blogBlogTagId);
        },

        commitDeleteBlogBlogTag: async ({commit}) => {
            await commit('DELETE_BLOG_BLOG_TAG');
        },

        commitResetBlogBlogTags: async ({commit}) => {
            await commit('RESET_BLOG_BLOG_TAGS');
        },

        commitResetBlogBlogTag: async ({commit}) => {
            await commit('RESET_BLOG_BLOG_TAG');
        },

        // get list of blogBlogTag by api call.
        getBlogBlogTags: async ({dispatch}, params = {}) => {
            const path = `v1/blog-blog-tags`;

            return  axios.get(path, {params: params}).then(response => {
                dispatch('commitSetBlogBlogTags', response.data);
                dispatch('commitSetPaginateLinks', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // get single blogBlogTag by api call.
        getBlogBlogTag: async ({dispatch}, paramObj) => {
            const path = `v1/blog-blog-tags/${paramObj.id}`;
            const params = paramObj.params ?? {};

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetBlogBlogTag', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // create new blogBlogTag on list by api call.
        postBlogBlogTagOnList: async ({dispatch}, data) => {
            const path = `v1/blog-blog-tags`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateBlogBlogTagOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // create new blogBlogTag by api call.
        postBlogBlogTag: async ({dispatch}, data) => {
            const path = `v1/blog-blog-tags`;

            return axios.post(path, data).then((response) => {

                dispatch('commitCreateBlogBlogTag', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // create new blogBlogTag list by api call.
        postBlogBlogTagStoreList: async (_, data) => {
            const path = `v1/blog-blog-tags/store-list`;

            return axios.post(path, data).then((response) => {
                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                    response: response,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing blogBlogTag on list by api call.
        putBlogBlogTagOnList: async ({dispatch}, dataObj) => {
            const path = `v1/blog-blog-tags/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateBlogBlogTagOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing blogBlogTag by api call.
        putBlogBlogTag: async ({dispatch}, dataObj) => {
            const path = `v1/blog-blog-tags/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateBlogBlogTag', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular blogBlogTag on list by api call.
        deleteBlogBlogTagOnList: async ({dispatch}, id) => {
            const path = `v1/blog-blog-tags/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteBlogBlogTagOnList', id);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular blogBlogTag by api call.
        deleteBlogBlogTag: async ({dispatch}, id) => {
            const path = `v1/blog-blog-tags/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteBlogBlogTag', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // reset blogBlogTags state.
        resetBlogBlogTags: async ({dispatch}) => {
            dispatch('commitResetBlogBlogTags');
        },

        // reset blogBlogTag state.
        resetBlogBlogTag: async ({dispatch}) => {
            dispatch('commitResetBlogBlogTag')
        },

    },
};
