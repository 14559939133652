import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {
        eventCalendars: [],
        paginateLinks: {},
        eventCalendar: {},
    },

    getters: {
        eventCalendars: (state) => {
            return state.eventCalendars;
        },

        paginateLinks: (state) => {
            return state.paginateLinks;
        },

        eventCalendar: (state) => {
            return state.eventCalendar;
        },
    },
    mutations: {
        SET_EVENT_CALENDARS(state, eventCalendars) {
            state.eventCalendars = eventCalendars;
        },

        SET_PAGINATE_LINKS(state, paginateLinks) {
            state.paginateLinks = paginateLinks;
        },

        SET_EVENT_CALENDAR(state, eventCalendar) {
            state.eventCalendar = eventCalendar;
        },

        CREATE_EVENT_CALENDAR_ON_LIST(state, eventCalendar) {
            state.eventCalendars.unshift(eventCalendar);
        },

        CREATE_EVENT_CALENDAR(state, eventCalendar) {
            state.eventCalendar = eventCalendar;
        },

        UPDATE_EVENT_CALENDAR_ON_LIST(state, eventCalendar) {
            let index = state.eventCalendars.findIndex(item => item.id === eventCalendar.id);
            state.eventCalendars.splice(index, 1, eventCalendar);
        },

        UPDATE_EVENT_CALENDAR(state, eventCalendar) {
            state.eventCalendar = eventCalendar;
        },

        DELETE_EVENT_CALENDAR_ON_LIST(state, eventCalendarId) {
            let index = state.eventCalendars.findIndex(item => item.id === eventCalendarId);
            state.eventCalendars.splice(index, 1);
        },

        DELETE_EVENT_CALENDAR(state) {
            state.eventCalendar = {};
        },

        RESET_EVENT_CALENDARS(state) {
            state.eventCalendars = [];
        },

        RESET_EVENT_CALENDAR(state) {
            state.eventCalendar = {};
        },
    },
    actions: {
        commitSetEventCalendars: async ({commit}, responseData) => {
            await commit('SET_EVENT_CALENDARS', responseData.data);
        },

        commitSetPaginateLinks: async ({commit}, responseData) => {
            await commit('SET_PAGINATE_LINKS', responseData.meta);
        },

        commitSetEventCalendar: async ({commit}, responseData) => {
            await commit('SET_EVENT_CALENDAR', responseData.data)
        },

        commitCreateEventCalendarOnList: async ({commit}, responseData) => {
            await commit('CREATE_EVENT_CALENDAR_ON_LIST', responseData.data);
        },

        commitCreateEventCalendar: async ({commit}, responseData) => {
            await commit('CREATE_EVENT_CALENDAR', responseData.data);
        },

        commitUpdateEventCalendarOnList: async ({commit}, responseData) => {
            await commit('UPDATE_EVENT_CALENDAR_ON_LIST', responseData.data);
        },

        commitUpdateEventCalendar: async ({commit}, responseData) => {
            await commit('UPDATE_EVENT_CALENDAR', responseData.data);
        },

        commitDeleteEventCalendarOnList: async ({commit}, eventCalendarId) => {
            await commit('DELETE_EVENT_CALENDAR_ON_LIST', eventCalendarId);
        },

        commitDeleteEventCalendar: async ({commit}) => {
            await commit('DELETE_EVENT_CALENDAR');
        },

        commitResetEventCalendars: async ({commit}) => {
            await commit('RESET_EVENT_CALENDARS');
        },

        commitResetEventCalendar: async ({commit}) => {
            await commit('RESET_EVENT_CALENDAR');
        },

        // get list of event calendar by api call.
        getEventCalendars: async ({dispatch}, params = {}) => {
            const path = `v1/event-calendars`;

            return  axios.get(path, {params: params}).then(response => {
                dispatch('commitSetEventCalendars', response.data);
                dispatch('commitSetPaginateLinks', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // get single event calendar by api call.
        getEventCalendar: async ({dispatch}, paramObj) => {
            const path = `v1/event-calendars/${paramObj.id}`;
            const params = paramObj.params ?? {};

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetEventCalendar', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // create new event calendar on list by api call.
        postEventCalendarOnList: async ({dispatch}, data) => {
            const path = `v1/event-calendars`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateEventCalendarOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // create new event calendar by api call.
        postEventCalendar: async ({dispatch}, data) => {
            const path = `v1/event-calendars`;

            return axios.post(path, data).then((response) => {

                dispatch('commitCreateEventCalendar', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing event calendar on list by api call.
        putEventCalendarOnList: async ({dispatch}, dataObj) => {
            const path = `v1/event-calendars/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateEventCalendarOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing event calendar by api call.
        putEventCalendar: async ({dispatch}, dataObj) => {
            const path = `v1/event-calendars/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateEventCalendar', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular event calendar on list by api call.
        deleteEventCalendarOnList: async ({dispatch}, id) => {
            const path = `v1/event-calendars/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteEventCalendarOnList', id);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular event calendar by api call.
        deleteEventCalendar: async ({dispatch}, id) => {
            const path = `v1/event-calendars/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteEventCalendar', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // reset event calendar state.
        resetEventCalendars: async ({dispatch}) => {
            dispatch('commitResetEventCalendars');
        },

        // reset event calendar state.
        resetEventCalendar: async ({dispatch}) => {
            dispatch('commitResetEventCalendar')
        },

    },
};
