import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {
        discountStoreList: {},
    },

    getters: {

    },
    mutations: {

        CREATE_DISCOUNT_STORE_LIST (state, discountStoreList)
        {
            state.discountStoreList = discountStoreList;
        },

    },
    actions: {

        commitCreateDiscountStoreList: async ({ commit }, responseData) =>
        {
            await commit('CREATE_DISCOUNT_STORE_LIST', responseData.data);
        },


        // create new discount store list by api call.
        postDiscountStoreList: async ({ dispatch }, data) =>
        {
            const path = `v1/discounts/store-list`;

            return axios.post(path, data).then((response) =>
            {

                dispatch('commitCreateDiscountStoreList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) =>
            {
                return responseErrorHandler(error);
            });
        },


    },
};
