import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {
        orderJobs: [],
        paginateLinks: {},
        orderJob: {},
    },

    getters: {
        orderJobs: (state) => {
            return state.orderJobs;
        },

        paginateLinks: (state) => {
            return state.paginateLinks;
        },

        orderJob: (state) => {
            return state.orderJob;
        },
    },
    mutations: {
        SET_ORDER_JOBS(state, orderJobs) {
            state.orderJobs = orderJobs;
        },

        SET_PAGINATE_LINKS(state, paginateLinks) {
            state.paginateLinks = paginateLinks;
        },

        SET_ORDER_JOB(state, orderJob) {
            state.orderJob = orderJob;
        },

        CREATE_ORDER_JOB_ON_LIST(state, orderJob) {
            state.orderJobs.unshift(orderJob);
        },

        CREATE_ORDER_JOB(state, orderJob) {
            state.orderJob = orderJob;
        },

        UPDATE_ORDER_JOB_ON_LIST(state, orderJob) {
            let index = state.orderJobs.findIndex(item => item.id === orderJob.id);
            state.orderJobs.splice(index, 1, orderJob);
        },

        UPDATE_ORDER_JOB(state, orderJob) {
            state.orderJob = orderJob;
        },

        DELETE_ORDER_JOB_ON_LIST(state, orderCreatorId) {
            let index = state.orderJobs.findIndex(item => item.id === orderCreatorId);
            state.orderJobs.splice(index, 1);
        },

        DELETE_ORDER_JOB(state) {
            state.orderJob = {};
        },

        RESET_ORDER_JOBS(state) {
            state.orderJobs = [];
        },

        RESET_ORDER_JOB(state) {
            state.orderJob = {};
        },
    },
    actions: {
        commitSetOrderJobs: async ({commit}, responseData) => {
            await commit('SET_ORDER_JOBS', responseData.data);
        },

        commitSetPaginateLinks: async ({commit}, responseData) => {
            await commit('SET_PAGINATE_LINKS', responseData.meta);
        },

        commitSetOrderJob: async ({commit}, responseData) => {
            await commit('SET_ORDER_JOB', responseData.data)
        },

        commitCreateOrderJobOnList: async ({commit}, responseData) => {
            await commit('CREATE_ORDER_JOB_ON_LIST', responseData.data);
        },

        commitCreateOrderJob: async ({commit}, responseData) => {
            await commit('CREATE_ORDER_JOB', responseData.data);
        },

        commitUpdateOrderJobOnList: async ({commit}, responseData) => {
            await commit('UPDATE_ORDER_JOB_ON_LIST', responseData.data);
        },

        commitUpdateOrderJob: async ({commit}, responseData) => {
            await commit('UPDATE_ORDER_JOB', responseData.data);
        },

        commitDeleteOrderJobOnList: async ({commit}, orderCreatorId) => {
            await commit('DELETE_ORDER_JOB_ON_LIST', orderCreatorId);
        },

        commitDeleteOrderJob: async ({commit}) => {
            await commit('DELETE_ORDER_JOB');
        },

        commitResetOrderJobs: async ({commit}) => {
            await commit('RESET_ORDER_JOBS');
        },

        commitResetOrderJob: async ({commit}) => {
            await commit('RESET_ORDER_JOB');
        },

        // get list of orderJob by api call.
        getOrderJobs: async ({dispatch}, params = {}) => {
            const path = `v1/order-jobs`;

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetOrderJobs', response.data);
                dispatch('commitSetPaginateLinks', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // get single orderJob by api call.
        getOrderJob: async ({dispatch}, paramObj) => {
            const path = `v1/order-jobs/${paramObj.id}`;
            const params = paramObj.params ?? {};

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetOrderJob', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // create new orderJob on list by api call.
        postOrderJobOnList: async ({dispatch}, data) => {
            const path = `v1/order-jobs`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateOrderJobOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // create new orderJob by api call.
        postOrderJob: async ({dispatch}, data) => {
            const path = `v1/order-jobs`;

            return axios.post(path, data).then((response) => {

                dispatch('commitCreateOrderJob', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing orderJob on list by api call.
        putOrderJobOnList: async ({dispatch}, dataObj) => {
            const path = `v1/order-jobs/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateOrderJobOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing orderJob by api call.
        putOrderJob: async ({dispatch}, dataObj) => {
            const path = `v1/order-jobs/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateOrderJob', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular orderJob on list by api call.
        deleteOrderJobOnList: async ({dispatch}, id) => {
            const path = `v1/order-jobs/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteOrderJobOnList', id);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular orderJob by api call.
        deleteOrderJob: async ({dispatch}, id) => {
            const path = `v1/order-jobs/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteOrderJob', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // reset orderJobs state.
        resetOrderJobs: async ({dispatch}) => {
            dispatch('commitResetOrderJobs');
        },

        // reset orderJob state.
        resetOrderJob: async ({dispatch}) => {
            dispatch('commitResetOrderJob')
        },

    },
};
