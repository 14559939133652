import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {
        teamLeads: [],
        paginateLinks: {},
        teamLead: {},
    },

    getters: {
        teamLeads: (state) => {
            return state.teamLeads;
        },

        paginateLinks: (state) => {
            return state.paginateLinks;
        },

        teamLead: (state) => {
            return state.teamLead;
        },
    },
    mutations: {
        SET_TEAM_LEADS(state, teamLeads) {
            state.teamLeads = teamLeads;
        },

        SET_PAGINATE_LINKS(state, paginateLinks) {
            state.paginateLinks = paginateLinks;
        },

        SET_TEAM_LEAD(state, teamLead) {
            state.teamLead = teamLead;
        },

        CREATE_TEAM_LEAD_ON_LIST(state, teamLead) {
            state.teamLeads.unshift(teamLead);
        },

        CREATE_TEAM_LEAD(state, teamLead) {
            state.teamLead = teamLead;
        },

        UPDATE_TEAM_LEAD_ON_LIST(state, teamLead) {
            let index = state.teamLeads.findIndex(item => item.id === teamLead.id);
            state.teamLeads.splice(index, 1, teamLead);
        },

        UPDATE_TEAM_LEAD(state, teamLead) {
            state.teamLead = teamLead;
        },

        DELETE_TEAM_LEAD_ON_LIST(state, teamLeadId) {
            let index = state.teamLeads.findIndex(item => item.id === teamLeadId);
            state.teamLeads.splice(index, 1);
        },

        DELETE_TEAM_LEAD(state) {
            state.teamLead = {};
        },

        RESET_TEAM_LEADS(state) {
            state.teamLeads = [];
        },

        RESET_TEAM_LEAD(state) {
            state.teamLead = {};
        },
    },
    actions: {
        commitSetTeamLeads: async ({commit}, responseData) => {
            await commit('SET_TEAM_LEADS', responseData.data);
        },

        commitSetPaginateLinks: async ({commit}, responseData) => {
            await commit('SET_PAGINATE_LINKS', responseData.meta);
        },

        commitSetTeamLead: async ({commit}, responseData) => {
            await commit('SET_TEAM_LEAD', responseData.data)
        },

        commitCreateTeamLeadOnList: async ({commit}, responseData) => {
            await commit('CREATE_TEAM_LEAD_ON_LIST', responseData.data);
        },

        commitCreateTeamLead: async ({commit}, responseData) => {
            await commit('CREATE_TEAM_LEAD', responseData.data);
        },

        commitUpdateTeamLeadOnList: async ({commit}, responseData) => {
            await commit('UPDATE_TEAM_LEAD_ON_LIST', responseData.data);
        },

        commitUpdateTeamLead: async ({commit}, responseData) => {
            await commit('UPDATE_TEAM_LEAD', responseData.data);
        },

        commitDeleteTeamLeadOnList: async ({commit}, teamLeadId) => {
            await commit('DELETE_TEAM_LEAD_ON_LIST', teamLeadId);
        },

        commitDeleteTeamLead: async ({commit}) => {
            await commit('DELETE_TEAM_LEAD');
        },

        commitResetTeamLeads: async ({commit}) => {
            await commit('RESET_TEAM_LEADS');
        },

        commitResetTeamLead: async ({commit}) => {
            await commit('RESET_TEAM_LEAD');
        },

        // get list of team lead by api call.
        getTeamLeads: async ({dispatch}, params = {}) => {
            const path = `v1/team-leads`;

            return  axios.get(path, {params: params}).then(response => {
                dispatch('commitSetTeamLeads', response.data);
                dispatch('commitSetPaginateLinks', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // get single team lead by api call.
        getTeamLead: async ({dispatch}, paramObj) => {
            const path = `v1/team-leads/${paramObj.id}`;
            const params = paramObj.params ?? {};

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetTeamLead', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // create new team lead on list by api call.
        postTeamLeadOnList: async ({dispatch}, data) => {
            const path = `v1/team-leads`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateTeamLeadOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // create new team lead by api call.
        postTeamLead: async ({dispatch}, data) => {
            const path = `v1/team-leads`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateTeamLead', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing team lead on list by api call.
        putTeamLeadOnList: async ({dispatch}, dataObj) => {
            const path = `v1/team-leads/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateTeamLeadOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing team lead by api call.
        putTeamLead: async ({dispatch}, dataObj) => {
            const path = `v1/team-leads/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateTeamLead', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular team lead on list by api call.
        deleteTeamLeadOnList: async ({dispatch}, id) => {
            const path = `v1/team-leads/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteTeamLeadOnList', id);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular team lead by api call.
        deleteTeamLead: async ({dispatch}, id) => {
            const path = `v1/team-leads/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteTeamLead', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // reset team leads state.
        resetTeamLeads: async ({dispatch}) => {
            dispatch('commitResetTeamLeads');
        },

        // reset team lead state.
        resetTeamLead: async ({dispatch}) => {
            dispatch('commitResetTeamLead')
        },

    },
};
