import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {
       
    },

    getters: {
      

    },
    mutations: {
      

    },
    actions: {
    
        // create new appointment schedule by api call.
        postAppointmentSchedule: async (_, data) => {
            const path = `v1/appointment-schedules`;

            return axios.post(path, data).then((response) => {

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

       
    },
};
