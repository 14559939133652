import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {
        referrals: [],
        paginateLinks: {},
        referral: {},
    },

    getters: {
        referrals: (state) => {
            return state.referrals;
        },

        paginateLinks: (state) => {
            return state.paginateLinks;
        },

        referral: (state) => {
            return state.referral;
        },
    },
    mutations: {
        SET_REFERRALS(state, referrals) {
            state.referrals = referrals;
        },

        SET_PAGINATE_LINKS(state, paginateLinks) {
            state.paginateLinks = paginateLinks;
        },

        SET_REFERRAL(state, referral) {
            state.referral = referral;
        },

        CREATE_REFERRAL_ON_LIST(state, referral) {
            state.referrals.unshift(referral);
        },

        CREATE_REFERRAL(state, referral) {
            state.referral = referral;
        },

        UPDATE_REFERRAL_ON_LIST(state, referral) {
            let index = state.referrals.findIndex(item => item.id === referral.id);
            state.referrals.splice(index, 1, referral);
        },

        UPDATE_REFERRAL(state, referral) {
            state.referral = referral;
        },

        DELETE_REFERRAL_ON_LIST(state, franchiseeId) {
            let index = state.referrals.findIndex(item => item.id === franchiseeId);
            state.referrals.splice(index, 1);
        },

        DELETE_REFERRAL(state) {
            state.referral = {};
        },

        RESET_REFERRALS(state) {
            state.referrals = [];
        },

        RESET_REFERRAL(state) {
            state.referral = {};
        },
    },
    actions: {
        commitSetReferrals: async ({commit}, responseData) => {
            await commit('SET_REFERRALS', responseData.data);
        },

        commitSetPaginateLinks: async ({commit}, responseData) => {
            await commit('SET_PAGINATE_LINKS', responseData.meta);
        },

        commitSetReferral: async ({commit}, responseData) => {
            await commit('SET_REFERRAL', responseData.data)
        },

        commitCreateReferralOnList: async ({commit}, responseData) => {
            await commit('CREATE_REFERRAL_ON_LIST', responseData.data);
        },

        commitCreateReferral: async ({commit}, responseData) => {
            await commit('CREATE_REFERRAL', responseData.data);
        },

        commitUpdateReferralOnList: async ({commit}, responseData) => {
            await commit('UPDATE_REFERRAL_ON_LIST', responseData.data);
        },

        commitUpdateReferral: async ({commit}, responseData) => {
            await commit('UPDATE_REFERRAL', responseData.data);
        },

        commitDeleteReferralOnList: async ({commit}, franchiseeId) => {
            await commit('DELETE_REFERRAL_ON_LIST', franchiseeId);
        },

        commitDeleteReferral: async ({commit}) => {
            await commit('DELETE_REFERRAL');
        },

        commitResetReferrals: async ({commit}) => {
            await commit('RESET_REFERRALS');
        },

        commitResetReferral: async ({commit}) => {
            await commit('RESET_REFERRAL');
        },

        // get list of referral by api call.
        getReferrals: async ({dispatch}, params = {}) => {
            const path = `v1/referrals`;

            return  axios.get(path, {params: params}).then(response => {
                dispatch('commitSetReferrals', response.data);
                dispatch('commitSetPaginateLinks', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // get single referral by api call.
        getReferral: async ({dispatch}, paramObj) => {
            const path = `v1/referrals/${paramObj.id}`;
            const params = paramObj.params ?? {};

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetReferral', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // create new referral on list by api call.
        postReferralOnList: async ({dispatch}, data) => {
            const path = `v1/referrals`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateReferralOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // create new referral by api call.
        postReferral: async ({dispatch}, data) => {
            const path = `v1/referrals`;

            return axios.post(path, data).then((response) => {

                dispatch('commitCreateReferral', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing referral on list by api call.
        putReferralOnList: async ({dispatch}, dataObj) => {
            const path = `v1/referrals/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateReferralOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing referral by api call.
        putReferral: async ({dispatch}, dataObj) => {
            const path = `v1/referrals/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateReferral', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular referral on list by api call.
        deleteReferralOnList: async ({dispatch}, id) => {
            const path = `v1/referrals/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteReferralOnList', id);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular referral by api call.
        deleteReferral: async ({dispatch}, id) => {
            const path = `v1/referrals/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteReferral', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // reset referrals state.
        resetReferrals: async ({dispatch}) => {
            dispatch('commitResetReferrals');
        },

        // reset referral state.
        resetReferral: async ({dispatch}) => {
            dispatch('commitResetReferral')
        },

    },
};
